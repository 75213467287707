import { t } from '@lingui/macro';
import BaseModal from 'component/BaseComponent/BaseModal';
import { trans } from 'config/LinguiConfig';
import React from 'react';
import Routes from 'router/routes.js';
import { isEmpty } from 'lodash';

class PreviewZnsTemplateModal extends BaseModal {
  constructor(props) {
    super(props);
    const params = this.getDataFromRouter();
    if (isEmpty(params)) this.redirectTo(Routes.znsTemplates.path);
    this.state = {
      open: true,
      loading: false,
      title: trans(t`Mẫu`) + ' ' + params?.id,
      ...params,
    };
    this.goPathWhenClose = Routes.znsTemplates.path;
  }

  renderBody() {
    let { previewUrl } = this.state;
    return (
      <div className="flex flex-1 bg-[#ffff] w-full h-full">
        <iframe
          src={previewUrl}
          className="w-full h-full min-h-[500px] rounded"
          title="description"
        />
      </div>
    );
  }
}

export default PreviewZnsTemplateModal;
