import Grid from '@material-ui/core/Grid/Grid';
import { AssetImage } from 'assets/Asset';
import classNames from 'classnames';

import Button from 'MUI_Kit/components/CustomButtons/Button';

import CustomPagination from 'MUI_Kit/components/Pagination/CustomPagination';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { trans } from 'config/LinguiConfig';
import { xPath } from 'testingClasses';
import { t } from '@lingui/macro';

class ListDataModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { currentPage: 1 };
  }

  renderLoading = () => {
    const { loadingTitle, loading, data } = this.props;
    if (!loading || data.length > 0) return null;
    return (
      <Grid item xs={12}>
        <div className={classNames('content', ' flex-center-col', xPath.stateView.loading)}>
          <img src={AssetImage.Loading} alt="loading" className="listdata-img" />
          <div style={{ height: 30 }} />
          <div className="description-text">{loadingTitle || 'Đang tải dữ liệu...'}</div>
        </div>
      </Grid>
    );
  };

  renderEmpty = () => {
    const { emptyTitle, emptyButton } = this.props;
    const { data: oData, keyword } = this.props;
    const data = this.filterData(oData, keyword);
    if (data.length === 0)
      return (
        <Grid item xs={12}>
          <div className={classNames('content', ' flex-center-col', xPath.stateView.empty)}>
            <img src={AssetImage.Empty} alt="empty" className="listdata-img" />
            <div style={{ height: 30 }} />
            <div className="description-text">{emptyTitle || 'Không có dữ liệu'}</div>
            <div style={{ height: 20 }} />
            {emptyButton && emptyButton()}
          </div>
        </Grid>
      );
    return null;
  };

  renderError = () => {
    const { error, errorButton, errorAction, errorTitle } = this.props;
    if (error)
      return (
        <Grid item xs={12}>
          <div className={classNames('content', 'flex-center-col', xPath.stateView.error)}>
            <img src={AssetImage.Error} alt="error" className="listdata-img" />
            <div style={{ height: 30 }} />
            <div className="description-text">
              <div>{errorTitle || 'Có lỗi xảy ra'}</div>
              <div>{t`Vui lòng nhấn Ctrl+F5 hoặc Cmd+F5 để tải lại trang`}</div>
            </div>
            <div style={{ height: 20 }} />
            {errorButton && (
              <Button color="info" onClick={errorAction} round>
                {errorButton}
              </Button>
            )}
          </div>
        </Grid>
      );
    return null;
  };

  goToPage = (page) => {
    if (this.props.goToPage) {
      this.props.goToPage(page);
      this.setState({ currentPage: page });
    }
  };

  handleChange = (event) => {
    let val = event.target ? event.target.value : event;
    this.setState({
      value: val,
    });

    //todo search
    this.props.search(val);
  };

  renderSearch = () => {
    const { value } = this.state;
    const { linkBelowSearch } = this.props;
    if (this.props.search)
      return (
        <Grid item xs={12} container spacing={1}>
          <Grid item xs={12}>
            <div
              className="Search-label"
              style={{
                padding: 3,
                paddingRight: 10,
                height: 38,
                width: '100%',
                borderRadius: 7,
              }}
              onClick={this.props.onClick}>
              <Button color="transparent" justIcon size="sm" round>
                <i className="fal fa-search" style={{ opacity: 0.5 }} />
              </Button>
              <input
                placeholder="Tìm kiếm"
                value={value}
                className="Search-input"
                style={{ display: 'block' }}
                onChange={this.handleChange}
              />
              {value && value.length > 0 && (
                <Button
                  color="transparent"
                  justIcon
                  size="sm"
                  round
                  onClick={() => this.handleChange('')}>
                  <i className="fal fa-times" />
                </Button>
              )}
            </div>
          </Grid>
          <Grid item xs={12}>
            {linkBelowSearch && (
              <div className="link">
                <Link to={linkBelowSearch.url}>{linkBelowSearch.display}</Link>
              </div>
            )}
          </Grid>
        </Grid>
      );
    return null;
  };

  renderData = () => {
    let { data: oData, renderItem, keyword, paging, heightList, search } = this.props;
    paging = paging || {};
    const data = this.filterData(oData, keyword);
    const height = heightList
      ? heightList + (paging.total_page === 1 ? 50 : 0) + (!search ? 63 : 0)
      : 'auto';
    if (data.length > 0)
      return [
        <Grid
          item
          xs={12}
          container
          spacing={2}
          key={'list'}
          style={{ height: height, overflow: 'auto' }}
          alignContent="flex-start">
          {data.map((item, index) => renderItem(item, index))}
        </Grid>,
        <Grid item xs={12} key={'pagination'}>
          <CustomPagination
            totalPage={paging && paging.total_page ? paging.total_page : 1}
            goToPage={this.goToPage}
            currentPage={this.state.currentPage}
          />
        </Grid>,
      ];

    return null;
  };

  render() {
    return [
      this.renderSearch(),
      this.renderLoading() || this.renderError() || this.renderEmpty() || this.renderData(),
    ];
  }

  filterData = (data, keyword) => {
    // if (!data) return [];
    // let new_keyworld = change_alias(keyword);
    // let nData = data.filter((item) =>
    // {
    // 	if (item.name)
    // 		return change_alias(item.name).includes(new_keyworld);
    // 	else if (item.domain)
    // 		return change_alias(item.domain).includes(new_keyworld);
    // 	else if (item.oa_id)
    // 		return change_alias(item.oa_id).includes(new_keyworld);
    // 	else if (item.username)
    // 		return change_alias(item.username).includes(new_keyworld);
    //
    // });
    // return nData;
    return data;
  };
}

ListDataModal.propTypes = {
  data: PropTypes.array,
  content: PropTypes.string,
  emptyTitle: PropTypes.string,
  emptyButton: PropTypes.string,
  emptyUrl: PropTypes.string,
  keyword: PropTypes.string,
  search: PropTypes.func,
  single: PropTypes.bool,
};

export default ListDataModal;
