import { t } from '@lingui/macro';
import { trans } from 'config/LinguiConfig';
import isEmpty from 'lodash/isEmpty';
import React, { memo } from 'react';
import { dangerColor, primaryColor, successColor, warningColor } from 'variables/color';
import CustomChart from '../components/CustomChart/CustomChart';
import { CHART_DATE_FORMAT, getTimeUnit } from '../utils';

const getOption = (min, max) => ({
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
        scaleLabel: {
          labelString: t`Thời gian`,
        },
        type: 'time',
        time: {
          min: new Date(min * 1000),
          max: new Date(max * 1000),
          unit: getTimeUnit(min, max),
          displayFormats: {
            day: CHART_DATE_FORMAT,
            hour: 'HH:ss',
            month: 'MM/YYYY',
          },
        },
      },
    ],
    yAxes: [
      {
        id: 'y',
        stacked: true,
        scaleLabel: {
          labelString: t`Tổng số đơn hàng`,
        },
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
      },
    ],
  },
  annotation: {
    annotations: [
      {
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y',
        value: '0',
        borderWidth: 1,
        borderDash: [2, 2],
      },
    ],
    drawTime: 'afterDraw', // (default)
  },
  tooltips: {
    mode: 'index',
  },
});

const color = [primaryColor, warningColor, successColor, warningColor, primaryColor, dangerColor];
const normalizedData = (data) => {
  if (isEmpty(data) && !data?.datasets) return {};

  data.datasets.forEach((item, index) => {
    item.backgroundColor = color[index];
  });

  data.labels.forEach((item, index) => {
    data.labels[index] = new Date(data.labels[index] * 1000);
  });

  console.log(' normalizedData ', data);
  return data;
};

const getColumns = () => [
  {
    Header: t`Thời gian`,
    width: 100,
    accessor: 'x',
  },
  {
    Header: t`Tổng số đơn hàng`,
    width: 100,
    accessor: 'y',
  },
];

const Order = (props) => {
  return (
    <CustomChart
      normalizedData={normalizedData}
      getOption={getOption}
      columns={getColumns()}
      {...props}
    />
  );
};

export default memo(Order);
