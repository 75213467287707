import 'config/SentryConfig';
import { PureComponent } from 'react';
import { handleConnection } from 'redux/action';
import connect from 'react-redux/es/connect/connect';
import DV from 'variables/DV';
import { trans } from 'config/LinguiConfig';
import { t } from '@lingui/macro';

class Network extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { network: null };
  }

  updateNetwork = (showAlert = true) => {
    if (!navigator.onLine && showAlert) DV.showNotify(t`Vui lòng kiểm tra lại kết nối`, 'warning');
    if (navigator.onLine && showAlert) DV.showNotify(t`Đã kết nối với hệ thống`, 'success');
    DV.network = navigator.onLine;
    this.props.handleConnection(navigator.onLine);
  };

  componentDidMount() {
    window.addEventListener('online', () => this.updateNetwork());
    window.addEventListener('offline', () => this.updateNetwork());
    this.updateNetwork(false);
  }

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleConnection: (value) => dispatch(handleConnection(value)),
  };
};

export default connect(null, mapDispatchToProps)(Network);
