import { t } from '@lingui/macro';
import MenuItem from '@material-ui/core/MenuItem';
import { getPickAddresses } from 'api/api';
import { trans } from 'config/LinguiConfig';
import { handleApi } from 'helper/helper';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import CustomSelect from 'MUI_Kit/components/CustomSelect/CustomSelect';
import React from 'react';
import Routes, { createPath } from 'router/routes';
import DV from 'variables/DV';

export default class SelectPickAddress extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      data: [],
    };
  }

  async componentDidMount() {
    if (this.props.disable) return null;

    this.setState({ loading: true, error: null });
    let { data, error } = await handleApi(getPickAddresses());
    this.setState({ loading: false });

    if (error) {
      this.setState({ error, data: [] });
      DV.showNotify(error, 'danger');
    } else this.setState({ data });
    this.props.setDefaultPickAddress && this.props.setDefaultPickAddress(data);
  }

  render() {
    const { data, error, loading } = this.state;
    const { disabled, onChange, value, label } = this.props;

    let text = label || t`Điểm lấy hàng:`;
    if (error) text = t`Có lỗi xảy ra...`;
    if (loading) text = t`Đang tải điểm lấy hàng...`;

    if (disabled)
      return (
        <CustomInput
          labelText={text}
          inputProps={{
            value,
            disabled,
            startAdornment: (
              <i
                className="fas fa-map-marker-alt"
                style={{
                  color: '#fc4349',
                  margin: '0 5px',
                  fontSize: '20px',
                }}
              />
            ),
          }}
        />
      );

    return (
      <CustomSelect
        label={text}
        data={data || []}
        value={value || ''}
        addButton={{
          name: t`Tạo điểm lấy hàng mới`,
          onClick: () => this.props.redirectToModal(Routes.pickupAddress.path + createPath),
        }}
        onChange={onChange}
        renderItem={this.renderAddressItem}
        disabled={disabled || loading || error}
      />
    );
  }

  renderAddressItem = (item, key, classes) => {
    const { id, full_address } = item;
    return (
      <MenuItem
        key={key}
        value={id}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}>
        <div className="flex-start-row">
          <i
            className="fas fa-map-marker-alt only-button"
            style={{
              color: '#fc4349',
              margin: '0 5px',
              fontSize: '20px',
            }}
          />
          {full_address}
        </div>
      </MenuItem>
    );
  };
}
