import { Chip } from "@doopage/react-ui-kit";
import Grid from "@material-ui/core/Grid/Grid";
import classNames from "classnames";
import React, { memo } from "react";
import { getActionGroup } from "screen/ScenarioFlow/utils";
import { useFlagKey } from "../../../addOnComponent/FlagrProvider";

const SelectActions = ({ scenario, addAction }) => {
  const { actions = [] } = scenario;
  const { checkIsEnable } = useFlagKey();
  return (
    <div className="overflow-y-auto overflow-x-hidden">
      <Grid container spacing={2} className="group-actions">
        {getActionGroup().map(({ name, actions, flagKey }, index) => {
          if (!checkIsEnable(flagKey)) return null;
          return (
            <Grid item key={index}>
              {!!name && (
                <div className="opacity-70 mb-2 mt-3 font-bold border-b border-gray-200">
                  {name}
                </div>
              )}
              <Grid container spacing={2}>
                {actions.map((item) => {
                  const { key, label, icon, isPro, image, flagKey } = item;
                  if (!checkIsEnable(flagKey)) return null;
                  return (
                    <Grid item key={key} xs={4}>
                      <div
                        className="action flex flex-col items-center justify-center"
                        onClick={() => {
                          addAction(item);
                        }}>
                        <div className="flex-center-col">
                          {!!icon && <i className={classNames("fad", icon, "icon")} />}
                          {!!image && (
                            <img src={image} className="w-[30px] mb-1 filter grayscale" />
                          )}
                          <div className="label">{label}</div>
                        </div>
                        {isPro && (
                          <Chip className="pro-icon" color="primary" square>
                            PRO
                          </Chip>
                        )}
                      </div>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default memo(SelectActions);
