import { Button, Input, showErrorSnackbar, showSuccessSnackbar, Space } from "@doopage/react-ui-kit";
import "@doopage/react-ui-kit/dist/doopage-ui-kit.css";
import { t } from "@lingui/macro";
import { useQuery } from "@redux-requests/react";
import BaseModal from "component/BaseComponent/BaseModal";
import produce from "immer";
import { set } from "lodash";
import React, { createRef, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getScenario, getScenarios, updateScenario } from "redux/action/apiAction";
import Routes from "router/routes";
import ChannelCondition from "screen/ScenarioFlow/modals/conditions/ChannelCondition";
import TimeCondition from "screen/ScenarioFlow/modals/conditions/TimeCondition/TimeCondition";
import { showDebugObj } from "screen/ScenarioFlow/utils";
import { PLATFORM_SUPPORT_AUTOMATION } from "variables/permission";
import CommentCondition from "./conditions/CommentCondition";
import MessageCondition from "./conditions/MessageCondition";
import MmeLinkCondition from "./conditions/MmeLinkCondition";
import OrderCondition from "./conditions/OrderCondition";
import OrderStatusCondition from "./conditions/OrderStatusCondition";
import SegmentCondition from "./conditions/SegmentCondition";
import TagCondition from "./conditions/TagCondition";
import { resetRequests } from "@redux-requests/core";
import DisableTagSegmentModal from "../../../component/Modal/DisableTagSegmentModal";
import Modal from "@material-ui/core/Modal";
import { useHistory } from "react-router-dom";
import DV from "../../../variables/DV";
import RatingCondition from "./conditions/RatingCondition";
import FollowZaloOACondition from "./conditions/FollowZaloOACondition";
import CallApiCondition from "./conditions/CallApiCondition";
import GetStartMessengerCondition from "./conditions/GetStartMessengerCondition";
import Separator from "../../../component/Separator";
import IceBreakersInstagramCondition from "./conditions/IceBreakersInstagramCondition";
import SelectPriority from "../components/SelectPriority";
import { FlagKeyCheck } from "../../../addOnComponent/FlagrProvider";

class ScenarioConditionModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: t`Cập nhật điều kiện kích hoạt`,
      loading: false,
      name: ""
    };
    this.refreshOnClose = true;
    this.goPathWhenClose = Routes.scenario.path;
    this.maxWidth = !showDebugObj ? "sm" : "lg";
    this.scenarioDetail = createRef();
    this.modal = createRef();
  }

  onClose = () => {
    this.props.dispatch(resetRequests([getScenario]));
  };

  renderBody() {
    return (
      <div style={{ height: "80vh" }}>
        <Content
          closeModal={this.close}
          ref={this.modal}
          id={this.props.match.params.id}
          onLoading={(value) => this.setState({ loading: value })}
        />
      </div>
    );
  }

  renderFooter() {
    return (
      <Button
        onClick={() => {
          this.modal.current.submit();
        }}
        color="info"
        loading={this.state.loading}>
        {t`Hoàn tất`}
      </Button>
    );
  }
}

export default connect()(ScenarioConditionModal);

export const Content = forwardRef(({ closeModal, id, onLoading }, ref) => {
  const { data, loading, error } = useQuery({ type: getScenario });
  const [scenario, setScenario] = useState({
    name: "",
    trigger_conditions: {}
  });
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const isProUser = useMemo(() => {
    const isProFromMainSubscription =
      DV.currentSubscription?.main_subscription?.automation_marketing_active;
    const isPropFromCouponSubscription = !!DV.currentSubscription?.coupon_subscriptions?.find(
      (sub) => sub.automation_marketing_active
    );
    return isProFromMainSubscription || isPropFromCouponSubscription;
  }, []);

  let {
    channels = [],
    time_range = {},
    time_cycle = {},
    comment = {},
    referral = {},
    message = {},
    segments = {},
    tags = {},
    order_status = {},
    create_order = {},
    rating = {},
    follow_oa = {},
    api = {},
    started_messenger = {},
    started_instagram = {},
    persistent_menu_messenger = {},
    priority
  } = scenario?.trigger_conditions;

  const filterByPlatform = x => PLATFORM_SUPPORT_AUTOMATION.indexOf(x.channel_type) >= 0;
  channels = channels.filter(filterByPlatform);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) closeModal();
  }, [error]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const { time_cycle, ...rest } = scenario.trigger_conditions;
      const date = new Date();
      const offset = date.getTimezoneOffset();
     
      const { error } = await dispatch(
        updateScenario(id, {
          name: scenario.name,
          short_code: scenario.short_code,
          trigger_conditions: { ...rest, time_cycle: { ...time_cycle, timezone_offset: offset } }
        })
      );

      if (!error) {
        closeModal();
        showSuccessSnackbar(t`Cập nhật thành công`);
      } else showErrorSnackbar(error);

      dispatch(getScenarios());
    }
  }));

  useEffect(() => {
    dispatch(getScenario(id));
  }, [id]);

  useEffect(() => {
    if (data) setScenario(data);
  }, [data]);

  const update = useCallback((path, value) => {
    if (
      (path.includes("trigger_conditions.segments") || path.includes("trigger_conditions.tags")) &&
      !isProUser
    )
      setOpen(true);
    else
      setScenario(
        produce((draftState) => {
          set(draftState, path, value);
        })
      );
  }, []);

  const platforms = channels.map((item) => item?.platform);

  return (
    <>
      <Space direction="vertical">
        <Input
          label={t`Tên kịch bản`}
          fullWidth
          onChange={(e) => {
            update("name", e.target.value);
          }}
          value={scenario.name}
        />
        <Input
          startIcon={"/"}
          label={t`Mã gọi nhanh`}
          fullWidth
          onChange={(e) => {
            update("short_code", e.target.value);
          }}
          value={scenario.short_code}
        />
        <SelectPriority onChange={update} priority={priority} />
        <ChannelCondition onChange={update} channels={channels} filterBy={filterByPlatform} />
        <TimeCondition onChange={update} timeRange={time_range} timeCycle={time_cycle} />
        <CommentCondition onChange={update} comment={comment} show={platforms.includes("fb")} />
        <MmeLinkCondition
          onChange={update}
          referral={referral}
          channels={channels}
          show={platforms.includes("fb")}
        />
        <RatingCondition onChange={update} rating={rating} show={platforms.includes("shopee")} />
        <MessageCondition onChange={update} message={message} />
        <SegmentCondition onChange={update} segments={segments} />
        <TagCondition onChange={update} tags={tags} />
        <FlagKeyCheck flagKey={"SALE_ENABLED"} flagKeyDefaultValue={false}>
          <OrderStatusCondition onChange={update} orderStatus={order_status} />
          <OrderCondition onChange={update} createOrder={create_order} />
        </FlagKeyCheck>
        <FollowZaloOACondition
          onChange={update}
          followOA={follow_oa}
          show={platforms.includes("zalo_oa")}
        />
        <CallApiCondition onChange={update} api={api} id={id} />
        <GetStartMessengerCondition
          onChange={update}
          startedMessenger={started_messenger}
          persistentMenuMessenger={persistent_menu_messenger}
          show={platforms.includes("fb")}
          channels={channels}
        />
        <IceBreakersInstagramCondition
          onChange={update}
          startedInstagram={started_instagram}
          show={platforms.includes("instagram")}
          channels={channels}
        />
        <Separator height={10} />
      </Space>
      <Modal open={isOpen} onClose={() => setOpen(false)}>
        <DisableTagSegmentModal onClose={() => setOpen(false)} history={history} />
      </Modal>
    </>
  );
});
