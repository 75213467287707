import { t } from '@lingui/macro';
import { Grid } from '@material-ui/core';
import React from 'react';
import ButtonAdd from './ButtonAdd';
import { Button, Input } from '@doopage/react-ui-kit';

const ListConversationStarter = ({ value, onChange, ...props }) => {
  const handleChange = (index) => {
    return (newValue) => {
      value[index] = newValue;
      onChange(value);
    };
  };
  const handleAdd = () => {
    onChange([...value, '']);
  };
  return (
    <>
      {value.map((item, index) => (
        <Grid item xs={12} key={index}>
          <Button color="white" style={{ color: '#000' }} disableRipple fullWidth>
            <Input
              className="w-full text-center border-b"
              placeholder={t`Nhập gợi ý`}
              value={item}
              onChange={(e) => handleChange(index)(e.target.value)}
            />
          </Button>
        </Grid>
      ))}
      <Grid item xs={12}>
        <ButtonAdd label={t`Thêm gợi ý`} onClick={handleAdd} />
      </Grid>
    </>
  );
};

export default ListConversationStarter;
