import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import Check from '@material-ui/icons/Check';
import Clear from '@material-ui/icons/Clear';
// nodejs library that concatenates classes
import classNames from 'classnames';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import customInputStyle from '../../assets/jss/material-dashboard-pro-react/components/customInputStyle.jsx';
import Danger from '../Typography/Danger';
import Success from '../Typography/Success';
import Warning from '../Typography/Warning';

class CustomInput extends PureComponent {
  state = { isValid: null, helpText: null };

  focus = () => {
    this.input.focus();
  };

  error = () => {
    this.setState({ isValid: false });
  };

  render() {
    const {
      errorText,
      successText,
      required,
      classes,
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      error,
      white,
      inputRootCustomClasses,
      success,
      className,
      validInput,
      nextAction,
      helpText,
      instantValidate,
      containerStyle,
    } = this.props;
    const { isValid } = this.state;

    const labelClasses = classNames({
      [' ' + classes.labelRootError]: error,
      [' ' + classes.labelRootSuccess]: success && !error,
    });
    const underlineClasses = classNames({
      [classes.underlineError]: error || isValid === false,
      [classes.underlineSuccess]: (success && !error) || isValid === true,
      [classes.underline]: true,
      [classes.whiteUnderline]: white,
    });
    const marginTop = classNames({
      [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
    });
    const inputClasses = classNames(
      {
        [classes.input]: true,
        [classes.whiteInput]: white,
      },
      className,
    );

    let formControlClasses;
    if (formControlProps !== undefined) {
      formControlClasses = classNames(formControlProps.className, classes.formControl);
    } else {
      formControlClasses = classes.formControl;
    }

    let feedbackClasses = classes.feedback;
    if (inputProps !== undefined) {
      if (inputProps.endAdornment !== undefined) {
        feedbackClasses = feedbackClasses + ' ' + classes.feedbackRight;
      }
    }

    return (
      <div style={{ position: 'relative', width: '100%' }}>
        <FormControl
          fullWidth
          {...formControlProps}
          className={formControlClasses}
          style={containerStyle}>
          {labelText && (
            <InputLabel
              className={classes.labelRoot + ' ' + labelClasses}
              htmlFor={id}
              {...labelProps}>
              {labelText + (required || inputProps.required ? ' *' : '')}
            </InputLabel>
          )}
          <Input
            classes={{
              input: inputClasses,
              root: marginTop,
              disabled: classes.disabled,
              underline: underlineClasses,
            }}
            id={id}
            {...inputProps}
            onChange={(event) => {
              inputProps.onChange(event);
              this.setState({ isValid: null });
              if (!!event.target.value && instantValidate && validInput) {
                let showValid = validInput(inputProps.value);
                this.setState({ showValid });
              }
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter' && validInput) {
                let isValid = validInput(inputProps.value);
                this.setState({ isValid: isValid });
                if (isValid && nextAction) {
                  nextAction();
                }
              }
            }}
            inputRef={(input) => (this.input = input)}
            onBlur={() => {
              if (this.props.onBlur) this.props.onBlur();
              if (validInput) {
                let isValid = validInput(inputProps.value);
                this.setState({ isValid });
              }
            }}
          />
          {error || isValid === false ? (
            <Clear className={feedbackClasses + ' ' + classes.labelRootError} />
          ) : success || isValid === true ? (
            <Check className={feedbackClasses + ' ' + classes.labelRootSuccess} />
          ) : null}
        </FormControl>
        <div style={{ marginTop: -17, marginBottom: 5 }}>
          {(error || isValid === false) && errorText && <Danger help>{errorText}</Danger>}
          {(success || isValid === true) && successText && <Success help>{successText}</Success>}
          {helpText && !isValid && <Warning help>{helpText}</Warning>}
        </div>
      </div>
    );
  }
}

CustomInput.propTypes = {
  classes: PropTypes.object.isRequired,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  id: PropTypes.string,
  inputProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  successText: PropTypes.string,
  errorText: PropTypes.string,
  helpText: PropTypes.string,
  white: PropTypes.bool,
  validInput: PropTypes.func,
  nextAction: PropTypes.func,
  required: PropTypes.bool,
  instantValidate: PropTypes.bool,
};

CustomInput.defaultProps = {
  instantValidate: true,
  containerStyle: {},
};
export default withStyles(customInputStyle)(CustomInput);
