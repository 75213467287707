import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import Grow from '@material-ui/core/Grow/Grow';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import Paper from '@material-ui/core/Paper/Paper';
import Popper from '@material-ui/core/Popper/Popper';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Zoom from '@material-ui/core/Zoom/Zoom';
import React, { PureComponent } from 'react';
import Button from '../../../../MUI_Kit/components/CustomButtons/Button';
import { findColorName } from '../../../../variables/color';

class SegmentSelect extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) return;
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    const { segment, isTookThisInbox } = this.props;
    const caret = {
      transition: 'all 150ms ease-in',
      display: 'inline-block',
      width: '0',
      height: '0',
      marginLeft: '4px',
      verticalAlign: 'middle',
      borderTop: '4px solid',
      borderRight: '4px solid transparent',
      borderLeft: '4px solid transparent',
    };
    const caretActive = open ? { transform: 'rotate(180deg)' } : {};

    let { color, name } = segment;
    let display_name = name
      .split(' ')
      .map((word) => word.charAt(0))
      .join('');

    return (
      <div>
        <Tooltip title={name} TransitionComponent={Zoom}>
          <Button
            buttonRef={(node) => {
              this.anchorEl = node;
            }}
            aria-owns={open ? 'menu-list-grow' : null}
            aria-haspopup="true"
            onClick={(event) => {
              event.stopPropagation();
              if (isTookThisInbox) this.setState((state) => ({ open: !state.open }));
            }}
            round
            noneUpcase={false}
            color={findColorName(color)}
            style={{ padding: 5, width: 60 }}>
            {display_name}
            {isTookThisInbox && <b style={{ ...caret, ...caretActive }} />}
          </Button>
        </Tooltip>
        {isTookThisInbox && this.renderSegmentMenu()}
      </div>
    );
  }

  renderSegmentMenu = () => {
    const { open } = this.state;
    return (
      <Popper
        open={open}
        anchorEl={this.anchorEl}
        transition
        style={{ zIndex: 10000, marginTop: 10 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            id="menu-list-grow"
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}>
            <Paper>
              <ClickAwayListener onClickAway={this.handleClose}>
                <List>
                  {this.renderSegmentItem({
                    id: -1,
                    name: 'Chưa xác định',
                    color: '#999999',
                  })}
                  {this.props.segments.map((item) => this.renderSegmentItem(item))}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    );
  };

  renderSegmentItem = (item) => {
    const { name, color, id } = item;
    const { blockInbox, updateSegment } = this.props;
    return (
      <ListItem
        button
        style={{ color, fontSize: 14 }}
        onClick={async (event) => {
          event.stopPropagation();
          this.handleClose(event);

          if (id === -2) blockInbox();
          else updateSegment(item);
        }}>
        {name}
      </ListItem>
    );
  };
}

export default SegmentSelect;
