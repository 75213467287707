import {t} from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import {getStaffs, GetListCallAgents, patchCallAgent} from 'api/api';
import Avatar from 'component/Avatar';
import BaseModal from 'component/BaseComponent/BaseModal';
import ListData from 'component/ListData';
import {handleApi} from 'helper/helper';
import Card from 'MUI_Kit/components/Card/Card';
import CustomSwitch from 'MUI_Kit/components/Switch/CustomSwitch';
import React, {useEffect, useRef, useState} from 'react';
import {withRouter} from 'react-router';
import Routes from 'router/routes';
import DV from 'variables/DV';
import {Button, Input} from "@doopage/react-ui-kit";
import {cloneDeep, keyBy} from "lodash";
import debounce from "lodash/debounce";


const ActionInputs = ({username, password, agentId, updateAgent}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [innerUsername, setInnerUsername] = useState('')
  const [innerPassword, setInnerPassword] = useState('')

  useEffect(() => {
    setInnerUsername(username)
  }, [username])


  useEffect(() => {
    setInnerPassword(password)
  }, [password])
  return( <Grid item xs={12} sm={6} md={6} className="flex-center-row">
    <Input value={innerUsername} onChange={(e) => {
     setInnerUsername(e.target.value)
      updateAgent(agentId, "username", e.target.value)
    }
    }/>
    <div className=" text-xl mx-2">/</div>
    <Input inputProps={{type: showPassword ? "text":  "password"}} value={innerPassword} onChange={(e) => {
     setInnerPassword(e.target.value)
      updateAgent(agentId, "password", e.target.value)
    }} />
    <Button
        justIcon
        size="small"
        color="white"
        onClick={() =>{ setShowPassword(!showPassword)  }}>
      <i className={showPassword? "fas fa-eye-slash" :"fas fa-eye"}/>
    </Button>
    <Button
        justIcon
        size="small"
        color="white"
        onClick={() =>  {
          navigator.clipboard.writeText(password)
          DV.showNotify(t`Đã sao chép mật khẩu`, "success")
        }
        }>
      <i className="fas fa-copy"/>
    </Button>
  </Grid>)
}
class PbxAgentModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: this.getTitle(),
      callAgents: [],
      callStaffAgent: {},
      staffs: [],
      loading: true,
      phone_number: null,
      ...this.getDataFromRouter(),
    };
    this.goPathWhenClose = Routes.channel.path;
    this.maxWidth = "md"
  }

  getTitle() {
    const {pbx} = this.getDataFromRouter();
    if (pbx) {
      return t`Cài đặt máy nhánh`;
    }
    return null;
  }






  renderBody() {
    const {staffs, loading} = this.state;
    return (<div className="content-father" id="list_callAgents">
      <ListData
        data={staffs || []}
        renderItem={this.renderItem}
        emptyTitle={t`Chưa có nhánh nào`}
        loading={loading}
        loadingTitle={t`Đang tải dữ liệu...`}
      />
    </div>);
  }

  renderItem = (staff, index) => {
    const {id, name, avatar} = staff;
    const agent = this.state.agents[id];
    const isEnabled = agent?.status === 'publish';
    return (<Card
      style={{
        marginTop: index === 0 ? 10 : 0, width: '100%', marginBottom: 15, padding: 10, overflow: 'initial',
      }}
      key={index}>
      <div className="flex-center-row" style={{paddingLeft: 10, paddingRight: 10}}>
        <Grid container className="flex-center-row">
          <Grid item xs={12} sm={1} md={1} className="flex-start-row flex-center-row-xs">
            <Avatar user={{name, avatar}} size={40} margin={false} style={{marginLeft: 5}}/>
          </Grid>
          <Grid item xs={12} sm={9} md={9} className="flex-start-row flex-center-row-xs">
            {name}
          </Grid>
          <Grid item xs={12} sm={2} md={2} className="flex-end-row">
            <CustomSwitch
              checked={isEnabled}
              onChange={() => agent?.id && this.setAgentStatus(agent.id, isEnabled ? 'disable' : 'publish')}
              color={'primary'}
              disabled={!agent}
            />
          </Grid>
        </Grid>
      </div>
    </Card>);
  };

  async setAgentStatus(agent_id, status) {
    const {pbx} = this.state;
    const {error} = await handleApi(patchCallAgent(pbx.id, agent_id, {status}));
    if (error) {
      DV.showNotify(error, 'danger');
    }
    await this.refreshAgents();
  }

   updateAgent = async (agent_id, key, value) => {
    const {pbx} = this.state;
    const {error} = await handleApi(patchCallAgent(pbx.id, agent_id, {[key]: value}));
    if (error) {
      DV.showNotify(error, 'danger');
      return
    }
    DV.showNotify("Cập nhật thành công")
  }

  async componentDidMount() {
    const {pbx} = this.state;
    if (!pbx) {
      return this.close();
    }
    this.setState({loading: true});
    await Promise.all([
      this.fetchStaffData(),
      this.fetchAgentsData(),
    ]);
    this.setState({loading: false});
  }

  async refreshAgents() {
    this.setState({loading: true});
    await this.fetchAgentsData();
    this.setState({loading: false});
  }

  async fetchStaffData() {
    let res = await handleApi(getStaffs());
    if (res.success) {
      this.setState({
        staffs: res.data.data,
      });
    }
  }

  async fetchAgentsData() {
    const {pbx} = this.state;
    let res = await handleApi(GetListCallAgents(pbx.id));
    if (res.success) {
      this.setState({
        agents: keyBy( res.data, "agent_staff_id"),
      });
    }
  }
}

export default withRouter(PbxAgentModal);
