import { Accordion, Button, Chip, Space, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import { trans } from 'config/LinguiConfig';
import React, { memo } from 'react';
import { SERVER_BASE_URL } from '../../../../config/Env';

const CallApiCondition = ({ api, onChange, id }) => {
  const { active = false } = api;

  const update = (path, value) => {
    onChange(`trigger_conditions.api.${path}`, value);
  };

  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <i className="fad fa-link mr-3 text-2xl" />
            {trans(t`Khi gọi API`)}
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('active', !active)}>
        <Space direction="vertical">
          <div className="flex items-center" style={{ marginRight: 8 }}>
            <div className="text-lg opacity-70 mr-2">{trans(t`ID`)}</div>
            <Chip square>{id}</Chip>
            <Button
              justIcon
              size="tiny"
              helperText={trans(t`Hướng dẫn`)}
              color="transparent"
              round
              href={`${SERVER_BASE_URL}/public/api/v1.0/docs/#/chatbot/chatbot_messages_create`}
              target="_blank">
              <i className="fal fa-question-circle text-warning" />
            </Button>
          </div>
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(CallApiCondition);
