import { Input, Select, Space, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import SelectProduct from 'component/Select/SelectProduct';
import { trans } from 'config/LinguiConfig';
import React, { memo, useState } from 'react';

const SendOrderAction = ({ hide, value: order = {}, onChange }) => {
  const { discount = {}, append_products = [] } = order;
  const { value = 0, unit = 'đ' } = discount;
  const [isOpen, setOpen] = useState(() => value > 0);

  if (hide) return null;

  const updateProducts = (values) => {
    onChange('order_form', {
      discount,
      append_products: values,
    });
  };

  return (
    <div className="p-3">
      <Space direction="vertical" spaceHeight={10}>
        <div className="font-bold">
          <i className="fad fa-cart-plus mr-2" />
          {t`Gửi form chốt đơn`}
        </div>
        <div className="rounded-md bg-white p-3 pt-1">
          <div className="ml-[-12px]">
            <Switch
              label={t`Khuyến mãi`}
              labelPlacement="start"
              checked={isOpen}
              onChange={() => setOpen((preValue) => !preValue)}
            />
          </div>
          {isOpen && (
            <Space direction="horizontal" spaceWidth={5}>
              <div>
                <Input
                  type="number"
                  label={t`Giá trị`}
                  value={value}
                  onChange={(e) =>
                    onChange('order_form', {
                      discount: { value: e.target.value, unit },
                      append_products,
                    })
                  }
                />
              </div>
              <div className="h-[42px] flex items-end">
                <Select
                  value={unit}
                  onChange={(e) =>
                    onChange('order_form', {
                      discount: { value, unit: e.target.value },
                      append_products,
                    })
                  }
                  options={[
                    { id: 'value', name: 'đ' },
                    { id: 'percent', name: '%' },
                  ]}
                />
              </div>
            </Space>
          )}
        </div>
        <div className="rounded-md bg-white p-4 flex flex-col items-center justify-center">
          <SelectProduct
            multiple
            fullWidth
            label={t`Thêm vào giỏ`}
            placeholder={t`chọn sản phẩm hoặc bỏ trống để không thêm sản phẩm vào giỏ hàng hiện tại`}
            onChange={updateProducts}
            value={append_products}
          />
          {/*<div className="flex flex-col w-full max-h-[150px] overflow-auto">*/}
          {/*  {append_products.map((item) => {*/}
          {/*    const { id, qty, image, name } = item;*/}
          {/*    return (*/}
          {/*      <div className="flex mt-2 w-full" key={id}>*/}
          {/*        <Avatar*/}
          {/*          rounded*/}
          {/*          src={image}*/}
          {/*          badgeProps={{*/}
          {/*            anchorOrigin: {*/}
          {/*              vertical: "top",*/}
          {/*              horizontal: "right",*/}
          {/*            },*/}
          {/*          }}*/}
          {/*          badge={*/}
          {/*            <div className="w-[15px] h-[15px] rounded-full bg-info flex justify-center items-center text-white text-bold">*/}
          {/*              {qty}*/}
          {/*            </div>*/}
          {/*          }*/}
          {/*        />*/}
          {/*        <Separator width={10} />*/}
          {/*        <div className="text-bold">{name}</div>*/}
          {/*      </div>*/}
          {/*    );*/}
          {/*  })}*/}
          {/*</div>*/}
          {/*<div*/}
          {/*  className="cursor-pointer text-center p-2 rounded-md border-2 border-dashed border-gray-400 hover:border-info hover:text-info text-gray-500 w-full mt-3"*/}
          {/*  onClick={() => {*/}
          {/*    // Need save current edit scenario before open product modal to keep current draft scenario*/}
          {/*    onSaveScenario();*/}
          {/*    window.document.addEventListener(*/}
          {/*      "postback_order",*/}
          {/*      updateProducts,*/}
          {/*      {*/}
          {/*        once: true,*/}
          {/*      }*/}
          {/*    );*/}

          {/*    history.push({*/}
          {/*      pathname: Routes.order.tagProducts.path,*/}
          {/*      state: {*/}
          {/*        isModal: true,*/}
          {/*        goPathWhenClose: history.location.pathname,*/}
          {/*        isCreateOrder: false,*/}
          {/*        allowEmpty: true,*/}
          {/*        selectItem: append_products,*/}
          {/*      },*/}
          {/*    });*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <div className="flex items-center justify-center">*/}
          {/*    <i className="fad fa-plus mr-1" /> {(t`Thêm sản phẩm`)}*/}
          {/*  </div>*/}
          {/*  {!append_products.length && (*/}
          {/*    <div className="mt-1">*/}
          {/*      {(*/}
          {/*        t`hoặc bỏ trống để không thêm sản phẩm vào giỏ hàng hiện tại`*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  )}*/}
          {/*</div>*/}
        </div>
      </Space>
    </div>
  );
};

export default memo(SendOrderAction);
