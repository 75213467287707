import { t, Trans } from '@lingui/macro';
import React from 'react';
import { AssetImage } from '../../assets/Asset';
import DV from '../../variables/DV';
import { FIBONACCI_NUMBERS } from '../../variables/staticValue';
import { thousandSeparate, toCurrency } from '../../helper/helper';

export const LIST_MONTH_PLAN = () => {

  const atpPartnerPlans = {}
  if (DV.partner === 'atpcare.vn') {
    return atpPartnerPlans;
  }
  const otherPartnerPlans = {
    1: { monthNumber: 1, discountMonth: 0 },
    6: { monthNumber: 6, discountMonth: 0 },
    12: { monthNumber: 12, discountMonth: 0.1 },
  }
  return otherPartnerPlans
};

export const TRIAL_PLAN = () => ({
  title: t`Dùng thử`,
  id: 'trial_plan',
  icon: 'fas fa-power-off',
  hasCustomSize: false,
  channel_number: 1,
  staff_number: 2,
  call_agent_number: 0,
});

export const BASIC_PLAN = () => {

  const atpPlans = []
  if (DV.partner === 'atpcare.vn') {
    return atpPlans;
  }
  const otherPartnerPlans = [
    {
      title: t`Nhỏ`,
      id: 'small_plan',
      icon: 'far fa-star',
      hasCustomSize: false,
      channel_number: 1,
      staff_number: 2,
      call_agent_number: 0,
      credit_limit: 0,
      automation_marketing_active: false,
      extraFeatures: [
        {
          name: 'Chatbot',
          description: t`Tạo kịch bản tự động trả lời theo điều kiện kịch hoạt. Gửi tin nhắn hàng loạt theo nhóm khách hàng. Tạo mẫu tin nhắn nhanh.`,
        },
        {
          name: t`Nhóm khách hàng`,
          description: t`Quản lý thông tin liên lạc của khách hàng, phân phễu khách hàng, tag khách hàng.`,
        },
        {
          name: t`Bán hàng`,
          description: t`Quản lý đơn hàng, quản lý thông tin sản phẩm, biến thể sản phẩm, quản lý nhân viên bán hàng.`,
        },
        {
          name: t`Giao vận`,
          description: t`Hỗ trợ GHN, GHTK, VNPost, Topship.`,
        },
        {
          name: t`Phân tích`,
        },
        {
          name: t`API`,
        },
      ],
    },
    {
      title: t`Vừa`,
      id: 'mid_plan',
      icon: 'fas fa-star-half-alt',
      hasCustomSize: false,
      channel_number: 3,
      staff_number: 4,
      call_agent_number: 0,
      credit_limit: 10000,
      automation_marketing_active: true,
      extraFeatures: [
        {
          name: (
              <div>
                {t`Các tính năng của gói`} <span className="text-bold text-[#555555]">{t`Nhỏ`}</span>
              </div>
          ),
        },
        {
          name: t`Marketing tự động`,
          description: t`Tạo luật chatbot được kích hoạt khi chuyển phễu, chuyển tag khách hàng.`,
        },
        {
          name: t`Tổng đài cơ bản 1 chiều`,
          description:   t`Tổng đài cơ bản 1 chiều`
        },
      ],
    },
    {
      title: t`Pro`,
      id: 'pro_plan',
      icon: 'fas fa-star',
      hasCustomSize: true,
      channel_number: 5,
      staff_number: 8,
      call_agent_number: 2,
      credit_limit: 50000,
      automation_marketing_active: true,
      extraFeatures: [
        {
          name: (
              <div>
                {t`Các tính năng của gói`} <span className="text-bold text-[#555555]">{t`Vừa`}</span>
              </div>
          ),
        },
        {
          name: t`Tổng đài cơ bản 2 chiều`,
          description:   t`Tổng đài cơ bản 2 chiều`
        },
        {
          name: 'Zalo Notification Service',
          description: t`Đăng ký và quản lý mẫu tin nhắn ZNS.`,
        },
      ],
    },
    {
      title: t`Contact Center`,
      id: 'contact_center',
      icon: 'fas fa-star',
      hasCustomSize: true,
      channel_number: 5,
      staff_number: 8,
      call_agent_number: 8,
      credit_limit: 50000,
      automation_marketing_active: true,
      extraFeatures: [
        {
          name: (
              <div>
                {t`Các tính năng của gói`} <span className="text-bold text-[#555555]">{t`Pro`}</span>
              </div>
          ),
        },
        {
          name: t`Tổng đài đầy đủ`,
          description:  t`Tổng đài đầy đủ`
        },
        {
          name: t`Ghi âm cuộc gọi`,
        },
      ],
    },
  ]
  return otherPartnerPlans;
}

export const SUPER_CHEAP_PLAN = () => [
  {
    title: t`Vĩnh viễn nhỏ`,
    id: 'small',
    icon: 'far fa-star',
    hasCustomSize: false,
    is_super_cheap_package: true,
    channel_number: 1,
    staff_number: 1,
    extraFeatures: [
      {
        name: 'Chatbot',
        description: t`Tạo kịch bản tự động trả lời theo điều kiện kịch hoạt. Gửi tin nhắn hàng loạt theo nhóm khách hàng. Tạo mẫu tin nhắn nhanh.`,
      },
      {
        name: t`Nhóm khách hàng`,
        description: t`Quản lý thông tin liên lạc của khách hàng, phân phễu khách hàng, tag khách hàng.`,
      },
      {
        name: t`Bán hàng`,
        description: t`Quản lý đơn hàng, quản lý thông tin sản phẩm, biến thể sản phẩm, quản lý nhân viên bán hàng.`,
      },
      {
        name: t`Giao vận`,
        description: t`Hỗ trợ GHN, GHTK, VNPost, Topship.`,
      },
      {
        name: t`Phân tích`,
      },
      {
        name: t`API`,
      },
    ],
  },
  {
    title: t`Vĩnh viễn vừa`,
    id: 'medium',
    icon: 'fas fa-star-half-alt',
    hasCustomSize: false,
    is_super_cheap_package: true,
    channel_number: 2,
    staff_number: 3,
    extraFeatures: [
      {
        name: (
          <div>
            {t`Các tính năng của gói`} <span className="text-bold text-[#555555]">{t`Nhỏ`}</span>
          </div>
        ),
      },
    ],
  },
  {
    title: t`Vĩnh viễn to`,
    id: 'large',
    icon: 'fas fa-star',
    hasCustomSize: false,
    is_super_cheap_package: true,
    channel_number: 4,
    staff_number: 4,
    extraFeatures: [
      {
        name: (
          <div>
            {t`Các tính năng của gói`} <span className="text-bold text-[#555555]">{t`Vừa`}</span>
          </div>
        ),
      },
    ],
  },
];

export const listPlatform = () => ({
  channel_number: {
    name: t`Kênh`,
    description: t`Sử dụng cho các kênh: Fanpage, Instagram, Shopee, ZaloOA, Web Live Chat, Email.`,
    icon: AssetImage.Facebook_image,
    beta: false,
    suffix: t`Kênh`, // suffix của mỗi value trong select số lượng platform
    numberIndex: 'channel_number', // lấy số lượng của platform từ currentSubscription.main_subscription[numberIndex]
    priceIndex: 'channel', // lấy giá tiền của platform từ currentSubscription.prices[numberIndex]
  },
  staff_number: {
    name: t`Người dùng`,
    numberIndex: 'staff_number',
    priceIndex: 'staff',
  },
  call_pbx_number: {
    showExtraValue: false,
    name: t`Tổng đài`,
    numberIndex: 'call_pbx_number',
    priceIndex: 'call_pbx',
    sliderConfig: {
      max: 4,
    },
    disabled: true,
  },
  call_agent_number: {
    showExtraValue: false,
    name: t`Nhánh tổng đài`,
    description: t`Mỗi máy nhánh là một tổng đài viên trực tổng đài điện thoại.`,
    numberIndex: 'call_agent_number',
    priceIndex: 'call_agent',
    sliderConfig: {
      max: 8,
    },
  },
  credit_limit: {
    showExtraValue: false,
    name: t`Tín dụng`,
    description: t`Tín dụng là tiền nạp trước dùng để thực hiện cuộc gọi ra hoặc gửi tin nhắn ZNS.`,
    numberIndex: 'credit_limit',
    priceIndex: 'credit_limit',
    sliderConfig: {
      step: 1,
      min: 0,
      max: FIBONACCI_NUMBERS.length - 1,
      valueLabelFormat: (value) => thousandSeparate(FIBONACCI_NUMBERS[value] * 50000),
    },
  },
});

export const getActivePlatforms = () => {
  const activePlatforms = {};
  const listPlatforms = listPlatform();
  Object.keys(listPlatforms).forEach((key) => {
    if (!listPlatforms[key].disabled) activePlatforms[key] = listPlatforms[key];
  });
  return activePlatforms;
};
