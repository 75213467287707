import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import { addShopeeSource, updateSourceSyncProduct } from 'api/api';
import { createSourceSyncProduct } from 'api/api.js';
import { trans } from 'config/LinguiConfig';
import { handleApi } from 'helper/helper.js';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import CustomSelect from 'MUI_Kit/components/CustomSelect/CustomSelect';
import React from 'react';
import Routes from 'router/routes.js';
import { primaryColor } from 'variables/color';
import DV from 'variables/DV';
import { LIST_DOMAIN } from '../../../variables/staticValue';
import BaseModal from '../../BaseComponent/BaseModal';
import CustomSwitch from '../../../MUI_Kit/components/Switch/CustomSwitch';
import 'css/classes.css';
import './SourceSyncProduct.css';
import BtnRedirectToShopee from './BtnRedirectToShopee';
import routes from 'router/routes';
import { setState } from '../../../config/Core';

class SourceSyncProductModal extends BaseModal {
  constructor(props) {
    super(props);
    let params = this.getDataFromRouter();
    this.state = {
      open: true,
      isLoadingModal: false,
      background: primaryColor,
      color: '#fff',
      sourceSyncData: [
        { key: 'kiot_viet', value: 'Kiot Viet' },
        { key: 'product_feed', value: 'Product Feed' },
        {
          key: 'shopee',
          value: 'Shopee',
        },
      ],
      sourceSync: params?.product_source || 'shopee',
      title: this.getTitle(),
      clientId: '',
      secureCode: '',
      name: params?.name || '',
      feedUrl: params?.feed_url ?? '',
      isPullProduct: params?.is_pull_product ?? true,
      isPushProduct: params?.is_push_product ?? false,
      isPullOrder: params?.is_pull_order ?? true,
      isPushOrder: params?.is_push_order ?? false,
      shopeeLink: '',
      id: params?.id,
    };
    this.goPathWhenClose = Routes.syncProducts.path;
  }

  getTitle() {
    let { id } = this.getDataFromRouter();
    if (id) return t`Cập nhật nguồn đồng bộ`;
    return t`Thêm nguồn đồng bộ`;
  }

  handleChange = (name) => async (event) => {
    let value = event.target ? event.target.value : event;
    console.log('BaseModal handleChange value', { name, value });
    if (name === 'isPullOrder' && value === true) {
      setState(this)({
        isPullOrder: true,
        isPullProduct: true,
      });
    }
    if (name === 'isPullProduct' && value === false && this.state.isPullOrder === true) {
      return;
    }
    await setState(this)({
      [name]: value,
    });
  };

  renderTextField = () => {
    const { feedUrl, sourceSync, clientId, secureCode } = this.state;
    if (sourceSync === null) return null;
    if (sourceSync === 'product_feed') {
      return (
        <CustomInput
          labelText={'Feed url'}
          inputProps={{
            value: feedUrl || '',
            placeholder: 'http://domain.com/product_feed.xml',
            onChange: this.handleChange('feedUrl'),
          }}
        />
      );
    } else {
      return [
        <CustomInput
          labelText={'ClientId'}
          inputProps={{
            value: clientId || '',
            placeholder: 'ClientId',
            onChange: this.handleChange('clientId'),
          }}
        />,
        <CustomInput
          labelText={t`Mã bảo mật`}
          inputProps={{
            value: secureCode || '',
            placeholder: 'secureCode',
            onChange: this.handleChange('secureCode'),
          }}
        />,
      ];
    }
  };

  renderBody() {
    const {
      name,
      sourceSyncData,
      sourceSync,
      isPullProduct,
      isPushProduct,
      isPullOrder,
      isPushOrder,
    } = this.state;
    console.clear();
    console.log('sourceSync', sourceSync);
    return (
      <Grid container>
        <CustomSelect
          label={t`Nguồn đồng bộ`}
          data={sourceSyncData || []}
          value={sourceSync || ''}
          onChange={this.handleChange('sourceSync')}
        />
        {sourceSync !== 'shopee' && (
          <>
            <CustomInput
              labelText={t`Tên nguồn đồng bộ`}
              inputProps={{
                value: name || '',
                placeholder: t`Tên nguồn đồng bộ`,
                onChange: this.handleChange('name'),
              }}
            />
            {this.renderTextField()}
          </>
        )}

        <Grid container className={'flex-space-between-row source-sync-switches'}>
          <Grid item xs={12} md={4} className={'bold'}>
            {t` Đồng bộ sản phẩm:`}
          </Grid>
          <Grid item xs={6} md={4} className={'flex-start-row'}>
            {t`nguồn`} → {t`đây`}
            <CustomSwitch
              checked={!!isPullProduct}
              onChange={() => this.handleChange('isPullProduct')(!!!isPullProduct)}
              color={'primary'}
            />
          </Grid>
          <Grid item xs={6} md={4} className={'flex-start-row'}>
            {t`đây`} → {t`nguồn`}
            <CustomSwitch
              checked={!!isPushProduct}
              onChange={() => this.handleChange('isPushProduct')(!!!isPushProduct)}
              color={'primary'}
              disabled={sourceSync !== 'kiot_viet'}
            />
          </Grid>
        </Grid>
        {(sourceSync === 'kiot_viet' || sourceSync === 'shopee') && (
          <Grid container className={'flex-space-between-row source-sync-switches'}>
            <Grid item xs={12} md={4} className={'bold'}>
              {t`Đồng bộ đơn hàng:`}
            </Grid>
            <Grid item xs={6} md={4} className={'flex-start-row'}>
              <div>
                {t`nguồn`} → {t`đây`}
              </div>
              <CustomSwitch
                checked={!!isPullOrder}
                onChange={() => this.handleChange('isPullOrder')(!!!isPullOrder)}
                color={'primary'}
              />
            </Grid>
            <Grid item xs={6} md={4} className={'flex-start-row'}>
              <div>
                {t`đây`} → {t`nguồn`}
              </div>
              <CustomSwitch
                checked={!!isPushOrder}
                onChange={() => this.handleChange('isPushOrder')(!!!isPushOrder)}
                color={'primary'}
                disabled={sourceSync !== 'kiot_viet'}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  validKiotViet = () => {
    const { clientId, secureCode, name } = this.state;
    if (name === '') {
      DV.showNotify(t`Tên công ty không được để trống`, 'danger');
      return false;
    }
    if (clientId === '') {
      DV.showNotify(t`Client ID không được để trống`, 'danger');
      return false;
    }
    if (secureCode === '') {
      DV.showNotify(t`Mã bảo mật không được để trống`, 'danger');
      return false;
    }
    return true;
  };

  validProductFeed = () => {
    const { feedUrl, name } = this.state;
    if (name === '') {
      DV.showNotify(t`Tên nguồn đồng bộ không được để trống`, 'danger');
      return false;
    }
    if (feedUrl === '') {
      DV.showNotify(t`Feed url không được để trống`, 'danger');
      return false;
    }
    return true;
  };

  add = async (payload) => {
    console.log('Add source:', payload);
    this.setState({ isLoadingModal: true });
    let { success, error } = await handleApi(createSourceSyncProduct(payload));
    this.setState({ isLoadingModal: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Thêm nguồn đồng bộ thành công`);
      if (payload.product_source === 'kiot_viet') {
        DV.showNotify(
          t`Thời gian trung bình để đồng bộ 100SKU là 5 phút. Sau khi đồng bộ hoàn tất hệ thống sẽ cập nhật vào phần Sản phẩm. Bạn vui lòng kiểm tra lại sau 5 phút nữa.`,
          'success',
          5,
        );
      } else {
        DV.showNotify(t`Thêm nguồn đồng bộ thành công`);
      }
    } else {
      DV.showNotify(error, 'danger');
    }
  };

  update = async (id, payload) => {
    console.log('Update source:', id, payload);
    this.setState({ isLoadingModal: true });
    let { success, error } = await handleApi(updateSourceSyncProduct(id, payload));
    this.setState({ isLoadingModal: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Cập nhật nguồn đồng bộ thành công`);
      if (payload.product_source === 'kiot_viet') {
        DV.showNotify(
          t`Thời gian trung bình để đồng bộ 100SKU là 5 phút. Sau khi đồng bộ hoàn tất hệ thống sẽ cập nhật vào phần Sản phẩm. Bạn vui lòng kiểm tra lại sau 5 phút nữa.`,
          'success',
          5,
        );
      } else {
        DV.showNotify(t`Cập nhật nguồn đồng bộ thành công`);
      }
    } else {
      DV.showNotify(error, 'danger');
    }
  };

  addShopee = async (payload) => {
    const { data, error } = await handleApi(addShopeeSource(payload));
    if (error) {
      DV.showNotify(error, 'danger');
      return;
    }
    this.setState({ shopeeLink: data?.link });
  };

  handleSourceSync = () => {
    const {
      feedUrl,
      sourceSync,
      clientId,
      secureCode,
      name,
      id,
      isPullProduct,
      isPushProduct,
      isPullOrder,
      isPushOrder,
    } = this.state;
    let payload = {
      is_pull_product: isPullProduct,
      is_push_product: isPushProduct,
      is_pull_order: isPullOrder,
      is_push_order: isPushOrder,
    };

    if (sourceSync === 'shopee') {
      payload = {
        ...payload,
        redirect: window.location.origin + routes?.syncProducts?.path,
      };

      this.addShopee(payload);
      return;
    }

    if (sourceSync === 'kiot_viet') {
      if (!this.validKiotViet()) return;
      payload = {
        ...payload,
        name,
        product_source: 'kiot_viet',
        client_id: clientId,
        secure_code: secureCode,
      };
    } else if (sourceSync === 'product_feed') {
      if (!this.validProductFeed()) return;
      payload = {
        name,
        product_source: 'product_feed',
        feed_url: feedUrl,
      };
    }

    if (id) this.update(id, payload);
    else this.add(payload);
  };

  renderFooter() {
    const { sourceSync, isLoadingModal, shopeeLink, loading } = this.state;
    if (shopeeLink) {
      return <BtnRedirectToShopee link={shopeeLink} />;
    }
    let guideUrl =
      sourceSync === 'kiot_viet'
        ? LIST_DOMAIN[DV.partner].guide.kiotViet
        : LIST_DOMAIN[DV.partner].guide.productFeed;
    return [
      <Button
        helperText={t`Hướng dẫn`}
        href={guideUrl}
        color="info"
        simple
        target="_blank"
        rel="noopener noreferrer">
        {t`Hướng dẫn`}
      </Button>,
      <Button
        loading={loading}
        onClick={this.handleSourceSync}
        color="info"
        disabled={isLoadingModal}>
        {t`Hoàn tất`}
      </Button>,
    ];
  }
}

export default SourceSyncProductModal;
