import { Select } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';

const options = [
  { id: 1, name: t`Xác nhận / Cập nhật thông tin giao dịch` },
  { id: 2, name: t`Thông báo hoàn tất giao dịch và hỗ trợ có liên quan` },
  { id: 3, name: t`Cập nhật thông tin tài khoản` },
  { id: 4, name: t`Cập nhật thay đổi về sản phẩm, dịch vụ` },
];

const SelectSendingPurpose = ({ value, onChange }) => {
  return (
    <Select
      fullWidth
      label={t`Chọn mục đích gửi tin`}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

export default SelectSendingPurpose;
