import { t, Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { getPaymentMethods, postMakeCompanyOrder } from 'api/api';
import AddressInput from 'component/AddressInput/AddressInput.js';
import { trans } from 'config/LinguiConfig';
import { getHeightModal, handleApi, toCurrency, validatePhone } from 'helper/helper';
import extendedTablesStyle from 'MUI_Kit/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';
import Card from 'MUI_Kit/components/Card/Card';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import CustomSelect from 'MUI_Kit/components/CustomSelect/CustomSelect';
import React from 'react';
import Routes from 'router/routes.js';
import DV from 'variables/DV';
import Avatar from '../../Avatar';
import BaseModal from '../../BaseComponent/BaseModal';
import ListDataModal from '../ListDataModal';
import './OrderModal.css';

const styles = {
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
    borderRadius: 12,
  },
  ...extendedTablesStyle,
};

class OrderModal extends BaseModal {
  constructor(props) {
    super(props);
    this.maxWidth = 'lg';
    this.isSaveData = true;
    this.styleDialogContent = { overflow: 'hidden' };
    this.state = {
      name: '',
      open: true,
      phone: '',
      address: '',
      note: '',
      ward: null,
      district: null,
      province: null,
      paymentMethod: null,
      paymentMethodData: [],
      loading: false,
      ...this.getDataFromRouter(),
      ...this.getSaveData(),
    };
  }

  getData = async () => {
    let paymentMethodData = [];
    let res = await handleApi(getPaymentMethods());
    if (res.success)
      paymentMethodData = res.data.map((paymentMethod) => ({
        key: paymentMethod.id,
        value: paymentMethod.name,
      }));

    this.setState({
      paymentMethodData,
    });
  };

  componentDidMount() {
    this.getData();
  }

  handleSelectedChange = (name) => async (event) => {
    this.setState({
      [name]: event,
    });
  };

  renderLeft = () => {
    const selectItem = this.state.selectItem || [];

    return (
      <Grid item xs={6} sm={6} md={6} className="Order-column">
        <Grid
          item
          xs={12}
          className="flex-center-row"
          style={{ justifyContent: 'space-between', zIndex: 10 }}>
          <div style={{ fontSize: 20, marginBottom: 15 }} className="bold">
            {t`Danh sách sản phẩm`}
          </div>
          <Button
            color="info"
            onClick={() =>
              this.redirectToModal(Routes.order.tagProducts.path, {
                selectItem,
              })
            }>
            {t`Tag sản phẩm`}
          </Button>
        </Grid>
        <div style={{ height: getHeightModal(110) }} className="overflowY">
          <ListDataModal
            heightList={getHeightModal(195)}
            data={selectItem}
            renderItem={this.renderCartItem}
            emptyTitle={t`Bạn chưa có sản phẩm nào nào, vui lòng thêm sản phẩm`}
          />
        </div>
        {selectItem.length > 0 && (
          <div className="flex-end-row bold" style={{ fontSize: 20, marginTop: 10 }}>
            {t`Tổng cộng`}: {this.getTotal()}
          </div>
        )}
      </Grid>
    );
  };

  renderRight = () => {
    const { address, ward, district, province, paymentMethodData, paymentMethod } = this.state;
    return (
      <Grid item xs={6} sm={6} md={6} style={{ paddingLeft: 25, overflow: 'hidden' }}>
        <Grid item xs={12}>
          <div style={{ fontSize: 20, marginBottom: 15 }} className="bold">
            <Trans>Thông tin giao hàng</Trans>
          </div>
        </Grid>
        <div style={{ height: getHeightModal(66) }} className="overflowY">
          <Grid container>
            <Grid item xs={12}>
              <CustomInput
                labelText={t`Họ tên người nhận`}
                inputProps={{
                  value: this.state.name || '',
                  onChange: this.handleChange('name'),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                labelText={t`Số điện thoại`}
                inputProps={{
                  value: this.state.phone || '',
                  onChange: this.handleChange('phone'),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <AddressInput
                ref={(ref) => (this.addressInput = ref)}
                data={{ address, province, district, ward }}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomSelect
                label={t`Hình thức thanh toán`}
                data={paymentMethodData}
                value={paymentMethod || ''}
                onChange={this.handleSelectedChange('paymentMethod')}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                labelText={t`Ghi chú`}
                inputProps={{
                  value: this.state.note || '',
                  onChange: this.handleChange('note'),
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    );
  };

  renderBody() {
    const height = getHeightModal(10);
    return (
      <Grid container style={{ height, overflow: 'hidden' }}>
        {this.renderLeft()}
        {this.renderRight()}
      </Grid>
    );
  }

  getTotal = () => {
    const { selectItem } = this.state;
    let total = 0;
    selectItem &&
      selectItem.forEach((item) => {
        if (item && item.price && item.qty) total += item.price * item.qty;
      });
    return toCurrency(total);
  };

  renderCartItem = (item) => {
    const { image, name, qty, price, unit, attribute_description } = item;
    return (
      <Grid item xs={12} key={item.id}>
        <Card style={{ margin: 0, overflow: 'hidden' }}>
          <Grid container style={{ padding: 7, height: 87 }}>
            <Grid item style={{ marginRight: 10 }}>
              <Avatar user={{ name, avatar: image }} border={12} size={73} margin={false} />
            </Grid>
            <Grid item xs>
              <div className="TagProducts-item-text bold">{name}</div>
              <div className="TagProducts-item-text">{attribute_description}</div>
              <Grid container>
                <Grid item className="flex-start-row" xs={4}>
                  <div style={{ fontSize: 15, fontWeight: 500 }}>{toCurrency(price)}</div>
                  &nbsp; {unit && '/' + unit}
                </Grid>
                <Grid item className="flex-center-row" xs={1}>
                  &nbsp;
                  <i className="fal fa-times" />
                  &nbsp;
                </Grid>
                <Grid item className="flex-end-row" xs={2}>
                  <div
                    className="flex-center-row"
                    style={{
                      marginLeft: 7,
                      marginRight: 7,
                      fontSize: 14,
                      width: 20,
                    }}>
                    {qty}
                  </div>
                </Grid>
                <Grid item className="flex-center-row" xs={1}>
                  &nbsp;
                  <i className="fal fa-equals" />
                  &nbsp;
                </Grid>
                <Grid
                  item
                  className="flex-end-row bold"
                  xs={4}
                  style={{ fontSize: 18, paddingRight: 5 }}>
                  {toCurrency(qty * price)}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  };

  placeOrder = async () => {
    let { name, phone, note, paymentMethod, searchParams } = this.state;
    const selectItem = this.state.selectItem || [];
    let products = selectItem.map((item) => ({
      product: item.id,
      qty: item.qty,
    }));
    let order = {
      recipient_name: name,
      payment_method: paymentMethod,
      note,
      phone,
      ...this.addressInput.getData(),
    };
    order.products = products;
    if (!this.validateData()) return;
    this.setState({ loading: true });
    let { success, error } = await handleApi(postMakeCompanyOrder(order));
    this.setState({ loading: false });
    if (success) {
      DV.showNotify('Đặt hàng thành công');
      this.close(true);
      this.props.history.push({
        pathname: Routes.order.path,
        search: searchParams,
      });
    } else DV.showNotify(error, 'danger');
  };

  onClose = () => {
    let { searchParams } = this.state;
    this.props.history.push({
      pathname: Routes.order.path,
      search: searchParams,
    });
  };

  validateData = () => {
    let { name, phone, paymentMethod } = this.state;
    if (name.length < 3) {
      DV.showNotify('Tên người nhận phải chứa ít nhất 3 ký tự', 'danger');
      return false;
    }

    if (!validatePhone(phone)) {
      DV.showNotify('Sô điện thoại không hợp lệ', 'danger');
      return false;
    }

    if (!paymentMethod) {
      DV.showNotify('Vui lòng chọn phương thức thanh toán', 'danger');
      return false;
    }
    return this.addressInput.validate();
  };

  renderFooter() {
    const { loading } = this.state;
    return (
      <Button onClick={this.placeOrder} color="info" loading={loading}>
        {t`Hoàn tất`}
      </Button>
    );
  }
}

export default withStyles(styles)(OrderModal);
