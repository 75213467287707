import { t } from '@lingui/macro';
import { addShopee, getTiktokOauthLink } from 'api/api';
import BaseModal from 'component/BaseComponent/BaseModal.js';
import { handleApi } from 'helper/helper';
import React from 'react';
import routes from 'router/routes.js';
import Routes from 'router/routes.js';
import DV from 'variables/DV';
import { trans } from '../../config/LinguiConfig';
import Button from '../../MUI_Kit/components/CustomButtons/Button';

class RedirectToTiktokModal extends BaseModal {
  constructor(props) {
    super(props);
    this.showCloseButton = false;

    this.timer = 0;

    this.goPathWhenClose = this.props.openFromChannels ? Routes.channel.path : Routes.tiktok.path;

    this.state = {
      open: false,
      time: 5,
      seconds: 5,
      ...this.getDataFromRouter(),
    };
  }

  async componentDidMount() {
    const { success } = await this.getUrlShopeeAuthorize();
    if (!success) {
      return this.close();
    }
    this.setState({ time: this.state.seconds });
    this.startTimer();
  }

  getUrlShopeeAuthorize = async () => {
    const redirect = window.location.origin + this.goPathWhenClose;
    let { success, data, error } = await handleApi(getTiktokOauthLink());
    if (error) {
      DV.showNotify(error, 'danger');
      return { error };
    }
    if (success) {
      if (data.redirect_url) {
        await this.setState({ url: data.redirect_url, open: true });
      }
      return { success: true };
    }
  };

  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: seconds,
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      window.location.href = this.state.url;
    }
  };

  renderBody() {
    let { open } = this.state;
    return (
      <div>
        {t`Bạn sẽ được chuyển sang website của Tiktok Business để tích hợp tài khoản Tiktok Business với DooPage.`}
      </div>
    );
  }

  renderFooter() {
    return (
      <Button
        onClick={() => {
          window.location.replace(this.state.url);
        }}
        color="info">
        {t`CHUYỂN ĐẾN TIKTOK BUSINESS` + ' (' + this.state.time + ')'}
      </Button>
    );
  }
}

export default RedirectToTiktokModal;
