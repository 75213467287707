import { AutoComplete } from '@doopage/react-ui-kit';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { trans } from 'config/LinguiConfig';
import { t } from '@lingui/macro';
import { getZnsTemplates } from 'redux/action/apiAction';
import { useQuery } from '@redux-requests/react';

const SelectZnsTemplate = ({ value, ...rest }) => {
  const { data, error, loading } = useQuery({ type: getZnsTemplates });
  const dispatch = useDispatch();
  const options =
    data?.data?.map?.((item, index) => {
      const listInput = item.template_data?.listParams?.reduce?.((res, item) => {
        const { name, require, maxLength, minLength, type } = item;
        res[name] = undefined;
        return res;
      }, {});

      return {
        id: item.id.toString(),
        name: item.template_name,
        data: listInput,
      };
    }) ?? [];

  useEffect(() => {
    if (loading || data?.data?.length > 0) return;
    dispatch(
      getZnsTemplates({
        template_status: 'enabled',
        object: { value: true, text: '12222', array: ['1', '3', 3, 3] },
      }),
    );
  }, []);

  return (
    <AutoComplete
      key={options.length}
      error={error}
      label="ZNS"
      fullWidth
      placeholder={trans(t`Chọn mẫu`)}
      options={options}
      value={value?.toString()}
      {...rest}
    />
  );
};

export default SelectZnsTemplate;
