import {
  Accordion,
  AutoComplete,
  Select,
  showErrorSnackbar,
  Space,
  Switch,
} from '@doopage/react-ui-kit';
import { t, Trans } from '@lingui/macro';
import { Card } from '@material-ui/core';
import React, { memo, useEffect, useMemo, useState } from 'react';
import ChannelLogo from '../../../../assets/ChannelLogo';
import { useDispatch } from 'react-redux';
import { getFanpages, updateFanpage } from '../../../../redux/action/apiAction';
import { useQuery } from '@redux-requests/react';
import { dangerColor } from '../../../../variables/color';
import { getLocale } from '../../../../variables/utils';
import produce from 'immer';

const GetStartMessengerCondition = ({
  startedMessenger,
  onChange,
  show,
  channels,
  persistentMenuMessenger,
}) => {
  const { active = false, button_hash = [], action } = startedMessenger;
  const dispatch = useDispatch();
  const { data } = useQuery({ type: getFanpages });
  const fanpages = useMemo(() => data?.data ?? [], [data?.data]);
  const selectedFanpage = useMemo(() => {
    return fanpages.reduce((arr, item) => {
      const isSelected = channels.some((el) => el.platform === 'fb' && `${item.id}` === `${el.id}`);
      if (!isSelected) return arr;
      return [...arr, item];
    }, []);
  }, [fanpages, channels]);
  const [iceBreakerOptions, setIceBreakerOptions] = useState([]);

  const update = (path, value) => {
    onChange(`trigger_conditions.${path}`, value);
  };

  const [nonActivePage, setNonActivePage] = useState([]);
  const [menuOptions, setMenuOptions] = useState([]);

  useEffect(() => {
    if (active) {
      dispatch(getFanpages());
    }
  }, [active, channels]);

  useEffect(() => {
    if (!action) return;

    let nonActivePage = [];
    let optionsMenu = {};
    let optionsButton = {};
    selectedFanpage.forEach((item) => {
      const { ice_breakers, started_button, persistent_menu } = item.messenger_config || {};

      if (
        (action === 'started_button' && !started_button.active) ||
        (action === 'ice_breaker' && !ice_breakers.active)
      )
        nonActivePage.push(item);

      const { buttons = [] } = ice_breakers;
      buttons.forEach((btn) => {
        optionsButton[btn.hash] = btn;
      });

      const { menu = [] } = persistent_menu;
      menu.forEach((item) => {
        const { call_to_actions, locale } = item;
        call_to_actions.forEach((action) => {
          optionsMenu[`${locale}-${action.hash}`] = { locale, title: action.title };
        });
      });
    });

    setNonActivePage(nonActivePage);

    setMenuOptions(
      Object.keys(optionsMenu).map((key) => ({
        id: key,
        name: `${optionsMenu[key].title} (${getLocale(optionsMenu[key].locale).name})`,
        locale: optionsMenu[key].locale,
      })),
    );

    setIceBreakerOptions(
      Object.keys(optionsButton).map((key) => ({
        id: key,
        name: optionsButton[key].title,
      })),
    );
  }, [fanpages, action]);

  const createMenu = async (text) => {
    const results = await Promise.all(
      selectedFanpage.map((item) => {
        const { id, messenger_config = {} } = item;
        const { persistent_menu } = messenger_config;

        return dispatch(
          updateFanpage(id, {
            messenger_config: {
              persistent_menu: produce(persistent_menu, (draftState) => {
                draftState.menu
                  .find((item) => item.locale === 'default')
                  .call_to_actions.push({ title: text, type: 'postback' });
              }),
            },
          }),
        );
      }),
    );

    if (results.some((item) => item.status !== 200)) {
      showErrorSnackbar('Có lỗi xảy ra vui lòng thử lại');
      return;
    }

    const menu = results[0].data.messenger_config.persistent_menu.menu.find(
      (item) => item.locale === 'default',
    );
    return menu.call_to_actions[menu.call_to_actions.length - 1].hash;
  };

  const activeMessengerPageConfig = async (type) => {
    const results = await Promise.all(
      nonActivePage.map((item) => {
        item.messenger_config[type].active = true;
        return dispatch(updateFanpage(item.id, item));
      }),
    );

    if (results.some((item) => item.status !== 200)) {
      showErrorSnackbar('Có lỗi xảy ra vui lòng thử lại');
      return;
    }

    setNonActivePage([]);
  };

  const createStartedButton = async (text) => {
    const results = await Promise.all(
      selectedFanpage.map((item) => {
        const { id, messenger_config = {} } = item;
        const { ice_breakers, started_button } = messenger_config;

        return dispatch(
          updateFanpage(id, {
            messenger_config: {
              ice_breakers: {
                ...ice_breakers,
                buttons: [...ice_breakers.buttons, { title: text }],
              },
              started_button,
            },
          }),
        );
      }),
    );

    if (results.some((item) => item.status !== 200)) {
      showErrorSnackbar('Có lỗi xảy ra vui lòng thử lại');
      return;
    }

    return results[0].data.messenger_config.ice_breakers.buttons.find((item) => item.title === text)
      .hash;
  };

  const renderError = () => {
    if (!nonActivePage.length) return null;

    const listPage = nonActivePage.map((item) => `"${item.name}"`).join(', ');
    if (action === 'started_button')
      return (
        <div style={{ color: dangerColor }}>
          <Trans>
            Trang {listPage} chưa cài đặt nút "Bắt đầu".{' '}
            <span
              className="underline cursor-pointer"
              onClick={() => activeMessengerPageConfig('started_button')}>
              Khắc phục ngay
            </span>
            . Lưu ý: cài đặt nút "Bắt đầu" sẻ hủy các cài đặt gợi ý khi bắt đầu.
          </Trans>
        </div>
      );

    return (
      <div style={{ color: dangerColor }}>
        <Trans>
          Trang {listPage} chưa cài đặt chọn gợi ý.{' '}
          <span
            className="underline cursor-pointer"
            onClick={() => activeMessengerPageConfig('ice_breakers')}>
            Khắc phục ngay
          </span>
          . Lưu ý: cài đặt nút "Bắt đầu" sẻ hủy các cài đặt gợi ý khi bắt đầu.
        </Trans>
      </div>
    );
  };

  if (!show) return null;
  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <ChannelLogo.Messenger />
            <span className="ml-2">{t`Khi bắt đầu Messenger`} </span>
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('started_messenger.active', !active)}>
        <Space direction="vertical">
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <Select
              label={t`Chọn hành động`}
              placeholder={t`Vui lòng chọn hành động`}
              fullWidth
              options={[
                { id: 'started_button', name: t`Khi bấm "Bắt đầu"` },
                { id: 'ice_breaker', name: t`Khi chọn gợi ý` },
              ]}
              value={action}
              onChange={(e) => {
                update('started_messenger.action', e.target.value);
                update('persistent_menu_messenger', {
                  ...persistentMenuMessenger,
                  active: e.target.value === 'started_button',
                  action: 'persistent_menu',
                });
              }}
            />
          </div>
          {action === 'ice_breaker' && nonActivePage.length === 0 && (
            <div className="flex items-end" style={{ marginRight: 8 }}>
              <AutoComplete
                key={iceBreakerOptions.length || 'init'}
                allowCreate
                label={t`Chọn gợi ý`}
                placeholder={t`Vui lòng chọn gợi ý hoặc nhập gợi ý mới`}
                fullWidth
                options={iceBreakerOptions}
                value={button_hash}
                onChange={async (value, { inputValue }) => {
                  if (inputValue) {
                    const hash = await createStartedButton(inputValue);
                    update('started_messenger.button_hash', hash);
                  } else update('started_messenger.button_hash', value);
                }}
                setCreateText={(value) => <Trans>Thêm {value}</Trans>}
              />
            </div>
          )}
          {action === 'started_button' && nonActivePage.length === 0 && (
            <div className="flex items-end" style={{ marginRight: 8 }}>
              <AutoComplete
                multiple
                key={menuOptions.length || 'init'}
                allowCreate
                label={t`Chọn menu`}
                placeholder={t`Vui lòng chọn menu hoặc nhập menu mới`}
                fullWidth
                options={menuOptions}
                value={
                  Array.isArray(persistentMenuMessenger.button_hash)
                    ? persistentMenuMessenger.button_hash
                    : persistentMenuMessenger.button_hash
                    ? [persistentMenuMessenger.button_hash]
                    : undefined
                }
                onChange={async (value, newValue) => {
                  const { inputValue } = newValue?.[newValue.length - 1] ?? {};
                  if (inputValue) {
                    const hash = await createMenu(inputValue);
                    update(
                      'persistent_menu_messenger.button_hash',
                      produce(value, (draftState) => {
                        draftState[value.length - 1] = hash;
                      }),
                    );
                  } else update('persistent_menu_messenger.button_hash', value);
                }}
                setCreateText={(value) => <Trans>Thêm {value}</Trans>}
              />
            </div>
          )}

          {renderError()}
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(GetStartMessengerCondition);
