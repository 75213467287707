import {
  createReducer,
  getTypeClear,
  getTypeFail,
  getTypeLoading,
  getTypeSuccess,
  getTypeUpdate,
} from 'redux/utils';

export const baseFetchObj = {
  data: [],
  loading: false,
  error: null,
  success: null, //null -> not loading yet, true -> loading success, false -> loading error
};

export default (type, moreReducer) =>
  createReducer(
    { ...baseFetchObj },
    {
      [getTypeLoading(type)]: (state, action) => {
        return {
          ...state,
          ...baseFetchObj,
          loading: true,
          data: state.data,
        };
      },
      [getTypeSuccess(type)]: (state, action) => {
        return {
          ...state,
          ...baseFetchObj,
          ...action.payload,
          success: true,
        };
      },
      [getTypeFail(type)]: (state, action) => {
        return {
          ...state,
          ...baseFetchObj,
          error: action.payload,
          data: state.data,
          success: false,
          loading: false,
        };
      },
      [getTypeUpdate(type)]: (state, action) => {
        return {
          ...state,
          ...action.payload,
        };
      },
      [getTypeClear(type)]: (state, action) => {
        return {
          ...baseFetchObj,
        };
      },
      ...moreReducer,
    },
  );
