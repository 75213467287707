import { DateRangeInput } from '@doopage/react-ui-kit';
import moment from 'moment';
import React from 'react';
import { t } from '@lingui/macro';

const TimeRangeInput = ({ onChange, timeRange }) => {
  const { start_at, end_at } = timeRange;
  return (
    <div
      className="!w-full flex flex-row pt-[4px]"
      onClick={() => {
        onChange(`time_range.active`, true);
        onChange(`time_cycle.active`, false);
      }}>
      <div className="mr-2">{t`Giới hạn`}:</div>

      <DateRangeInput
        fullWidth
        value={[+moment(start_at), moment(end_at)]}
        onChange={([from, to]) => {
          let fromMomentObj = moment(from);
          fromMomentObj.set('hour', 0);
          fromMomentObj.set('minute', 0);
          fromMomentObj.set('second', 0);
          let toMomentObj = moment(to);
          toMomentObj.set('hour', 23);
          toMomentObj.set('minute', 59);
          toMomentObj.set('second', 59);
          onChange('time_range.start_at', fromMomentObj.toISOString());
          onChange('time_range.end_at', toMomentObj.toISOString());
        }}
      />
    </div>
  );
};

export default TimeRangeInput;
