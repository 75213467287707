import { Trans } from '@lingui/macro';
import InfoIcon from '@material-ui/icons/Info';
import ValueChange from 'component/ValueChange.js';
import moment from 'moment';
import React from 'react';
import store from 'redux/store';
import { toCurrency } from '../helper/helper';
import Badge from '../MUI_Kit/components/Badge/Badge';
import Button from '../MUI_Kit/components/CustomButtons/Button';
import { openModal } from '../redux/action/modalAction';
import { Types } from '../redux/type';
import { formatDateTime } from './utils';
import { AssetImage } from '../assets/Asset';

const mapStatus = {
  success: {
    value: <Trans>Thành công</Trans>,
    color: 'success',
  },
  canceled: {
    value: <Trans>Đã hủy</Trans>,
    color: 'danger',
  },
  processing: {
    value: <Trans>Đợi thanh toán</Trans>,
    color: 'warning',
  },
};

export const HeaderWebhooksErrors = [
  {
    Header: 'Date',
    accessor: 'date',
    width: 200,
    Cell: ({ value }) => {
      return formatDateTime(value);
    },
  },
  {
    Header: 'Event',
    accessor: 'event',
  },
  {
    Header: 'Status Code',
    accessor: 'status_code',
    width: 150,
  },
];

const mappingZnsTemplateResultStatusIcon = {
  success: AssetImage.SuccessIcon,
  pending: AssetImage.WarningIcon,
  failed: AssetImage.ErrorIcon,
};

export const HeaderZnsTemplateResults = [
  {
    // Header: <Trans>Trạng thái</Trans>,
    accessor: 'status',
    width: 50,
    Cell: ({ value }) => {
      const icon = mappingZnsTemplateResultStatusIcon[value];
      if (!icon) return value;
      return <img src={icon} alt={'img'} className="h-[20px]" />;
    },
  },
  {
    // Header: <Trans>Số điện thoại nhận</Trans>,
    accessor: 'receiver_phone',
  },
  {
    // Header: <Trans>Tên người nhận</Trans>,
    accessor: 'receiver_name',
    width: 150,
  },
  {
    // Header: <Trans>Thời gian tạo</Trans>,
    accessor: 'created_at',
    width: 200,
    Cell: ({ value }) => {
      return moment(value).fromNow();
    },
  },
];

export const HeaderCreditsTransaction = [
  {
    Header: 'ID',
    accessor: 'id',
    width: 150,
    headerClassName: 'text-center',
    className: 'text-center',
    Cell: ({ value }) => {
      return '#' + value;
    },
  },
  {
    Header: <Trans>Số tiền</Trans>,
    accessor: 'amount',
    width: 150,
    headerClassName: 'text-center',
    className: 'text-center',
    Cell: ({ value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: <Trans>Nội dung</Trans>,
    accessor: 'message',
    headerClassName: 'text-center',
    className: 'text-center',
  },
  {
    Header: <Trans>Thời gian</Trans>,
    accessor: 'created_at',
    width: 150,
    headerClassName: 'text-center',
    className: 'text-center',
    Cell: ({ value }) => {
      return moment(value).fromNow();
    },
  },
];

export const HeaderHistory = [
  {
    Header: <Trans>Mã thanh toán</Trans>,
    accessor: 'id',
    width: 150,
    headerClassName: 'text-center',
    className: 'text-center',
    Cell: ({ value }) => {
      return '#' + value;
    },
  },
  {
    Header: <Trans>Số tiền</Trans>,
    accessor: 'final_value',
    style: { fontWeight: 600 },
    headerClassName: 'text-end',
    className: 'text-end',
    Cell: ({ value }) => {
      return value > 0 ? toCurrency(value) : 'Miễn phí';
    },
  },
  {
    Header: <Trans>Ngày thanh toán</Trans>,
    accessor: 'start_date',
    headerClassName: 'text-end',
    className: 'text-end',
    Cell: ({ value }) => {
      return moment(value).format('ll');
    },
  },
  {
    Header: <Trans>Ngày hết hạn</Trans>,
    accessor: 'end_date',
    headerClassName: 'text-end',
    className: 'text-end',
    Cell: ({ value }) => {
      return moment(value).format('ll');
    },
  },
  {
    Header: <Trans>Trạng thái</Trans>,
    accessor: 'status',
    headerClassName: 'text-center',
    width: 190,
    className: 'text-center',
    Cell: ({ value, original }) => {
      let status = mapStatus[value];
      return (
        <div className="flex-start-row">
          <Badge color={status.color} style={{ width: 140 }}>
            {status.value}
          </Badge>
          <Button
            show={value === 'processing'}
            justIcon
            color="info"
            size="sm"
            round
            style={{ marginLeft: 5 }}
            onClick={() => {
              store.dispatch(openModal(Types.modalPaymentInfo, original));
            }}>
            <InfoIcon />
          </Button>
        </div>
      );
    },
  },
];

export const HeaderStaffStatistic = [
  {
    Header: <Trans>Nhân viên</Trans>,
    accessor: 'name',
    width: 250,
  },
  {
    Header: <Trans>Thời gian trực</Trans>,
    accessor: 'online_time',
    width: 140,
    Cell: (row) => {
      return (
        <div className="flex-start-row">
          {row.value.value}
          <ValueChange style={{ marginLeft: 10 }} value={row.value.change} fontSize={13} />
        </div>
      );
    },
  },
  {
    Header: <Trans>KH mới xử lý</Trans>,
    accessor: 'new_customer',
    style: { fontWeight: 600 },
    width: 140,
    Cell: (row) => {
      return (
        <div className="flex-start-row">
          {row.value.value}
          <ValueChange style={{ marginLeft: 10 }} value={row.value.change} fontSize={13} />
        </div>
      );
    },
  },
  {
    Header: <Trans>KH cũ xử lý</Trans>,
    accessor: 'old_customer',
    width: 140,
    Cell: (row) => {
      return (
        <div className="flex-start-row">
          {row.value.value}
          <ValueChange style={{ marginLeft: 10 }} value={row.value.change} fontSize={13} />
        </div>
      );
    },
  },
  {
    Header: <Trans>Thời gian trả lời hội thoại</Trans>,
    accessor: 'time_reply_inbox',
    width: 250,
    Cell: (row) => {
      return (
        <div className="flex-start-row">
          {row.value.value}
          <ValueChange style={{ marginLeft: 10 }} value={row.value.change} fontSize={13} />
        </div>
      );
    },
  },
  {
    Header: <Trans>Tỷ lệ chốt đơn</Trans>,
    accessor: 'make_order_rate',
    width: 150,
    Cell: (row) => {
      return (
        <div className="flex-start-row">
          {row.value.value}
          <ValueChange style={{ marginLeft: 10 }} value={row.value.change} fontSize={13} />
        </div>
      );
    },
  },
  {
    Header: <Trans> Tỷ lệ hài lòng</Trans>,
    accessor: 'satisfied_rate',
    width: 150,
    Cell: (row) => {
      return (
        <div className="flex-start-row">
          {row.value.value}
          <ValueChange style={{ marginLeft: 10 }} value={row.value.change} fontSize={13} />
        </div>
      );
    },
  },
  {
    Header: <Trans> Lần đăng nhập cuối</Trans>,
    accessor: 'last_login',
    width: 200,
  },
  // {
  // 	Header: <Trans>Trạng thái</Trans>,
  // 	accessor: "status",
  // 	width: 140,
  // 	Cell: row =>
  // 	{
  // 		let status = mapStatus[row.value];
  // 		return (
  // 			<Badge color={status.color} fullWidth>{status.value}</Badge>
  // 		);
  // 	},
  // },
];
export const HeaderChannelStatistic = [
  {
    Header: <Trans>Kênh</Trans>,
    accessor: 'channel',
  },
  {
    Header: <Trans>Hội thoại mới phát sinh</Trans>,
    accessor: 'new_inbox',
  },
  {
    Header: <Trans>Tỷ lệ chốt đơn</Trans>,
    accessor: 'make_order_rate',
    style: { fontWeight: 600 },
  },
  {
    Header: <Trans>Doanh thu</Trans>,
    accessor: 'revenue',
  },
];
