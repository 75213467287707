import { GA_ID } from 'config/Env';
import ReactGA from 'react-ga';
import DV from 'variables/DV';

ReactGA.initialize(GA_ID);

export const sendGAAction = (event) => ReactGA.event(event);

export const sendSidebarAction = (action) => {
  sendGAAction({
    category: 'Sidebar',
    action: 'Click ' + action,
    label: 'Click ' + action + ' from Sidebar',
  });
};

export const sendButtonAction = (action) => {
  sendGAAction({
    category: 'Button',
    action: 'Click button ' + action,
    label: 'Click button ' + action + ' at ' + window.location.pathname,
  });
};

export const sendSearchAction = (keyword) => {
  sendGAAction({
    category: 'Search',
    action: `Search "${keyword}"`,
    label: `Search "${keyword}" at ` + window.location.pathname,
  });
};

export const sendSwitchAction = (value) => {
  sendGAAction({
    category: 'Switch',
    action: `Turn ${value}`,
    label: `Turn ${value} at ` + window.location.pathname,
  });
};

export const sendSelectAction = (action) => (value) => {
  sendGAAction({
    category: 'Select',
    action: 'Select ' + value + ' in ' + action,
    label: 'Select ' + value + ' in ' + action + ' at ' + window.location.pathname,
  });
};

const { detect } = require('detect-browser');
const browserInfo = detect() || {};
export const sendCompanyEvent = () => {
  ReactGA.event({
    category: 'Company',
    action: DV.company.name + ' - ' + DV.company.id,
    label: browserInfo.name + ', ' + browserInfo.version + ', ' + browserInfo.os,
  });
};
