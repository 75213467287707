import { Button, showErrorSnackbar, showSuccessSnackbar } from "@doopage/react-ui-kit";
import "@doopage/react-ui-kit/dist/doopage-ui-kit.css";
import { t } from "@lingui/macro";
import Grid from "@material-ui/core/Grid/Grid";
import { useQuery } from "@redux-requests/react";
import { enableFanpages, enableInstagrams, getAuthorizedFanpages, getAuthorizedInstagrams, updateInstagram } from "api/api";
import classNames from "classnames";
import Avatar from "component/Avatar";
import BaseModal from "component/BaseComponent/BaseModal";
import FacebookLogin from "component/FacebookLogin/FacebookLogin";
import ListData from "component/ListData";
import { getScope, handleApi, refreshToken } from "helper/helper";
import Card from "MUI_Kit/components/Card/Card";
import Warning from "MUI_Kit/components/Typography/Warning";
import React, { createRef, forwardRef, useEffect, useMemo, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getAllChannels, getInstagrams } from "redux/action/apiAction";
import Routes from "router/routes";
import { xPath } from "testingClasses";
import DV from "variables/DV";
import { isEmpty } from "lodash";
import { useApi } from "../../../../hook/utils";

export const Content = forwardRef(({ closeModal, selectedInstagrams, setSelectedInstagrams, accessToken }) => {
  const { doFetch, loading } = useApi();
  const [data, setData] = useState([]);
  const [error, setError] = useState('')


  const getData = async () => {
    let response = await doFetch(getAuthorizedInstagrams({ fb_access_token: accessToken }));
    if (response.error) {
      setError(response.error)
      return
    }
    setData(response.data || [])
  }

  useEffect(() => {
    getData();
  }, [])

  const handleClick = async ({ id }) => {
    if (selectedInstagrams.includes(id)) {
      setSelectedInstagrams(selectedInstagrams.filter(i => i !== id))
      return
    }
    setSelectedInstagrams([...selectedInstagrams, id])
  };

  const renderItem = (item, index) => {
    const { name, fb_id, avatar, warning, id } = item || {};
    return (
      <Card
        style={{
          width: "100%",
          marginBottom: 15,
          padding: 10,
          overflow: "initial",
          marginTop: index === 0 ? 10 : 0
        }}
        key={index}
        className={classNames(xPath.item, xPath.item_(index), "cursor-pointer", { 'card-active': selectedInstagrams.includes(id) })}
        onClick={() => handleClick(item)}>
        <div className="flex-center-row" style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar user={{ avatar, name, fb_id }} size={45} margin={false} />
            </Grid>
            <Grid item xs>
              <Grid container>
                <Grid item xs={12} sm={6} className="flex-start-row bold">
                  {name}
                </Grid>
                <Grid item xs={12} sm={4} className="flex-start-col">
                  <Warning useTooltip>{warning || ""}</Warning>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  };

  return (
    <>
      <div>{t`Chọn những tài khoản cần tích hợp.`}</div>
      <ListData
        data={data}
        renderItem={renderItem}
        emptyTitle={t`Bạn chưa có tài khoản Instagram nào cần tích hợp`}
        loading={loading}
        loadingTitle={t`Đang tải dữ liệu...`}
        error={!!error}
        errorTitle={error}
        errorButton={t`Thử lại`}
        errorAction={() => {
          getData()
        }}
      />
    </>
  );
});

class InstagramSelectModal extends BaseModal {
  constructor(props) {
    super(props);
    const stateFromRouter = this.getDataFromRouter()
    if (!stateFromRouter.accessToken) this.close(false);
    this.state = {
      open: true,
      title: t`Chọn Instagram`,
      loading: false,
      name: "",
      selectedInstagrams: [],
      ...stateFromRouter,
    };
    this.refreshOnClose = true;

    this.goPathWhenClose = this.props.openFromChannels ? Routes.channel.path : Routes.fanpage.path;
    this.modal = createRef();
  }


  renderBody() {
    const { selectedInstagrams, accessToken } = this.state;
    return <Content
      closeModal={this.close}
      ref={this.modal}
      selectedInstagrams={selectedInstagrams}
      setSelectedInstagrams={(values) => {
        this.setState({
          selectedInstagrams: values
        })
      }}
      accessToken={accessToken}
    />;
  }

  handleSubmit = async () => {
    const { selectedInstagrams } = this.state;
    if(isEmpty(selectedInstagrams)) {
      showSuccessSnackbar(t`Vui lòng chọn ít nhất một tài khoản`)
      return
    }

    this.setState({
      adding: true
    })
    let { success, error } = await handleApi(enableInstagrams({ channel_ids: selectedInstagrams }));
    this.setState({
      adding: false
    })

    if (success) {
      showSuccessSnackbar(t`Kích hoạt thành công`);
      this.close(true)
    } else {
      let { is_shared_doopage_to_facebook } = DV.company;
      if (error && error.includes(t`nâng cấp gói cước`) && !is_shared_doopage_to_facebook)
        DV.showWarningLimitModal();
      else showErrorSnackbar(error || t`Có lỗi xảy ra`);
    }
  }


  renderFooter() {
    const { adding } = this.state;
    return (
      <Button loading={adding} onClick={this.handleSubmit} color="info">
        {t`Kích hoạt`}
      </Button>
    );
  }
}

export default connect()(InstagramSelectModal);
