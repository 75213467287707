import { setLogStyle, useLogStyle } from "config/LogConfig";
import { getListAllowRoutes } from "helper/helperPermission";
import React from "react";
import { Route } from "react-router-dom";
import { dangerColor } from "variables/color";
import DV from "variables/DV";

export const goBack = (history, path) => {
  console.log(useLogStyle + 'HISTORY GO BACK', setLogStyle(dangerColor));
  if (path && history.location.pathname === DV.url) history.push(path);
  else history.goBack();
};

export const generateRoute = (root) => {
  const result = [];
  const addRoute = (router, exact) => {
    const allowRoutes = getListAllowRoutes(router);
    result.push(...allowRoutes.map((item) => ({ ...item, exact })));

    for (const key in allowRoutes) {
      const { permission, path, component, ...restRouter } = allowRoutes[key];
      addRoute(restRouter, false);
    }
  };

  addRoute(root, true);

  return result
    .filter((item) => !!item.component)
    .map(({ path, component: Component, props, exact }) => (
      <Route
        key={path + '-' + DV.reRender}
        exact={exact}
        path={path}
        render={(p) => {
          return <Component {...p} {...props} openFromChannels={path.includes('/channel/')} />;
        }}
      />
    ));
};
