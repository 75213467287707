import { Button, Input, Popup, Radio, Select, Space } from '@doopage/react-ui-kit';
import { t, Trans } from '@lingui/macro';
import { RadioGroup } from '@material-ui/core';
import Separator from 'component/Separator';
import React, { memo } from 'react';
import SelectScenario from '../../components/SelectScenario';
import SelectProduct from '../../../../component/Select/SelectProduct';

const QuickReplyAction = ({ value = {}, onChange, hide }) => {
  if (hide) return null;

  const { items: buttons = [] } = value;
  return (
    <div className="w-full p-3 pt-2">
      <Space direction="vertical" spaceHeight={8}>
        {buttons.map((item, index) => {
          const { title, type, doopage = {} } = item;
          const updateButton = (path, btnValue) => {
            const cloneButtons = [...buttons];
            cloneButtons[index] = { ...cloneButtons[index], [path]: btnValue };
            onChange('quick_reply', { ...value, items: cloneButtons });
          };

          return (
            <Popup
              key={index}
              button={
                <div
                  key={index}
                  className="cursor-pointer p-2 rounded-xl w-full text-center transition bg-gray-200 hover:bg-gray-300 font-bold relative">
                  {title || t`Lựa chọn`}
                  <div className="absolute right-0 top-0 overflow-hidden">
                    <div
                      className="flex justify-center items-center overflow-hidden"
                      style={{ height: 33 }}>
                      <Button
                        justIcon
                        color="transparent"
                        onClick={(e) => {
                          e.stopPropagation();
                          onChange('quick_reply', {
                            ...value,
                            items: buttons.filter((_, i) => i !== index),
                          });
                        }}>
                        <i className="fad fa-times" />
                      </Button>
                    </div>
                  </div>
                </div>
              }>
              <div className="p-4" style={{ width: 300 }}>
                <Input
                  fullWidth
                  label={t`Tiêu đề lựa chọn`}
                  value={title}
                  onChange={(e) => updateButton('title', e.target.value)}
                />
                <Separator height={10} />
                {t`Hành động`}
                <RadioGroup
                  value={type}
                  onChange={(e) => {
                    updateButton('type', e.target.value);
                  }}>
                  <div className="flex flex-col">
                    <Radio value="postback">{t`Không làm gì`}</Radio>
                    <Radio value="doo_execute_scenario">{t`Chạy kịch bản`}</Radio>
                    <Radio value="doo_add_product_to_cart">{t`Thêm sản phẩm vào giỏ hàng`}</Radio>
                    <Radio value="doo_save_to_customer_property">{t`Lưu thông tin khách hàng`}</Radio>
                  </div>
                </RadioGroup>

                <SelectProduct
                  hide={type !== 'doo_add_product_to_cart'}
                  fullWidth
                  label={t`Chọn sản phẩm`}
                  placeholder={t`chọn sản phẩm`}
                  onChange={(value) => {
                    console.log('select product', value);
                    updateButton('doopage', {
                      ...doopage,
                      product: { id: value?.id },
                    });
                  }}
                  value={doopage?.product?.id}
                />

                {type === 'doo_save_to_customer_property' && (
                  <div className="flex items-end mt-3">
                    <Trans>Lưu vào: customer.</Trans>
                    <Input
                      value={doopage?.customer_property?.property_name ?? ''}
                      onChange={(e) =>
                        updateButton('doopage', {
                          ...doopage,
                          customer_property: { property_name: e.target.value },
                        })
                      }
                    />
                  </div>
                )}

                <SelectScenario
                  label={t`Chọn hoặc kéo trong sơ đồ`}
                  hide={type !== 'doo_execute_scenario'}
                  value={doopage?.scenario?.uuid || ''}
                  onChange={(scenario_id) => {
                    updateButton('doopage', {
                      ...doopage,
                      scenario: { uuid: scenario_id },
                    });
                  }}
                />
              </div>
            </Popup>
          );
        })}
        {buttons.length < 10 && (
          <div
            className="cursor-pointer border-2 border-gray-400 border border-dashed p-1 rounded-xl w-full text-center transition hover:border-info hover:text-info"
            onClick={() =>
              onChange('quick_reply', {
                ...value,
                items: [
                  ...buttons,
                  {
                    title: t`Lựa chọn` + ' ' + buttons.length,
                    type: 'postback',
                  },
                ],
              })
            }>
            <i className="fad fa-plus mr-1" />
            {t`Thêm lựa chọn`}
          </div>
        )}
      </Space>
    </div>
  );
};

export default memo(QuickReplyAction);
