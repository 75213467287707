import { Accordion, Input, Space, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import SelectProduct from 'component/Select/SelectProduct';
import { trans } from 'config/LinguiConfig';
import React, { memo } from 'react';

const OrderCondition = ({ createOrder, onChange }) => {
  const {
    min_value,
    max_value,
    products = [],
    exclude_products = [],
    active = false,
  } = createOrder;

  const update = (path, value) => {
    onChange(`trigger_conditions.create_order.${path}`, value);
  };

  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <i className="fad fa-shopping-bag mr-3 text-2xl" />
            {t`Khi có đơn mới`}
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('active', !active)}>
        <Space direction="vertical">
          <Space direction="horizontal">
            <Input
              fullWidth
              label={t`Giá trị tối thiểu`}
              placeholder={t`Vui lòng nhập giá trị`}
              value={min_value}
              onChange={(e) => update('min_value', e.target.value)}
              type="number"
              inputProps={{ min: 0 }}
              InputLabelProps={{ shrink: true }}
            />
            <Input
              fullWidth
              label={t`Giá trị tối đa (không bắt buộc)`}
              placeholder={t`Vui lòng nhập giá trị`}
              value={max_value}
              onChange={(e) => update('max_value', e.target.value)}
              type="number"
              inputProps={{ min: 0 }}
              InputLabelProps={{ shrink: true }}
            />
          </Space>
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 30 }} className="text-lg opacity-70">
              {t`Và`}
            </div>
            <SelectProduct
              multiple
              fullWidth
              label={t`có sản phẩm (bỏ trống để chạy với sản phẩm bất kỳ)`}
              placeholder={t`chọn sản phẩm`}
              onChange={(value) => {
                update('products', value);
              }}
              value={products}
            />
          </div>
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 30 }} className="text-lg opacity-70">
              {t`Và`}
            </div>
            <SelectProduct
              multiple
              fullWidth
              label={t`KHÔNG có sản phẩm (bỏ trống để chạy với sản phẩm bất kỳ)`}
              placeholder={t`chọn sản phẩm`}
              onChange={(value) => {
                update('exclude_products', value);
              }}
              value={exclude_products}
            />
          </div>
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(OrderCondition);
