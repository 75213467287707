import { AutoComplete } from '@doopage/react-ui-kit';
import { getProducts } from 'api/api';
import { AssetImage } from 'assets/Asset';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from 'redux/action/fetchAction';
import { Types } from 'redux/type';
import { getValue } from 'variables/utils';

const SelectProduct = ({ onChange, ...props }) => {
  const { data, error, loading } = useSelector(getValue(Types.selectProduct));
  const dispatch = useDispatch();

  const getProductsOption = (value = '') => {
    dispatch(fetchData(Types.selectProduct, getProducts('?page=1&page_size=10&q=' + value)));
  };

  return (
    <AutoComplete
      onChange={(value, newValue) => {
        onChange(newValue);
      }}
      options={data.map(({ id, name, image }) => ({
        id,
        name,
        image: image || AssetImage.DefaultProduct,
      }))}
      getData={getProductsOption}
      loading={loading}
      error={error}
      {...props}
    />
  );
};

export default SelectProduct;
