import { Button, Input, Popup, Radio, Select, Space } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { RadioGroup } from '@material-ui/core';
import Separator from 'component/Separator';
import React, { memo } from 'react';
import SelectScenario from '../../components/SelectScenario';

const ButtonsAction = ({ buttons = [], onChange, hide }) => {
  if (hide) return null;

  return (
    <div className="w-full p-3 pt-2">
      <Space direction="vertical" spaceHeight={8}>
        {buttons.map((item, index) => {
          const { title, type, doopage = {}, url, webview_height_ratio } = item;
          const updateButton = (path, value) => {
            const cloneButtons = [...buttons];
            cloneButtons[index] = { ...cloneButtons[index], [path]: value };
            onChange('buttons', cloneButtons);
          };

          return (
            <Popup
              key={index}
              button={
                <div
                  key={index}
                  className="cursor-pointer p-2 rounded-xl w-full text-center transition bg-gray-200 hover:bg-gray-300 font-bold relative">
                  {title || t`Nút`}
                  <div className="absolute right-0 top-0 overflow-hidden">
                    <div
                      className="flex justify-center items-center overflow-hidden"
                      style={{ height: 33 }}>
                      <Button
                        justIcon
                        color="transparent"
                        onClick={(e) => {
                          e.stopPropagation();
                          onChange(
                            'buttons',
                            buttons.filter((_, i) => i !== index),
                          );
                        }}>
                        <i className="fad fa-times" />
                      </Button>
                    </div>
                  </div>
                </div>
              }>
              <div className="p-4" style={{ width: 300 }}>
                <Input
                  fullWidth
                  label={t`Tiêu đề nút`}
                  value={title}
                  onChange={(e) => updateButton('title', e.target.value)}
                />
                <Separator height={10} />
                {t`Hành động`}
                <RadioGroup
                  value={type}
                  onChange={(e) => {
                    updateButton('type', e.target.value);
                  }}>
                  <div className="flex flex-col">
                    <Radio value="postback">{t`Không làm gì`}</Radio>
                    <Radio value="web_url">{t`Mở trang web`}</Radio>
                    <Radio value="doo_execute_scenario">{t`Chạy kịch bản`}</Radio>
                  </div>
                </RadioGroup>
                <Input
                  label={t`Nhập url`}
                  hide={type !== 'web_url'}
                  fullWidth
                  value={url || ''}
                  onChange={(e) => {
                    updateButton('url', e.target.value);
                    if (!webview_height_ratio) updateButton('webview_height_ratio', 'full');
                  }}
                />
                <Select
                  fullWidth
                  hide={type !== 'web_url'}
                  label={t`Cách mở trang`}
                  options={[
                    { id: 'compact', name: t`Compact pop-up` },
                    { id: 'tall', name: t`Pop-up` },
                    { id: 'full', name: t`Toàn cửa sổ` },
                  ]}
                  value={webview_height_ratio}
                  onChange={(e) => updateButton('webview_height_ratio', e.target.value)}
                />
                <SelectScenario
                  label={t`Chọn hoặc kéo trong sơ đồ`}
                  hide={type !== 'doo_execute_scenario'}
                  value={doopage?.scenario_id || ''}
                  onChange={(scenario_id) => {
                    updateButton('doopage', { scenario_id });
                  }}
                />
              </div>
            </Popup>
          );
        })}
        {buttons.length < 3 && (
          <div
            className="cursor-pointer border-2 border-gray-400 border border-dashed p-1 rounded-xl w-full text-center transition hover:border-info hover:text-info"
            onClick={() =>
              onChange('buttons', [
                ...buttons,
                {
                  title: t`Nút` + ' ' + buttons.length,
                  type: 'postback',
                },
              ])
            }>
            <i className="fad fa-plus mr-1" />
            {t`Thêm nút`}
          </div>
        )}
      </Space>
    </div>
  );
};

export default memo(ButtonsAction);
