import { Accordion, ChipInput, Space, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import { trans } from 'config/LinguiConfig';
import React, { memo } from 'react';

const addPrefix = (item, preFix) => {
  if (!item) return preFix;
  if (item[0] === preFix) return item;
  return preFix + item;
};

const CommentCondition = ({ comment, onChange, show = true }) => {
  const {
    active = false,
    exclude_keywords = [],
    keywords = [],
    post_keywords = [],
    is_not_trigger_on_same_inbox_and_post = false,
  } = comment;

  const update = (path, value) => {
    onChange(`trigger_conditions.comment.${path}`, value);
  };

  if (!show) return null;

  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <i className="fad fa-comments-alt mr-3 text-2xl" />
            {t`Khi nhận comment`}
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('active', !active)}>
        <Space direction="vertical">
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 30 }} className="text-lg opacity-70">
              {t`Từ`}
            </div>
            <ChipInput
              fullWidth
              label={t`Bài viết chủ đề có chứa hashtag:`}
              placeholder={t`Bài viết chủ đề phải có chứa một trong những keywords này`}
              onChange={(value) =>
                update(
                  'post_keywords',
                  value?.map((item) => addPrefix(item, '#')),
                )
              }
              value={post_keywords ?? []}
              chipProps={{
                outlined: false,
                rounded: true,
                color: 'info',
              }}
            />
          </div>
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 30 }} className="text-lg opacity-70">
              {t`Và`}
            </div>
            <ChipInput
              fullWidth
              placeholder={t`Comment phải có chứa một trong những keyword này`}
              label={t`Comment chứa keyword (không bắt buộc):`}
              onChange={(value) => update('keywords', value)}
              value={keywords ?? []}
              chipProps={{
                outlined: false,
                rounded: true,
                color: 'success',
              }}
            />
          </div>
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 30 }} className="text-lg opacity-70">
              {t`Và`}
            </div>
            <ChipInput
              fullWidth
              placeholder={t`Comment không chứa một trong những keyword này`}
              label={t`Comment không chứa keyword (không bắt buộc):`}
              onChange={(value) => update('exclude_keywords', value)}
              value={exclude_keywords ?? []}
              chipProps={{
                outlined: false,
                rounded: true,
                color: 'danger',
              }}
            />
          </div>
          <div
            className="flex justify-between items-center"
            onClick={() => {
              update(
                'is_not_trigger_on_same_inbox_and_post',
                !is_not_trigger_on_same_inbox_and_post,
              );
            }}>
            <div className="opacity-70 text-lg">
              {t`Và không lặp lại với cùng một người trong cùng một bài viết:`}
            </div>
            <Switch
              color="primary"
              checked={is_not_trigger_on_same_inbox_and_post}
              onChange={() => {
                update(
                  'is_not_trigger_on_same_inbox_and_post',
                  !is_not_trigger_on_same_inbox_and_post,
                );
              }}
            />
          </div>
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(CommentCondition);
