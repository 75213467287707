import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import AddressInput from 'component/AddressInput/AddressInput.js';
import React from 'react';
import { addPickAddress, updatePickAddress } from '../../api/api';
import { trans } from '../../config/LinguiConfig';
import { handleApi } from '../../helper/helper';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import CustomInput from '../../MUI_Kit/components/CustomInput/CustomInput';
import Routes, { detailPath } from '../../router/routes';
import DV from '../../variables/DV';
import BaseModal from '../BaseComponent/BaseModal';

class PickAddressModal extends BaseModal {
  constructor(props) {
    super(props);
    let { id, phone, full_address, province, district, ward, goBackPath } =
      this.getDataFromRouter();
    let address;
    if (full_address) {
      let temp = full_address.split(',');
      address = temp[0] || '';
    }
    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      id,
      phone,
      address,
      ward,
      district,
      province,
    };
    this.goPathWhenClose = goBackPath || Routes.pickupAddress.path;
  }

  getTitle() {
    const { id } = this.getDataFromRouter();
    if (id) return t`Cập nhật địa chỉ`;
    else return t`Thêm địa chỉ`;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  validateData = () => {
    const { phone } = this.state;

    if (phone.length < 9 || phone.length > 13) {
      DV.showNotify(t`Số điện thoại không hợp lệ`, 'danger');
      return false;
    }
    return this.addressInput.validate();
  };

  handleSubmit = () => {
    const { phone, id } = this.state;
    if (!this.validateData()) return;
    if (id) {
      this.update({ id, data: { phone, ...this.addressInput.getData() } });
    } else {
      this.add({ phone, ...this.addressInput.getData() });
    }
  };

  add = async (payload) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(addPickAddress(payload));
    this.setState({ loading: false });
    if (success) {
      DV.showNotify(t`Thêm địa chỉ lấy hàng thành công.`);
      if (DV.openViewOrder) {
        this.redirectToModal(Routes.order.path + detailPath, DV.openViewOrder);
      } else this.close(true);
    } else DV.showNotify(error, 'danger');
  };

  update = async (payload) => {
    console.log('Update pick adress:', payload);
    let { id, data } = payload;
    this.setState({ loading: true });
    let { success, error } = await handleApi(updatePickAddress(id, data));
    this.setState({ loading: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Cập nhật thành công`);
    } else DV.showNotify(error, 'danger');
  };

  renderFooter() {
    return (
      <Button loading={this.props.loading} onClick={this.handleSubmit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }

  renderBody() {
    const { ward, district, province, phone, address } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <CustomInput
            labelText={t`Số điện thoại`}
            inputProps={{
              type: 'number',
              value: phone || '',
              onChange: this.handleChange('phone'),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <AddressInput
            ref={(ref) => (this.addressInput = ref)}
            data={{ address, province, district, ward }}
          />
        </Grid>
      </Grid>
    );
  }
}

export default PickAddressModal;
