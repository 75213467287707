import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import { trans } from 'config/LinguiConfig.js';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput.jsx';
import React from 'react';
import DV from 'variables/DV.js';
import SelectProvince from './components/SelectProvince';
import SelectDistrict from './components/SelectDistrict';
import SelectWard from './components/SelectWard';

export default class AddressInput extends React.PureComponent {
  constructor(props) {
    super(props);
    let { address, province, district, ward } = this.props.data;
    this.state = {
      address,
      province,
      district,
      ward,
      provinceData: [],
      districtData: [],
      wardData: [],
    };
  }

  handleChange = (name) => async (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleSelectedChange = (name) => async (value) => {
    let { ward, district, province } = this.state;
    if (name !== 'ward') ward = null;
    if (name === 'province') district = null;

    this.setState({
      ward,
      district,
      province,
      [name]: value,
    });
  };

  validate = () => {
    let { address, province, district } = this.state;
    const { allowEmpty } = this.props;
    let validAddress = address && province && district;
    if (allowEmpty) validAddress = validAddress || (!address && !province && !district);
    if (!validAddress) {
      DV.showNotify(t`Vui lòng nhập đầy đủ địa chỉ, tỉnh/thành phố và quận/huyện`, 'danger');
      return false;
    }
    return true;
  };

  getData = () => {
    const { address, province, district, ward } = this.state;
    let result = { address, province, district };
    if (ward) result.ward = ward;
    return result;
  };

  render() {
    const { province, district, ward, address } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <CustomInput
            labelText={t`Địa chỉ`}
            inputProps={{
              value: address || '',
              onChange: this.handleChange('address'),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectProvince value={province || ''} onChange={this.handleSelectedChange} />
        </Grid>
        <Grid item xs={12}>
          <SelectDistrict
            key={province}
            province={province}
            value={district || ''}
            onChange={this.handleSelectedChange}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectWard
            key={`${province}-${district}`}
            district={district}
            value={ward || ''}
            onChange={this.handleSelectedChange}
          />
        </Grid>
      </Grid>
    );
  }
}
