import { t } from '@lingui/macro';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import Color from 'color';
import isEmpty from 'lodash/isEmpty';
import React, { memo } from 'react';
import { trans } from '../../../config/LinguiConfig';
import { successColor } from '../../../variables/color';
import CustomChart from '../components/CustomChart/CustomChart';
import { thousandSeparate } from '../../../helper/helper';

const getOption = () => ({
  plugins: [ChartAnnotation],
  aspectRatio: 1,
  tooltips: {
    enabled: false,
    position: 'nearest',
    mode: 'nearest',
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    intersect: 0,
  },
  annotation: {
    drawTime: 'beforeDatasetsDraw',
    annotations: [
      {
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: 0,
        borderColor: '#eeeeee',
        borderWidth: 2,
        borderDash: [3, 3],
      },
    ],
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          labelString: t`Đơn hàng`,
        },
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return value;
            }
          },
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          labelString: t`Doanh thu`,
        },
        ticks: {
          suggestedMin: 0,
          callback: function (value) {
            if (value % 1 === 0) {
              return thousandSeparate(value);
            }
          },
        },
      },
    ],
  },
});

const color = [successColor];
const normalizedData = (data) => {
  if (isEmpty(data)) return {};
  if (data?.datasets?.forEach)
    data.datasets.forEach((item, index) => {
      item.backgroundColor = Color(color[index]).alpha(0.5).string();
      item.borderColor = color[index];
    });

  return data;
};

const getColumns = () => [
  {
    Header: t`Tên sản phẩm`,
    accessor: 'name',
  },
  {
    Header: t`Đơn hàng`,
    width: 100,
    accessor: 'x',
  },
  {
    Header: t`Doanh thu`,
    width: 100,
    accessor: 'y',
  },
];

const ProductsPerformance = (props) => {
  return (
    <CustomChart
      normalizedData={normalizedData}
      getOption={getOption}
      columns={getColumns()}
      {...props}
    />
  );
};

export default memo(ProductsPerformance);
