import React from 'react';
import FlipMove from 'react-flip-move';

export default class CustomFlipMove extends React.PureComponent {
  render() {
    const { children, disabled, className } = this.props;
    if (disabled) return <div style={{ position: 'relative' }}>{children}</div>;

    return (
      <div style={{ position: 'relative' }} className={className}>
        <FlipMove
          typeName={null}
          className="content"
          duration={250}
          delay={15}
          appearAnimation="elevator"
          enterAnimation="elevator"
          leaveAnimation="elevator"
          staggerDurationBy={30}
          staggerDelayBy={20}>
          {children}
        </FlipMove>
      </div>
    );
  }
}
