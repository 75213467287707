import React from 'react';
import './jquery-cron-quartz.css';
import $ from 'jquery';
window.jQuery = $;
require('./jquery-cron-quartz');

class CronBuilder extends React.Component {
  handleChange = (value) => {
    this.props.onChange(value);
  };

  componentDidMount() {
    $('#cron').cronBuilder({
      onChange: this.handleChange,
    });

    $('#cron')
      .data('cronBuilder')
      .parseExpression(this.props.value || '* * * * *');
  }

  getExpression = () => {
    return $('#cron').data('cronBuilder').getExpression();
  };

  render() {
    return <div id="cron" style={{ minWidth: 340 }}></div>;
  }
}

export default CronBuilder;
