import { GTM_ID } from 'config/Env';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';
import { isDebug } from '../config/LogConfig';

const tagManagerArgs = {
  gtmId: GTM_ID,
};

GTM_ID && !isDebug() && TagManager.initialize(tagManagerArgs);

const GTMConnect = () => {
  const location = useLocation();
  useEffect(() => {
    if (!GTM_ID || isDebug()) return;
    const tagManagerArgs = {
      dataLayer: {
        event: 'Pageview',
        pagePath: location.pathname,
      },
    };
    console.log('change TagManager ', location.pathname);
    TagManager.dataLayer(tagManagerArgs);
  }, [location]);

  return null;
};

export default GTMConnect;
