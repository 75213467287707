import firebase from 'firebase/app';
import 'firebase/auth';

export class GoogleAuthHelper extends firebase.auth.GoogleAuthProvider {
  constructor(scopes = [], params = {}) {
    super();
    for (const scope of scopes) {
      this.addScope(scope);
    }
    this.setCustomParameters(params);
  }

  async signInWithPopup() {
    return await firebase.auth().signInWithPopup(this);
  }

  static forAccessEmail() {
    return new this([
      'https://www.googleapis.com/auth/gmail.readonly',
      'https://www.googleapis.com/auth/gmail.send',
      'https://mail.google.com/',
    ]);
  }
}
