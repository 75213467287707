import { t } from '@lingui/macro';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import { getShipmentServices } from 'api/api';
import CustomRadio from 'component/CustomRadio';
import { handleApi, toCurrency } from 'helper/helper';
import moment from 'moment';
import Warning from 'MUI_Kit/components/Typography/Warning';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { mapShipmentServiceIcon, SPACE } from 'variables/staticValue';
import './Receipt.css';
import './ShipmentService.css';

class ShipmentService extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      data: [],
    };
  }

  componentDidMount() {
    const { picking_status, shipment_service, pick_address_id } = this.props;
    if (!(picking_status !== 'pending' && shipment_service) && pick_address_id)
      this.getShipmentServices();
  }

  getShipmentServices = async () => {
    const { pick_address_id, shipment_account_id, include_insurance, id } = this.props;
    this.setState({ loading: true, error: null });
    let { data, error } = await handleApi(
      getShipmentServices(id, {
        pick_address_id,
        shipment_account_id,
        include_insurance,
      }),
    );
    this.setState({ loading: false });
    if (error) {
      this.setState({ error, data: [] });
    } else this.setState({ data: this.groupShipmentServices(data) });
  };

  groupShipmentServices = (data) => {
    let result = {};
    data.forEach((item) => {
      if (!item.code) item.code = uuid();
      if (!result[item.carrier]) result[item.carrier] = [];
      result[item.carrier].push(item);
    });
    return result;
  };

  renderFee = (fee, fee_COD, fee_DVCT, fee_VAT, isHCMPOST) => {
    return (
      <>
        {fee_VAT !== undefined && (
          <div className="text" style={{ fontSize: '0.75rem', paddingLeft: 8 }}>
            {t`Chính: ` + toCurrency(fee_VAT)}
          </div>
        )}

        {fee_COD !== undefined && (
          <div className="text" style={{ fontSize: '0.8rem', paddingLeft: 8 }}>
            {'COD: ' + toCurrency(fee_COD)}
          </div>
        )}

        {fee_DVCT !== undefined && (
          <div className="text" style={{ fontSize: '0.75rem', paddingLeft: 8 }}>
            {'DVCT: ' + toCurrency(fee_DVCT)}
          </div>
        )}

        {fee !== '' && isHCMPOST && (
          <div className="text bold" style={{ fontSize: '0.75rem', paddingLeft: 8 }}>
            {t`Tổng cộng:`}
          </div>
        )}

        {fee !== '' && (
          <div
            className="text bold"
            style={{ fontSize: '1.1rem', paddingLeft: 8, marginBottom: 12 }}>
            {toCurrency(fee)}
          </div>
        )}
      </>
    );
  };

  render() {
    const { shipment_service, picking_status, is_cancelled, onChange, isHCMPOST } = this.props;
    if (picking_status !== 'pending' && shipment_service) {
      let {
        name,
        carrier,
        fee,
        estimated_pickup_at,
        estimated_delivery_at,
        fee_COD,
        fee_DVCT,
        fee_VAT,
      } = shipment_service;
      const isHCMPOST = carrier === 'vnpost' || carrier === 'hcmpost';
      const cardStyle = isHCMPOST ? { height: 200, borderRadius: 14 } : { borderRadius: 14 };
      return (
        <Grid container style={{ marginTop: 10, marginBottom: 10 }} key={'3x'} spacing={1}>
          <Grid item container spacing={1} style={cardStyle}>
            <Grid item xs={4}>
              <Card style={cardStyle} className={'flex-center-col card-container'}>
                <img
                  src={mapShipmentServiceIcon[carrier]}
                  alt={carrier}
                  style={{ width: '100%', objectFit: 'fill' }}
                />
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card className="card-container" style={cardStyle}>
                <div
                  className="flex-start-row bold"
                  style={{
                    paddingLeft: 8,
                    paddingRight: 8,
                    height: 30,
                    fontSize: '0.85rem',
                  }}>
                  {t`Gói`}
                  {SPACE}
                  {name || ''}
                </div>

                {this.renderFee(fee, fee_COD, fee_DVCT, fee_VAT, isHCMPOST)}
                <div className="estimated-pickup-time-container">
                  <div>
                    <span className="bold">{t`Lấy`} : </span>
                    {estimated_pickup_at
                      ? moment(estimated_pickup_at).format('L')
                      : 'Không xác định'}
                  </div>
                  <div>
                    <span className="bold">{t`Giao`} : </span>
                    {estimated_delivery_at
                      ? moment(estimated_delivery_at).format('L')
                      : 'Không xác định'}
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      );
    }

    let { data, error, loading } = this.state;
    if (error)
      return (
        <div className="flex-center-col bold">
          <div>{t`Không tải được đơn vị vận chuyển`}</div>
          <div style={{ color: 'rgb(124, 124, 124)' }}>(Lỗi: {error})</div>
        </div>
      );
    if (loading)
      return <div className="flex-center-col bold">{t`Đang tải đơn vị vận chuyển...`}</div>;

    const cardStyle = isHCMPOST ? { height: 200, borderRadius: 14 } : { borderRadius: 14 };

    return (
      <Grid container style={{ marginTop: 10 }} key={'3x'} spacing={1}>
        {Object.keys(data).map((key) => {
          const item = data[key];
          let list = [];
          let carrier = key;
          if (carrier === 'partner') {
            if (item[0] && item[0].carrier_info?.name) {
              carrier = item[0].carrier_info.name.split('-').pop().trim();
            }
          }
          list.push(
            <Grid item xs={4} key={key}>
              <Card style={cardStyle} className={'flex-center-col card-container'}>
                <img
                  src={mapShipmentServiceIcon[carrier]}
                  alt={carrier}
                  style={{
                    width: '100%',
                    objectFit: 'fill',
                  }}
                />
              </Card>
            </Grid>,
          );

          item.forEach((item, index) => {
            let {
              name,
              fee,
              estimated_pickup_at,
              estimated_delivery_at,
              fee_COD,
              fee_DVCT,
              fee_VAT,
              code,
              carrier,
            } = item;
            code = code || '.';
            const checked = !!(shipment_service && shipment_service.code === code);

            list.push(
              <Grid item xs={4} key={key + '-' + index}>
                <Card
                  style={cardStyle}
                  className="card-container"
                  onClick={() => !is_cancelled && onChange(item)}>
                  <CardActionArea style={{ height: '100%' }}>
                    <div
                      className="flex-center-row bold"
                      style={{
                        justifyContent: 'space-between',
                        paddingLeft: 8,
                        paddingRight: 8,
                        fontSize: '0.85rem',
                      }}>
                      <div>
                        {t`Gói`}
                        {SPACE}
                        {name}
                      </div>
                      <div style={{ marginRight: -25 }}>
                        <CustomRadio label={''} checked={checked} />
                      </div>
                    </div>
                    {this.renderFee(fee, fee_COD, fee_DVCT, fee_VAT, isHCMPOST)}
                    <div className="estimated-pickup-time-container">
                      <div>
                        <span className="bold">{t`Lấy`} : </span>
                        {estimated_pickup_at
                          ? moment(estimated_pickup_at).format('L')
                          : 'Không xác định'}
                      </div>
                      <div>
                        <span className="bold">{t`Giao`} : </span>
                        {estimated_delivery_at
                          ? moment(estimated_delivery_at).format('L')
                          : 'Không xác định'}
                      </div>
                    </div>
                  </CardActionArea>
                </Card>
              </Grid>,
            );
          });
          return (
            <>
              <Grid item container key={key} spacing={1}>
                {list}
              </Grid>
              {(data.vnpost || data.hcmpost) && (
                <Warning>
                  {t`Lưu ý: Giá giao hàng của VNPost là giá tham khảo. Giá có thể thay đổi tùy theo kích thước, trọng lượng thực tế của hàng hóa.`}
                </Warning>
              )}
            </>
          );
        })}
      </Grid>
    );
  }
}

export default ShipmentService;
