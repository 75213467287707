import { t } from '@lingui/macro';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid/Grid';
import IconButton from '@material-ui/core/IconButton/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Refresh from '@material-ui/icons/Refresh';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { createShipmentService, updateShipmentService } from 'api/api.js';
import Avatar from 'component/Avatar';
import { trans } from 'config/LinguiConfig';
import { handleApi } from 'helper/helper.js';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import CustomSelect from 'MUI_Kit/components/CustomSelect/CustomSelect';
import React from 'react';
import Routes, { detailPath } from 'router/routes.js';
import { primaryColor } from 'variables/color';
import DV from 'variables/DV';
import BaseModal from '../BaseComponent/BaseModal';

class ShipmentServiceModal extends BaseModal {
  constructor(props) {
    super(props);
    let service = 'topship';

    let servicesData = [
      { key: 'topship', value: 'eTop (Topship)' },
      { key: 'ghtk', value: 'Giao hàng tiết kiệm' },
      { key: 'ghn', value: 'Giao hàng nhanh' },
    ];

    if (DV.partner === 'hcmpost.vn') {
      service = 'hcmpost';
      servicesData.push({ key: 'hcmpost', value: 'HCMPOST' });
    } else {
      servicesData.push({ key: 'vnpost', value: 'VNPOST' });
    }

    this.state = {
      open: true,
      background: primaryColor,
      color: '#fff',
      title: this.getTitle(),
      name: '',
      email: '',
      apiToken: '',
      servicesData: servicesData,
      service: service,
      username: '',
      password: '',
      status: '',
      showPassword: false,
      shops: null,
      showOTP: false,
      otp: '',
      ...this.getDataFromRouter(),
    };
    this.goPathWhenClose = Routes.shipmentServices.path;
  }

  getTitle() {
    const { id } = this.getDataFromRouter();
    if (id) return t`Cập nhật dịch vụ`;
    else return t`Thêm dịch vụ`;
  }

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleResendOTP = async () => {
    const { service, apiToken, email } = this.state;
    let { success, error } = await handleApi(
      createShipmentService({
        service,
        api_token: apiToken,
        email,
        action: 'otp',
      }),
    );
    if (success) {
      DV.showNotify(t`Đã gửi lại mã OTP thành công`, 'success');
    } else {
      DV.showNotify(error, 'danger');
    }
  };

  renderInput = () => {
    const { id } = this.getDataFromRouter();
    let { username, password, service, email, apiToken, showPassword } = this.state;
    if (service === 'hcmpost' || service === 'vnpost') {
      return (
        <form>
          <CustomInput
            labelText={'Mã thẻ KHTT'}
            inputProps={{
              value: username || email || '',
              placeholder: 'Mã thẻ KHTT',
              onChange: this.handleChange('username'),
              disabled: !!id,
            }}
          />

          <CustomInput
            labelText={t`Mật khẩu`}
            inputProps={{
              placeholder: t`Nhập vào mật khẩu`,
              type: showPassword ? 'text' : 'password',
              value: password || '',
              onChange: this.handleChange('password'),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Toggle password visibility"
                    onClick={this.handleClickShowPassword}>
                    {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {service === 'hcmpost' && (
            <div style={{ marginTop: 15 }}>
              <a
                href="http://donhang.hcmpost.vn/"
                target="_blank"
                rel="noopener noreferrer"
                className="link">
                {t`Chưa có tài khoản VNPost?`}
              </a>
            </div>
          )}
        </form>
      );
    } else {
      const loginByEmailOrPhone = service === 'topship';
      const loginByEmail = service === 'ghtk';
      const loginByToken = service === 'ghtk' || service === 'ghn';
      return (
        <>
          {(loginByEmail || loginByEmailOrPhone || id) && (
            <CustomInput
              labelText={
                loginByEmailOrPhone
                  ? t`Email hoặc số điện thoại`
                  : loginByEmail
                  ? 'Email'
                  : 'Shop ID'
              }
              inputProps={{
                value: email || '',
                placeholder: loginByEmailOrPhone
                  ? 'Email/Phone'
                  : loginByEmail
                  ? 'Email'
                  : 'Shop ID',
                onChange: this.handleChange('email'),
                disabled: !!id,
              }}
            />
          )}

          {loginByToken && (
            <CustomInput
              labelText={t`API token`}
              inputProps={{
                value: apiToken || '',
                placeholder: t`API Token`,
                onChange: this.handleChange('apiToken'),
              }}
            />
          )}
          {service === 'ghtk' && (
            <div style={{ marginTop: 15 }}>
              <a
                href="https://khachhang.giaohangtietkiem.vn/khach-hang/thong-tin-ca-nhan#ShopAgainstBankOwn"
                target="_blank"
                className="link"
                rel="noopener noreferrer">
                {t`Click vào đây để lấy API Token`}
              </a>
            </div>
          )}
        </>
      );
    }
  };

  handleSelectShop = (shop) => {
    this.setState({
      email: shop.id,
    });
  };

  renderShops = () => {
    const { shops, email } = this.state;
    if (shops) {
      return (
        <List>
          {shops.map((shop) => (
            <div>
              <ListItem
                button
                selected={email === shop.id}
                onClick={() => this.handleSelectShop(shop)}>
                <ListItemAvatar>
                  <Avatar
                    user={{
                      name: shop.name,
                      avatar: shop.icon,
                    }}
                    size={48}
                    margin={false}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={shop.name}
                  secondary={
                    <>
                      <span>{shop.phone}</span>
                      <br />
                      <small>{shop.address}</small>
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      );
    }
  };

  renderOTP() {
    const { showOTP, otp } = this.state;
    if (!showOTP) {
      return;
    }
    return (
      <>
        <CustomInput
          labelText={t`Mã OTP`}
          inputProps={{
            placeholder: t`Nhập vào mã OTP`,
            value: otp || '',
            onChange: this.handleChange('otp'),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="Resend OTP" onClick={this.handleResendOTP}>
                  <Refresh />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </>
    );
  }

  renderBody() {
    const { id } = this.getDataFromRouter();
    const { servicesData, service } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          {DV.partner !== 'hcmpost.vn' && (
            <CustomSelect
              label={t`Dịch vụ giao nhận`}
              data={servicesData || []}
              value={service || ''}
              onChange={this.handleChange('service')}
              disabled={!!id}
            />
          )}
          {this.renderInput()}
          {this.renderShops()}
          {this.renderOTP()}
        </Grid>
      </Grid>
    );
  }

  validate = () => {
    const { service, email, shops } = this.state;

    if (service === '') {
      DV.showNotify(t`Vui lòng chọn dịch vụ giao nhận`, 'danger');
      return false;
    }

    if (service === 'vnpost' || service === 'hcmpost') {
      const { username, password } = this.state;
      if (username.length < 5) {
        DV.showNotify(t`Tên đăng nhập không hợp lệ`, 'danger');
        return false;
      }
      if (password.length < 5) {
        DV.showNotify(t`Mật khẩu không hợp lệ`, 'danger');
        return false;
      }
    } else {
      if (((service === 'ghn' && shops !== null) || service !== 'ghn') && email === '') {
        if (shops) {
          DV.showNotify(t`Vui lòng chọn một Shop để tiếp tục`, 'danger');
        } else {
          DV.showNotify(t`Email/số điện thoại không được bỏ trống`, 'danger');
        }
        return false;
      }
      if (service === 'ghtk' || service === 'ghn') {
        const { apiToken } = this.state;
        if (apiToken.length < 8) {
          DV.showNotify(t`Api token không hợp lệ`, 'danger');
          return false;
        }
      }
    }
    return true;
  };

  add = async (payload) => {
    this.setState({ loading: true });
    let { success, code, error, data } = await handleApi(createShipmentService(payload));
    this.setState({ loading: false });
    if (success) {
      if (!!data.shops) {
        DV.showNotify(t`Hãy chọn một cửa hàng để liên kết`);
        this.setState({
          shops: data.shops,
        });
      } else if (!!data.auth_url) {
        DV.showNotify(t`Bạn đang được chuyển hướng tới trang đăng nhập`);
        this.redirectToModal(Routes.redirectToTopshipModal.path, {
          url: data.auth_url,
        });
      } else if (DV.openViewOrder) {
        this.redirectTo(Routes.order.path + detailPath, DV.openViewOrder);
      } else {
        DV.showNotify(t`Thêm dịch vụ thành công`);
        this.close(true);
      }
    } else {
      if (code === 'REQUIRE_OTP') {
        this.setState({
          showOTP: true,
        });
        DV.showNotify(t`Hãy nhập mã OTP đã được gửi tới điện thoại của bạn`, 'success');
      } else {
        DV.showNotify(error, 'danger');
      }
    }
  };

  update = async (payload, id) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(updateShipmentService(payload, id));
    this.setState({ loading: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Cập nhật thành công`);
    } else DV.showNotify(error, 'danger');
  };

  submit = () => {
    const { apiToken, email, service, id, username, password, showOTP, otp } = this.state;

    if (this.validate()) {
      let payload = {};
      if (service === 'hcmpost' || service === 'vnpost') {
        payload = {
          service,
          username,
          password,
        };
      } else {
        payload = {
          api_token: apiToken,
          service,
          email,
        };
      }
      if (showOTP) {
        payload.otp = otp;
      }
      if (id) this.update(payload, id);
      else this.add(payload);
    }
  };

  renderFooter() {
    return (
      <Button loading={this.state.loading} onClick={this.submit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }
}

export default ShipmentServiceModal;
