import {t} from '@lingui/macro';
import * as Sentry from '@sentry/browser';
import {ENV, DISABLE_SENTRY} from 'config/Env';
import store from 'redux/store';
import {trans} from './LinguiConfig';

const pjson = require('../../package.json');
const isLocalHost = window.location.href.toString().includes('localhost');

const DSN = 'https://8e392b52aa4e4c9286175ec83958c3cf@sentry.io/1286754';

if (!isLocalHost && !DISABLE_SENTRY) {
  Sentry.init({
    dsn: DSN,
    debug: false,
    environment: ENV,
    release: pjson.version,
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
  });
}

export const showReportDialog = (eventId) => {
  Sentry.showReportDialog({
    dsn: DSN,
    eventId,
    lang: store.getState().language,
    title: t`Xin lỗi vì sự bất tiện này!`,
    subtitle: t`Chúng tôi sẽ phản hồi bạn nhanh nhất có thể.`,
    subtitle2: t`Cảm ơn bạn đã đóng góp ý kiến`,
    labelComments: t`Góp ý / ý kiến của bạn:`,
    labelName: t`Họ tên:`,
    labelClose: t`Đóng`,
    labelSubmit: t`Gửi`,
    errorGeneric: t`Có lỗi khi kết nối với máy chủ, bạn vui lòng thử lại!`,
    errorFormEntry: t`Bạn chưa cung cấp đủ thông tin để chúng tôi có thể hỗ trợ bạn tốt nhất, vui lòng kiểm tra lại!`,
    successMessage: t`Cảm ơn bạn đã tin dùng!`,
  });
};
