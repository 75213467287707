import {AutoComplete} from '@doopage/react-ui-kit';

import {getAllLanguageCode, getLanguageInfo} from 'languages';
import React from 'react';

const getLanguage = code => ({
    code,
    ...getLanguageInfo(code),
});
const languages = getAllLanguageCode().map(code => getLanguage(code));

const SelectLanguage = ({value, options, onChange, ...props}) => {
    let onHandleChange, getOptions = x => x;
    if (props.multiple) {
        value = Array.from(value).map(getLanguage);
        onHandleChange = items => onChange(items.map(item => item.code));
        getOptions = options => options.filter(option => !value.find(item => option.code === item.code));
    } else {
        value = getLanguage(value);
        onHandleChange = item => onChange(item.code);
    }
    if (options) {
        options = Array.from(options || []).map(getLanguage);
    } else {
        options = languages;
    }
    return (
        <AutoComplete
            {...props}
            value={value}
            onChange={onHandleChange}
            options={getOptions(options)}
        />
    );
};


export default SelectLanguage;
