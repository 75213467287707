import { Button } from '@material-ui/core';
import React from 'react';

const ButtonAdd = ({ label, onClick, ...props }) => {
  return (
    <Button
      onClick={onClick}
      variant="outlined"
      style={{
        width: '100%',
        border: '2px dashed #8c8c8c',
        borderRadius: '16px',
      }}
      {...props}>
      + {label}
    </Button>
  );
};

export default ButtonAdd;
