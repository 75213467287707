import { Trans } from '@lingui/macro';
import React from 'react';
import Avatar from '../../../component/Avatar';

const ToolTipStaffResponsePerformance = ({ item }) => {
  const { x, y, r, name, cart_count, avatar } = item;
  return (
    <div style={{ fontSize: 13, padding: 5 }}>
      <div className={'flex-start-row'}>
        <Avatar user={{ avatar }} size={40} />
        <div>{name}</div>
      </div>
      <div>
        <Trans>Tốc độ:</Trans>&nbsp;{y}&nbsp;phút
      </div>
      <div>
        <Trans>Khách hàng:</Trans>&nbsp;{x}
      </div>
    </div>
  );
};

export default ToolTipStaffResponsePerformance;
