import { t } from '@lingui/macro';
import { trans } from 'config/LinguiConfig';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import Routes from 'router/routes';
import routes from 'router/routes';
import BaseModal from '../BaseComponent/BaseModal';

class SyncOrderShopee extends BaseModal {
  constructor(props) {
    super(props);
    this.state = { open: true };
    this.goPathWhenClose = !this.props.openFromChannels ? Routes.shopee.path : Routes.channel.path;
    this.showCloseButton = false;
  }

  renderBody() {
    return (
      <div style={{ width: '100%' }}>
        <div>
          {t`Bạn có muốn đồng bộ đơn hàng và sản phẩm từ Shopee vào hệ thống? Đồng bộ đơn giúp bạn có thể tạo được kịch bản chatbot theo trạng thái đơn hàng.`}
        </div>
      </div>
    );
  }

  renderFooter() {
    return [
      <Button onClick={() => this.close(false)} color="info" simple>
        {t`Để sau`}
      </Button>,
      <Button
        onClick={() => {
          this.redirectTo(routes.syncProducts.path);
        }}
        color="info">
        {t`Đồng bộ ngay`}
      </Button>,
    ];
  }
}

export default SyncOrderShopee;
