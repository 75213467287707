import React from 'react';
import DV from '../variables/DV';
import { LIST_DOMAIN } from '../variables/staticValue';

export default class MainLogo extends React.PureComponent {
  render() {
    const { logo, mainLogoStyle } = LIST_DOMAIN[DV.partner];
    return (
      <img
        src={logo}
        alt="logo"
        style={{
          maxWidth: 200,
          width: '50vw',
          objectFit: 'contain',
          ...mainLogoStyle,
        }}
      />
    );
  }
}
