import { ReactComponent as Chorme } from './channels/chorme.svg';
import { ReactComponent as Email } from './channels/email.svg';
import { ReactComponent as Facebook } from './channels/facebook.svg';
import { ReactComponent as Instagram } from './channels/instagram.svg';
import { ReactComponent as Manual } from './channels/manual.svg';
import { ReactComponent as Phone } from './channels/phone.svg';
import { ReactComponent as Shopee } from './channels/shopee.svg';
import { ReactComponent as Tiktok } from './channels/tiktok.svg';
import { ReactComponent as Whatapp } from './channels/whatapp.svg';
import { ReactComponent as Zalo } from './channels/zalo.svg';
import { ReactComponent as Messenger } from './channels/messenger.svg';

export default {
  Chorme,
  Email,
  Facebook,
  Manual,
  Instagram,
  Phone,
  Shopee,
  Tiktok,
  Whatapp,
  Zalo,
  Messenger,
};
