import { t, Trans } from '@lingui/macro';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { addGBM, updateGBM } from 'api/api';
import { setState } from 'config/Core';
import { handleApi } from 'helper/helper';
import { toPairs } from 'lodash';
import ChipInput from 'material-ui-chip-input';
import Card from 'MUI_Kit/components/Card/Card';
import CardBody from 'MUI_Kit/components/Card/CardBody';
import CardHeader from 'MUI_Kit/components/Card/CardHeader';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import ImageUpload from 'MUI_Kit/components/CustomUpload/ImageUpload';
import Warning from 'MUI_Kit/components/Typography/Warning';
import React from 'react';
import Routes from 'router/routes';
import DV from 'variables/DV';
import BaseModal from '../../BaseComponent/BaseModal';
import AdvancedSwitch from './component/AdvancedSwitch';
import SelectLanguage from './component/SelectLanguage';

const CONTACT_OPTIONS = {
  WEB_CHAT: t`Web Chat`,
  PHONE: t`Phone`,
  EMAIL: t`Email`,
  FAQS: t`FAQs`,
  TWITTER: t`Twitter`,
  WHATSAPP: t`Whatsapp`,
};

class GBMModal extends BaseModal {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      display_name: '',
      image: '',
      languages_selected: [],
      default_language: '',
      url: '',
      contact_options: [],
      domains: [],
      phone: '',
      phones: [],
      brand_name: '',
      ...this.getDataFromRouter(),
    };
    this.imageUpload = null;

    this.goPathWhenClose = !this.props.openFromChannels ? Routes.gbm.path : Routes.channel.path;
  }

  getTitle() {
    const { id } = this.getDataFromRouter();
    if (id) return t`Cập nhật tài khoản`;
    else return t`Thêm tài khoản`;
  }

  update = async (data, id) => {
    await setState(this)({ loading: true });
    let { success, error } = await handleApi(updateGBM(id, data));
    await setState(this)({ loading: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Đã cập nhật thành công`);
    } else DV.showNotify(error, 'danger');
  };

  add = async (data) => {
    await setState(this)({ loading: true });
    let { success, data: result, error } = await handleApi(addGBM(data));
    await setState(this)({ loading: false });
    if (success) {
      // noinspection JSUnresolvedVariable
      if (result?.verification_state?.state === 'VERIFICATION_STATE_UNVERIFIED') {
        return this.redirectTo(Routes.gbm.path + '/verification/' + result.id, result, '', true);
      }
      this.close(true);
    } else DV.showNotify(error, 'danger');
  };

  check_url = (url) => {
    try {
      const url_parsed = new URL(url);
      return /^https?:$/.test(url_parsed.protocol) && !url_parsed.search && !url_parsed.hash;
    } catch (e) {
      return false;
    }
  };

  check_phone = (phone) => {
    return /^\+([0-9]{9,13})$/.test(phone);
  };

  handleSubmit = async () => {
    const {
      id,
      display_name,
      image,
      languages_selected,
      default_language,
      url,
      contact_options,
      domains,
      phone,
      phones,
      brand_name,
    } = this.state;
    if (!display_name) {
      DV.showNotify(t`Hãy nhập tên Google Business Message`, 'danger');
      return;
    }
    if (!image) {
      DV.showNotify(t`Hãy chọn một logo`, 'danger');
      return;
    }
    if (!languages_selected || !languages_selected.length) {
      DV.showNotify(t`Hãy chọn ít nhất một ngôn ngữ`, 'danger');
      return;
    }
    if (!default_language) {
      DV.showNotify(t`Hãy chọn ngôn ngữ mặc định`, 'danger');
      return;
    }
    if (!url) {
      DV.showNotify(t`Hãy điền URL tới Website của bạn`, 'danger');
      return;
    }
    if (!contact_options || !contact_options.length) {
      DV.showNotify(t`Hãy chọn ít nhất một kênh liên hệ`, 'danger');
      return;
    }
    if (!domains || !domains.length) {
      DV.showNotify(t`Hãy nhập ít nhất một domain`, 'danger');
      return;
    }
    if (!phone) {
      DV.showNotify(t`Hãy nhập số điện thoại mặc định`, 'danger');
      return;
    }
    for (const item of [url, ...domains]) {
      if (!this.check_url(item)) {
        DV.showNotify(
          t`URL ${item} không hợp lệ. Phải là định dạng https://example.com/`,
          'danger',
        );
        return;
      }
    }
    for (const item of [phone, ...phones]) {
      if (!this.check_phone(item)) {
        DV.showNotify(
          t`Số điện thoại ${item} không hợp lệ. Phải là định dạng +84123456789`,
          'danger',
        );
        return;
      }
    }
    const data = {
      display_name,
      image,
      languages_selected,
      default_language,
      url,
      contact_options,
      domains,
      phone,
      phones,
    };
    if (brand_name) {
      data.brand_name = brand_name;
    }
    if (id) {
      await this.update(data, id);
    } else await this.add(data);
  };

  handleImageChange = (file, url) => {
    this.setState({
      image: url,
      image_field: file,
    });
  };

  onChangeContactOption = (key) => {
    return (e) => {
      let { contact_options } = this.state;
      contact_options = contact_options.filter((item) => item !== key);
      if (e.target.checked) {
        contact_options.push(key);
      }
      this.setState({
        contact_options,
      });
    };
  };

  handleLanguagesChange = (items) => {
    const { default_language } = this.state;
    this.handleChange('languages_selected')(items);
    if (items.indexOf(default_language) === -1) {
      this.handleChange('default_language')('');
    }
  };

  renderBody() {
    const {
      display_name,
      image,
      languages_selected,
      default_language,
      url,
      contact_options,
      domains,
      phone,
      phones,
      brand_name,
    } = this.state;

    return [
      <Warning variant="body2" color="warning" style={{ textAlign: 'justify' }}>
        {t`Lưu ý: các thông tin này chỉ cài đặt một lần và không thể chỉnh sửa sau khi Google Business Message đã được xác minh.`}
      </Warning>,
      <Grid container direction="column" spacing={2} style={{ marginTop: 16 }}>
        <Grid container item justify="center">
          <Grid container item sm={3} justify="center" alignItems="center">
            <Grid item sm={10}>
              <ImageUpload
                ref={(ref) => (this.imageUpload = ref)}
                onChange={this.handleImageChange}
                imageSubmitAddress={image}
                size={113}
                borderRadius={100}
              />
            </Grid>
          </Grid>
          <Grid container item sm={9} spacing={5} justify="center">
            <Grid container item sm={10} direction="column" spacing={1}>
              <Grid item>
                <TextField
                  label={t`Tên hiển thị`}
                  type="text"
                  value={display_name || ''}
                  onChange={this.handleChange('display_name')}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <SelectLanguage
                  value={languages_selected}
                  multiple
                  label={t`Các ngôn ngữ được hỗ trợ`}
                  onChange={this.handleLanguagesChange}
                />
              </Grid>
              <Grid item>
                <SelectLanguage
                  value={default_language}
                  options={languages_selected}
                  label={t`Ngôn ngữ mặc định`}
                  onChange={this.handleChange('default_language')}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card style={{ boxShadow: 'none', margin: 0 }}>
          <CardHeader>
            <h4 style={{ marginBottom: -5 }}>{t`Website liên hệ`}</h4>
          </CardHeader>
          <CardBody>
            <Grid container item justify="center">
              <Grid item sm={12}>
                <TextField
                  // label={t`Website liên hệ`}
                  placeholder="https://your-website.com"
                  type="url"
                  value={url || ''}
                  onChange={this.handleChange('url')}
                  fullWidth
                />
              </Grid>
              <Grid item sm={12}>
                <p style={{ color: '#555555', marginTop: 10, marginBottom: -5, fontSize: 12 }}>
                  <Trans>Hỗ trợ hình thức liên lạc</Trans>:
                </p>
              </Grid>
              <Grid item sm={11}>
                {toPairs(CONTACT_OPTIONS).map(([key, value]) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contact_options.indexOf(key) >= 0}
                        onChange={this.onChangeContactOption(key)}
                        name={key}
                        color="primary"
                      />
                    }
                    label={value}
                  />
                ))}
              </Grid>
            </Grid>
          </CardBody>
        </Card>
        <Card style={{ boxShadow: 'none', margin: 0 }}>
          <CardHeader>
            <h4 style={{ marginBottom: -10 }}>{t`Domain`}</h4>
          </CardHeader>
          <CardBody>
            <Grid container item justify="center">
              <Grid item sm={12}>
                <ChipInput
                  // label={t`Domain`}
                  placeholder={t`Domain những website được phép hiển thị`}
                  value={domains}
                  onAdd={(value) => this.setState({ domains: [...domains, value] })}
                  onDelete={(value) =>
                    this.setState({ domains: domains.filter((x) => x !== value) })
                  }
                  fullWidth
                  fullWidthInput
                />
              </Grid>
            </Grid>
          </CardBody>
        </Card>
        <Card style={{ boxShadow: 'none', margin: 0 }}>
          <CardHeader>
            <h4 style={{ marginBottom: -10 }}>{t`Số điện thoại liên hệ`}</h4>
          </CardHeader>
          <CardBody>
            <Grid container item spacing={1} justify="center">
              <Grid item sm={12}>
                <TextField
                  label={t`Số điện thoại chính`}
                  type="text"
                  value={phone || ''}
                  onChange={this.handleChange('phone')}
                  fullWidth
                />
              </Grid>
              <Grid item sm={12}>
                <ChipInput
                  label={t`Danh sách số điện thoại phụ`}
                  value={phones}
                  onAdd={(value) => this.setState({ phones: [...phones, value] })}
                  onDelete={(value) => this.setState({ phones: phones.filter((x) => x !== value) })}
                  fullWidth
                  fullWidthInput
                />
              </Grid>
            </Grid>
          </CardBody>
        </Card>
        <Grid container item justify="center">
          <AdvancedSwitch label={t`Nâng cao`}>
            <Grid item sm={11}>
              <TextField
                label={t`Tên thương hiệu`}
                type="text"
                value={brand_name || ''}
                onChange={this.handleChange('brand_name')}
                fullWidth
              />
            </Grid>
          </AdvancedSwitch>
        </Grid>
      </Grid>,
    ];
  }

  renderFooter() {
    const { loading, id } = this.state;
    return [
      <Button onClick={this.handleSubmit} color="info" loading={loading}>
        {id ? t`Hoàn tất` : t`Thêm GBM`}
      </Button>,
    ];
  }
}

export default GBMModal;
