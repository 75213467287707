import {AutoComplete} from '@doopage/react-ui-kit';
import {Trans} from '@lingui/macro';
import {days as _days} from 'config/Date';
import React from 'react';

const days = _days.map(({key, value}) => ({
    code: key,
    name: value,
}));

const getDay = code => days.find(day => day.code === code);

const SelectDay = ({value, options, onChange, ...props}) => {
    let onHandleChange, getOptions = x => x;
    if (props.multiple) {
        value = Array.from(value).map(getDay);
        onHandleChange = items => onChange(items.map(item => item.code));
        getOptions = options => options.filter(option => !value.find(item => option.code === item.code));
    } else {
        value = getDay(value);
        onHandleChange = item => onChange(item.code);
    }
    if (options) {
        options = Array.from(options || []).map(getDay);
    } else {
        options = days;
    }
    return (
        <AutoComplete
            {...props}
            value={value}
            onChange={onHandleChange}
            options={getOptions(options)}
            renderOption={(option) => {
                return <Trans>{option.name}</Trans>;
            }}
        />
    );
};


export default SelectDay;
