import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import { registerZnsTemplates, updateZnsTemplates, uploadZnsTemplateLogo } from 'api/api.js';
import BaseModal from 'component/BaseComponent/BaseModal';
import { trans } from 'config/LinguiConfig';
import { getFormData, handleApi, validateURL } from 'helper/helper.js';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import Routes from 'router/routes.js';
import { Input, showErrorSnackbar, showSuccessSnackbar } from '@doopage/react-ui-kit';
import ImageUpload from '../../../../MUI_Kit/components/CustomUpload/ImageUpload';
import { setState } from '../../../../config/Core';
import SelectSendingPurpose from './components/SelectSendingPurpose';
import TemplateContent from './components/TemplateContent';
import {
  countNumberOfParagraphs,
  countSubstitution,
  validContent,
  validLogo,
  validTemplateName,
  validTitle,
} from './utils';
import ModalCropImage from '../../ModalCropImage';

class RegisterZnsTemplateModal extends BaseModal {
  constructor(props) {
    super(props);
    const data = this.getDataFromRouter();
    const {
      id,
      template_name,
      short_code,
      template_request_data = {},
      zalo_oa_id,
      rejected_reason,
      template_status,
    } = data;
    const { content, dark_logo, light_logo, reason_tag, title, active_condition } =
      template_request_data;
    console.log('RegisterZnsTemplateModal id:', id);
    if (!zalo_oa_id) this.redirectTo(Routes.zalo.path);
    this.maxWidth = 'sm';
    this.state = {
      open: true,
      loading: false,
      title: id ? t`Cập nhật mẫu` : t`Đăng ký mẫu`,
      name: template_name || '',
      shortCode: short_code || '',
      lightLogo: light_logo ? { url: light_logo } : null,
      darkLogo: dark_logo ? { url: dark_logo } : null,
      titleTemplate: title || '',
      content: content || '',
      sendingPurpose: reason_tag || null,
      note: active_condition || '',
      zaloOAId: zalo_oa_id,
      templateId: id,
      rejectedReason: rejected_reason,
      showValidError: false,
      templateStatus: template_status || '',
    };
    this.goPathWhenClose = Routes.znsTemplates.path;
  }

  handleImageChange = (name) => async (file, url) => {
    const oldState = { ...(this.state?.[name] ?? {}) };
    await setState(this)({
      loading: true,
      [name]: { url, file },
    });

    const { error, data } = await handleApi(uploadZnsTemplateLogo(getFormData({ file })));

    if (error) {
      await setState(this)({
        loading: false,
        [name]: oldState,
      });
      showErrorSnackbar(error || trans(t`Lỗi upload logo. Vui lòng thử lại.`));
    } else {
      await setState(this)({
        loading: false,
        [name]: { url: data?.url },
      });
    }
  };

  renderBody() {
    let {
      name,
      shortCode,
      darkLogo,
      lightLogo,
      titleTemplate,
      content,
      buttons,
      sendingPurpose,
      note,
      showValidError,
      rejectedReason,
      templateStatus,
    } = this.state;
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Input
            fullWidth
            label={t`Tên mẫu`}
            placeholder={t`Tên mẫu`}
            value={name}
            onChange={this.handleChange('name')}
            error={showValidError ? validTemplateName(name) : undefined}
          />
        </Grid>

        {rejectedReason && (
          <Grid item xs={12}>
            <div className="flex flex-row rounded bg-[#faebcc] text-[#555555] p-5">
              <i className="fas fa-minus-circle text-[#d6030a] mt-1 mr-3" />
              <div>
                <div className="font-semibold">{t`Mẫu tin của bạn chưa được duyệt vì lý do sau:`}</div>
                <ul style={{ listStyle: 'disc', marginLeft: 20 }}>
                  <li>{rejectedReason}</li>
                </ul>
              </div>
            </div>
          </Grid>
        )}

        <ModalCropImage />

        {templateStatus !== 'ENABLE' && (
          <>
            <Grid item xs={12} md={6}>
              <div className="flex-center-col mb-3">
                <h5>{t`Logo`}</h5>
                <ImageUpload
                  thumbnailStyle={{ width: 200, height: 48 }}
                  ref={(ref) => (this.uploadLightLogo = ref)}
                  onChange={this.handleImageChange('lightLogo')}
                  imageSubmitAddress={lightLogo?.url}
                  error={showValidError ? validLogo(lightLogo) : undefined}
                  crop={{ width: 400, height: 96 }}
                  hideButton={true}
                />
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className="flex-center-col  mb-3">
                <h5>{t`Logo chế độ tối`}</h5>
                <ImageUpload
                  thumbnailStyle={{ width: 200, height: 48 }}
                  ref={(ref) => (this.uploadDarkLogo = ref)}
                  onChange={this.handleImageChange('darkLogo')}
                  imageSubmitAddress={darkLogo?.url}
                  error={showValidError ? validLogo(darkLogo) : undefined}
                  crop={{ width: 400, height: 96 }}
                  hideButton={true}
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <TemplateContent
                onChange={this.handleChange}
                darkLogo={darkLogo}
                lightLogo={lightLogo}
                titleTemplate={titleTemplate}
                content={content}
                buttons={buttons || []}
                showValidError={showValidError}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectSendingPurpose
                value={sendingPurpose}
                onChange={this.handleChange('sendingPurpose')}
              />
            </Grid>

            <Grid item xs={12}>
              <Input
                multiline
                minRows={1}
                fullWidth
                label={t`Ghi chú cho người kiểm duyệt`}
                placeholder={t`Ghi chú cho người kiểm duyệt`}
                value={note}
                onChange={this.handleChange('note')}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Input
            fullWidth
            label={'Shortcode'}
            placeholder={'/shortcode'}
            value={shortCode}
            onChange={this.handleChange('shortCode')}
          />
        </Grid>
      </Grid>
    );
  }

  handleSubmit = async () => {
    const {
      name,
      shortCode,
      lightLogo,
      darkLogo,
      titleTemplate,
      content,
      sendingPurpose,
      note,
      buttons,
      zaloOAId,
      templateId,
    } = this.state;
    console.log({
      countPara: countNumberOfParagraphs(content),
      countSubstitution: countSubstitution(content),
      content,
    });

    if (!this.validate()) return;
    this.setState({ loading: true });

    let payload = {
      template_name: name,
      short_code: shortCode,
      template_request_data: {
        light_logo: lightLogo?.url,
        dark_logo: darkLogo?.url,
        title: titleTemplate,
        content,
        buttons,
        reason_tag: sendingPurpose,
        active_condition: note,
      },
    };
    console.log('znsTemlate handleSubmit', payload);
    if (templateId) {
      const { success, error } = await handleApi(updateZnsTemplates(templateId, payload));
      if (success) {
        showSuccessSnackbar(trans(t`Cập nhật mẫu thành công`));
        this.close(true);
      } else {
        showErrorSnackbar(error || trans(t`Có lỗi xảy ra`));
      }
    } else {
      payload.zalo_oa_id = zaloOAId;
      const { success, error } = await handleApi(registerZnsTemplates(payload));
      if (success) {
        showSuccessSnackbar(trans(t`Đăng ký mẫu thành công`));
        this.close(true);
      } else {
        showErrorSnackbar(error || trans(t`Có lỗi xảy ra`));
      }
    }
    this.setState({ loading: false });
  };

  validate = () => {
    const { name, titleTemplate, content, lightLogo, darkLogo } = this.state;
    this.setState({ showValidError: true });
    return (
      validTemplateName(name) === null &&
      validTitle(titleTemplate) === null &&
      validContent(content) === null &&
      validLogo(lightLogo) === null &&
      validLogo(darkLogo) === null
    );
  };

  renderFooter() {
    return (
      <Button loading={this.state.loading} onClick={this.handleSubmit} color="info">
        {trans(t`Hoàn tất`)}
      </Button>
    );
  }
}

export default RegisterZnsTemplateModal;
