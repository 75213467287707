import { t } from '@lingui/macro';
import IconButton from '@material-ui/core/IconButton/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { addSendo, updateSendo } from 'api/api.js';
import { setState } from 'config/Core.js';
import _ from 'lodash';
import React from 'react';
import Routes from 'router/routes.js';
import DV from 'variables/DV.js';
import { LIST_DOMAIN } from 'variables/staticValue.js';
import { trans } from '../../config/LinguiConfig';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import CustomInput from '../../MUI_Kit/components/CustomInput/CustomInput';
import Warning from '../../MUI_Kit/components/Typography/Warning';
import BaseModal from '../BaseComponent/BaseModal';
import { handleApi, validatePhone } from 'helper/helper';

class SendoModal extends BaseModal {
  constructor(props) {
    super(props);
    let params = this.getDataFromRouter();
    if (_.isEmpty(params)) this.redirectTo(Routes.sendo.path);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      username: '',
      password: '',
      showPassword: false,
      ...params,
    };

    this.goPathWhenClose = !this.props.openFromChannels ? Routes.sendo.path : Routes.channel.path;
  }

  getTitle() {
    if (this.state.id) return t`Cập nhật tài khoản`;
    else return t`Thêm tài khoản`;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value.trim(),
    });
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  renderLoginForm = () => {
    const { id, username, password, showPassword } = this.state;

    return (
      <form>
        <CustomInput
          labelText={t`Số điện thoại`}
          validInput={(value) => validatePhone(value)}
          errorText={t`Số điện thoại không đúng định dạng`}
          required
          inputProps={{
            disabled: !!id,
            placeholder: t`Nhập vào số điện thoại`,
            type: 'text',
            value: username || '',
            onChange: this.handleChange('username'),
          }}
        />
        <CustomInput
          labelText={t`Mật khẩu`}
          required
          inputProps={{
            placeholder: t`Nhập vào mật khẩu`,
            type: showPassword ? 'text' : 'password',
            value: password || '',
            onChange: this.handleChange('password'),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}>
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {!id && (
          <Warning variant="body2" color="warning" style={{ textAlign: 'justify', marginTop: 30 }}>
            {t`Lưu ý: Sendo không chính thức hỗ trợ tích hợp quản lý tin nhắn từ xa. Bạn cần phải hiểu rằng
				chúng tôi cần phải lưu trữ mật khẩu Sendo của bạn để có thể sử dụng tính năng này. Chính vì
				vậy, để an toàn cho chính bạn, bạn vui lòng đổi mật khẩu Sendo bạn muốn chúng tôi quản lý
				thành một mật khẩu khác không trùng với những mật khẩu mà bạn thường hay sử dụng.`}
          </Warning>
        )}
      </form>
    );
  };

  renderBody() {
    return this.renderLoginForm();
  }

  update = async (payload, id) => {
    this.setState({ loading: true });
    let { success, error, data } = await handleApi(updateSendo(id, payload));
    this.setState({ loading: false });

    if (error) {
      DV.showNotify(error, 'danger');
      return { error };
    }
    if (success) {
      this.refreshOnClose = true;
      DV.showNotify(t`Cập nhật thành công`);
      return { success: true };
    }
  };

  add = async (username = '', password = '') => {
    await setState(this)({ loading: true });
    let { success, data, error } = await handleApi(
      addSendo(
        {
          username,
          password,
        },
        DV.company.id,
      ),
    );
    await setState(this)({ loading: false });

    if (error) {
      DV.showNotify(error, 'danger');
      return { error };
    }
    if (success) {
      this.refreshOnClose = true;
      DV.showNotify(t`Thêm thành công`);
      return { success: true };
    }
  };

  validate = () => {
    const { username, password } = this.state;
    return username && username !== '' && password && password !== '';
  };

  handleSubmit = async () => {
    const { id, username, password } = this.state;
    let res = id
      ? await this.update({ username, password }, id)
      : await this.add(username, password);

    if (res.success) {
      this.close(true);
    }
  };

  renderFooter() {
    const { loading } = this.state;
    return [
      <Button
        href={LIST_DOMAIN[DV.partner].guide.sendo}
        color="info"
        simple
        target="_blank"
        rel="noopener noreferrer">
        {t`Hướng dẫn`}
      </Button>,
      <Button onClick={this.handleSubmit} color="info" loading={loading}>
        {t`Hoàn tất`}
      </Button>,
    ];
  }
}

export default SendoModal;
