import { t, Trans } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import Icon from '@material-ui/core/Icon/Icon';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { addProduct, getProductAttributes, updateProduct } from 'api/api';
import { setState } from 'config/Core';
import { trans } from 'config/LinguiConfig';
import { getFormData, handleApi, validateHTTP } from 'helper/helper';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import IconButton from 'MUI_Kit/components/CustomButtons/IconButton';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import CustomSelect from 'MUI_Kit/components/CustomSelect/CustomSelect';
import ImageUpload from 'MUI_Kit/components/CustomUpload/ImageUpload.jsx';
import GridContainer from 'MUI_Kit/components/Grid/GridContainer';
import GridItem from 'MUI_Kit/components/Grid/GridItem';
import CustomSwitch from 'MUI_Kit/components/Switch/CustomSwitch';
import React from 'react';
import ReactTable from 'react-table';
import Routes from 'router/routes';
import DV from 'variables/DV';
import BaseModal from '../../BaseComponent/BaseModal';
import CustomChipColor from '../../CustomChip/CustomChipColor';
import CustomNumberFormat from '../../CustomNumberFormat';

class ProductModal extends BaseModal {
  maxWidth = 'md';

  constructor(props) {
    super(props);
    this.isSaveData = true;
    this.goPathWhenClose = Routes.product.path;

    let product = this.getDataFromRouter();
    let isEdit = product && product.id;
    let localData = this.getSaveData();

    this.state = {
      file: null,
      open: true,
      headerTable: [],
      title: '',
      loading: false,
      listAttributes: [],
      variant: this.convertVariantToTable(product.variant),
      attr: this.convertVariantToAttribute(product.variant),
      description: '',
      isUpdateModal: !!product.variant,
      height: product.package_size?.height || 0,
      width: product.package_size?.width || 0,
      length: product.package_size?.length || 0,
      ...product,
      name: product.origin_name ?? product.name,
      isEdit,
      ...localData,
    };
  }

  updateTable = () => {
    this.updateHeaderTable();
    this.updateDataTable();
  };

  async componentDidMount() {
    let { error, success, data } = await handleApi(getProductAttributes());
    if (success) this.setState({ listAttributes: data });
    else DV.showNotify(error, 'danger');

    this.updateTable();
  }

  update = async (id, product) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(updateProduct(id, getFormData(product)));
    this.setState({ loading: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Đã cập nhật sản phẩm`);
    } else DV.showNotify(error, 'danger');
  };

  add = async (product) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(addProduct(getFormData(product)));
    this.setState({ loading: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Thêm sản phẩm thành công`);
    } else DV.showNotify(error, 'danger');
  };

  convertVariantToAttribute = (variant) => {
    if (!variant) return [{ attributeId: 0, value: [] }];
    console.log('convertVariantToAttribute', variant);
    let array = [];
    variant.forEach((item) => {
      item.attributes.forEach((item) => {
        let { id, value } = item;
        console.log('convertVariantToAttribute item', item, array);
        let index = array.findIndex((item) => item.attributeId === id);
        if (index < 0) array.push({ attributeId: id, value: [value] });
        else if (!array[index].value.includes(value)) array[index].value.push(value);
      });
    });
    return array;
  };

  convertVariantToTable = (variant) => {
    if (!variant) return [];
    return variant.map((item) => {
      let obj = {};
      obj.price = item.price;
      obj.status = item.enable;
      obj.sku = item.sku;
      obj.id = item.id || '';
      item.attributes.forEach((item) => (obj[item.id] = item.value));
      return obj;
    });
  };

  convertTableToVariant = () => {
    const { variant } = this.state;
    return variant.map((item) => {
      let obj = {};
      obj.price = item.price;
      obj.enable = item.status;
      obj.sku = item.sku;
      obj.attributes = [];
      obj.id = item.id;
      Object.keys(item).forEach((key) => {
        if (key !== 'price' && key !== 'status' && key !== 'sku' && !isNaN(key)) {
          obj.attributes.push({ id: Number(key), value: item[key] });
        }
      });

      console.log('convertTableToVariant', obj);
      return obj;
    });
  };

  getTitle() {
    const { id } = this.getDataFromRouter();
    if (id) return t`Cập nhật sản phẩm`;
    else return t`Thêm sản phẩm`;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target ? event.target.value : event,
    });
  };

  renderBody() {
    return (
      <>
        {this.renderProductInfo()}
        {this.renderAttributes()}
        {this.renderProductTable()}
      </>
    );
  }

  renderFooter() {
    let { loading } = this.state;
    return (
      <Button loading={loading} onClick={this.postData} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }

  postData = () => {
    let {
      image,
      sku,
      name,
      weight,
      unit,
      price,
      isEdit,
      id,
      variant,
      status,
      enable,
      file,
      description,
      height,
      width,
      length,
    } = this.state;
    let { add, update } = this;

    variant = this.convertTableToVariant(variant).filter((v) => v.sku || v.attributes.length);

    let payload = {
      image: validateHTTP(image) ? null : file,
      sku,
      short_name: name,
      weight,
      unit,
      price,
      variant,
      status,
      enable,
      description,
      package_size: { height, width, length },
    };
    if (!this.validData(payload)) return;

    if (!isEdit) add(payload);
    else update(id, payload);
  };

  validData = (payload) => {
    const { sku, short_name, weight, price, unit } = payload;
    if (!!!short_name) {
      DV.showNotify(t`Vui lòng nhập tên sản phẩm`, 'danger');
      return false;
    }
    // if (!!!sku)
    // {
    // 	showNotify((t`Vui lòng nhập SKU`), "danger");
    // 	return false;
    // }
    if (!!!price) {
      DV.showNotify(t`Vui lòng nhập giá sản phẩm`, 'danger');
      return false;
    }
    if (!!!weight) {
      DV.showNotify(t`Vui lòng nhập khối lương`, 'danger');
      return false;
    }
    if (!!!unit) {
      DV.showNotify(t`Vui lòng nhập đơn vị`, 'danger');
      return false;
    }
    return true;
  };

  handleImageChange = (file, url) => {
    this.setState({
      image: url,
      file,
    });
  };

  renderProductInfo = () => {
    let { image, sku, name, weight, unit, price, index, description, height, width, length } =
      this.state;
    return (
      <GridContainer>
        <GridItem xs={12} sm={4} md={3} lg={3} xl={3}>
          <Grid container justify="center">
            <div className="flex-center-col">
              <h5>{t`Hình sản phẩm`}</h5>
              <ImageUpload
                ref={(ref) => (this.imageUpload = ref)}
                onChange={this.handleImageChange}
                imageSubmitAddress={image}
              />
            </div>
          </Grid>
        </GridItem>
        <GridItem xs={12} sm={8} md={9} lg={9} xl={9}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomInput
                labelText={t`Tên sản phẩm`}
                inputProps={{
                  required: true,
                  value: name || '',
                  onChange: this.handleChange('name'),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInput
                labelText={t`SKU`}
                inputProps={{
                  required: false,
                  placeholder: t`Đơn vị phân loại hàng hóa`,
                  value: sku || '',
                  onChange: this.handleChange('sku'),
                }}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <CustomInput
                labelText={t`Đơn vị tính`}
                inputProps={{
                  required: true,
                  placeholder: t`Đơn vị tính`,
                  value: unit || '',
                  onChange: this.handleChange('unit'),
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <CustomInput
                labelText={t`Khối lượng`}
                inputProps={{
                  required: true,
                  value: weight || 0,
                  type: 'number',
                  endAdornment: <InputAdornment position="end">Kg</InputAdornment>,
                  onChange: this.handleChange('weight'),
                }}
              />
            </Grid>
          </Grid>
          <CustomInput
            labelText={t`Giá`}
            inputProps={{
              required: true,
              value: price || '',
              onChange: this.handleChange('price'),
              inputComponent: CustomNumberFormat,
            }}
          />
          <CustomInput
            labelText={t`Mô tả`}
            inputProps={{
              required: true,
              value: description || '',
              onChange: this.handleChange('description'),
              multiline: true,
            }}
          />
          <Grid container spacing={2} style={{ alignItems: 'flex-end' }}>
            <Grid
              item
              style={{
                position: 'relative',
                bottom: 5,
              }}>
              <span style={{ fontSize: 14 }}>{t`Kích Thước`}</span> &nbsp;
              <small style={{ fontSize: 8 }}>{t`(DxRxC)`}</small> &nbsp; :
            </Grid>
            <Grid item xs={2}>
              <CustomInput
                inputProps={{
                  type: 'number',
                  required: true,
                  value: length || 0,
                  onChange: this.handleChange('length'),
                  endAdornment: <InputAdornment position="end">Cm</InputAdornment>,
                }}
              />
            </Grid>
            <span style={{ position: 'relative', bottom: 20 }}>x</span>
            <Grid item xs={2}>
              <CustomInput
                inputProps={{
                  type: 'number',
                  required: true,
                  value: width || 0,
                  onChange: this.handleChange('width'),
                  endAdornment: <InputAdornment position="end">Cm</InputAdornment>,
                }}
              />
            </Grid>
            <span style={{ position: 'relative', bottom: 20 }}>x</span>
            <Grid item xs={2}>
              <CustomInput
                inputProps={{
                  type: 'number',
                  required: true,
                  value: height || 0,
                  onChange: this.handleChange('height'),
                  endAdornment: <InputAdornment position="end">Cm</InputAdornment>,
                }}
              />
            </Grid>
          </Grid>
        </GridItem>
      </GridContainer>
    );
  };

  handleAttribute = async (index = -1) => {
    let { listAttributes, attr } = this.state;
    if (index >= 0) attr.splice(index, 1);
    else {
      if (attr.length < listAttributes.length) attr.push({ attributeId: 0, value: [] });
    }

    await setState(this)({ attr: [...attr] });

    this.updateTable();
  };

  renderAttributes = () => {
    let { listAttributes, attr } = this.state;
    let isAttributesEmpty = !listAttributes || (listAttributes && listAttributes.length === 0);
    return [
      <GridContainer>
        <GridItem>
          <div style={{ fontSize: 20, marginTop: 20 }}>{t`Thuộc tính`}</div>
        </GridItem>
        <GridItem>
          {attr.length < listAttributes.length && (
            <IconButton
              color="success"
              round
              justIcon
              helperText={t`Thêm thuộc tính`}
              style={{ marginTop: 15 }}
              onClick={this.handleAttribute}>
              <Icon>add</Icon>
            </IconButton>
          )}
        </GridItem>
      </GridContainer>,
      this.renderAttribute(attr, isAttributesEmpty),
    ];
  };

  getAttributeSelect = (index) => {
    let dataSelect = [];
    let { listAttributes, attr } = this.state;

    for (let i = 0; i < listAttributes.length; i++) {
      let t = attr.findIndex((elem) => elem.attributeId === listAttributes[i].id);
      if (t < 0 || t === index) {
        dataSelect.push({
          key: listAttributes[i].id,
          value: listAttributes[i].name,
        });
      }
    }
    return dataSelect;
  };

  handleAddAttribute =
    (index) =>
    async (...chips) => {
      chips = chips.map((chip) => chip.trim());

      let data = [...this.state.attr];
      data[index].value = [...data[index].value, ...chips];

      await setState(this)({ attr: data });

      this.updateTable();
    };

  handleDeleteAttribute = (index) => async (deletedChip) => {
    let data = [...this.state.attr];
    data[index].value = data[index].value.filter((c) =>
      !!c.name ? c.name !== deletedChip : c !== deletedChip,
    );
    await setState(this)({ attr: data });

    this.updateTable();
  };

  handleChangeTypeAttribute = (index) => async (event) => {
    let data = [...this.state.attr];
    data[index].attributeId = event;
    await setState(this)({ attr: data });

    this.updateTable();
  };

  renderAttribute = (data, isAttributesEmpty) => {
    if (isAttributesEmpty) {
      return (
        <div className="flex-center-col">
          <div className="bold">{t`Hiện chưa có thuộc tính nào. Vui lòng thêm thuộc tính.`}</div>
          <Button
            color="info"
            round
            onClick={() => this.redirectTo(Routes.attribute.create.path)}
            helperText="Thêm thuộc tính">
            {t`Thêm thuộc tính`}
          </Button>
        </div>
      );
    }

    return data.map((item, index) => {
      let dataSelect = this.getAttributeSelect(index);
      if (dataSelect.length === 0) return null;
      return (
        <GridContainer key={index} style={{ marginTop: 5 }}>
          <GridItem xs={3}>
            <CustomSelect
              label={t`Chọn thuộc tính`}
              data={dataSelect || []}
              value={data[index].attributeId || ''}
              formControlProps={{ fullWidth: true }}
              addButton={{
                onClick: () => this.redirectToModal(Routes.attribute.create.path),
                name: t`Thêm thuộc tính`,
              }}
              onChange={this.handleChangeTypeAttribute(index)}
            />
          </GridItem>
          <GridItem xs={8}>
            <CustomChipColor
              label={t`Giá trị`}
              placeholder={t`Nhập vào giá trị thuộc tính, Enter để hoàn tất`}
              value={data[index].value}
              onAdd={this.handleAddAttribute(index)}
              onDelete={this.handleDeleteAttribute(index)}
              fullWidth
            />
          </GridItem>
          <GridItem xs={1} className="flex-end-row">
            <Button
              color="danger"
              round
              justIcon
              style={{ marginTop: 15 }}
              helperText={t`Xoá thuộc tính này`}
              onClick={() => this.handleAttribute(index)}>
              <Icon>close</Icon>
            </Button>
          </GridItem>
        </GridContainer>
      );
    });
  };

  findAttributeName = (id) => {
    let { listAttributes } = this.state;
    let index = listAttributes.findIndex((item) => item.id === id);
    if (index < 0) return null;
    else return listAttributes[index].name;
  };

  handleSwitchTableChange = (index) => async (event) => {
    let value = event.target.checked;
    let { variant } = this.state;
    variant[index].status = value;
    this.setState({ variant: [...variant] });
  };

  handleInputTableChange = (index, name) => async (event) => {
    let value = event.target.value;
    let { variant } = this.state;
    variant[index][name] = value;
    this.setState({ variant: [...variant] });
  };

  updateHeaderTable = () => {
    const { attr } = this.state;
    let a = [];
    //console.log('updateHeaderTable', {attr});
    attr.forEach((item) => {
      let name = this.findAttributeName(item.attributeId);
      if (name) a.push({ Header: name, accessor: item.attributeId + '' });
    });

    if (a.length === 0) this.setState({ headerTable: [] });
    else
      this.setState({
        headerTable: [
          ...a,
          {
            Header: <Trans>SKU</Trans>,
            accessor: 'sku',
            Cell: (row) => {
              return (
                <CustomInput
                  labelText={t`SKU`}
                  inputProps={{
                    value: row.value || '',
                    onChange: this.handleInputTableChange(row.index, 'sku'),
                  }}
                />
              );
            },
          },
          {
            Header: <Trans>Tồn kho</Trans>,
            accessor: 'status',
            width: 140,
            Cell: (row) => {
              return (
                <CustomSwitch
                  checked={row.value !== false}
                  onChange={this.handleSwitchTableChange(row.index)}
                  color={'primary'}
                />
              );
            },
          },
          {
            Header: <Trans>Giá</Trans>,
            accessor: 'price',
            Cell: (row) => {
              return (
                <CustomInput
                  labelText={t`Giá`}
                  inputProps={{
                    value: row.original.price || '',
                    onChange: this.handleInputTableChange(row.index, 'price'),
                    inputComponent: CustomNumberFormat,
                  }}
                />
              );
            },
          },
        ],
      });
  };

  updateDataTable = () => {
    this.dataTable = [];
    this.getFieldValue(0);
    console.log('updateDataTable', this.dataTable, this.state.variant);
    this.setState({ variant: [...this.dataTable] });
  };

  getVariantInfo = (obj) => {
    const { variant, isUpdateModal, price } = this.state;
    const defaultPrice = price ?? '';
    if (!isUpdateModal || !variant) return { price: defaultPrice, status: true };

    console.log('getVariantPrice', obj, variant);

    const index = variant.findIndex(({ attributes }) => {
      console.log('attributes', attributes);
      const convertAttr =
        attributes?.reduce((result, { id, value }) => {
          result[id] = value;
          return result;
        }, {}) ?? {};
      return JSON.stringify(convertAttr) === JSON.stringify(obj);
    });

    console.log('index', index);
    if (index < 0) return { price: defaultPrice, status: true };
    else return { status: !!variant[index].enable, ...variant[index] };
  };

  getFieldValue = (index, obj = {}) => {
    const { attr } = this.state;
    if (attr.length === 0) return;
    if (index === attr.length && attr.length > 0) {
      this.dataTable.push({ ...obj, ...this.getVariantInfo(obj) });
    } else {
      if (!attr[index].value || attr[index].value.length === 0) this.getFieldValue(index + 1, obj);
      else
        attr[index].value.forEach((item) => {
          obj[attr[index].attributeId] = item;
          this.getFieldValue(index + 1, obj);
        });
    }
  };

  renderProductTable = () => {
    const { variant, headerTable } = this.state;
    if (variant.length === 0 || headerTable.length === 0) return null;

    return (
      <GridContainer>
        <GridItem xs={12}>
          <div style={{ fontSize: 20, marginTop: 20, marginBottom: 10 }}>{t`Biến thể`}</div>
        </GridItem>
        <GridItem xs={12}>
          <ReactTable
            previousText={t`Trang trước`}
            nextText={t`Trang sau`}
            pageText={t`Trang`}
            ofText={'/'}
            rowsText={t`Dòng`}
            noDataText={t`Không có dữ liệu`}
            loadingText={t`Đang tải dữ liệu...`}
            data={variant}
            columns={headerTable}
            defaultPageSize={5}
            minRows={variant.length > 5 ? 5 : 1}
            showPaginationTop={false}
            showPaginationBottom={variant.length > 5}
            className="-striped -highlight"
          />
        </GridItem>
      </GridContainer>
    );
  };
}

ProductModal.propTypes = {};

export default ProductModal;
