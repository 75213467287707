// ##############################
// // // Badge component styles
// #############################

import {
  dangerColor,
  grayColor,
  infoColor,
  primaryColor,
  roseColor,
  successColor,
  warningColor,
} from '../../../../assets/jss/material-dashboard-pro-react.jsx';

const badgeStyle = {
  badge: {
    borderRadius: '12px',
    padding: '5px 12px',
    fontSize: 13,
    lineHeight: '1',
    color: '#fff',
    textAlign: 'center',
    verticalAlign: 'baseline',
    display: 'inline-block',
  },
  small: {
    fontSize: 10,
  },
  primary: {
    backgroundColor: primaryColor,
  },
  warning: {
    backgroundColor: warningColor,
  },
  danger: {
    backgroundColor: dangerColor,
  },
  success: {
    backgroundColor: successColor,
  },
  info: {
    backgroundColor: infoColor,
  },
  rose: {
    backgroundColor: roseColor,
  },
  gray: {
    backgroundColor: grayColor,
  },
};
export default badgeStyle;
