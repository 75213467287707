import { baseReduxObj, STATE_SOCKET } from '../../variables/staticValue';
import createReducer from '../createReducer';
import { Types } from '../type';
import LocalStorage from '../../config/LocalStorage';
import { addMonths } from 'date-fns';
import { sortBy } from 'lodash';
import { getDefaultLanguage } from '../../config/LinguiConfig';

const getDateRange = () => {
  let { startDate, endDate } = LocalStorage.getStatisticDateRange();
  startDate = !startDate ? addMonths(new Date(), -1) : new Date(startDate);
  endDate = !endDate ? new Date() : new Date(endDate);
  return { startDate, endDate };
};

export const INIT_STATE_LIST = {
  fanpages: baseReduxObj,
  zaloOAs: baseReduxObj,
  products: baseReduxObj,
  staffs: baseReduxObj,
  websites: baseReduxObj,
  rules: baseReduxObj,
  orders: baseReduxObj,
  pickAddresses: baseReduxObj,
  subscriptions: baseReduxObj,
  productAttributes: baseReduxObj,
  variants: baseReduxObj,
  instagrams: baseReduxObj,
  shopee: baseReduxObj,
  sendo: baseReduxObj,
  email: baseReduxObj,
  personalZalo: baseReduxObj,
  personalfbs: baseReduxObj,
  fbGroups: baseReduxObj,
  customers: baseReduxObj,
  sourceSyncProduct: baseReduxObj,
  segments: baseReduxObj,
  tags: baseReduxObj,
  shipmentServices: baseReduxObj,
  broadcasts: baseReduxObj,
  quickReplies: baseReduxObj,
  paymentMethods: baseReduxObj,
  listPbx: baseReduxObj,
  callAgents: baseReduxObj,
  agents: baseReduxObj,
  manualChannels: baseReduxObj,
  znsTemplates: baseReduxObj,
  gbms: baseReduxObj,
  gbmLocations: baseReduxObj,
  appleBusinesses: baseReduxObj,
  graphs: [],
  statisticDateRange: getDateRange(),
  stateConnectSocket: STATE_SOCKET.none,
};

const INIT_STATE = {
  company: baseReduxObj,
  owner: baseReduxObj,
  ...INIT_STATE_LIST,
  currentProduct: { id: null, name: '' },
  notifyMessage: null,
  notifyColor: null,
  online: true,
  language: getDefaultLanguage(),
  currentCompany: { id: 1 },
};

export default createReducer(INIT_STATE, {
  //get Token
  [Types.GET_TOKEN]: (state, action) => {
    return {
      ...state,
      token: {
        ...baseReduxObj,
        loading: true,
      },
    };
  },
  [Types.GET_TOKEN + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      token: {
        ...baseReduxObj,
        data: action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_TOKEN + '_FAIL']: (state, action) => {
    return {
      ...state,
      token: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_OWNER_PROFILE]: (state, action) => {
    return {
      ...state,
      owner: {
        ...baseReduxObj,
        loading: true,
      },
    };
  },
  [Types.GET_OWNER_PROFILE + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      owner: {
        ...baseReduxObj,
        data: action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_OWNER_PROFILE + '_FAIL']: (state, action) => {
    return {
      ...state,
      owner: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.CREATE_COMPANY]: (state, action) => {
    return {
      ...state,
      company: {
        ...baseReduxObj,
        loading: true,
      },
    };
  },
  [Types.CREATE_COMPANY + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      company: {
        ...baseReduxObj,
        data: action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.CREATE_COMPANY + '_FAIL']: (state, action) => {
    return {
      ...state,
      company: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_FANPAGES_V2]: (state, action) => {
    let { fanpages: value } = state;
    return {
      ...state,
      fanpages: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_FANPAGES_V2 + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      fanpages: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_FANPAGES_V2 + '_FAIL']: (state, action) => {
    return {
      ...state,
      fanpages: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_ZALO_OA]: (state, action) => {
    let { zaloOAs: value } = state;
    return {
      ...state,
      zaloOAs: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_ZALO_OA + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      zaloOAs: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },

  [Types.GET_ZALO_OA + '_FAIL']: (state, action) => {
    return {
      ...state,
      zaloOAs: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_PRODUCT]: (state, action) => {
    let { products: value } = state;
    return {
      ...state,
      products: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_PRODUCT + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      products: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_PRODUCT + '_FAIL']: (state, action) => {
    return {
      ...state,
      products: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_STAFFS]: (state, action) => {
    let { staffs } = state;
    return {
      ...state,
      staffs: {
        ...baseReduxObj,
        loading: !(staffs.data.length > 0),
        data: staffs.data,
      },
    };
  },
  [Types.GET_STAFFS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      staffs: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_STAFFS + '_FAIL']: (state, action) => {
    return {
      ...state,
      staffs: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_WEBSITES]: (state, action) => {
    let { websites: value } = state;
    return {
      ...state,
      websites: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_WEBSITES + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      websites: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_WEBSITES + '_FAIL']: (state, action) => {
    return {
      ...state,
      websites: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_RULES]: (state, action) => {
    let { rules: value } = state;
    return {
      ...state,
      rules: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_RULES + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      rules: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_RULES + '_FAIL']: (state, action) => {
    return {
      ...state,
      rules: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },
  [Types.GET_SOURCE_SYNC_PRODUCT]: (state, action) => {
    let { sourceSyncProduct: value } = state;
    return {
      ...state,
      sourceSyncProduct: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_SOURCE_SYNC_PRODUCT + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      sourceSyncProduct: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_SOURCE_SYNC_PRODUCT + '_FAIL']: (state, action) => {
    return {
      ...state,
      sourceSyncProduct: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },
  [Types.GET_SHIPMENT_SERVICES]: (state, action) => {
    let { shipmentServices } = state;
    let data = shipmentServices.data;
    return {
      ...state,
      shipmentServices: {
        ...baseReduxObj,
        loading: !(data.length > 0),
        data: data,
      },
    };
  },
  [Types.GET_SHIPMENT_SERVICES + '_SUCCESS']: (state, action) => {
    let data = action.payload.data;
    return {
      ...state,
      shipmentServices: {
        ...baseReduxObj,
        ...action.payload,
        data: data,
        isSuccess: true,
      },
    };
  },
  [Types.GET_SHIPMENT_SERVICES + '_FAIL']: (state, action) => {
    return {
      ...state,
      shipmentServices: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },
  [Types.GET_SEGMENTS]: (state, action) => {
    let { segments: value } = state;
    return {
      ...state,
      segments: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_SEGMENTS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      segments: {
        ...baseReduxObj,
        ...action.payload,
        data: sortBy(action.payload.data, 'ordinal_number'),
        isSuccess: true,
      },
    };
  },
  [Types.GET_SEGMENTS + '_FAIL']: (state, action) => {
    return {
      ...state,
      segments: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },
  [Types.GET_TAGS]: (state, action) => {
    let { tags: value } = state;
    return {
      ...state,
      tags: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_TAGS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      tags: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_TAGS + '_FAIL']: (state, action) => {
    return {
      ...state,
      tags: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },
  [Types.GET_ORDERS]: (state, action) => {
    let { orders } = state;
    return {
      ...state,
      orders: {
        ...baseReduxObj,
        // ...action.payload,
        loading: !(orders.data.length > 0),
        data: orders.data,
      },
    };
  },
  [Types.GET_ORDERS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      orders: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_ORDERS + '_FAIL']: (state, action) => {
    return {
      ...state,
      orders: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_PICK_ADDRESSES]: (state, action) => {
    let { pickAddresses } = state;
    return {
      ...state,
      pickAddresses: {
        ...baseReduxObj,
        loading: !(pickAddresses.data.length > 0),
        data: pickAddresses.data,
      },
    };
  },
  [Types.GET_PICK_ADDRESSES + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      pickAddresses: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_PICK_ADDRESSES + '_FAIL']: (state, action) => {
    return {
      ...state,
      pickAddresses: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_SUBSCRIPTIONS]: (state, action) => {
    return {
      ...state,
      subscriptions: {
        ...baseReduxObj,
        ...action.payload,
        data: state.subscriptions.data,
        loading: true,
      },
    };
  },
  [Types.GET_SUBSCRIPTIONS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      subscriptions: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_SUBSCRIPTIONS + '_FAIL']: (state, action) => {
    return {
      ...state,
      subscriptions: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_PRODUCT_ATTRIBUTES]: (state, action) => {
    let { productAttributes: value } = state;
    return {
      ...state,
      productAttributes: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_PRODUCT_ATTRIBUTES + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      productAttributes: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_PRODUCT_ATTRIBUTES + '_FAIL']: (state, action) => {
    return {
      ...state,
      productAttributes: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_VARIANTS]: (state, action) => {
    let { variants } = state;
    return {
      ...state,
      variants: {
        ...baseReduxObj,
        ...action.payload,
        data: variants.data,
        loading: !(variants.data.length > 0),
      },
    };
  },
  [Types.GET_VARIANTS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      variants: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_VARIANTS + '_FAIL']: (state, action) => {
    return {
      ...state,
      variants: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_INSTAGRAMS]: (state, action) => {
    let { instagrams: value } = state;
    return {
      ...state,
      instagrams: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_INSTAGRAMS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      instagrams: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_INSTAGRAMS + '_FAIL']: (state, action) => {
    return {
      ...state,
      instagrams: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_SHOPEE]: (state, action) => {
    let { shopee: value } = state;
    return {
      ...state,
      shopee: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_SHOPEE + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      shopee: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_SHOPEE + '_FAIL']: (state, action) => {
    return {
      ...state,
      shopee: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_SENDO]: (state, action) => {
    let { sendo: value } = state;
    return {
      ...state,
      sendo: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_SENDO + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      sendo: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_SENDO + '_FAIL']: (state, action) => {
    return {
      ...state,
      sendo: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_EMAIL]: (state, action) => {
    let { email: value } = state;
    return {
      ...state,
      email: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_EMAIL + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      email: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_EMAIL + '_FAIL']: (state, action) => {
    return {
      ...state,
      email: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_PERSONAL_ZALO]: (state, action) => {
    let { personalZalo: value } = state;
    return {
      ...state,
      personalZalo: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_PERSONAL_ZALO + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      personalZalo: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_PERSONAL_ZALO + '_FAIL']: (state, action) => {
    return {
      ...state,
      personalZalo: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_PERSONAL_FACEBOOKS]: (state, action) => {
    let { personalfbs: value } = state;
    return {
      ...state,
      personalfbs: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },

  [Types.GET_PERSONAL_FACEBOOKS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      personalfbs: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_PERSONAL_FACEBOOKS + '_FAIL']: (state, action) => {
    return {
      ...state,
      personalfbs: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },
  [Types.GET_FACEBOOK_GROUPS]: (state, action) => {
    let { fbGroups: value } = state;
    return {
      ...state,
      fbGroups: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },

  [Types.GET_FACEBOOK_GROUPS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      fbGroups: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_FACEBOOK_GROUPS + '_FAIL']: (state, action) => {
    return {
      ...state,
      fbGroups: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  //todo: GET_CUSTOMERS
  [Types.GET_CUSTOMERS]: (state, action) => {
    let { customers: value } = state;
    return {
      ...state,
      customers: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_CUSTOMERS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      customers: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_CUSTOMERS + '_FAIL']: (state, action) => {
    return {
      ...state,
      customers: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },
  [Types.GET_BROADCAST]: (state, action) => {
    let { broadcasts: value } = state;
    return {
      ...state,
      broadcasts: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_BROADCAST + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      broadcasts: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_BROADCAST + '_FAIL']: (state, action) => {
    return {
      ...state,
      broadcasts: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },
  [Types.GET_QUICK_REPLIES]: (state, action) => {
    let { quickReplies: value } = state;
    return {
      ...state,
      quickReplies: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_QUICK_REPLIES + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      quickReplies: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_QUICK_REPLIES + '_FAIL']: (state, action) => {
    return {
      ...state,
      quickReplies: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  //todo: GET_PAYMENT_METHODS
  [Types.GET_PAYMENT_METHODS]: (state, action) => {
    let { paymentMethods: value } = state;
    return {
      ...state,
      paymentMethods: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_PAYMENT_METHODS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      paymentMethods: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_PAYMENT_METHODS + '_FAIL']: (state, action) => {
    return {
      ...state,
      paymentMethods: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_LIST_PBX]: (state, action) => {
    let { paymentMethods: value } = state;
    return {
      ...state,
      listPbx: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_LIST_PBX + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      listPbx: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_LIST_PBX + '_FAIL']: (state, action) => {
    return {
      ...state,
      listPbx: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_CALL_AGENTS]: (state, action) => {
    let { paymentMethods: value } = state;
    return {
      ...state,
      callAgents: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_CALL_AGENTS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      callAgents: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_CALL_AGENTS + '_FAIL']: (state, action) => {
    return {
      ...state,
      callAgents: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.SET_FANPAGES]: (state, action) => {
    return {
      ...state,
      fanpages: action.payload,
    };
  },
  [Types.SET_CURRENT_PRODUCT]: (state, action) => {
    return {
      ...state,
      currentProduct: action.payload,
    };
  },
  [Types.SHOW_WARNING_PAYMENT_MODAL]: (state, action) => {
    return {
      ...state,
      showWaringPaymentModal: true,
    };
  },
  [Types.HIDE_WARNING_PAYMENT_MODAL]: (state, action) => {
    return {
      ...state,
      showWaringPaymentModal: false,
    };
  },
  [Types.SET_CONNECTION]: (state, action) => {
    return {
      ...state,
      online: action.payload,
    };
  },
  [Types.CLEAR_DATA]: (state, action) => {
    return {
      ...state,
      [action.key]: {
        ...baseReduxObj,
        loading: action.toLoading,
      },
    };
  },

  [Types.SET_LANGUAGE]: (state, action) => {
    return {
      ...state,
      language: action.payload,
    };
  },

  [Types.SET_COMPANY]: (state, action) => {
    return {
      ...state,
      ...INIT_STATE_LIST,
      currentCompany: { ...action.payload },
    };
  },
  [Types.SET_GRAPHS]: (state, action) => {
    return {
      ...state,
      graphs: [...action.payload],
    };
  },
  [Types.SET_STATISTIC_DATE_RANGE]: (state, action) => {
    return {
      ...state,
      statisticDateRange: { ...action.payload },
    };
  },

  [Types.GET_AGENTS]: (state, action) => {
    let { agents: value } = state;
    return {
      ...state,
      agents: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_AGENTS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      agents: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_AGENTS + '_FAIL']: (state, action) => {
    return {
      ...state,
      agents: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  //todo: manual channel
  [Types.GET_MANUAL_CHANNELS]: (state, action) => {
    let { productAttributes: value } = state;
    return {
      ...state,
      manualChannels: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_MANUAL_CHANNELS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      manualChannels: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_MANUAL_CHANNELS + '_FAIL']: (state, action) => {
    return {
      ...state,
      manualChannels: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  //todo: zns
  [Types.GET_ZNS_TEMPLATES]: (state, action) => {
    let { znsTemplates: value } = state;
    return {
      ...state,
      znsTemplates: {
        ...baseReduxObj,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_ZNS_TEMPLATES + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      znsTemplates: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_ZNS_TEMPLATES + '_FAIL']: (state, action) => {
    return {
      ...state,
      znsTemplates: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  // TODO: Load GBM
  [Types.GET_GBM]: (state, action) => {
    let { gbms: value } = state;
    return {
      ...state,
      gbms: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_GBMS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      gbms: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_GBMS + '_FAIL']: (state, action) => {
    return {
      ...state,
      gbms: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_GBM_LOCATIONS]: (state, action) => {
    let { gbmLocations: value } = state;
    return {
      ...state,
      gbmLocations: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_GBM_LOCATIONS + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      gbmLocations: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_GBM_LOCATIONS + '_FAIL']: (state, action) => {
    return {
      ...state,
      gbmLocations: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.GET_APPLE_BUSINESSES]: (state, action) => {
    let { appleBusinesses: value } = state;
    return {
      ...state,
      appleBusinesses: {
        ...baseReduxObj,
        ...action.payload,
        loading: !(value.data.length > 0),
        data: value.data,
      },
    };
  },
  [Types.GET_APPLE_BUSINESSES + '_SUCCESS']: (state, action) => {
    return {
      ...state,
      appleBusinesses: {
        ...baseReduxObj,
        ...action.payload,
        isSuccess: true,
      },
    };
  },
  [Types.GET_APPLE_BUSINESSES + '_FAIL']: (state, action) => {
    return {
      ...state,
      appleBusinesses: {
        ...baseReduxObj,
        error: action.payload,
        isSuccess: false,
      },
    };
  },

  [Types.CONNECT_SOCKET]: (state, action) => {
    return {
      ...state,
      stateConnectSocket: action.payload,
    };
  },
});
