import {
  CLASS_INTRO_CENTER,
  CLASS_INTRO_CUSTOMER,
  CLASS_INTRO_FANPAGE,
  CLASS_INTRO_GROUP,
  CLASS_INTRO_ORDER,
  CLASS_INTRO_PERSONAL,
  CLASS_INTRO_STAFF,
  CLASS_INTRO_WEBSITE,
  CLASS_INTRO_ZALO,
} from './classIntro';
import { trans } from '../config/LinguiConfig';
import { t } from '@lingui/macro';

export const STEPS = () => [
  {
    selector: `.${CLASS_INTRO_FANPAGE}`,
    text: t`Bạn có thể quản lý comment, reply và inbox của từng fanpage tại đây.`,
  },
  {
    selector: `.${CLASS_INTRO_GROUP}`,
    text: t`Tại đây, bạn có thể quản lý comment  trên Facebook Group.`,
  },
  {
    selector: `.${CLASS_INTRO_PERSONAL}`,
    text: t`Tại đây, bạn có thể quản lý quản lý comment, inbox trên profile cá nhân.`,
  },
  {
    selector: `.${CLASS_INTRO_ZALO}`,
    text: t`Tại đây, bạn có thể quản lý inbox từ Zalo official account.`,
  },
  {
    selector: `.${CLASS_INTRO_WEBSITE}`,
    text: t`Bạn có thể quản lý inbox từ các websites có tích hợp chatbox của DooPage tại đây.`,
  },
  {
    selector: `.${CLASS_INTRO_STAFF}`,
    text: t`Đây là nơi quản lý danh sách nhân viên bán hàng, hủy một nhân viên hoặc chấp nhận một nhân viên mới.`,
  },
  {
    selector: `.${CLASS_INTRO_ORDER}`,
    text: t`Đây là nơi quản lý đơn hàng, đơn hàng sẽ được tạo bởi nhân viên bán hàng từ Trung tâm bán hàng hoặc trên ứng dụng di động. Tại đây, người quản trị có thể xác nhận đơn hàng, chuyển đơn cho đơn vị vận chuyển hoặc ghi nhận đã thu phí...`,
  },
  {
    selector: `.${CLASS_INTRO_CUSTOMER}`,
    text: t`Đây là nơi quản lý danh sách khách hàng.`,
  },
  {
    selector: `.${CLASS_INTRO_CENTER}`,
    text: t`Click vào đây để vào Trung tâm tư vấn trên web, hoặc tải app DooPage trên Android/iOS để vào trung tâm tư vấn.`,
  },
];
