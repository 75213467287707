import { Trans } from '@lingui/macro';
import React from 'react';
import { formatVND } from 'variables/utils';
import Avatar from 'component/Avatar';
import 'css/classes.css';

const ToolTipStaffOrderPerformance = ({ item }) => {
  const { x, y, name, avatar, percent } = item;
  return (
    <div style={{ fontSize: 13, padding: 5 }}>
      <div className={'flex-start-row'}>
        <Avatar user={{ avatar }} size={40} />
        <div>{name}</div>
      </div>
      <div>
        <Trans>Doanh thu:</Trans>&nbsp;{formatVND(y)}
      </div>
      <div>
        <Trans>Số đơn:</Trans>&nbsp;{x}
      </div>
      <div>
        <Trans>Chốt:</Trans>&nbsp;{percent}%
      </div>
    </div>
  );
};

export default ToolTipStaffOrderPerformance;
