import { Select } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import React from 'react';

const priorityOptions = [
  {
    id: -2,
    name: t`Rất thấp`,
  },
  {
    id: -1,
    name: t`Thấp`,
  },
  {
    id: 0,
    name: t`Trung bình`,
  },
  {
    id: 1,
    name: t`Cao`,
  },
  {
    id: 2,
    name: t`Rất cao`,
  },
];

const SelectPriority = ({ onChange, priority }) => {
  const update = (path, value) => {
    onChange(`trigger_conditions.${path}`, value);
  };

  return (
    <Select
      className="flex flex-1"
      label={t`Ưu tiên`}
      value={priority || 0}
      options={priorityOptions}
      onChange={(e) => {
        update('priority', e?.target?.value);
      }}
    />
  );
};

export default SelectPriority;
