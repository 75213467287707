import { t } from '@lingui/macro';
import React, { memo } from 'react';
import { AssetImage } from '../assets/Asset';
import { trans } from '../config/LinguiConfig';

const LoadingUI = ({ title, action }) => {
  return (
    <div className="content flex-center-col TEST__loading" style={{ marginTop: 100 }}>
      <img src={AssetImage.Loading} className="listdata-img" alt="img" />
      <div style={{ height: 30 }} />
      <div className="description-text">{title || t`Đang tải dữ liệu...`}</div>
      <div style={{ height: 20 }} />
      {action?.()}
    </div>
  );
};

export default memo(LoadingUI);
