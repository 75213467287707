import React, { memo, useRef, useState } from 'react';
import { Input, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import Divider from '@material-ui/core/Divider';
import './styles.scss';
import { ZnsTemplateSubstitutions } from '../../../../../variables/staticValue';
import ButtonAddField from '../../../../../screen/ScenarioDetail/ScenarioContent/component/ButtonAddField';
import ListTemplateButtons from './ListTemplateButtons';
import { validContent, validTitle } from '../utils';
import classNames from 'classnames';

const TemplateContent = ({
  darkLogo,
  lightLogo,
  onChange,
  titleTemplate,
  content,
  buttons,
  showValidError,
}) => {
  const [darkTheme, setDarkTheme] = useState(false);
  const logoUrl = darkTheme ? darkLogo : lightLogo;
  const contentInputRef = useRef();
  return (
    <div
      className={classNames(
        'w-full bg-gray-300 rounded flex flex-col items-center p-3 template-content',
        { dark: darkTheme, light: !darkTheme },
      )}>
      <Switch
        labelPlacement="start"
        color="info"
        checked={darkTheme}
        onChange={(e) => {
          setDarkTheme(e.target.checked);
        }}>
        {t`Chế độ tối`}
      </Switch>
      <div className="flex flex-col template-background rounded w-[300px]">
        <div className="w-full p-3">
          {logoUrl ? (
            <img src={logoUrl.url} style={{ height: 50 }} alt="logo" />
          ) : (
            <div className="flex flex-row  text-[#a8a8a8] items-center">
              <i className="fal fa-file-image mr-3 text-4xl " />
              <div>{t`Chưa có logo`}</div>
            </div>
          )}
        </div>
        <Divider />
        <div className="p-3 title-input">
          <Input
            fullWidth
            placeholder={t`Tiêu đề`}
            value={titleTemplate}
            onChange={onChange('titleTemplate')}
            error={showValidError ? validTitle(titleTemplate) : undefined}
          />
        </div>
        <Divider />
        <div className="p-3 input-content content-input">
          <Input
            inputRef={contentInputRef}
            fullWidth
            placeholder={t`Nội dung`}
            value={content}
            onChange={onChange('content')}
            multiline
            minRows={3}
            error={showValidError ? validContent(content) : undefined}
          />
          <div className="flex flex-row items-center input-content-footer bg-[white]">
            <ButtonAddField
              data={ZnsTemplateSubstitutions()}
              handleChange={async (code) => {
                const caretPos = contentInputRef.current.selectionStart;
                const value =
                  content?.substring(0, caretPos) + code + ' ' + content?.substring(caretPos);
                await onChange('content')(value);
                contentInputRef?.current.focus();
              }}
            />
            <div>{400 - content?.length}</div>
          </div>
        </div>
        <Divider />
        <ListTemplateButtons onChange={onChange} buttons={buttons} />
      </div>
    </div>
  );
};

export default memo(TemplateContent);
