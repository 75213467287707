import { AutoComplete } from '@doopage/react-ui-kit';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTags } from 'api/api';
import { fetchData } from 'redux/action/fetchAction';
import { Types } from 'redux/type';
import { getValue } from 'variables/utils';

const SelectTag = (props) => {
  const { data, error, loading } = useSelector(getValue(Types.selectTag));
  const dispatch = useDispatch();

  useEffect(() => {
    if (loading || (data && data.length > 0)) return;
    dispatch(fetchData(Types.selectTag, getTags()));
  }, []);

  return <AutoComplete options={data} loading={loading} error={error} {...props} />;
};

export default SelectTag;
