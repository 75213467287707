import {
  createReducer,
  getTypeClear,
  getTypeFail,
  getTypeLoading,
  getTypeSuccess,
} from 'redux/utils';
import { Types } from '../../../redux/type';
import { BASIC_PLAN, SUPER_CHEAP_PLAN } from '../utils';

export const baseFetchObj = {
  key: new Date().getTime(),
  loading: false,
  error: null,
  success: null, //null -> not loading yet, true -> loading success, false -> loading error
  monthPlan: { monthNumber: 1, discountMonth: 0 },
  planList: [],
  applyCoupon: {},
  basicPLans: [],
  currentPlanIndex: -1,
};

const type = Types.subscription;
export default createReducer(baseFetchObj, {
  [getTypeLoading(type)]: (state, action) => {
    return {
      //...state,
      ...baseFetchObj,
      loading: true,
      basicPLans: BASIC_PLAN(),
      superCheapPlans: SUPER_CHEAP_PLAN(),
    };
  },
  [getTypeSuccess(type)]: (state, action) => {
    return {
      ...state,
      ...action.payload,
      loading: false,
      success: true,
    };
  },
  [getTypeFail(type)]: (state, action) => {
    return {
      ...state,
      loading: false,
      error: action.payload,
      success: false,
    };
  },
  [`${type}/monthPlan`]: (state, action) => {
    return {
      ...state,
      monthPlan: action.payload,
    };
  },
  [`${type}/updatePlanList`]: (state, action) => {
    return {
      ...state,
      planList: action.payload,
    };
  },
  [`${type}/updateSuperCheapPlans`]: (state, action) => {
    return {
      ...state,
      superCheapPlans: action.payload,
    };
  },
  [`${type}/updatePlatformValue`]: (state, action) => {
    const { data, id } = action.payload;
    console.log('updatePlatformValue ', { id, data });

    //use id instead of index because of change position of plan when merge basic plan and current plan together
    const index = state.basicPLans.findIndex((item) => item.id === id);
    if (index >= 0) state.basicPLans[index] = { ...state.basicPLans[index], ...data };

    return {
      ...state,
      basicPLans: [...state.basicPLans],
    };
  },
  [`${type}/applyCoupon`]: (state, action) => {
    return {
      ...state,
      applyCoupon: action.payload,
    };
  },
  [getTypeClear(type)]: () => {
    return {
      ...baseFetchObj,
    };
  },
  [`${type}}/reset`]: () => {
    return {
      ...baseFetchObj,
      key: new Date().getTime(),
    };
  },
  [`${type}}/currentPlanIndex`]: (state, action) => {
    console.log('action currentPlanIndex', action);
    return {
      ...state,
      currentPlanIndex: action?.currentPlanIndex ?? -1,
    };
  },
});
