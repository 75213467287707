import { t } from '@lingui/macro';
import { Grid } from '@material-ui/core';
import React from 'react';
import Availability from './Availability';
import ButtonAdd from './ButtonAdd';

const ListAvailability = ({ value, onChange, ...props }) => {
  const handleChange = (index, propKey) => {
    return (newValue) => {
      value[index][propKey] = newValue;
      onChange(value);
    };
  };
  const handleAdd = () => {
    onChange([
      ...value,
      {
        timezone: 'Asia/Ho_Chi_Minh',
        from_day: 'MONDAY',
        end_day: 'SUNDAY',
        from_time: '09:00',
        end_time: '17:00',
      },
    ]);
  };
  return (
    <Grid container spacing={1} {...props}>
      {value.map((item, index) => (
        <Availability
          timezone={item.timezone}
          onChangeTimezone={handleChange(index, 'timezone')}
          fromDay={item.from_day}
          onChangeFromDay={handleChange(index, 'from_day')}
          toDay={item.end_day}
          onChangeToDay={handleChange(index, 'end_day')}
          fromTime={item.from_time}
          onChangeFromTime={handleChange(index, 'from_time')}
          toTime={item.end_time}
          onChangeToTime={handleChange(index, 'end_time')}
        />
      ))}
      <Grid item xs={12} justify="center">
        <ButtonAdd label={t`Thêm thời gian`} onClick={handleAdd} />
      </Grid>
    </Grid>
  );
};

export default ListAvailability;
