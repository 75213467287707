import { Trans } from '@lingui/macro';
import React from 'react';
import { formatVND } from '../../../variables/utils';

const ToolTipProductsPerformance = ({ item }) => {
  const { x, y, r, name, cart_count } = item;
  return (
    <div style={{ fontSize: 13, padding: 5 }}>
      <div>{name}</div>
      <div>
        <Trans>Doanh thu:</Trans>&nbsp;{formatVND(y)}
      </div>
      <div>
        <Trans>Đơn hàng:</Trans>&nbsp;{x}
      </div>
      <div>
        <Trans>Thêm vào giỏ:</Trans>&nbsp;{cart_count}
      </div>
    </div>
  );
};

export default ToolTipProductsPerformance;
