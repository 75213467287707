import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import { trans } from '../../config/LinguiConfig';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import BaseModal from '../BaseComponent/BaseModal';
import './importModal.css';
import 'css/classes.css';
import DV from '../../variables/DV';
import _ from 'lodash';
import CustomDropzone from '../CustomDropzone/CustomDropzone';

class ImportModal extends BaseModal {
  constructor(props) {
    super(props);
    let { goBackPath, guide } = this.getDataFromRouter();
    this.refreshOnClose = false;
    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      file: null,
      guide,
      error: null,
    };
    this.goPathWhenClose = goBackPath;
  }

  getTitle() {
    return t`Import file`;
  }

  renderFile = (file) => {
    const fileName = _.get(file, 'name');
    if (!file)
      return (
        <>
          <i className="fal fa-file-upload import-modal-icon"></i>
          <div className={'import-modal-helper-text'}>{t`Click hoặc kéo thả file vào đây`}</div>
        </>
      );
    return (
      <>
        <i className="fas fa-file-excel import-modal-icon" style={{ color: '#217345' }}></i>
        <div className={'import-modal-helper-text'} style={{ color: 'black' }}>
          {fileName}
        </div>
      </>
    );
  };

  handleSubmit = async () => {
    const success_or_error = await DV.importFile(this.state.file);
    if (success_or_error === true) {
      this.setState({ file: null });
      this.close();
    } else if (typeof success_or_error === 'string') {
      this.setState({ error: success_or_error });
    }
  };

  handleSelectFile = (e) => {
    e.preventDefault();
    try {
      const reader = new FileReader();
      const file = e.target.files[0];
      e.target.value = null;
      const maxSize = 10 * 1024 * 1024;
      if (file.size > maxSize) {
        this.setState({ error: t`Kích cõ file phải nhỏ hơn 10MB` });
      } else {
        reader.onloadend = async () => {
          console.log('selectFile', file);
          this.setState({
            file: file,
          });
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      DV.showNotify(error, 'danger');
    }
  };

  renderFooter() {
    return (
      <>
        <Button
          helperText={t`Hướng dẫn nhập file`}
          href={this.state.guide}
          color="info"
          simple
          target="_blank"
          rel="noopener noreferrer">
          {t`Hướng dẫn`}
        </Button>
        <Button onClick={this.handleSubmit} disabled={!this.state.file} color="info">
          {t`Hoàn tất`}
        </Button>
      </>
    );
  }

  openInputFile = () => {
    this.inputFile && this.inputFile.click();
  };

  handleDrop = (file) => {
    this.setState({ file });
  };

  renderBody() {
    return (
      <Grid container>
        <Grid item xs={12}>
          <CustomDropzone
            accept=".csv, .xlsx"
            disabled={this.state.loading}
            handleDrop={this.handleDrop}>
            <div
              className={'import-modal-dropzone-container flex-center-col'}
              onClick={this.openInputFile}>
              {this.renderFile(this.state.file)}
            </div>
          </CustomDropzone>
        </Grid>
        <input
          key={'file'}
          style={{ display: 'none' }}
          type="file"
          onChange={this.handleSelectFile}
          ref={(ref) => (this.inputFile = ref)}
          accept=".csv, .xlsx"
        />
        {this.state.error !== null ? (
          <span style={{ 'font-size': '12px', color: '#f44336' }}>* {this.state.error}</span>
        ) : null}
      </Grid>
    );
  }
}

export default ImportModal;
