import { t } from '@lingui/macro';
import { getSettingGBM, updateSettingGBM } from 'api/api';
import { setState } from 'config/Core';
import { handleApi } from 'helper/helper';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import Routes from 'router/routes';
import DV from 'variables/DV';
import BaseModal from '../../BaseComponent/BaseModal';
import ListAvailability from './component/ListAvailability';
import ListConversational from './component/ListConversational';
import { Switch } from '@doopage/react-ui-kit';

class GBMSettingModal extends BaseModal {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      title: this.getTitle(),
      is_human_availability: false,
      loading: false,
      human_availability: [],
      conversational_settings: {},
      languages_selected: [],
      ...this.getDataFromRouter(),
    };
    this.maxWidth = 'md';
    this.goPathWhenClose = !this.props.openFromChannels ? Routes.gbm.path : Routes.channel.path;
  }

  getTitle() {
    return t`Cài đặt Google Business Message`;
  }

  async componentDidMount() {
    const { open, id } = this.state;
    if (open && !id) {
      return this.close();
    }
    let { success, data } = await handleApi(getSettingGBM(id));
    if (success) {
      this.setState(data);
      if (data.human_availability?.length) {
        this.setState({
          is_human_availability: true,
        });
      }
      this.setState({
        open: true,
      });
    }
  }

  check_url = (url) => {
    try {
      const url_parsed = new URL(url);
      return /^https?:$/.test(url_parsed.protocol) && !url_parsed.search && !url_parsed.hash;
    } catch (e) {
      return false;
    }
  };

  updateSetting = async (id, data) => {
    await setState(this)({ loading: true });
    let { success, error } = await handleApi(updateSettingGBM(id, data));
    await setState(this)({ loading: false });
    if (success) {
      this.close(true);
    } else DV.showNotify(error, 'danger');
  };

  handleSubmit = async () => {
    const { id, is_human_availability, human_availability, conversational_settings } = this.state;
    if (is_human_availability && human_availability.length < 1) {
      DV.showNotify(t`Phải khai báo ít nhất 1 khung giờ nếu có người trực`, 'danger');
      return;
    }
    if (conversational_settings) {
      for (const conversational of Object.values(conversational_settings)) {
        if (!conversational.welcome_message) {
          DV.showNotify(t`Hãy nhập tin nhắn chào`, 'danger');
          return;
        }
        if (!conversational.privacy_policy) {
          DV.showNotify(t`Hãy nhập URL tới chính sách`, 'danger');
          return;
        }
        if (!this.check_url(conversational.privacy_policy)) {
          DV.showNotify(t`${conversational.privacy_policy} không phải là một URL hợp lệ`, 'danger');
          return;
        }
      }
    }
    const data = { conversational_settings };
    if (is_human_availability) {
      data.human_availability = human_availability;
    }
    await this.updateSetting(id, data);
  };

  renderBody() {
    const {
      is_human_availability,
      human_availability,
      conversational_settings,
      languages_selected,
    } = this.state;

    return (
      <>
        <div className="font-bold !mb-[20px] text-[15px]">{t`Thường trực`}</div>
        <Switch
          label={t`Có người trực`}
          checked={is_human_availability}
          onChange={(event) => this.handleChange('is_human_availability')(event.target.checked)}
          color={'info'}
        />
        {is_human_availability && (
          <ListAvailability
            value={human_availability}
            onChange={this.handleChange('human_availability')}
          />
        )}
        <div className="font-bold !mb-[20px] mt-[20px] text-[15px]">{t`Lời chào`}</div>
        <ListConversational
          languages={languages_selected}
          value={conversational_settings}
          onChange={this.handleChange('conversational_settings')}
        />
      </>
    );
  }

  renderFooter() {
    const { loading } = this.state;
    return [
      <Button onClick={this.handleSubmit} color="info" loading={loading}>
        {t`Lưu`}
      </Button>,
    ];
  }
}

export default GBMSettingModal;
