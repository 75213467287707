// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import React from 'react';

import typographyStyle from '../../assets/jss/material-dashboard-pro-react/components/typographyStyle.jsx';
import { t } from '@lingui/macro';
import { Tooltip } from '@material-ui/core';

function Danger({ ...props }) {
  const { classes, children, help, onClick, useTooltip } = props;
  if (!children || children.length === 0) return null;

  const content = (
    <div
      className={
        classes.defaultFontStyle + ' ' + classes.dangerText + ' ' + (help ? classes.helpText : '')
      }
      onClick={onClick}
      style={{ marginTop: 5 }}>
      <i className="fas fa-exclamation-triangle" style={{ marginTop: 1.5, marginRight: 3 }} />
      {useTooltip ? t`Lỗi` : children}
    </div>
  );

  if (useTooltip) {
    return <Tooltip title={children}>{content}</Tooltip>;
  }
  return <div>{content}</div>;
}

Danger.propTypes = {
  classes: PropTypes.object.isRequired,
  help: PropTypes.bool,
  useTooltip: PropTypes.bool,
};

export default withStyles(typographyStyle)(Danger);
