import { t } from '@lingui/macro';
import isEmpty from 'lodash/isEmpty';
import React, { memo } from 'react';
import { infoColor, warningColor } from 'variables/color';
import CustomChart from '../components/CustomChart/CustomChart';
import { CHART_DATE_FORMAT, getTimeUnit } from '../utils';
import { thousandSeparate } from '../../../helper/helper';

const datasetsOption = [
  {
    backgroundColor: warningColor,
    type: 'bar',
    yAxisID: 'y-axis-1',
    order: 2,
  },
  {
    backgroundColor: infoColor,
    borderColor: infoColor,
    type: 'line',
    showLine: false,
    yAxisID: 'y-axis-2',
    fill: false,
    order: 1,
  },
];

const getOption = (min, max) => ({
  responsive: true,
  scales: {
    xAxes: [
      {
        stacked: true,
        scaleLabel: {
          labelString: t`Thời gian`,
        },
        type: 'time',
        time: {
          min: new Date(min * 1000),
          max: new Date(max * 1000),
          unit: getTimeUnit(min, max),
          displayFormats: {
            day: CHART_DATE_FORMAT,
          },
        },
      },
    ],
    yAxes: [
      {
        stacked: true,
        scaleLabel: {
          labelString: t`Doanh thu (triệu đồng)`,
        },
        id: datasetsOption[0].yAxisID,
        position: 'left',
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return thousandSeparate(value);
            }
          },
        },
      },
      {
        stacked: true,
        scaleLabel: {
          labelString: t`Số đơn`,
        },
        id: datasetsOption[1].yAxisID,
        position: 'right',
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return thousandSeparate(value);
            }
          },
        },
      },
    ],
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        let tooltipValue =
          data?.datasets?.[tooltipItem.datasetIndex]?.data?.[tooltipItem.index] ?? '0';
        let tooltipLabel = data?.datasets?.[tooltipItem.datasetIndex]?.label ?? '';
        return `${tooltipLabel}: ` + thousandSeparate(parseInt(tooltipValue));
      },
    },
    mode: 'index',
  },
});

const normalizedData = (data) => {
  if (isEmpty(data) && !data?.datasets) return {};

  data.datasets.forEach((item, index) => {
    data.datasets[index] = { ...item, ...datasetsOption[index] };
  });

  data.labels.forEach((item, index) => {
    data.labels[index] = new Date(data.labels[index] * 1000);
  });

  return data;
};

const getColumns = () => [
  {
    Header: t`Thời gian`,
    width: 100,
    accessor: 'x',
  },
  {
    Header: t`Doanh thu (triệu đồng)`,
    width: 100,
    accessor: 'y',
  },
  {
    Header: t`Số đơn`,
    width: 100,
    accessor: 'y',
  },
];

const Income = (props) => {
  return (
    <CustomChart
      normalizedData={normalizedData}
      columns={getColumns()}
      getOption={getOption}
      {...props}
    />
  );
};

export default memo(Income);
