import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { t } from '@lingui/macro';
import { Input, SelectLocale } from '@doopage/react-ui-kit';
import { getLocale } from 'variables/utils';
import produce from 'immer';

const GreetingEditor = ({ elements = {}, update, show = true }) => {
  const [selectedLocale, setSelectedLocale] = useState('default');

  const getSelectedLocale = () => {
    const result = elements?.reduce((arr, item) => {
      const locale = getLocale(item.locale);

      if (locale && !arr.some((i) => i.id === item.locale)) return [...arr, locale];
      return arr;
    }, []);

    if (result.length === 0) result.push(getLocale('default'));

    return result;
  };

  if (!show) return null;

  return (
    <>
      <SelectLocale
        key={`${elements?.length}`}
        selectProps={{
          label: t`Ngôn ngữ`,
          placeholder: t`Vui lòng chọn ngôn ngữ`,
        }}
        defaultSelectedLocale={selectedLocale}
        onSelectedChange={setSelectedLocale}
        defaultOptions={getSelectedLocale()}
        onOptionsChange={(newLocales) => {
          update('greeting', {
            active: true,
            elements: newLocales.reduce((arr, ele) => {
              const data = elements.find((item) => item.locale === ele.id) || {
                locale: ele.id,
                text: '',
              };
              return [...arr, data];
            }, []),
          });
        }}
      />
      {elements.map((item, index) => {
        const { locale, text } = item;

        if (locale !== selectedLocale) return null;
        return (
          <Input
            key={locale}
            value={text}
            onChange={(e) => {
              update(
                'greeting.elements',
                produce(elements, (draftState) => {
                  draftState[index].text = e.target.value;
                }),
              );
            }}
            fullWidth
            label={t`Tin nhắn chào ${getLocale(locale).name}`}
            placeholder={t`Nhập tin nhắn chào`}
          />
        );
      })}
    </>
  );
};

GreetingEditor.propTypes = {
  exProps: PropTypes.bool,
};

export default memo(GreetingEditor);
