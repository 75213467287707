import { t } from '@lingui/macro';
import MenuItem from '@material-ui/core/MenuItem';
import { getShipmentAccounts } from 'api/api';
import { trans } from 'config/LinguiConfig';
import { handleApi } from 'helper/helper';
import CustomSelect from 'MUI_Kit/components/CustomSelect/CustomSelect';
import React from 'react';
import Routes, { createPath } from 'router/routes';
import DV from 'variables/DV';
import { mapShipmentOnlyIcon } from 'variables/staticValue';

export default class SelectShipmentAccount extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loading: false,
      data: [],
    };
  }

  async componentDidMount() {
    this.setState({ loading: true, error: null });
    let { data, error } = await handleApi(getShipmentAccounts());
    this.setState({ loading: false });

    if (error) {
      this.setState({ error, data: [] });
      DV.showNotify(error, 'danger');
    } else {
      if (DV.partner === 'hcmpost.vn')
        data = data.filter((item) => item.service === 'hcmpost' && item.status === 'publish');
      else data = data.filter((item) => item.status === 'publish' && item.service !== 'hcmpost');
      this.setState({
        data: [...data, { id: -1, service: t`Tự giao hàng` }],
      });
      let hasInsurance = false;

      const { onChange, value } = this.props;
      if (value) {
        let index = data.findIndex((item) => item.id === value);
        hasInsurance = index > 0 && data[index].service === 'topship';
        onChange(value, hasInsurance);
      }
    }
    this.props.setDefaultShipmentAccount && this.props.setDefaultShipmentAccount(this.state.data);
  }

  render() {
    const { data, error, loading } = this.state;
    const { disabled, onChange, value } = this.props;
    let text = t`Đơn vị vận chuyển`;
    if (error) text = t`Có lỗi xảy ra...`;
    if (loading) text = t`Đang tải đơn vị vận chuyển...`;

    let nameAddBtn =
      DV.partner === 'hcmpost.vn' ? t`Thêm tài khoản VNPOST` : t`Tích hợp đơn vị vận chuyển khác`;
    return (
      <CustomSelect
        label={text}
        data={data || []}
        value={value || ''}
        addButton={{
          name: nameAddBtn,
          onClick: () => this.props.redirectToModal(Routes.shipmentServices.path + createPath),
        }}
        onChange={(value) => {
          let account;
          if (!loading && !error && value) account = data.find((item) => item.id === value);
          onChange(value, account);
        }}
        renderItem={this.renderShipmentAccountsItem}
        disabled={disabled || loading || error}
      />
    );
  }

  renderShipmentIcon = (service) => {
    const icon = mapShipmentOnlyIcon[service];
    if (icon) {
      return (
        <img
          src={icon}
          className="only-button"
          style={{
            margin: '0 5px',
            height: '20px',
          }}
          alt={service}
        />
      );
    }
    return (
      <i
        className="fas fa-shipping-fast only-button"
        style={{
          color: '#39d754',
          margin: '0 5px',
          fontSize: '20px',
        }}
      />
    );
  };

  renderShipmentAccountsItem = (item, key, classes) => {
    const { id, service, email, username } = item;
    return (
      <MenuItem
        key={key}
        value={id}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}>
        <div className="flex-start-row">
          {this.renderShipmentIcon(service)}
          {
            <div>
              {service} {email || username ? `(${email || username})` : ''}
            </div>
          }
        </div>
      </MenuItem>
    );
  };
}
