import React, { useEffect, useRef, useState } from 'react'; /* CSS */
import cronstrue from 'cronstrue';
import { Input, Select } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import CronBuilder from './CronBuilder';
import Popover from '@material-ui/core/Popover';

//options must sorted by id (number seconds) descending
const options = [
  {
    id: 86400, // number seconds
    name: t`Ngày`,
  },
  { id: 3600, name: t`Giờ` },
  { id: 60, name: t`Phút` },
  { id: 1, name: t`Giây` },
];

const getDuration = (duration) => {
  if (duration) {
    const matchOpt = options.find((item) => duration % item?.id === 0);
    return {
      unit: matchOpt?.id,
      number: duration / matchOpt?.id,
    };
  }
  return { unit: 60, number: 0 };
};

const TimeCycleInput = ({ onChange, timeCycle = {} }) => {
  const { crontab, duration } = timeCycle;
  const displayCron = cronstrue.toString(crontab ?? '* * * * *');
  const { number, unit } = getDuration(duration);

  const [unitValue, setUnitValue] = useState(1);
  useEffect(() => {
    if (unit !== unitValue) {
      setUnitValue(unit);
    }
  }, [unit]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const cronBuilderRef = useRef();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'cron-builder-popover' : undefined;

  return (
    <div
      className="flex flex-col"
      onClick={() => {
        onChange?.(`time_range.active`, false);
        onChange?.(`time_cycle.active`, true);
        // onChange?.(`time_cycle.crontab`, '* * * * *');
      }}>
      <div className="flex flex-row items-center pt-[6px]">
        <div className="w-[80px]">{t`Chu kỳ:`}</div>
        <Input className="w-full" multiline onClick={handleClick} value={displayCron} />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}>
        <div className="flex flex-col p-5">
          <CronBuilder
            ref={cronBuilderRef}
            onChange={(value) => onChange('time_cycle.crontab', value)}
            value={crontab}
          />
        </div>
      </Popover>
      <div className="flex flex-row  w-full items-center mt-2">
        <div className="mr-2">{t`Kết thúc sau:`}</div>
        <Input
          value={number}
          onChange={(e) => onChange('time_cycle.duration', e.target.value * unit)}
          className="w-[50px] mx-1"
          type="number"
        />
        <Select
          value={unitValue}
          options={options}
          onChange={(e) => {
            setUnitValue(e.target.value);
            onChange('time_cycle.duration', e.target.value * number);
          }}
        />
      </div>
    </div>
  );
};

export default TimeCycleInput;
