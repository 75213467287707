import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Zoom from '@material-ui/core/Zoom/Zoom';
// nodejs library that concatenates classes
import classNames from 'classnames';
import { sendButtonAction } from 'config/GoogleAnalyticsConfig';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
import React from 'react';
import { checkPermission } from '../../../helper/helperPermission';
import DV from '../../../variables/DV';

import buttonStyle from '../../assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';
import tooltipsStyle from '../../assets/jss/material-dashboard-pro-react/components/tooltipsStyle.jsx';
import Badge from '../Badge/Badge';

const styles = {
  ...tooltipsStyle,
  ...buttonStyle,
};

const renderLoading = (justIcon) => {
  if (justIcon) {
    return (
      <div style={{ height: 24, marginTop: -2, color: '#fff' }} className="flex-center-col">
        <CircularProgress color="#fff" size={24} />
      </div>
    );
  }
  return 'Đang xử lý...';
};

/**
 * @return {null}
 */
function RegularButton({ ...props }) {
  const {
    classes,
    color,
    round,
    children,
    fullWidth,
    disabled,
    simple,
    size,
    block,
    link,
    justIcon,
    className,
    muiClasses,
    helperText,
    margin,
    badgeContent,
    badgeColor,
    noneUpcase,
    alignLeft,
    roundCorner,
    permission,
    onClick,
    pageName,
    show,
    outlined,
    ...rest
  } = props;

  let loading = props.loading || false;

  const btnClasses = classNames({
    [classes.button]: true,
    [classes[size]]: size,
    [classes[color]]: color,
    [classes.round]: round,
    [classes.outlined]: outlined,
    [classes.roundCorner]: roundCorner,
    [classes.fullWidth]: fullWidth,
    [classes.noneUpcase]: noneUpcase !== false,
    [classes.disabled]: disabled,
    [classes.simple]: simple,
    [classes.block]: block,
    [classes.link]: link,
    [classes.justIcon]: justIcon,
    [className]: className,
  });

  if (!show) return null;
  if (permission && !checkPermission(permission, DV.permission)) return null;

  let styles = { ...(margin ? { marginRight: 10 } : {}), ...rest.style };
  if (alignLeft) styles = { ...styles, justifyContent: 'flex-start' };

  let button = (
    <Button
      {...rest}
      classes={muiClasses}
      className={btnClasses}
      disabled={loading || disabled}
      style={styles}
      onClick={(event) => {
        onClick && onClick(event);
        if (typeof children === 'string') sendButtonAction(children);
        else if (helperText) sendButtonAction(helperText);
      }}>
      {!loading ? children : renderLoading(justIcon)}
      {badgeContent && <span className="Badget-dot">{badgeContent}</span>}
    </Button>
  );

  if (badgeContent) {
    button = (
      <Badge color="secondary" badgeContent={badgeContent} style={{ top: 0, right: 0 }}>
        {button}
      </Badge>
    );
  }

  if (helperText) {
    return (
      <Tooltip
        TransitionComponent={Zoom}
        id="tooltip-bottom"
        title={helperText}
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}>
        {button}
      </Tooltip>
    );
  }

  return button;
}

RegularButton.defaultProps = {
  show: true,
};

RegularButton.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf([
    'gray',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'twitter',
    'facebook',
    'google',
    'linkedin',
    'pinterest',
    'youtube',
    'tumblr',
    'github',
    'behance',
    'dribbble',
    'reddit',
    'transparent',
  ]),
  size: PropTypes.oneOf(['sm', 'lg', 'xs']),
  simple: PropTypes.bool,
  outlined: PropTypes.bool,
  show: PropTypes.bool,
  round: PropTypes.bool,
  roundCorner: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  link: PropTypes.bool,
  justIcon: PropTypes.bool,
  className: PropTypes.string,
  helperText: PropTypes.string,
  muiClasses: PropTypes.object,
  margin: PropTypes.bool,
  style: PropTypes.object,
  badgeContent: PropTypes.string,
  badgeColor: PropTypes.string,
  noneUpcase: PropTypes.bool,
  alignLeft: PropTypes.bool,
  loading: PropTypes.bool,
};

export default React.memo(withStyles(styles)(RegularButton));
