export const primaryColor = '#ef6c00';
export const secondaryColor = '#faebcc';
export const warningColor = '#ff9800';
export const dangerColor = '#f44336';
export const successColor = '#4caf50';
export const infoColor = '#2979ff';
export const roseColor = '#e91e63';
export const grayColor = '#999999';
export const twitterColor = '#55acee';
export const linkedinColor = '#0976b4';
export const tumblrColor = '#35465c';
export const githubColor = '#333333';
export const behanceColor = '#1769ff';
export const dribbbleColor = '#ea4c89';
export const redditColor = '#ff4500';

export const COLOR_ARRAY = [
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  twitterColor,
  primaryColor,
  githubColor,
  linkedinColor,
  dribbbleColor,
  behanceColor,
  secondaryColor,
  grayColor,
];

export const COLOR_OBJECT = {
  primary: '#ef6c00',
  warning: '#ff9800',
  danger: '#f44336',
  success: '#4caf50',
  info: '#2979ff',
  rose: '#e91e63',
  gray: '#999999',
  twitter: '#55acee',
  linkedin: '#0976b4',
  behance: '#1769ff',
  dribbble: '#ea4c89',
  reddit: '#ff4500',
  black: '#000',
  tumblr: '#35465c',
};

export const findColorName = (value) => {
  let name = null;
  if (value)
    Object.keys(COLOR_OBJECT).forEach((key) => {
      if (COLOR_OBJECT[key] === value) name = key;
    });

  return name;
};

export const COLOR_LETTER_AVATAR = [
  ['#F9D423', '#e65c00'],
  ['#e52d27', '#b31217'],
  ['#EDDE5D', '#F09819'],
  ['#26D0CE', '#1A2980'],
  ['#F45C43', '#EB3349'],
  ['#3193FC', '#3a7bd5'],
];

export const CHIP_COLOR_YELLOW = ['#fffc00', '#ffffff'];
export const CHIP_COLOR_GREEN = ['#56ab2f', '#a8e063'];
export const CHIP_COLOR_BLUE = [infoColor, '#00a5d6'];
export const CHIP_COLOR_RED = ['#ff416c', '#ff4b2b'];
export const CHIP_COLOR_MULTI = ['#ef6c00', '#ff9800'];
