import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/performance';

import { deviceDetect, isMobile } from 'react-device-detect';
import ReactGA from 'react-ga';

const firebaseConfig = {
  apiKey: 'AIzaSyABCDLmRdE4ixCPyf1nkrTmLKeG2oeibUk',
  authDomain: 'doopage-youthdev.firebaseapp.com',
  databaseURL: 'https://doopage-youthdev.firebaseio.com',
  projectId: 'doopage-youthdev',
  storageBucket: 'doopage-youthdev.appspot.com',
  messagingSenderId: '470930908550',
  appId: '1:470930908550:web:ba1725b922d6df3d',
};

firebase.initializeApp(firebaseConfig);
// Initialize Performance Monitoring and get a reference to the service
const perf = firebase.performance();

window.perfMetrics &&
  window.perfMetrics.onFirstInputDelay((delay, evt) => {
    ReactGA.event({
      category: 'Perf Metrics',
      action: 'first-input-delay',
      label: evt.type,
    });
  });

const devices = deviceDetect();
if (firebase.database && isMobile) {
  const newNode = firebase.database().ref('devices').push();
  newNode.set(devices);
}
