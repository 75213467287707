import moment from 'moment';
import React from 'react';

const _days = new Array(7)
    .fill(null)
    .map((_, index) => index)
    .map(day => moment(0).locale('en').utc().day(day).format('dddd'));

export const days = _days.map(day => ({
    key: day.toUpperCase(),
    value: day,
}));
