import { Accordion, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import { trans } from 'config/LinguiConfig';
import React, { memo } from 'react';
import { AssetImage } from '../../../../assets/Asset';

const FollowZaloOACondition = ({ followOA, onChange, show = true }) => {
  const { active = false } = followOA;

  const update = (path, value) => {
    onChange(`trigger_conditions.follow_oa.${path}`, value);
  };

  if (!show) return null;

  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <img
              src={AssetImage.Zalo}
              style={{ background: 'white' }}
              className="bg-white mr-2 w-[20px]"
            />
            {trans(t`Khi quan tâm ZaloOA`)}
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('active', !active)}>
        {trans(t`Chạy kịch bản khi quan tâm ZaloOA`)}
      </Accordion>
    </Card>
  );
};

export default memo(FollowZaloOACondition);
