const PREFIX = 'TEST__';
const SIDE_BAR = 'sidebar';
const HEADER = 'header';
const CONTENT = 'content';
const ITEM = 'item';
const SWITCH = 'switch';
const INPUT = 'input';
const MODAL = 'modal';
const SELECT = 'select';

const sideMenu = [
  {
    name: 'center',
  },
  {
    name: 'fanpage',
  },
  {
    name: 'fanpage',
  },
  {
    name: 'zalo',
  },
  {
    name: 'instagram',
  },
  {
    name: 'website',
  },
  {
    name: 'staff',
  },
  {
    name: 'order',
  },
  {
    name: 'customer',
  },
  {
    name: 'product',
  },
  {
    name: 'instagram',
  },
  {
    name: 'chart',
  },
  {
    name: 'personal',
  },
];

const keymirror = (obj, prefix = PREFIX) => {
  let ret = {};
  let key;

  for (key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      ret[obj[key]] = prefix + '_' + obj[key];
    }
  }
  return ret;
};

export const xPath = {
  sidebar: PREFIX + SIDE_BAR,
  platform: {
    ...keymirror(sideMenu.map((item) => item.name)),
  },
  header: PREFIX + HEADER,
  modal: PREFIX + MODAL,

  element: {
    ...keymirror(['add_button', 'buy_more_button', 'submit_button']),
  },
  content: PREFIX + CONTENT,

  item: PREFIX + ITEM,
  item_: (index) => PREFIX + ITEM + '_' + index, //xPath.item, xPath.item_(index)

  switchButton: PREFIX + SWITCH,
  switchButton_: (value) => PREFIX + SWITCH + '_' + value,

  select: PREFIX + SELECT,
  select_: (value) => PREFIX + SELECT + '_' + value,

  input: PREFIX + INPUT,
  input_: (id) => PREFIX + INPUT + '_' + id,

  stateView: {
    ...keymirror(['empty', 'loading', 'error']),
  },
};

export const getClasses = (...list) => {
  let className = '';
  if (list instanceof Array) {
    list.map((item) => {
      if (item) {
        className += ' ';
        if (item instanceof Array) {
          item.map((item) => (className += `.${item}`));
        } else className += `.${item}`;
      }
      className = className.trim();
    });
  } else className += `.${list}`;

  return className;
};

console.log('%c XPATH ', 'background: red; color: white; font-size: 35px', xPath);
console.log(
  '%c EXAMPLE',
  'color: red; ',
  xPath.item_(1),
  xPath.switchButton_(true),
  xPath.select_(1),
);
console.log('%c EXAMPLE', 'color: red; ');
console.log('getClasses(xPath.sidebar) => ', getClasses(xPath.sidebar));
console.log(
  'getClasses(xPath.sidebar, xPath.platform.zalo) => ',
  getClasses(xPath.sidebar, xPath.platform.zalo),
);
console.log(
  'getClasses(xPath.content, [xPath.item, xPath.item_(1)]) => ',
  getClasses(xPath.content, [xPath.item, xPath.item_(1)]),
);
console.log(
  'getClasses(xPath.content, [xPath.item, xPath.item_(1)]) => ',
  getClasses(xPath.content, [xPath.item, xPath.item_(1)]),
);
