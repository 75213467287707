import { t } from '@lingui/macro';
import BaseModal from 'component/BaseComponent/BaseModal';
import { trans } from 'config/LinguiConfig';
import React from 'react';
import Routes from 'router/routes.js';
import { isEmpty } from 'lodash';
import { handleApi } from '../../../helper/helper';
import { getZnsTemplateResults } from '../../../api/api';
import ReactTable from 'react-table';
import { showErrorSnackbar } from '@doopage/react-ui-kit';
import { HeaderZnsTemplateResults } from '../../../variables/headerTable';

class ZnsTemplateResultsModal extends BaseModal {
  constructor(props) {
    super(props);
    const params = this.getDataFromRouter();
    if (isEmpty(params)) this.redirectTo(Routes.znsTemplates.path);
    this.state = {
      open: true,
      loading: false,
      title: trans(t`Mẫu`) + ' ' + params?.id,
      ...params,
    };
    this.goPathWhenClose = Routes.znsTemplates.path;
  }

  async componentDidMount() {
    this.setState({ loading: true });
    const { success, data, error } = await handleApi(getZnsTemplateResults(this.state.id));
    this.setState({ loading: false });
    if (error) {
      showErrorSnackbar(error || trans(t`Có lỗi xảy ra`));
    } else {
      this.setState({ ...data });
    }
  }

  renderBody() {
    let { data, paging, loading } = this.state;
    return (
      <div className="flex flex-1 bg-[#ffff] w-full h-full">
        <ReactTable
          manual
          loading={loading}
          minRows={data?.length ?? 3}
          data={data}
          sortable={false}
          noDataText={trans(t`Không có dữ liệu`)}
          loadingText={trans(t`Đang tải...`)}
          columns={HeaderZnsTemplateResults}
          className="-striped -highlight w-full"
          paging={false}
          showPagination={false}
        />
      </div>
    );
  }
}

export default ZnsTemplateResultsModal;
