import {t} from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import {addWebsites, updateWebsite} from 'api/api';
import BaseModal from 'component/BaseComponent/BaseModal';
import TwitterColorPicker from 'component/ColorPicker/TwitterColorPicker';
import {handleApi, validateDomain, validateHTTP} from 'helper/helper';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import React from 'react';
import Routes from 'router/routes';
import {primaryColor} from 'variables/color';
import DV from 'variables/DV';

class AddWebsiteModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      primary_color: primaryColor,
      text_color: '#fff',
      error: null,
      loading: false,
      open: true,
      id: null,
      title: this.getTitle(),
      ...this.getDataFromRouter(),
    };

    this.goPathWhenClose = !this.props.openFromChannels
      ? Routes.website.path
      : Routes.channel.path;
  }

  getTitle() {
    const {id} = this.getDataFromRouter();
    console.log('// hiep ', this.getDataFromRouter());
    return id ? t`Cập nhật website ${id}` : t`Thêm website`;
  }

  renderBody() {
    const {domain, text_color, primary_color, error} = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CustomInput
            labelText={t`Domain`}
            inputProps={{
              value: domain || '',
              placeholder: 'example.com',
              onChange: this.handleChange('domain'),
            }}
            validInput={(value) => this.validateDomain(value.trim())}
            errorText={error}
          />
        </Grid>
      </Grid>
    );
  }

  renderPicker = (title, key) => {
    return (
      <Grid container spacing={1}>
        <Grid item xs={9}>
          {title}
        </Grid>
        <Grid item xs={3} alignItems="flex-end"></Grid>
        <Grid item xs={12}>
          <TwitterColorPicker onChange={this.handleChange(key)}/>
        </Grid>
      </Grid>
    );
  };

  validateDomain = (value, require = false) => {
    if (value === 'localhost') return true;
    if (validateHTTP(value)) {
      this.setState({error: 'Domain không cần chứa http/https'});
      return false;
    }
    if (!validateDomain(value)) {
      this.setState({error: 'Domain cần có dạng example.com'});
      return false;
    }
    if (!!!value && require) {
      this.setState({error: 'Vui lòng nhập domain website của bạn'});
      return false;
    }
    return true;
  };

  handleSubmit = async () => {
    const {id, domain} = this.state;
    if (!this.validateDomain(domain.trim(), true)) return;
    this.setState({loading: true});
    if (id) await this.update();
    else await this.add();
    this.setState({loading: false});
  };

  renderFooter() {
    return (
      <Button loading={this.props.loading} onClick={this.handleSubmit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }

  add = async () => {
    const {domain, text_color, primary_color} = this.state;
    let {success, error, data} = await handleApi(addWebsites({domain, primary_color, text_color}));

    if (success) {
      // show setting modal after update complete
      this.goPathWhenClose = (!this.props.openFromChannels
        ? Routes.website.detail.path
        : Routes.channel.website.detail.path).replace(':id', data.id);
      DV.url = window.location.pathname;

      this.close(true);
      DV.showNotify(t`Thêm website thành công`);

    } else {
      let {is_shared_doopage_to_facebook} = DV.company;
      if (error.includes(t`nâng cấp gói cước`) && !is_shared_doopage_to_facebook)
        DV.showWarningLimitModal();
      else DV.showNotify(error, 'danger');
    }
  };

  update = async () => {
    const {domain, id, text_color, primary_color} = this.state;
    let {success, error} = await handleApi(
      updateWebsite(id, {
        domain,
        primary_color,
        text_color,
      }),
    );

    if (success) {
      // show setting modal after update complete
      this.goPathWhenClose = (!this.props.openFromChannels
        ? Routes.website.detail.path
        : Routes.channel.website.detail.path).replace(':id', id);
      DV.url = window.location.pathname;

      this.close(true);
      DV.showNotify(t`Cập nhật website thành công`);

    } else DV.showNotify(error, 'danger');
  };
}

export default AddWebsiteModal;
