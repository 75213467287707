import Chip from '@material-ui/core/Chip';
import ButtonAddPermission from 'component/ButtonAddPermission.js';
import { setState } from 'config/Core';
import * as React from 'react';
import { ALL_PERMISSION, PERMISSION, PERMISSION_NAME } from 'variables/staticValue.js';

const { owner } = PERMISSION_NAME;

export default class AddPermission extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: ['seller'],
      isOwner: false,
    };
  }

  deleteOwner = async () => {
    await setState(this)({ isOwner: false });
    if (this.props.onChange) this.props.onChange();
  };

  render = () => {
    let mapGroup = PERMISSION();
    let { permission, isOwner } = this.state;

    let list = permission.map((item, index) => {
      const { title, color } = mapGroup[item];
      return (
        <Chip
          key={item}
          label={title}
          clickable
          onDelete={() => {
            permission.splice(index, 1);
            if (this.props.onChange) this.props.onChange();
            this.setState({ permission });
          }}
          style={{ backgroundColor: color, margin: 3, color: '#fff' }}
          deleteIcon={
            <i
              className="fal fa-times"
              style={{ padding: 5, color: '#fff', fontSize: 17, height: 26 }}
            />
          }
        />
      );
    });

    {
      const { title, color } = mapGroup[owner];
      if (isOwner)
        list.push(
          <Chip
            key={owner}
            label={title}
            clickable
            onDelete={this.deleteOwner}
            style={{ backgroundColor: color, margin: 3, color: '#fff' }}
            deleteIcon={
              <i
                className="fal fa-times"
                style={{ padding: 5, color: '#fff', fontSize: 17, height: 26 }}
              />
            }
          />,
        );
    }

    if (list.length < ALL_PERMISSION.length)
      list.push(
        <div style={{ display: 'inline-block' }}>
          <ButtonAddPermission addPermission={this.addPermission} />
        </div>,
      );
    return list;
  };

  addPermission = async (key) => {
    if (key === owner) {
      await setState(this)({ isOwner: true });
    } else {
      let { permission } = this.state;
      let exist = permission.some((item) => item === key);
      if (exist) return;
      permission.push(key);
      await setState(this)({ permission });
    }
    if (this.props.onChange) this.props.onChange();
  };

  getData = () => {
    const { permission, isOwner } = this.state;
    return { groups: permission, is_owner: isOwner };
  };
}
