import { t } from '@lingui/macro';
import React from 'react';
import { Bar, Bubble, Line, Pie } from 'react-chartjs-2';
import {
  getChannelPerformanceMessage,
  getCustomerSentiment,
  getDistributionDirectionMessage,
  getDistributionMessageTypeChannels,
  getDistributionMessageTypeEmployees,
  getIncome,
  getOrderStatus,
  getPerformanceMessage,
  getPerformanceOrder,
  getPerformanceSaleChannels,
  getPerformanceScenario,
  getProductsPerformance,
  getReceptionPerformance,
  getStaffRank,
} from 'api/api';
import ChannelMessageType from '../Charts/ChannelMessageType';
import ChannelPerformance from '../Charts/ChannelPerformance';
import Income from '../Charts/Income';
import Order from '../Charts/Order';
import ProductsPerformance from '../Charts/ProductsPerformance';
import StaffMessageDirection from '../Charts/StaffMessageDirection';
import StaffMessageType from '../Charts/StaffMessageType';
import StaffResponsePerformance from '../Charts/StaffResponsePerformance';
import ToolTipChannelMessageType from '../ToolTips/ToolTipChannelMessageType';
import ToolTipChannelPerformance from '../ToolTips/ToolTipChannelPerformance';
import ToolTipProductsPerformance from '../ToolTips/ToolTipProductsPerformance';
import ToolTipScenarioPerformance from '../ToolTips/ToolTipScenarioPerformance';
import ToolTipStaffMessageDirection from '../ToolTips/ToolTipStaffMessageDirection';
import ToolTipStaffOrderPerformance from '../ToolTips/ToolTipStaffOrderPerformance';
import ToolTipStaffResponsePerformance from '../ToolTips/ToolTipStaffResponsePerformance';
import ScenarioPerformance from './ScenarioPerformance';
import StaffOrderPerformance from './StaffOrderPerformance';
import StaffReceptionPerformance from './StaffReceptionPerformance';
import ToolTipStaffReceptionPerformance from '../ToolTips/ToolTipStaffReceptionPerformance';
import StaffRank from './StaffRank';
import ToolTipStaffRank from '../ToolTips/ToolTipStaffRank';
import CustomerSentiment from './CustomerSentiment';
import BubbleChart from "../components/BubbleChart";
import ChannelResponsePerformance from "./ChannelResponsePerformance";
import TooltipChannelResponsePerformance from "../ToolTips/ToolTipChannelResponsePerformance";

const classIcon = 'fal fa-';
export const getChartInfo = (type) => {
  const info = {
    bar: {
      icon: classIcon + 'chart-bar',
      color: 'info',
      chartComponent: Bar,
    },
    line: {
      icon: classIcon + 'chart-line',
      color: 'success',
      chartComponent: Line,
    },
    pie: {
      icon: classIcon + 'chart-pie',
      color: 'rose',
      chartComponent: Pie,
    },
    bubble: {
      icon: classIcon + 'chart-line',
      color: 'warning',
      chartComponent: Bubble,
    },
  };

  return info?.[type] ?? info.bar;
};

const ListCharts = () => ({
  income: {
    ...getChartInfo('bar'),
    component: Income,
    getApi: getIncome,
    title: t`Doanh thu`,
    description: t`Doanh thu và số lượng đơn hàng theo thời gian.`,
  },
  order: {
    ...getChartInfo('bar'),
    getApi: getOrderStatus,
    component: Order,
    title: t`Đơn hàng`,
    description: t`Đơn hàng theo trạng thái đơn hàng và thời gian.`,
  },
  staff_response_performance: {
    ...getChartInfo('bubble'),
    getApi: getPerformanceMessage,
    component: StaffResponsePerformance,
    title: t`Hiệu suất phản hồi của viên`,
    description: t`Tốc độ phản hồi, số khách hàng và rate của mổi nhân viên.`,
    tooltip: ToolTipStaffResponsePerformance,
    hasDetail: true,
  },
  channel_response_performance: {
    ...getChartInfo('bubble'),
    getApi: getChannelPerformanceMessage,
    component: ChannelResponsePerformance,
    title: t`Hiệu suất phản hồi của kênh`,
    description: t`Tốc độ phản hồi, số khách hàng và rate của mỗi kênh.`,
    tooltip: TooltipChannelResponsePerformance,
    hasDetail: true,
  },
  staff_order_performance: {
    ...getChartInfo('bubble'),
    getApi: getPerformanceOrder,
    component: StaffOrderPerformance,
    title: t`Hiệu suất chốt đơn`,
    description: t`Doanh thu, số đơn hàng và tỷ lệ chốt của mỗi nhân viên.`,
    tooltip: ToolTipStaffOrderPerformance,
    hasDetail: true,
  },
  scenario_performance: {
    ...getChartInfo('bubble'),
    getApi: getPerformanceScenario,
    component: ScenarioPerformance,
    title: t`Hiệu suất kịch bản`,
    description: t`Tỷ lệ tương tác và số lần hiển thị của mỗi kịch bản`,
    tooltip: ToolTipScenarioPerformance,
  },
  channel_performance: {
    ...getChartInfo('bubble'),
    getApi: getPerformanceSaleChannels,
    component: ChannelPerformance,
    tooltip: ToolTipChannelPerformance,
    title: t`Hiệu suất kênh`,
    description: t`Doanh thu, khách hàng và tỷ lệ chốt của mỗi kênh`,
  },
  product_performance: {
    ...getChartInfo('bubble'),
    getApi: getProductsPerformance,
    component: ProductsPerformance,
    tooltip: ToolTipProductsPerformance,
    title: t`Hiệu suất sản phẩm`,
    description: t`Doanh thu, số đơn hàng của từng sản phẩm.`,
  },
  channel_message_type: {
    ...getChartInfo('bubble'),
    getApi: getDistributionMessageTypeChannels,
    component: ChannelMessageType,
    tooltip: ToolTipChannelMessageType,
    title: t`Phân bổ loại tin nhận / kênh`,
    description: t`Số comment, tin nhắn nhận, khách hàng của từng kênh.`,
    hasDetail: true,
  },
  staff_message_type: {
    ...getChartInfo('bubble'),
    getApi: getDistributionMessageTypeEmployees,
    component: StaffMessageType,
    tooltip: ToolTipChannelMessageType,
    title: t`Phân bố loại tin gửi / nhân sự`,
    description: t`Số comment, tin nhắn gửi đi, số khách hàng của mỗi nhân viên.`,
    hasDetail: true,
  },
  staff_message_direction: {
    ...getChartInfo('bubble'),
    getApi: getDistributionDirectionMessage,
    component: StaffMessageDirection,
    tooltip: ToolTipStaffMessageDirection,
    title: t` Phân bổ chiều tương tác / nhân sự`,
    description: t`Tỷ lệ tin gửi / nhận của mỗi nhân viên.`,
  },
  staff_reception_performance: {
    ...getChartInfo('bubble'),
    getApi: getReceptionPerformance,
    component: StaffReceptionPerformance,
    tooltip: ToolTipStaffReceptionPerformance,
    title: t`Hiệu suất tiếp khách`,
    description: t`Hiệu suất tiếp khách theo lượt tiếp nhận và kết thúc`,
    hasDetail: true,
  },
  staff_rank: {
    ...getChartInfo('bubble'),
    getApi: getStaffRank,
    component: StaffRank,
    tooltip: ToolTipStaffRank,
    title: t`Xếp hạng`,
    description: t`Xếp hạng nhân viên theo số khách hàng đã tiếp nhật và đánh giá của khách hàng`,
    hasDetail: true,
  },
  customer_sentiment: {
    ...getChartInfo('pie'),
    getApi: getCustomerSentiment,
    component: CustomerSentiment,
    title: t`Cảm xúc`,
    description: t`Cảm xúc của khách hàng`,
  },
});

export default ListCharts;
