import LocalStorage from 'config/LocalStorage';

export default class DV {
  static nextPath = '';
  static token = '';
  static refreshToken = '';
  static tokenExpireTime = null;
  static refreshTokenExpireTime = null;
  static classes = {};
  static params = {};
  static url = '';
  static company = LocalStorage.getCompany();
  static joyride = null;
  static joyrideRun = null;
  static showGuideTour = false;
  static selector = null;
  static i18n = null;
  static language = 'vi';
  static currentFilterOrderStatus = 'pending';
  static closeModal = null;
  static owner = null;
  static permission = ['seller', 'stock_keeper', 'marketer', 'manager', 'owner'];
  static isOwner = false;
  static partner = '';
  static currency = 'VND';
  static currentSubscription = {};
  static typeCardImage = null;
  static idCardImage = null;
  static staffDeviceId = '';
  static currentProduct = null;

  static openViewOrder = null;
  static withMobileDialog = false;
  static reRender = false;
  static openExpiredModal = true;
  static country = 'Vietnam';
  static waitingCursor = {};
  static store = {};
  static importFile = null;
  static history = null;
  static currentPbx = null;
  static currentGBM = null;

  static socket = null;

  static scenarioDetail = {};

  static updateHeaderAction = () => {};

  static hideHeaderAction = () => {};

  static showNotify = () => {};

  static showWarningLimitModal = () => {};
  static showSubscribeSocialModal = () => {};

  static checkThanksModal = () => {};
}
