import { t, Trans } from '@lingui/macro';
import { AssetImage } from 'assets/Asset';
import { DOOPAGE_CENTER } from 'config/Env';
import { trans } from 'config/LinguiConfig';
import { dangerColor } from 'MUI_Kit/assets/jss/material-dashboard-pro-react';
import React from 'react';
import { primaryColor, roseColor, successColor, warningColor } from './color';
// import DV from './DV';

const pjson = require('../../package.json');

export const getAppVersion = () => pjson.version;

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
);

export const baseReduxObj = {
  data: [],
  loading: false,
  error: null,
  isSuccess: null, //todo null -> not loading yet, true -> loading success, false -> loading error
};

export const HEIGHT = window.innerHeight;
export const WIDTH = window.innerWidth;

const { detect } = require('detect-browser');
export const OS = detect() ? detect().os : 'NA';

export const DEBOUNCE_TIME = 600;

export const ALERT_SHOW_TIME = 3; //1s
export const ALERT_ERROR_SHOW_TIME = 20;

export const enableReduxPersist = false;

export const ORDER_PICKING = (isBackorderEnable) => {
  let result = {
    all: {
      name: t`Tất cả`,
      color: 'info',
      code: '',
    },
    pending: {
      name: t`Chờ xác nhận`,
      color: 'warning',
      code: 'pending',
    },
  };

  if (isBackorderEnable) {
    result = {
      ...result,
      back_ordering: {
        name: t`Đang đặt hàng`,
        color: 'warning',
        code: 'back_ordering',
      },
      back_ordered: {
        name: t`Đã nhập hàng`,
        color: 'rose',
        code: 'back_ordered',
      },
    };
  }

  result = {
    ...result,
    shipping: {
      name: t`Đã xác nhận`,
      color: 'primary',
      code: 'shipping',
    },
    shipped: {
      name: t`Đã giao hàng`,
      color: 'success',
      code: 'shipped',
    },
    returning: {
      name: t`Đang trả hàng`,
      color: 'warning',
      code: 'returning',
    },
    returned: {
      name: t`Đã trả hàng`,
      color: 'success',
      code: 'returned',
    },
    cancelled: {
      name: t`Đã huỷ`,
      color: 'danger',
      code: 'cancelled',
    },
  };

  return result;
};

export const ORDER_PICKING_ARRAY = (isBackorderEnable) => {
  const result = [
    {
      value: t`Chờ xác nhận`,
      key: 'pending',
    },
    {
      value: t`Đã xác nhận`,
      key: 'shipping',
    },
    {
      value: t`Đã giao hàng`,
      key: 'shipped',
    },
    {
      value: t`Đang trả hàng`,
      key: 'returning',
    },
    {
      value: t`Đã trả hàng`,
      key: 'returned',
    },
    {
      value: t`Đã hủy`,
      key: 'cancelled',
    },
  ];
  if (isBackorderEnable) {
    return [
      ...result,
      {
        value: t`Đang đặt hàng`,
        key: 'back_ordering',
      },
      {
        value: t`Đã nhập hàng`,
        key: 'back_ordered',
      },
    ];
  }

  return result;
};

export const ADVANCE_ORDER_OPTION = () => [
  {
    value: t`Giao hàng bù hàng`,
    key: 'compensation',
  },
  {
    value: t`Trả hàng hoàn tiền`,
    key: 'refund',
  },
  {
    value: t`Trả hàng đổi hàng`,
    key: 'exchange',
  },
];

export const ORDER_PAYMENT = () => ({
  waiting: {
    name: t`Chưa thanh toán`,
    color: 'danger',
    code: 'waiting',
  },

  paid: {
    name: t`Đã thanh toán`,
    color: 'success',
    code: 'paid',
  },
  refunded: {
    name: t`Đã hoàn tiền`,
    color: 'success',
    code: 'refunded',
  },
});

export const ORDER_PAYMENT_ARRAY = () => [
  {
    value: t`Chưa thanh toán`,
    key: 'waiting',
  },
  {
    value: t`Đã thanh toán`,
    key: 'paid',
  },
  {
    value: t`Đã hoàn tiền`,
    key: 'refunded',
  },
];

export const SCOPE = {
  pages_manage_metadata: true,
  pages_read_engagement: true,
  pages_messaging: true,
  pages_read_user_content: true,
  pages_manage_engagement: true,
  pages_manage_posts: true,
  email: true,
  public_profile: true,
  instagram_basic: true,
  instagram_manage_comments: true,
  instagram_manage_messages: true,
  business_management: true
};

export const PAGING = () => ({
  size: 5,
  head: t` Đầu  `,
  tall: t` Cuối  `,
  hidden: '...',
  next: t` Trang kế  `,
  previous: t` Trang trước  `,
});

export const SPACE = <span>&nbsp;</span>;

export const COLORS_CHART = ['warning', 'success', 'danger', 'info', 'primary', 'rose'];

export const VNPOST_TUTURIAL = 'https://vnpost.gitbook.io/vnpost-tutorial/';

export const runGuideTour = () => {
  // if (DV.partner === 'hcmpost.vn')
  // {
  //     window.open(VNPOST_TUTURIAL, '_blank');
  //     return;
  // }
  // DV.showGuideTour = true;
  // DV.joyrideRun();
};

export const BROADCAST_MESSAGE_CONTENT = (isComment = true) => {
  let result = {
    text: t`Văn bản`,
    card: t`Hình và nút`,
    input: t`Nhập liệu`,
  };
  return result;
};

export const SERVER_CARD_MESSAGE = () => ({
  type: 'card',
  message: {
    attachment: {
      type: 'template',
      payload: {
        template_type: 'generic',
        elements: [
          {
            title: '',
            image_url: '',
            subtitle: '',
            buttons: [],
          },
        ],
      },
    },
  },
});

export const SERVER_IMAGE_MESSAGE = () => ({
  type: 'card',
  message: {
    attachment: {
      type: 'image',
      payload: {
        url: '',
        is_reusable: true,
      },
    },
  },
});

export const BROADCAST_MESSAGE_OBJ = {
  text: {
    type: 'text',
    message: {
      text: '',
    },
  },
  input: {
    type: 'input',
    message: {
      text: '',
    },
  },
  card: {
    type: 'card',
    message: {
      title: '',
      subtitle: '',
      buttons: [],
      image_url: '',
    },
  },
};

export const BROADCAST_STATES = () => ({
  draft: {
    text: t`Bản nháp`,
    color: warningColor,
  },
  pending: {
    text: t`Đang chờ`,
    color: warningColor,
  },
  sending: {
    text: t`Đang gửi`,
    color: '#000',
  },
  pausing: {
    text: t`Tạm dừng`,
    color: warningColor,
  },
  done: {
    text: t`Đã gửi`,
    color: successColor,
  },
});

export const MESSAGE_CONTENT = (platforms = []) => {
  let temp = [
    {
      platform: [],
      data: { text: t`Văn bản` },
    },
    {
      platform: [],
      data: { image: t`Hình ảnh` },
    },
    {
      platform: ['fb'],
      data: { card: t`Hình và nút` },
    },
    {
      platform: ['fb', 'google_business'],
      data: { carousel: t`Carousel` },
    },
    {
      platform: ['fb'],
      data: { input: t`Nhập liệu` },
    },
    {
      platform: [],
      data: { update_segment: t`Chuyển phễu` },
    },
    {
      platform: [],
      data: { update_tag: t`Chuyển tag` },
    },
    {
      platform: ['fb'],
      data: { react_comment: t`Tương tác` },
    },
    {
      platform: ['fb'],
      data: { reply_comment: t`Trả lời comment` },
    },
    {
      platform: ['fb'],
      data: { hide_comment: t`Ẩn comment` },
    },
    {
      platform: [],
      data: { execute_scenario: t`Kích hoạt kịch bản khác` },
    },
    {
      platform: [
        'fb',
        'personal_fb',
        'zalo',
        'personal_zalo',
        'sendo',
        'website',
        'email',
        'instagram',
      ],
      data: { send_order_form: t`Gửi form chốt đơn` },
    },
    {
      platform: ['shopee'],
      data: { reply_review: t`Trả lời đánh giá` },
    },
  ];

  let result = {};
  temp.forEach((item) => {
    if (item.platform.length === 0 || platforms.every((key) => item.platform.includes(key))) {
      result = { ...result, ...item.data };
    }
  });
  return result;
};

export const MESSAGE_CONTENT_KEY = {
  text: 'text',
  card: 'card',
  carousel: 'carousel',
  input: 'input',
  react_comment: 'react_comment',
  reply_comment: 'reply_comment',
  api: 'api',
  update_segment: 'update_segment',
  update_tag: 'update_tag',
  hide_comment: 'hide_comment',
  image: 'image',
  execute_scenario: 'execute_scenario',
  send_order_form: 'send_order_form',
  reply_review: 'reply_review',
  zns_template: 'zns_template',
};

export const messageTypeWithoutBorder = [
  MESSAGE_CONTENT_KEY.card,
  MESSAGE_CONTENT_KEY.image,
  MESSAGE_CONTENT_KEY.text,
  MESSAGE_CONTENT_KEY.reply_comment,
  MESSAGE_CONTENT_KEY.reply_review,
  MESSAGE_CONTENT_KEY.carousel,
];

export const MESSAGE_CONTENT_ICON = {
  text: 'fa-align-left',
  card: 'fa-credit-card-blank',
  input: 'fa-keyboard',
  react_comment: 'fa-smile-plus',
  reply_comment: 'fa-comment-alt',
  api: 'fa-broadcast-tower',
  update_segment: 'fa-filter',
  update_tag: 'fa-tags',
  hide_comment: 'fa-eye-slash',
  image: 'fa-image',
  execute_scenario: 'fa-bolt',
  send_order_form: 'fa-cart-plus',
  reply_review: 'fa-star',
  carousel: 'fa-window-restore',
};

export const MESSAGE_CONTENT_GROUP = {
  Order: 'Đơn hàng',
  Advanced: 'Nâng cao',
  Experiment: 'Thử nghiệm',
};

export const MESSAGE_CONTENT_GROUP_ITEM = {
  // text: null,
  // image: null,
  // card: null,
  // input: null,
  // react_comment: null,
  // reply_comment: null,
  // hide_comment: null,
  /* Order */
  send_order_form: MESSAGE_CONTENT_GROUP.Order,
  /* Advanced */
  api: MESSAGE_CONTENT_GROUP.Advanced,
  update_segment: MESSAGE_CONTENT_GROUP.Advanced,
  update_tag: MESSAGE_CONTENT_GROUP.Advanced,
  execute_scenario: MESSAGE_CONTENT_GROUP.Advanced,
  /* Experiment */
};

export const PatternsButton = () => [
  {
    color: 'primary',
    text: t`Tên sản phẩm`,
    code: '{sku_name}',
  },
  {
    color: 'success',
    text: t`Mã sản phẩm`,
    code: '{sku_id}',
  },
  {
    color: 'warning',
    text: t`Thuộc tính sản phẩm`,
    code: '{sku_attribute}',
  },
  {
    color: 'rose',
    text: t`Giá sản phẩm`,
    code: '{sku_price}',
  },
  {
    color: 'gray',
    text: t`Mô tả`,
    code: '{sku_description}',
  },
  {
    color: 'info',
    text: t`Đơn vị bán`,
    code: '{sku_unit}',
  },
  {
    color: 'tumblr',
    text: t`Còn hàng/hết hàng`,
    code: '{sku_inventory}',
  },
];

export const PatternsButtonAddField = () => [
  {
    color: 'primary',
    text: t`Tên`,
    code: '{first_name}',
  },
  // {
  //     color: "success",
  //     text: (t`Giới tính`),
  //     code: '{gender}',
  // },
  // {
  //     color: "success",
  //     text: (t`Họ`),
  //     code: '{last_name}',
  // },
  // {
  //     color: "warning",
  //     text: (t`Tên đệm`),
  //     code: '{middle_name}',
  // },
  {
    color: 'rose',
    text: t`Tên đầy đủ`,
    code: '{full_name}',
  },
  {
    color: 'info',
    text: t`Email`,
    code: '{email}',
  },
  {
    color: 'tumblr',
    text: t`Số điện thoại`,
    code: '{phone}',
  },
  {
    color: 'dribbble',
    text: t`Địa chỉ`,
    code: '{address}',
  },
  {
    color: 'gray',
    text: t`Tên kênh`,
    code: '{platform_name}',
  },
];

export const ZnsTemplateSubstitutions = () => [
  {
    color: 'primary',
    text: t`Tên khách hàng`,
    code: '<customer_name>',
  },
  {
    color: 'tumblr',
    text: t`Số điện thoại`,
    code: '<customer_phone>',
  },
  {
    color: 'info',
    text: t`Email`,
    code: '<customer_email>',
  },
  {
    color: 'warning',
    text: t`Mã sản phẩm`,
    code: '<product_id>',
  },
  {
    color: 'rose',
    text: t`Tên sản phẩm`,
    code: '<product_name>',
  },
  {
    color: 'success',
    text: t`Giới tính`,
    code: '<gender>',
  },
  {
    color: 'dribbble',
    text: t`Địa chỉ`,
    code: '<address>',
  },
  {
    color: 'info',
    text: t`Số lượng`,
    code: '<quantity>',
  },
  {
    color: 'success',
    text: t`Số tiền`,
    code: '<amount_money>',
  },
  {
    color: 'primary',
    text: t`Mã đơn hàng`,
    code: '<order_id>',
  },
  {
    color: 'danger',
    text: t`Trạng thái đơn hàng`,
    code: '<order_status>',
  },

  {
    color: 'gray',
    text: t`Nhãn tùy chọn 1`,
    code: '<custom_label1>',
  },
  {
    color: 'rose',
    text: t`Nhãn tùy chọn 2`,
    code: '<custom_label2>',
  },
  {
    color: 'success',
    text: t`Tên công ty`,
    code: '<company_name>',
  },
];

export const QUICK_REPLIES = () => [
  {
    key: 'text',
    value: t`Văn bản`,
  },
  {
    key: 'location',
    value: t`Vị trí`,
  },
  {
    key: 'phone',
    value: t`Số điện thoại`,
  },
  {
    key: 'email',
    value: t`Email`,
  },
];

export const mapStatus = {
  disable: false,
  publish: true,
};

export const PERMISSION_NAME = {
  seller: 'seller',
  stock_keeper: 'stock_keeper',
  marketer: 'marketer',
  manager: 'manager',
  owner: 'owner',
};

export const ORDER_TYPE = {
  normal: 'normal',
  compensation: 'compensation',
  exchange: 'exchange',
  exchange_return: 'exchange_return',
  refund: 'refund',
};

export const ALL_PERMISSION = Object.keys(PERMISSION_NAME).map((key) => PERMISSION_NAME[key]);

export const PERMISSION = () => ({
  [PERMISSION_NAME.seller]: {
    title: t`Tư vấn viên`,
    color: successColor,
    permission: t`Truy xuất hộp thư đến.`,
  },
  [PERMISSION_NAME.stock_keeper]: {
    title: t`Thủ kho`,
    color: warningColor,
    permission: t`Quản lý đơn hàng, sản phẩm, thuộc tính sản phẩm.`,
  },
  [PERMISSION_NAME.marketer]: {
    title: t`Marketer`,
    color: roseColor,
    permission: t`Quản lý kịch bản, kênh, phễu khách hàng, khách hàng.`,
  },
  [PERMISSION_NAME.manager]: {
    title: t`Quản lý`,
    color: primaryColor,
    permission: t`Quản lý tích hợp (đồng bộ, giao nhận), thanh toán, nhân viên.`,
  },
  [PERMISSION_NAME.owner]: {
    title: t`Chủ sở hữu`,
    color: dangerColor,
    permission: t`Tất cả tính năng.`,
  },
});

export const NUMBER_PLATFORM = 30;
export const NUMBER_PLATFORM_DISCOUNT_MAX = 10;
export const NUMBER_MONTH = 30;
export const NUMBER_MONTH_DISCOUNT_MAX = 36;
export const NUMBER_SELLER = 25;
export const NUMBER_SELLER_DISCOUNT_MAX = 25;

export const TYPE_MESSAGE_BROADCAST = () => ({
  RESPONSE: {
    title: t`Tin nhắn trả lời`,
    descrip: (
      <Trans>
        Loại tin này giúp bạn trả lời đến những khách hàng đã nhắn tin cho bạn trong vòng 24 giờ.
        Loại tin này <span className="bold">ĐƯỢC</span> phép chứa nội dung quảng cáo. Lưu ý: Chỉ
        dùng để trả lời khách hàng, vui lòng lọc lần tương tác cuối trong vòng 24 giờ.
      </Trans>
    ),
  },
  UPDATE: {
    title: t`Tin nhắn cập nhật`,
    descrip: (
      <Trans>
        {' '}
        Tin nhắn chủ động gửi đi và không phải để trả lời cho tin nhắn đã nhận. Loại tin này{' '}
        <span className="bold">ĐƯỢC</span> phép chứa nội dung quảng cáo và chỉ có thể gửi cho khách
        hàng đã nhắn tin cho bạn trong vòng 24 giờ. Ví dụ: Dùng để cập nhật trạng thái đơn hàng cho
        khách hàng.
      </Trans>
    ),
  },
  MESSAGE_TAG: {
    title: t` Tin nhắn đăng ký`,
    descrip: (
      <Trans>
        {' '}
        Loại tin nhắn này giúp bạn gửi cho tất cả nhưng khách hàng đã nhắn tin cho bạn và không bị
        giới hạn trong vòng 24 giờ. Loại tin nhắn này <span className="bold">KHÔNG</span> được phép
        chứa nội dung quảng cáo. Lưu ý: để sử dụng loại tin nhắn này trang của bạn phải được đăng ký
        quyền Nhắn tin đăng ký với Facebook. Xem hướng dẫn{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://huongdan.doopage.com/danh-cho-chu-doanh-nghiep/quan-li-tin-nhan-hang-loat/huong-dan-cach-dang-ky-subscription-message-gui-tin-hang-loat-tren-facebook-fanpage">
          Tại đây
        </a>
      </Trans>
    ),
  },
});

export const mapShipmentServiceIcon = {
  topship: AssetImage.TopshipLogo,
  vtpost: AssetImage.VTPOST,
  ghn: AssetImage.GHN,
  ghtk: AssetImage.GHTK,
  vnpost: AssetImage.VnPostLogo,
  hcmpost: AssetImage.VnPostLogo,
  'J&T': AssetImage.JAndT,
  dhl: AssetImage.DhlLogo,
  ninjavan: AssetImage.NinjavanLogo,
};

export const mapShipmentOnlyIcon = {
  topship: AssetImage.TopshipIcon,
  vtpost: AssetImage.VTPOST,
  ghn: AssetImage.GHNIcon,
  ghtk: AssetImage.GHTKIcon,
  vnpost: AssetImage.VnPostLogo,
  hcmpost: AssetImage.VnPostLogo,
  'J&T': AssetImage.JAndT,
  dhl: AssetImage.DhlLogo,
  ninjavan: AssetImage.NinjavanLogo,
};

export const defaultBankAccount = {
  branch: 'Chi nhánh Tân Cảng',
  owner: 'Hứa Phước Trường',
  number: 4804,
  content: 'dp',
  bank: 'vpbank',
  logo: 'vpbank.png',
};
const DEFAULT_GUIDE_URL = {
  main: 'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan',
  website: 'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/website',
  zaloOA:
    'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/zalo-official-account',
  personal:
    'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/facebook-ca-nhan',
  shopee: 'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/shopee',
  sendo: 'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/sendo',
  email: 'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/email',
  personalZalo: 'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/zalo',
  importProduct:
    'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-san-pham/them-san-pham-tu-file-excel',
  importCustomer:
    'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-khach-hang/nhap-xuat-file-du-lieu-khach-hang-vao-doopage',
  kiotViet:
    'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-san-pham/dong-bo-san-pham',
  productFeed:
    'https://huongdan.doopage.com/quan-ly-va-cau-hinh-tai-khoan/quan-li-san-pham/dong-bo-san-pham-bang-product-feed',
  instagram:
    'https://huongdan.doopage.net/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/instagram-update',
  fanpage: 'https://huongdan.doopage.net/quan-ly-va-cau-hinh-tai-khoan/cap-nhat-danh-sach-fanpage',
};

export const LIST_DOMAIN = {
  'hcmpost.vn': {
    backgroundColor: '#fff',
    logo: AssetImage.VnPostLogo,
    headerLogo: AssetImage.VnPostHeaderLogo,
    printFormat:
      '@page { size: A5 portrait;  margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
    centerURL: 'https://banhang.hcmpost.vn',
    bankAccount: {
      bank: 'Ngân hàng TMCP Đầu tư và Phát triển Việt Nam',
      branch: 'Chi nhánh Sài Gòn',
      owner: 'Bưu điện TP Hồ Chí Minh',
      number: 14010001551662,
      content: 'vnpost ',
      logo: 'bidv.png',
    },
    indexTitle: 'VNPOST Shop',
    indexLogo: '/vnpost.jpg',
    guide: {
      main: 'https://vnpost.gitbook.io/vnpost-tutorial/quan-ly-va-cau-hinh-tai-khoan',
      website:
        'https://vnpost.gitbook.io/vnpost-tutorial/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/website',
      zaloOA:
        'https://vnpost.gitbook.io/vnpost-tutorial/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/zalo-official-account',
      personal:
        'https://vnpost.gitbook.io/vnpost-tutorial/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/facebook-ca-nhan',
      shopee:
        'https://vnpost.gitbook.io/vnpost-tutorial/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/shopee',
      sendo:
        'https://vnpost.gitbook.io/vnpost-tutorial/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/sendo',
      email:
        'https://vnpost.gitbook.io/vnpost-tutorial/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/email',
      personalZalo:
        'https://vnpost.gitbook.io/vnpost-tutorial/quan-ly-va-cau-hinh-tai-khoan/quan-li-da-kenh/zalo',
      importProduct:
        'https://vnpost.gitbook.io/vnpost-tutorial/quan-ly-va-cau-hinh-tai-khoan/quan-li-san-pham/them-san-pham-tu-file-excel',
      importCustomer:
        'https://vnpost.gitbook.io/vnpost-tutorial/quan-ly-va-cau-hinh-tai-khoan/quan-li-khach-hang/nhap-xuat-file-du-lieu-khach-hang-vao-doopage',
    },
    chatboxPath: '/chatbox/hcmpost.js',
  },
  'etop.vn': {
    backgroundColor: '#fff',
    logo: AssetImage.TopshipLogo,
    headerLogo: AssetImage.TopshipHeaderLogo,
    printFormat:
      '@page { size: A5 landscape;  margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
    centerURL: DOOPAGE_CENTER,
    bankAccount: defaultBankAccount,
    indexTitle: 'DooPage Dashboard',
    indexLogo: '/doopage.ico',
    guide: DEFAULT_GUIDE_URL,
  },
  'itop.website': {
    backgroundColor: '#5EAFD8',
    mainLogoStyle: { width: 350 },
    logo: AssetImage.iTopLogo,
    centerLogo: AssetImage.iTopLogoSquare,
    headerLogo: AssetImage.iTopHeaderLogo,
    printFormat:
      '@page { size: A5 landscape;  margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
    centerURL: 'https://bot-inbox.itop.website',
    bankAccount: {
      bank: 'TMCP Ngoại thương Việt Nam Vietcombank - VCB',
      branch: 'Chi nhánh Bình Thạnh',
      owner: 'Nguyễn Minh Đức',
      number: '0531000280056',
      content: 'itop ',
      logo: 'vietcombank.png',
    },
    indexTitle: 'Bot iTop',
    indexLogo: '/itop_logo.png',
    guide: DEFAULT_GUIDE_URL,
    chatboxPath: '/chatbox/loveitop.js',
  },
  'tatisale.com': {
    backgroundColor: '#fff',
    logo: AssetImage.TatisaleLogo,
    headerLogo: AssetImage.TatisaleHeaderLogo,
    printFormat:
      '@page { size: A5 landscape;  margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
    centerURL: 'https://center.tatisale.com',
    bankAccount: defaultBankAccount,
    indexTitle: 'Tatisale Dashboard',
    indexLogo: '/doopage.ico',
    guide: DEFAULT_GUIDE_URL,
  },
  'atpcare.vn': {
    backgroundColor: '#fff',
    logo: AssetImage.ATPCareLogo,
    headerLogo: AssetImage.ATPCareHeaderLogo,
    printFormat:
      '@page { size: A5 landscape;  margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
    centerURL: 'https://center.atpcare.vn',
    bankAccount: {
      bank: 'TMCP Ngoại thương Việt Nam Vietcombank - VCB',
      branch: 'Chi nhánh Tân Sơn Nhất - Hồ Chí Minh',
      owner: 'Phạm Thị Linh',
      number: '1014396318',
      content: 'atpcare ',
      logo: 'vietcombank.png',
    },
    indexTitle: 'ATPCare Dashboard',
    indexLogo: '/atpcare_logo.png',
    guide: DEFAULT_GUIDE_URL,
  },
  'moma.vn': {
    backgroundColor: '#fff',
    logo: AssetImage.MomaLogo,
    headerLogo: AssetImage.MomaHeaderLogo,
    printFormat:
      '@page { size: A5 landscape;  margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
    centerURL: 'https://all.moma.vn',
    bankAccount: defaultBankAccount,
    indexTitle: 'Moma Chat',
    indexLogo: '/moma_logo.png',
    guide: DEFAULT_GUIDE_URL,
  },
  'kidofa.vn': {
    backgroundColor: '#fff',
    logo: AssetImage.KidofaLogo,
    headerLogo: AssetImage.KidofaHeaderLogo,
    headerLogoBackgroundColor: '#ed1c24',
    printFormat:
      '@page { size: A5 landscape;  margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
    centerURL: 'https://center.kidofa.vn',
    bankAccount: defaultBankAccount,
    indexTitle: 'KIDOFA Dashboard',
    indexLogo: '/kidofa_logo.png',
    guide: DEFAULT_GUIDE_URL,
  },
  'cnvinbox.com': {
    backgroundColor: '#fff',
    logo: AssetImage.CNVInboxLogo,
    headerLogo: AssetImage.CNVInboxHeaderLogo,
    headerLogoBackgroundColor: '#f282a2',
    secondaryColor: '#fee9ef',
    printFormat:
      '@page { size: A5 landscape;  margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
    centerURL: 'https://chat.cnvinbox.com',
    bankAccount: defaultBankAccount,
    indexTitle: 'CNV Inbox Dashboard',
    indexLogo: '/cnvinbox_logo.png',
    guide: DEFAULT_GUIDE_URL,
  },
  '': {
    backgroundColor: '#fff',
    logo: AssetImage.DoopageLogo,
    headerLogo: AssetImage.DoopageHeaderLogo,
    printFormat:
      '@page { size: A5 landscape;  margin: 5mm; } @media print { body { -webkit-print-color-adjust: exact; } }',
    centerURL: DOOPAGE_CENTER,
    bankAccount: defaultBankAccount,
    indexTitle: 'DooPage Dashboard',
    indexLogo: '/doopage.ico',
    guide: DEFAULT_GUIDE_URL,
    chatboxPath: '/chatbox/doopage.js',
  },
};

export const cardContentType = {
  scenarioCarousel: 'scenarioCarousel',
};

export const FIBONACCI_NUMBERS = [0, 1, 2, 3, 5, 8, 13, 21, 34, 55, 89, 144, 233];

export const STATE_SOCKET = {
  none: 'none',
  pending: 'pending',
  connect: 'connect',
  close: 'close',
};

const PLATFORM_NAMES = {
  fb: 'Facebook',
  zalo_oa: 'Zalo OA',
  personal_zalo: t`Zalo cá nhân`,
  shopee: 'Shopee',
  sendo: 'Sendo',
  website: t`Website`,
  email: t`Email`,
  instagram: 'Instagram',
  gbm: t`Google Business Message`,
  sip: t`Tổng đài`,
};
export const getPlatformName = (id) => {
  return PLATFORM_NAMES?.[id] ?? id;
};

const PLATFORM_LOGO = {
  fb: AssetImage.Facebook_image,
  zalo_oa: AssetImage.Zalo_new,
  shopee: AssetImage.Shopee_image,
  website: AssetImage.Web_image,
  email: AssetImage.Web_image,
  instagram: AssetImage.Instagram_image,
  google_business: AssetImage.GBMIcon,
  sip: AssetImage.PbxLogo
};

export const getPlatformLogo = (channelType) => {
  return PLATFORM_LOGO?.[channelType];
};

