import { Select, Space } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { trans } from 'config/LinguiConfig';
import React, { memo } from 'react';
import SelectTag from '../../../../component/Select/SelectTag';

const ChangeTagAction = ({ hide, action, value, onChange }) => {
  if (hide) return null;

  return (
    <div className="p-3">
      <Space direction="vertical" spaceHeight={10}>
        <Select
          fullWidth
          label={t`Cách đánh tag`}
          options={[
            { id: 'add', name: t`Thêm tag` },
            { id: 'remove', name: t`Xoá tag` },
          ]}
          value={action}
          onChange={(e) => onChange('action', e.target.value)}
        />
        <SelectTag
          fullWidth
          value={value?.id}
          onChange={(_, value) => {
            onChange('tag', value);
          }}
          label={t`Tag`}
        />
      </Space>
    </div>
  );
};

export default memo(ChangeTagAction);
