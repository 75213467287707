import { Button, showErrorSnackbar } from '@doopage/react-ui-kit';
import '@doopage/react-ui-kit/dist/doopage-ui-kit.css';
import { t } from '@lingui/macro';
import { useQuery } from '@redux-requests/react';
import BaseModal from 'component/BaseComponent/BaseModal';
import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { getInstagram, updateInstagram } from 'redux/action/apiAction';
import Routes from 'router/routes';
import produce from 'immer';
import { set } from 'lodash';
import IceBreakerEditor from 'screen/Channel/Fanpage/components/IceBreakerEditor';

class InstagramConfigModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: t`Gợi ý`,
      loading: false,
      name: '',
    };
    this.refreshOnClose = false;
    this.goPathWhenClose = !this.props.openFromChannels ? Routes.fanpage.path : Routes.channel.path;
    this.modal = createRef();
  }

  onClose = () => {
    this.modal.current?.close?.();
  };

  renderBody() {
    return (
      <Content
        closeModal={this.close}
        ref={this.modal}
        id={this.props.match.params.id}
        onLoading={(value) => this.setState({ loading: value })}
      />
    );
  }

  renderFooter() {
    return (
      <Button
        onClick={() => {
          this.modal.current?.submit?.();
        }}
        loading={this.state.loading}
        color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }
}

export default connect()(InstagramConfigModal);

export const Content = forwardRef(({ closeModal, id, onLoading }, ref) => {
  const { data, loading, error } = useQuery({ type: getInstagram });
  const [{ ice_breakers }, setData] = useState({});
  const messengerConfig = data?.messenger_config;
  const dispatch = useDispatch();

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) closeModal();
  }, [error]);

  useEffect(() => {
    dispatch(getInstagram(id));
  }, [id]);

  useEffect(() => {
    if (messengerConfig) {
      setData(messengerConfig);
    }
  }, [messengerConfig]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const { error } = await dispatch(
        updateInstagram(id, {
          messenger_config: { ice_breakers: { ...ice_breakers, active: true } },
        }),
      );

      if (!error) {
        closeModal();
      } else showErrorSnackbar(error);
    },
  }));

  const update = useCallback((path, value) => {
    setData(
      produce((draftState) => {
        set(draftState, path, value);
      }),
    );
  }, []);

  return <IceBreakerEditor update={update} buttons={ice_breakers?.buttons ?? []} />;
});
