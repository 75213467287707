import { createReducer, getTypeClear } from 'redux/utils';
import { Types } from '../../../redux/type';

export const baseData = {
  customerCount: {
    channels: [],
    total_count: 0,
  },
  receiveMessageCount: {
    series: [],
    last_timestamp: 0,
    total_count: 0,
  },
  sellerMessageCount: {
    sellers: [],
    total_count: 0,
  },
};

export const sampleData = {
  customerCount: {
    channels: [
      {
        type: 'fb',
        count: 100,
        percent: 50,
      },
      {
        type: 'shopee',
        count: 50,
        percent: 25,
      },
      {
        type: 'instagram',
        count: 50,
        percent: 25,
      },
    ],
    total_count: 200,
  },
  receiveMessageCount: {
    series: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 10, 10, 0, 0, 5,
    ],
    total_count: 25,
  },
  sellerMessageCount: {
    sellers: [
      {
        id: 1,
        name: 'DooPage 1',
        avatar: 'https://domain.com/image.jpg',
        count: 100,
      },
      {
        id: 2,
        name: 'DooPage 2',
        avatar: 'https://domain.com/image2.jpg',
        count: 50,
      },
      {
        id: null,
        name: 'Other',
        avatar: null,
        count: 900,
      },
    ],
    total_count: 1050,
  },
};

const type = Types.statistic;

export default createReducer(baseData, {
  [`${type}/updateCustomerCount`]: (state, action) => {
    return {
      ...state,
      customerCount: action.payload,
    };
  },
  [`${type}/updateReceiveMessageCount`]: (state, action) => {
    //init
    if (!action.payload)
      return {
        ...state,
        receiveMessageCount: {
          series: new Array(20).fill(0),
          last_timestamp: 0,
          total_count: 20,
        },
      };

    const { series = [], last_timestamp, current_timestamp } = action.payload || {};
    let data = [...series];
    const currentTime = Math.floor(current_timestamp);
    const t = Math.floor((currentTime - last_timestamp) / 300); // số cột từ last_timestamp tới hiện tại

    //init data
    if (state.receiveMessageCount.series?.length === 0) {
      if (t < 0) {
        return {
          ...state,
          receiveMessageCount: {
            series: [...state.receiveMessageCount.series, ...series],
            last_timestamp,
          },
        };
      }
      if (t >= 20) {
        data = new Array(20).fill(0);
      } else {
        data = [...data, ...new Array(t).fill(0)];
        data = data.slice(Math.max(data.length - 20, 0));
      }
      return {
        ...state,
        receiveMessageCount: {
          series: data,
          last_timestamp: last_timestamp + t * 300,
        },
      };
    }

    //update data

    const d = Math.floor((last_timestamp - state.receiveMessageCount.last_timestamp) / 300);
    if (d > 0) {
      let data = [...state.receiveMessageCount.series];
      for (let i = 1; i < d; i++) {
        data.push(0);
      }
      data.push(series[0]);
      data = data.slice(Math.max(data.length - 20, 0));
      return {
        ...state,
        receiveMessageCount: {
          series: data,
          last_timestamp: state.receiveMessageCount.last_timestamp + d * 300,
        },
      };
    }
    if (d <= 0 && d > -20) {
      //update cột cũ
      let newData = [...state.receiveMessageCount.series];
      newData[newData?.length - 1 + d] = series[0];
      return {
        ...state,
        receiveMessageCount: {
          series: newData,
          last_timestamp: state.receiveMessageCount.last_timestamp,
        },
      };
    }
    return {
      ...state,
      receiveMessageCount: action.payload,
    };
  },
  [`${type}/updateSellerMessageCount`]: (state, action) => {
    return {
      ...state,
      sellerMessageCount: action.payload,
    };
  },
  [getTypeClear(type)]: () => {
    return {
      ...baseData,
    };
  },
});
