import { t } from '@lingui/macro';
import { Grid } from '@material-ui/core';
import { getOrderBillData } from 'api/api';
import { AssetImage } from 'assets/Asset';
import BaseModal from 'component/BaseComponent/BaseModal';
import Receipt from 'component/Modal/Order/Receipt';
import { trans } from 'config/LinguiConfig';
import { getParamsFromObject, handleApi } from 'helper/helper';
import _ from 'lodash';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import ReactToPrint from 'react-to-print';
import Routes from 'router/routes';
import DV from 'variables/DV';
import { LIST_DOMAIN } from 'variables/staticValue';
import './ReceiptOrderModal.css';

class ReceiptOrderModal extends BaseModal {
  constructor(props) {
    super(props);

    this.maxWidth = 'md';
    this.goPathWhenClose = Routes.order.path;

    let dataFromRouter = this.getDataFromRouter();
    if (_.isEmpty(dataFromRouter)) this.returnOrderList();

    this.state = {
      open: true,
      loading: true,
      params: {},
      orders: [],
      ...{
        ...dataFromRouter,
        params: {
          ...dataFromRouter.params,
          page: null,
          page_size: null,
        },
      },
      ...this.getSaveData(),
    };

    this.receiptRef = null;
  }

  returnOrderList() {
    this.redirectTo(this.goPathWhenClose);
  }

  async componentDidMount() {
    await this.setState({
      loading: false,
      orders: await this.getData(),
    });
  }

  async getData() {
    const { params } = this.state;

    const { success, data } = await handleApi(getOrderBillData(getParamsFromObject(params)));
    if (success) {
      return data;
    } else {
      DV.showNotify(t`Có lỗi xảy ra`, 'danger', 5);
    }
    return [];
  }

  renderLoading = () => {
    return (
      <div className="content flex_center">
        <img src={AssetImage.Loading} className="listdata-img" />
        <div style={{ height: 30 }} />
        <div className="description-text">{t`Đang tải dữ liệu...`}</div>
        <div style={{ height: 20 }} />
      </div>
    );
  };

  renderBody() {
    const { orders, loading } = this.state;
    if (loading) {
      return this.renderLoading();
    }
    return (
      <Grid
        container
        alignItems={'center'}
        justify={'center'}
        ref={(ref) => (this.receiptRef = ref)}
        className={'print-body A5 landscape'}>
        {orders.map((order, index) => this.renderOrder(index, order))}
      </Grid>
    );
  }

  renderOrder(index, order) {
    return (
      <Grid item className={'sheet print-page'}>
        <Receipt key={index} {...order} />
      </Grid>
    );
  }

  renderFooter() {
    const { loading } = this.state;

    return (
      <>
        <ReactToPrint
          trigger={() => (
            <Button color="rose" disabled={loading}>
              {t`In Phiếu`}
            </Button>
          )}
          content={() => this.receiptRef}
          pageStyle={LIST_DOMAIN[DV.partner].printFormat}
        />
      </>
    );
  }
}

export default ReceiptOrderModal;
