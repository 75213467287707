import React, { useCallback, useMemo, useState } from 'react';
import { convertFromRaw, EditorState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, { defaultSuggestionsFilter } from '@draft-js-plugins/mention';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import './style.scss';
import classNames from 'classnames';

const mentions = [
  '{customer.id}',
  '{customer.name}',
  '{customer.phone}',
  '{customer.email}',
  '{customer.segment.id}',
  '{customer.segment.name}',
  '{customer.num_ordered}',
  '{customer.revenue}',
  '{customer.last_order.id}',
  '{customer.last_order.value}',
  '{customer.last_order.lines}',
  '{customer.last_order.created_date}',
  '{customer.last_order.delivered_date}',
  '{customer.last_order.amount_due}',
  '{customer.last_order.amount_paid}',
];

export default function SuggestionInput({
  defaultValue,
  onChange,
  suggestionData = mentions,
  ...rest
}) {
  const [editorState, setEditorState] = useState(() => {
    if (!defaultValue) return EditorState.createEmpty();
    try {
      const contentState = convertFromRaw(JSON.parse(defaultValue));
      return EditorState.createWithContent(contentState);
    } catch (e) {
      return EditorState.createWithText(defaultValue);
    }
  });

  const mentions = useMemo(() => suggestionData.map((item) => ({ name: item })), [suggestionData]);
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(mentions);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionTrigger: ['{'],
      mentionComponent: (mentionProps) => {
        return <span className={classNames('mention')}>{mentionProps.children}</span>;
      },
      theme: {
        mentionSuggestions: 'mentionSuggestions',
        mentionSuggestionsEntry: 'mentionSuggestionsEntry',
        mentionSuggestionsEntryFocused: 'mentionSuggestionsEntryFocused',
      },
    });
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(
    ({ value }) => {
      setSuggestions(defaultSuggestionsFilter(value, mentions));
    },
    [mentions],
  );

  return (
    <div className="editor w-full">
      <Editor
        editorKey={'editor'}
        editorState={editorState}
        onChange={(value) => {
          setEditorState(value);
          // onChange({
          //   value: value.getCurrentContent().getPlainText('\n'),
          //   raw: JSON.stringify(convertToRaw(value.getCurrentContent())),
          // });
          onChange(value.getCurrentContent().getPlainText('\n'));
        }}
        plugins={plugins}
        {...rest}
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
      />
    </div>
  );
}
