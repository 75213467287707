import '../config/SentryConfig';
import React, { PureComponent } from 'react';
import Joyride from 'react-joyride';
import { STEPS } from '../variables/stepIntro';
import DV from '../variables/DV';
import { Trans } from '@lingui/macro';

export default class TouristIntro extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      stepsEnabled: false,
      initialStep: 0,
    };
  }

  run = (step = 0) => {
    // if (!this.state.hadRun && step >= 0)
    this.setState({ stepsEnabled: true, initialStep: step });
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  callback = (data) => {
    if (data.action === 'close' || (data.type === 'finished' && data.action === 'next')) {
      this.endTour();
    }
    DV.selector = data.type === 'tooltip:before' ? data.step.selector : '';

    this.setState({
      selector: data.type === 'tooltip:before' ? data.step.selector : '',
    });
  };

  endTour = () => {
    this.onExit();
    DV.showGuideTour = false;
  };

  render() {
    const { stepsEnabled, initialStep } = this.state;
    return (
      <Joyride
        run={stepsEnabled}
        stepIndex={initialStep}
        autoStart={true}
        ref={(ref) => (this.joyride = ref)}
        callback={this.callback}
        debug={false}
        // disableOverlay={selector === '.card-tickets'}
        locale={{
          back: (
            <span>
              <Trans>Trước</Trans>
            </span>
          ),
          close: (
            <span>
              <Trans>Đóng</Trans>
            </span>
          ),
          last: (
            <span>
              <Trans>Hoàn tất</Trans>
            </span>
          ),
          next: (
            <span>
              <Trans>Kế tiếp</Trans>
            </span>
          ),
          skip: (
            <span>
              <Trans>Bỏ qua</Trans>
            </span>
          ),
        }}
        showOverlay={true}
        steps={STEPS()}
        type={'continuous'}
        disableOverlay={true}
        disableOverlayClose={true}
      />
    );
  }

  componentDidMount() {
    DV.joyride = this.joyride;
    DV.joyrideRun = this.run;
  }
}
