import { t } from '@lingui/macro';
import BaseModal from 'component/BaseComponent/BaseModal';
import { CLIENT_BASE_URL, FACEBOOK_APP_ID } from 'config/Env';
import { trans } from 'config/LinguiConfig';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import Routes from 'router/routes';
import DV from 'variables/DV';

class WarningLimitModal extends BaseModal {
  open = () =>
    this.setState({
      open: true,
      title: this.getTitle(),
    });

  getTitle() {
    return t`Thông tin thanh toán`;
  }

  renderFooter() {
    const sellerInviteCode = DV.company.subscription_refer_code || '';
    const linkShare =
      'https://www.facebook.com/dialog/feed?app_id=' +
      FACEBOOK_APP_ID +
      '&link=' +
      'https://doopage.com&redirect_uri=' +
      CLIENT_BASE_URL +
      '/payment/?verify=' +
      sellerInviteCode +
      '%26coupon=FBSHARE';
    return (
      <>
        <Button
          href={Routes.subscriptions.path}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginRight: 15 }}
          onClick={() => this.close()}
          color="success">
          {t`Mua thêm`}
        </Button>
      </>
    );
  }

  renderBody() {
    return <div>{t`Bạn không thể kích hoạt thêm do giới hạn trong gói cước hiện tại.`}</div>;
  }
}

export default WarningLimitModal;
