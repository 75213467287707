import withStyles from '@material-ui/core/styles/withStyles';
import ChipInput from 'material-ui-chip-input';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'redux';
import { CHIP_COLOR_BLUE } from '../../variables/color';

const styles = {
  chip: {
    background: `linear-gradient(to bottom, ${CHIP_COLOR_BLUE.join(',')})`,
    backgroundSize: '1800% 1800%',
    animation: 'rainbow 18s ease infinite',
    color: '#fff',
  },
  '@keyframes rainbow': {
    '0%': { backgroundPosition: '0% 82%' },
    '50%': { backgroundPosition: '100% 19%' },
    '100%': { backgroundPosition: '0% 82%' },
  },
};

class CustomChipRed extends React.PureComponent {
  render() {
    const { label, placeholder, newChipKeyCodes, classes, onAdd, onDelete, value, ...rest } =
      this.props;

    return (
      <ChipInput
        {...rest}
        blurBehavior="add"
        label={label}
        placeholder={placeholder}
        value={value}
        onAdd={onAdd}
        onDelete={onDelete}
        newChipKeyCodes={newChipKeyCodes}
        classes={{
          chip: classes.chip,
        }}
        clearOnBlur={false}
      />
    );
  }
}

CustomChipRed.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  fullWidthInput: PropTypes.bool,
  newChipKeyCodes: PropTypes.array,
  value: PropTypes.array,
  clearOnBlur: PropTypes.bool,
  colors: PropTypes.array,
};

CustomChipRed.defaultProps = {
  placeholder: '',
  fullWidth: false,
  fullWidthInput: false,
  newChipKeyCodes: [13, 188],
  value: [],
  clearOnBlur: false,
};

export default compose(withStyles(styles))(CustomChipRed);
