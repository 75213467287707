import produce from "immer";

const initState = {
  nodes: [],
  edges: []
};

export default (state = initState, action) =>
  produce(state, draft => {
    const { type, payload } = action;
    switch (type) {
      case "SET_ELEMENTS":
        draft.nodes = payload.nodes;
        draft.edges = payload.edges;
        break;
      case "SET_NODES":
        draft.nodes = payload;
        break;
      case "SET_EDGES":
        draft.edges = payload;
        break;
      default:
        break;
    }
  });
