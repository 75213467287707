import { t } from '@lingui/macro';
import React from 'react';
import { trans } from '../config/LinguiConfig';
import Button from '../MUI_Kit/components/CustomButtons/Button';

export default class Search extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { value: props.defaultValue };
  }

  render() {
    const { onSubmit } = this.props;
    const { value } = this.state;
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmit(value);
        }}
        className="Search-container flex-start-row">
        <Button color="transparent" justIcon size="sm" onClick={() => onSubmit(value)}>
          <i className="far fa-search" style={{ opacity: 0.7 }} />
        </Button>
        <input
          placeholder={t`Tìm kiếm...`}
          value={value}
          name="search"
          autoComplete={'off'}
          className="Search-input"
          onChange={(e) => {
            const value = e.target.value;
            console.log(value);
            this.setState({ value });
          }}
        />

        {value?.length > 0 && (
          <Button
            color="transparent"
            justIcon
            size="sm"
            round
            onClick={() => {
              onSubmit('');
              this.setState({ value: '' });
            }}>
            <i className="fal fa-times" style={{ opacity: 0.7 }} />
          </Button>
        )}
      </form>
    );
  }
}
