import { t } from '@lingui/macro';
import BaseModal from 'component/BaseComponent/BaseModal';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import React from 'react';
import Routes from 'router/routes';
import DV from 'variables/DV';
import { handleApi } from '../../../helper/helper';
import { generateAgentConnectionString } from "../../../api/api";
import LoadingUI from "../../../component/LoadingUI";
import _ from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ViewWebsiteModal extends BaseModal {
  constructor(props) {
    super(props);
    const params = this.getDataFromRouter();
    if (_.isEmpty(params)) this.redirectTo(Routes.agent.path);
    this.state = {
      error: null,
      loading: false,
      open: true,
      id: null,
      title: t`Mã kết nối`,
      configs: {},
      ...params
    };

    this.refreshOnClose = false;
    this.goPathWhenClose = Routes.agent.path;
  }

  close = () => {
    const { history } = this.props;
    this.setState({ open: false });
    this.redirectTo(this.goPathWhenClose);
  };

  async componentDidMount() {
    const id = this.state.id;
    this.setState({ loading: true });
    const { success, data } = await handleApi(generateAgentConnectionString(id));
    this.setState({ loading: false });
    if (success) {
      this.setState({ code: data?.connection_string });
    }
  }


  renderBody() {
    const { name, loading, code, id } = this.state;
    return <>
      <CustomInput
        required
        labelText={t`Agent`}
        inputProps={{
          disabled: true,
          type: 'text',
          value: name || id
        }}
      />
      {
        loading && <LoadingUI />
      }
      {
        code && <>
        <label style={{marginTop: 16, fontSize: 10, color: '#aaa'}}>{t`Mã kết nối`}</label>
          <CopyToClipboard text={code} onCopy={() => DV.showNotify(t`Đã sao chép Mã kết nối`, 'info')}>
            <SyntaxHighlighter language="javascript" style={docco}>
              {code}
            </SyntaxHighlighter>
          </CopyToClipboard>
        </>
      }

    </>;
  }

}

export default ViewWebsiteModal;
