import { Input, Select } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { trans } from 'config/LinguiConfig';
import PropTypes from 'prop-types';
import { memo } from 'react';

const defaultOptionUnit = () => {
  return [
    { id: 'min', name: t`phút` },
    { id: 'hour', name: t`giờ` },
    { id: 'day', name: t`ngày` },
    { id: 'month', name: t`tháng` },
  ];
};

const SelectTime = ({ time, onChange, unitOption = defaultOptionUnit() }) => {
  const { value = 0, unit = '' } = time ?? {};

  const update = (path, value) => {
    console.log('update', path, value);
    onChange(`time.${path}`, value);
  };

  return (
    <div className="flex items-end">
      <Input
        label={t`Thời gian`}
        placeholder={t`Vui lòng nhập thời gian`}
        value={value}
        onChange={(e) => update('value', e.target.value)}
        style={{ width: 80 }}
        type="number"
        inputProps={{ min: 0 }}
        InputLabelProps={{ shrink: true }}
      />
      <Select
        options={unitOption}
        value={unit}
        onChange={(e) => update('unit', e.target.value)}
        style={{ width: 70 }}
      />
    </div>
  );
};

SelectTime.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  unitOption: PropTypes.array,
};

export default memo(SelectTime);
