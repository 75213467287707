import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { trans } from 'config/LinguiConfig';
import { toCurrency } from 'helper/helper';
import moment from 'moment';
import React from 'react';
import Barcode from 'react-barcode';
import DV from 'variables/DV';
import { LIST_DOMAIN, mapShipmentServiceIcon, ORDER_TYPE, SPACE } from 'variables/staticValue';
import './Receipt.css';
import 'css/classes.css';

const { refund, exchange_return } = ORDER_TYPE;

class Receipt extends React.PureComponent {
  getContent = () => {
    return this.componentRef;
  };

  getSenderReceiver = () => {
    let { address, customer, phone, pick_address, pick_phone, order_type } = this.props;

    if (order_type === refund || order_type === exchange_return) {
      return {
        sender: { name: customer.name, address, phone },
        receiver: {
          name: DV.company.name,
          address: pick_address,
          phone: pick_phone,
        },
      };
    }
    return {
      receiver: { name: customer.name, address, phone },
      sender: {
        name: DV.company.name,
        address: pick_address,
        phone: pick_phone,
      },
    };
  };

  render() {
    let {
      shipment_service,
      shipment_code,
      picking_status,
      cod_amount,
      created_at,
      note,
      shipment_order_separate_code,
      order_type,
      shipping_partner_url,
      id,
      products,
    } = this.props;
    if (picking_status === 'pending') return null;

    const createdAt = moment(created_at).format('Do MMMM YYYY');
    const { sender, receiver } = this.getSenderReceiver();

    return (
      <div className="receipt-container" ref={(el) => (this.componentRef = el)}>
        <Grid container>
          <Grid item xs={12} container className="receipt-border-bottom">
            <Grid item xs={7} className="receipt-border-right" container>
              <Grid
                item
                xs={12}
                className="receipt-border-bottom"
                justify="space-between"
                style={{ display: 'flex' }}>
                <img
                  className="receipt-logo"
                  src={
                    _.get(mapShipmentServiceIcon, '[shipment_service.carrier]') ||
                    LIST_DOMAIN[DV.partner].logo
                  }
                  alt="img"
                />
                <div className="flex_center_col">
                  <div className="receipt-title">VẬN ĐƠN</div>
                  <div className="receipt-date">{createdAt}</div>
                </div>
                <img className="receipt-logo" src={LIST_DOMAIN[DV.partner].logo} alt="img" />
              </Grid>
              <Grid item xs={12} className="receipt-padding">
                <span className="text bold">Người gửi:{SPACE}</span>
                <span className="text" style={{ whiteSpace: 'pre-wrap' }}>
                  {sender.name}
                  {sender.phone ? ' - ' + sender.phone : ''}
                </span>
                {sender.address && (
                  <div className="text-address">
                    Địa chỉ:{SPACE}
                    {sender.address}
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid item xs={5} className="receipt-padding">
              <span className="text bold">Ghi chú:{SPACE}</span>
              <span>{note}</span>
            </Grid>
          </Grid>
          <Grid item xs={12} container className="receipt-border-bottom">
            <Grid item xs={7} className="receipt-border-right" container>
              <Grid item xs={12} className="receipt-border-bottom receipt-padding">
                <span className="text bold">Người nhận:{SPACE}</span>
                <span className="text" style={{ whiteSpace: 'pre-wrap' }}>
                  {receiver.name}
                  {' - ' + receiver.phone || ''}
                </span>
                <div className="text-address">
                  Địa chỉ:{SPACE}
                  {receiver.address || ''}
                </div>
              </Grid>
              <Grid item xs={12} className="receipt-border-bottom receipt-padding">
                <span className="text bold">Dịch vụ:{SPACE}</span>
                {shipment_service && <span className="text">{shipment_service.name}</span>}
                <br />
                {/*<span className='text bold'>DVCT:{SPACE}</span>*/}
                {/*<span className='text'>{}</span>*/}
              </Grid>
              <Grid item xs={12} className="receipt-padding">
                {/*<span className='text bold'>Nội dung:{SPACE}</span>*/}
                {/*<span className='text'>{}</span><br/>*/}
                {shipment_code && (
                  <>
                    <span className="text bold">Mã đơn hàng:{SPACE}</span>
                    <span className="text">{shipment_code}</span>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={5} className="receipt-padding">
              {shipment_order_separate_code && (
                <>
                  <div className="bold">Mã chia</div>
                  <div className="flex-center-col">
                    <Barcode
                      value={shipment_order_separate_code}
                      width={1}
                      height={60}
                      format={'CODE128'}
                      displayValue={true}
                      fontOptions={''}
                      font={'monospace'}
                      textAlign={'center'}
                      textPosition={'bottom'}
                      textMargin={4}
                      fontSize={15}
                      background={'#ffffff'}
                      lineColor={'#000000'}
                      margin={10}
                    />
                  </div>
                </>
              )}

              <div className="bold">Số hiệu</div>
              {shipment_code !== 'Chưa có' && (
                <div
                  className="flex-center-col"
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => shipping_partner_url && window.open(shipping_partner_url)}>
                  <Barcode
                    value={shipment_code}
                    width={1}
                    height={60}
                    format={'CODE128'}
                    displayValue={true}
                    fontOptions={''}
                    font={'monospace'}
                    textAlign={'center'}
                    textPosition={'bottom'}
                    textMargin={4}
                    fontSize={15}
                    background={'#ffffff'}
                    lineColor={'#000000'}
                    margin={10}
                  />
                </div>
              )}

              <div className="flex-end-row bold">
                {t`Tiền COD:`}
                {toCurrency(cod_amount)}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <div className={'no-display'}>
          <Grid container className={'receipt-picking-slip-container'}>
            <div className={'bold'}>{t`PHIẾU XUẤT KHO`}</div>
            <div style={{ position: 'absolute', top: 20, right: 10 }}>#{id}</div>
            <div>{shipment_code}</div>
            {products.slice(0, 5).map((product, index) => {
              const { name, qty, unit, attribute_description, sku } = product;
              return (
                <Grid container className={'receipt-product'}>
                  <Grid item xs={1}>
                    #{index + 1}
                  </Grid>
                  <Grid item xs={9} className={'flex-start-row'}>
                    <div className={'bold'}>{name}</div>
                    &nbsp;
                    {/*{attribute_description ? <div>{`(${attribute_description})`}</div> : ''} &nbsp;*/}
                    {/*{sku ? <div>{`[${sku}]`}</div> : ''}*/}
                  </Grid>
                  <Grid item xs={2} className={'flex-end-row'}>
                    <div className={'bold'}>{qty}</div>
                    &nbsp;
                    <div>{`(${unit})`}</div>
                  </Grid>
                </Grid>
              );
            })}
            {products.length > 6 && (
              <>
                <Grid container className={'receipt-product'}>
                  Quá nhiều sản phẩm, vui lòng xem &nbsp; <b>Phiếu Xuất Kho</b> &nbsp; để xem toàn
                  bộ sản phẩm !
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </div>
    );
  }
}

export default Receipt;
