import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import React, {useState} from 'react';
import './AdvancedSwitch.scss';

const AdvancedSwitch = ({label, children, ...props}) => {
    const [expanded, setExpanded] = useState();
    return (<>
        <Accordion
            square
            elevation={0}
            className="advanced-switch"
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
        >
            <AccordionSummary>
                <Typography>
                    <span className="icon">
                        {expanded
                        && <i className="fas fa-caret-down"/>
                        || <i className="fas fa-caret-right"/>
                        }
                    </span>
                    &nbsp;&nbsp;
                    {label}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container item justify="center" {...props}>
                    {children}
                </Grid>
            </AccordionDetails>
        </Accordion>
    </>);
};


export default AdvancedSwitch;
