import BaseMenuGrow from 'component/BaseComponent/BaseMenuGrow';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import { PatternsButtonAddField } from 'variables/staticValue';

export default class ButtonAddField extends BaseMenuGrow {
  clickOutsideToClose = true;
  disablePortal = this.props.disablePortal;

  renderButton() {
    const { title, justIcon } = this.props;
    return (
      <Button
        color={'transparent'}
        size={'sm'}
        justIcon={justIcon}
        style={{ padding: 5, marginRight: 10 }}
        onClick={this.handleToggle}>
        {title}
      </Button>
    );
  }

  renderContent() {
    const buttons = this.props.data || PatternsButtonAddField();
    const { handleChange } = this.props;
    return (
      <div style={{ padding: 10, maxWidth: 200, maxHeight: 300, overflow: 'auto' }}>
        {buttons.map((item) => {
          const { text, code, color } = item;
          return (
            <Button
              size={'sm'}
              noneUpcase
              fullWidth
              color={color}
              onClick={async () => {
                await handleChange(code);
              }}>
              {text}
            </Button>
          );
        })}
      </div>
    );
  }
}

ButtonAddField.defaultProps = {
  justIcon: false,
  title: ' {-} ',
  disablePortal: true,
};

ButtonAddField.propTypes = {
  data: PropTypes.array,
  justIcon: PropTypes.bool,
  title: PropTypes.string,
  handleChange: PropTypes.func,
};
