import { t } from '@lingui/macro';
import AdvancedSwitch from 'component/Modal/GBM/component/AdvancedSwitch';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import React from 'react';
import DV from 'variables/DV';
import { GetPBX, addPBX, updatePBX } from 'api/api';
import { getFormData, handleApi } from 'helper/helper';
import Routes from '../../../router/routes';
import BaseModal from '../../BaseComponent/BaseModal';
import Grid from '@material-ui/core/Grid';
import AudioSelect from "./AudioSelect";


class PbxModal extends BaseModal {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      color: '',
      ...this.getDataFromRouter(),
    };

    // noinspection JSUnresolvedVariable
    this.goPathWhenClose = !this.props.openFromChannels ? Routes.pbx.path : Routes.channel.path;
    // noinspection JSUnresolvedVariable
    if (this.props.openFromChannels) {
      const { id } = this.props.match.params;
      this.state = {
        ...this.state,
        id,
        loading: !!id,
      };
    }
  }

  getTitle() {
    let { id } = this.state;
    if (id) return t`Cập nhật tổng đài`;
    return t`Thêm tổng đài`;
  }

   getFileObjFromUrl = (url) => {
   if(!url) return undefined;
     const lastSlashIndex = url.lastIndexOf('/');
     const questionMarkIndex = url.indexOf('?');
     const name = questionMarkIndex !== -1
       ? url.slice(lastSlashIndex + 1, questionMarkIndex)
       : url.slice(lastSlashIndex + 1);
     return  {
       name,
       url: url
     }
   };
  async componentDidMount() {
    // noinspection JSUnresolvedVariable
    if (this.props.openFromChannels && this.state.loading && this.state.id) {
      const { id } = this.state;
      let res = await handleApi(GetPBX(id));
      if (res.success)
        this.setState({
          ...res.data,
          welcome_message:this.getFileObjFromUrl(res.data.welcome_message_url),
          dissuading_message: this.getFileObjFromUrl(res.data.dissuading_message_url),
          queue_message: this.getFileObjFromUrl(res.data.queue_message_url),
          flow_message: this.getFileObjFromUrl(res.data.flow_message_url),
          loading: false,
        });
    }
  }

  valid = () => {
    const { name, is_locked, phone_whitelist } = this.state;
    if (!!!name) {
      DV.showNotify(t`Vui lòng nhập tên tổng đài`, 'danger');
      return false;
    }
    if (is_locked) {
      if (!phone_whitelist) {
        DV.showNotify(t`Vui lòng nhập số điện thoại được phép gọi vào`, 'danger');
        return false;
      }
    }
    return true;
  };

  handleSubmit = async () => {
    const {
      name,
      is_locked,
      phone_whitelist,
      sip_server,
      ws_server,
      id,
      welcome_message,
      dissuading_message,
      queue_message,
      flow_message,
    } = this.state;

    if (!this.valid()) return;

    if (id) {
      const data = {
        name: name,
        sip_server,
        ws_server,
        welcome_message,
        dissuading_message,
        queue_message,
        flow_message,
      };
      if (is_locked) {
        data.phone_whitelist = phone_whitelist;
      }
      await this.update(getFormData(data), id);
    } else {
      await this.add({ name: name });
    }
  };

  add = async (post_data) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(addPBX(post_data));
    this.setState({ loading: false });
    if (success) {
      this.close(true, true);
      DV.showNotify(t`Thêm tổng đài thành công`);
    } else DV.showNotify(error, 'danger');
  };

  update = async (payload, id) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(updatePBX(id, payload));
    this.setState({ loading: false });

    if (success) {
      this.close(true);
      DV.showNotify(t`Cập nhật tổng đài thành công`);
    } else DV.showNotify(error, 'danger');
  };

  renderFooter() {
    return (
      <Button loading={this.state.loading} onClick={this.handleSubmit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }

  handleFileChange = (field) => (e) => {
    if (e?.target?.files?.length > 0) {
      this.setState({ [field]: e.target.files.item(0) });
    } else {
      this.setState({ [field]: null });
    }
  };

  renderBody() {
    let {
      name,
      is_locked,
      phone_whitelist,
      sip_server,
      welcome_message,
      dissuading_message,
      queue_message,
      flow_message,
      ws_server,
      id,
    } = this.state;

    return (
      <div style={{ marginTop: -10 }}>
        <CustomInput
          labelText={t`Tên tổng đài`}
          inputProps={{
            type: 'text',
            value: name || '',
            onChange: this.handleChange('name'),
          }}
        />
        {id && (
          <>
            {is_locked && (
              <CustomInput
                labelText={t`Số điện thoại được phép gọi vào`}
                inputProps={{
                  type: 'text',
                  value: phone_whitelist || '',
                  onChange: this.handleChange('phone_whitelist'),
                }}
              />
            )}
            <Grid container direction="column" spacing={2} style={{ marginTop: 20 }}>
              <p style={{ fontWeight: 'bold' }}>{t`Âm thanh`}</p>
              <AudioSelect
                  label={t`Lời chào (.wav)`}
                  onChange={this.handleFileChange('welcome_message')}
                  file={welcome_message}
                  helper={t`Lời chào được phát ngay khi khách hàng gọi tới tổng đài.`}
              />
              <AudioSelect
                  label={t`Lời cáo lỗi (.wav)`}
                  onChange={this.handleFileChange('dissuading_message')}
                  file={dissuading_message}
                  helper={t`Lời cáo lỗi được phát khi khách hàng phải đợi lâu.`}
              />

              <AudioSelect
                  label={t`Nhạc chờ (.wav)`}
                  onChange={this.handleFileChange('queue_message')}
                  file={queue_message}
                  helper={t`Nhạc chờ được phát khi khách hàng đang đợi.`}
              />

              <AudioSelect
                  label={t`Lời nhắc tổng đài viên (.wav)`}
                  onChange={this.handleFileChange('flow_message')}
                  file={flow_message}
                  helper={t`Lời nhắc được phát tới tổng đài viên trước khi nhận cuộc gọi.`}
              />

            </Grid>
            <AdvancedSwitch label={t`Nâng cao`}>
              <CustomInput
                labelText={t`SIP URL`}
                inputProps={{
                  type: 'text',
                  value: sip_server || '',
                  onChange: this.handleChange('sip_server'),
                  placeholder: 'bizfly.doopage.com:5060',
                }}
              />
              <CustomInput
                labelText={t`WS Gateway URL`}
                inputProps={{
                  type: 'text',
                  value: ws_server || '',
                  onChange: this.handleChange('ws_server'),
                  placeholder: 'ws:bizfly.doopage.com/sipws',
                }}
              />
            </AdvancedSwitch>
            <audio style={{display: "none" }} controls id="pbx-modal-player"/>
          </>
        )}
      </div>
    );
  }
}

export default PbxModal;
