import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DV from '../../../variables/DV';

class CustomAlert extends React.PureComponent {
  render() {
    let classes = DV.classes;
    const { children, ...rest } = this.props;
    return (
      <SweetAlert
        style={{ borderRadius: 20 }}
        onCancel={() => this.props.hideAlert()}
        onConfirm={() => this.props.hideAlert()}
        confirmBtnCssClass={classes.button + ' ' + classes.danger}
        cancelBtnCssClass={classes.button}
        {...rest}>
        {children}
      </SweetAlert>
    );
  }
}

export default CustomAlert;
