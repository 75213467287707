import {AutoComplete} from '@doopage/react-ui-kit';
import React from 'react';

import {listTimeZones} from 'timezone-support';

const getTimezone = timezone => ({
    code: timezone,
    name: timezone,
});

const timezones = listTimeZones().map(getTimezone);

const SelectTimeZone = ({value, options, onChange, ...props}) => {
    let onHandleChange, getOptions = x => x;
    if (props.multiple) {
        value = Array.from(value).map(getTimezone);
        onHandleChange = items => onChange(items.map(item => item.code));
        getOptions = options => options.filter(option => !value.find(item => option.code === item.code));
    } else {
        value = getTimezone(value);
        onHandleChange = item => onChange(item.code);
    }
    if (options) {
        options = Array.from(options || []).map(getTimezone);
    } else {
        options = timezones;
    }
    return (
        <AutoComplete
            {...props}
            value={value}
            onChange={onHandleChange}
            options={getOptions(options)}
        />
    );
};


export default SelectTimeZone;
