import { Accordion, Chip, Space, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import SelectSegment from 'component/Select/SelectSegment';
import React, { memo } from 'react';
import SelectTime from 'screen/ScenarioFlow/components/SelectTime';

const SegmentCondition = ({ segments, onChange }) => {
  const { active = false, ids = [], time } = segments;

  const update = (path, value) => {
    onChange(`trigger_conditions.segments.${path}`, value);
  };

  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <i className="fad fa-filter mr-3 text-2xl" />
            {t`Khi chuyển phểu`}{' '}
            <Chip className="ml-1 transform scale-75" color="primary" square>
              PRO
            </Chip>
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('active', !active)}>
        <Space direction="vertical">
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 50 }} className="text-lg opacity-70">
              {t`Sang`}
            </div>
            <SelectSegment
              multiple
              fullWidth
              label={t`một trong các phễu:`}
              placeholder={t`chọn phễu`}
              onChange={(value) => {
                update('ids', value);
              }}
              value={ids}
            />
          </div>
          <div className="flex justify-between items-end">
            <div className="opacity-70 text-lg">{t`Sau:`}</div>
            <SelectTime onChange={update} time={time} />
          </div>
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(SegmentCondition);
