import { t } from '@lingui/macro';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Grid from '@material-ui/core/Grid/Grid';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { addShopee, getShopeeCaptcha, updateShopee } from 'api/api.js';
import { setState } from 'config/Core.js';
import { handleApi } from 'helper/helper.js';
import _ from 'lodash';
import React from 'react';
import Routes from 'router/routes.js';
import DV from 'variables/DV.js';
import { LIST_DOMAIN } from 'variables/staticValue.js';
import { trans } from '../../config/LinguiConfig';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import CustomInput from '../../MUI_Kit/components/CustomInput/CustomInput';
import Warning from '../../MUI_Kit/components/Typography/Warning';
import BaseModal from '../BaseComponent/BaseModal';

class ShopeeModal extends BaseModal {
  constructor(props) {
    super(props);
    let params = this.getDataFromRouter();
    if (_.isEmpty(params)) this.redirectTo(Routes.shopee.path);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      username: '',
      password: '',
      needcaptcha: false,
      captchaKey: '',
      captcha: '',
      needSecurityCode: params.needSecurityCode || false,
      securityCode: '',
      showPassword: false,
      ...params,
    };

    this.goPathWhenClose = !this.props.openFromChannels ? Routes.shopee.path : Routes.channel.path;
  }

  getTitle() {
    if (this.state.isEdit) return t`Cập nhật tài khoản`;
    else return t`Thêm tài khoản`;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value.trim(),
    });
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  handleUpdateCaptcha = async () => {
    const { success, error, data } = await handleApi(getShopeeCaptcha());
    if (success) {
      this.setState({
        needCaptcha: true,
        captcha_image: data,
        captcha: '',
      });
    }
  };

  renderLoginForm = () => {
    const { id, username, password, showPassword, needCaptcha, captcha_image, captcha } =
      this.state;

    return (
      <form>
        <CustomInput
          labelText={t`Username`}
          inputProps={{
            disabled: !!id,
            placeholder: t`Nhập vào username`,
            type: 'text',
            value: username || '',
            onChange: this.handleChange('username'),
          }}
        />
        <CustomInput
          labelText={t`Mật khẩu`}
          inputProps={{
            placeholder: t`Nhập vào mật khẩu`,
            type: showPassword ? 'text' : 'password',
            value: password || '',
            onChange: this.handleChange('password'),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="Toggle password visibility"
                  onClick={this.handleClickShowPassword}>
                  {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {needCaptcha && (
          <Grid container spacing={3}>
            <Grid item xs={7}>
              <CustomInput
                labelText={t`Captcha`}
                inputProps={{
                  placeholder: t`Nhập vào captcha`,
                  type: 'text',
                  value: captcha || '',
                  onChange: this.handleChange('captcha'),
                }}
              />
            </Grid>
            <Grid item xs={5} justify="center" alignItems="center">
              <img
                referrerpolicy={'no-referrer'}
                src={captcha_image.src}
                onClick={this.handleUpdateCaptcha}
                style={{ cursor: 'pointer' }}
              />
            </Grid>
          </Grid>
        )}
        {!id && (
          <Warning variant="body2" color="warning" style={{ textAlign: 'justify', marginTop: 30 }}>
            {t`Lưu ý: Shopee không chính thức hỗ trợ tích hợp quản lý tin nhắn từ xa. Bạn cần phải hiểu rằng
				chúng tôi cần phải lưu trữ mật khẩu Shopee của bạn để có thể sử dụng tính năng này. Chính vì
				vậy, để an toàn cho chính bạn, bạn vui lòng đổi mật khẩu Shopee bạn muốn chúng tôi quản lý
				thành một mật khẩu khác không trùng với những mật khẩu mà bạn thường hay sử dụng.`}
          </Warning>
        )}
      </form>
    );
  };

  renderBody() {
    const { needSecurityCode, securityCode } = this.state;

    if (needSecurityCode) {
      return [
        <CustomInput
          labelText={t`Mã bảo mật`}
          inputProps={{
            placeholder: t`Nhập vào mã bảo mật`,
            type: 'text',
            value: securityCode || '',
            onChange: this.handleChange('securityCode'),
          }}
        />,
        <Warning variant="body2" color="warning" style={{ textAlign: 'justify', marginTop: 30 }}>
          {t`Mã xác nhận được Shopee gửi về điện thoại hoặc email. Bạn vui lòng kiểm tra và nhập mã xác nhận vào ô bên trên.`}
        </Warning>,
      ];
    } else {
      return this.renderLoginForm();
    }
  }

  update = async (
    id,
    username = '',
    password = '',
    verification_code = null,
    captcha_key = '',
    captcha = '',
  ) => {
    this.setState({ loading: true });
    let payload = {};
    if (username) payload.username = username;
    if (password) payload.password = password;
    if (verification_code) payload.verification_code = verification_code;
    if (captcha_key && captcha) {
      payload.captcha = captcha;
      payload.captcha_key = captcha_key;
    }

    let { success, error, data } = await handleApi(updateShopee(id, payload));
    this.setState({ loading: false });

    if (error) {
      let { needCaptcha } = this.state;
      if (error.match(/captcha/i) || needCaptcha) {
        await this.handleUpdateCaptcha();
      }
      DV.showNotify(error, 'danger');
      return { error };
    }
    if (success) {
      if (!data.is_verify) return { needSecurityCode: true, id: data.id };
      this.refreshOnClose = true;
      // DV.showNotify((t`Cập nhật thành công`));
      return {
        success: true,
        is_integrate_order_synchronization: data.is_integrate_order_synchronization,
      };
    }
  };

  add = async (
    username = '',
    password = '',
    verification_code = null,
    captcha_key = '',
    captcha = '',
  ) => {
    await setState(this)({ loading: true });
    let { success, data, error } = await handleApi(
      addShopee(
        {
          username,
          password,
          captcha,
          captcha_key,
          verification_code,
        },
        DV.company.id,
      ),
    );
    await setState(this)({ loading: false });

    if (error) {
      let { needCaptcha } = this.state;
      if (error.match(/captcha/i) || needCaptcha) {
        await this.handleUpdateCaptcha();
      }
      DV.showNotify(error, 'danger');
      return { error };
    }
    if (success) {
      if (!data.is_verify) return { needSecurityCode: true, id: data.id };
      this.refreshOnClose = true;
      // DV.showNotify((t`Thêm thành công`));
      return {
        success: true,
        is_integrate_order_synchronization: data.is_integrate_order_synchronization,
      };
    }
  };

  validate = () => {
    const { username, password } = this.state;
    return username && username !== '' && password && password !== '';
  };

  handleSubmit = async () => {
    const { id, username, password, captcha_image, captcha, securityCode, needSecurityCode } =
      this.state;
    let captchaCode = null;

    if (needSecurityCode) {
      let result = await this.update(id, null, null, securityCode);
      if (result) this.close(true);
      return;
    }
    let res = id
      ? await this.update(id, username, password, securityCode, captcha_image?.key, captcha)
      : await this.add(username, password, securityCode, captcha_image?.key, captcha);

    if (res.success) {
      if (DV.isOwner && res.is_integrate_order_synchronization === false) {
        this.redirectToModal(Routes.shopee.syncOrder.path);
      }
      this.close(true);
    } else if (res.needSecurityCode) {
      this.setState({ needSecurityCode: res.needSecurityCode, id: res.id });
    }
  };

  renderFooter() {
    const { loading } = this.state;
    return [
      <Button
        href={LIST_DOMAIN[DV.partner].guide.shopee}
        color="info"
        simple
        target="_blank"
        rel="noopener noreferrer">
        {t`Hướng dẫn`}
      </Button>,
      <Button onClick={this.handleSubmit} color="info" loading={loading}>
        {t`Hoàn tất`}
      </Button>,
    ];
  }
}

export default ShopeeModal;
