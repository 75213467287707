import {t, Trans} from "@lingui/macro";
import Grid from "@material-ui/core/Grid/Grid";
import BaseModal from "component/BaseComponent/BaseModal";
import Button from "MUI_Kit/components/CustomButtons/Button";
import CustomInput from "MUI_Kit/components/CustomInput/CustomInput";
import React from "react";
import {connect} from "react-redux";
import Routes from "router/routes";
import DV from "variables/DV";
import {addTelegram, updateTelegram} from "../../../../redux/action/apiAction";

class AddTelegramModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      open: true,
      id: null,
      title: this.getTitle(),
      ...this.getDataFromRouter(),
    };

    this.goPathWhenClose = Routes.channel.path;
  }

  getTitle() {
    const {id} = this.getDataFromRouter();
    return id ? t`Cập nhật telegram ${id}` : t`Thêm telegram`;
  }

  renderBody() {
    const {bot_token} = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className="text-">
          <Trans>
            Bước 1: Tạo bot Telegram bằng cách chat với Bot Father của Telegram.
            <br/>
            - Truy cập <a href="https://t.me/botfather" target="_blank">https://t.me/botfather</a>
            <br/>
            - Gửi tin nhắn: /newbot
            <br/>
            - Bot Father sẽ yêu cầu bạn chọn tên cho kênh. Lưu ý, tên kênh phải kết thúc bằng "bot" hoặc "_bot".
            <br/>
            - Nếu tạo thành công, Bot Father sẽ cung cấp cho bạn một token quản lý kênh của bạn.
          </Trans>
          <br/><br/>
          <Trans>Bước 2: copy token được cung cấp bởi Bot Father:</Trans>
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            labelText={t`Bot token`}
            inputProps={{
              value: bot_token || "",
              onChange: this.handleChange("bot_token"),
            }}
          />
        </Grid>
      </Grid>
    );
  }

  handleSubmit = async () => {
    const {id} = this.state;
    this.setState({loading: true});
    if (id) await this.update();
    else await this.add();
    this.setState({loading: false});
  };

  renderFooter() {
    return (
      <Button loading={this.props.loading} onClick={this.handleSubmit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }

  add = async () => {
    const {bot_token} = this.state;
    let {error} = await this.props.dispatch(addTelegram({bot_token}));

    if (!error) {
      this.close(true);
      DV.showNotify(t`Thêm telegram thành công`);
    } else {
      let {is_shared_doopage_to_facebook} = DV.company;
      if (error.includes(t`nâng cấp gói cước`) && !is_shared_doopage_to_facebook)
        DV.showWarningLimitModal();
      else DV.showNotify(error, "danger");
    }
  };

  update = async () => {
    const {id, bot_token} = this.state;
    let {error} = await this.props.dispatch(
      updateTelegram(id, {
        bot_token,
      }),
    );

    if (!error) {
      DV.url = window.location.pathname;
      this.close(true);
      DV.showNotify(t`Cập nhật telegram thành công`);
    } else DV.showNotify(error, "danger");
  };
}

export default connect()(AddTelegramModal);
