import { t } from '@lingui/macro';
import React from 'react';
import { trans } from '../config/LinguiConfig';
import Button from '../MUI_Kit/components/CustomButtons/Button';
import { dangerColor, findColorName } from '../variables/color';
import DV from '../variables/DV';
import { PERMISSION, PERMISSION_NAME } from '../variables/staticValue';
import BaseMenuGrow from './BaseComponent/BaseMenuGrow';

export default class ButtonAddPermission extends BaseMenuGrow {
  disablePortal = false;

  renderButton() {
    return (
      <Button justIcon size="sm" round color="info" onClick={this.handleToggle} {...this.props.buttonProps}>
        <i className="fal fa-plus" />
      </Button>
    );
  }

  renderContent() {
    const { staffId } = this.props;
    const buttons = PERMISSION();
    if (DV.isOwner) {
      buttons[PERMISSION_NAME.owner] = {
        title: t`Chủ sở hữu`,
        color: dangerColor,
        permission: t`Tất cả tính năng.`,
      };
    }
    return (
      <div style={{ width: 400 }}>
        {Object.keys(buttons).map((key) => {
          const { title, color, permission } = buttons[key];
          return (
            <Button
              size={'sm'}
              noneUpcase
              alignLeft
              fullWidth
              simple
              color={findColorName(color)}
              style={{ margin: 0 }}
              onClick={() => this.props.addPermission(key, staffId)}
            >
              <div className="flex-start-col" style={{ alignItems: 'flex-start' }}>
                <div style={{ color: '#000', fontSize: 'larger' }} className="bold">
                  {title}
                </div>
                <div style={{ color: '#000', opacity: 0.5 }}>{permission}</div>
              </div>
            </Button>
          );
        })}
      </div>
    );
  }
}
