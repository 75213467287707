import { withStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Datetime from 'react-datetime';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { defaultFont } from '../../assets/jss/material-dashboard-pro-react';
import customInputStyle from '../../assets/jss/material-dashboard-pro-react/components/customInputStyle';

const styles = {
  ...customInputStyle,
  label: {
    ...defaultFont,
    color: '#AAAAAA !important',
    fontWeight: '400',
    lineHeight: '1',
    '& + $underline': {
      marginTop: '0px',
    },
  },
};

class CustomDateTime extends PureComponent {
  state = {
    helpText: '',
  };

  renderMonth = (props, month, year, selectedDate) => {
    console.log('renderMonth', props, month, year, selectedDate);
    return <td {...props}>{month + 1}</td>;
  };

  render() {
    const {
      label,
      dateFormat,
      value,
      disabled,
      timeFormat,
      onChange,
      required,
      classes,
      language,
      placeholder,
    } = this.props;

    let helpTextClasses = classNames({
      [classes.labelRootError]: !!this.state.helpText,
    });

    return [
      !!label && (
        <InputLabel className={classes.label} style={{ fontSize: 12 }} required={required}>
          {label}
        </InputLabel>
      ),
      <FormControl fullWidth>
        <Datetime
          locale={language}
          timeFormat={timeFormat}
          dateFormat={dateFormat}
          renderMonth={this.renderMonth}
          inputProps={{
            disabled: disabled,
            readOnly: true,
            placeholder: placeholder,
          }}
          onChange={onChange}
          closeOnSelect
          value={value}
        />
        {!!this.state.helpText ? (
          <FormHelperText id={'date-text'} className={helpTextClasses}>
            {this.state.helpText}
          </FormHelperText>
        ) : null}
      </FormControl>,
    ];
  }
}

CustomDateTime.propTypes = {
  required: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  labelText: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  timeFormat: PropTypes.bool,
  dateFormat: PropTypes.bool,
  value: PropTypes.string,
};

CustomDateTime.defaultProps = {
  dateFormat: 'DD/MM/YYYY',
  disabled: false,
  timeFormat: false,
};

const mapStateToProps = (state) => {
  return {
    language: state.common.language,
  };
};

export default compose(withStyles(styles), connect(mapStateToProps, null))(CustomDateTime);
