import { Button, showErrorSnackbar, showSuccessSnackbar } from '@doopage/react-ui-kit';
import '@doopage/react-ui-kit/dist/doopage-ui-kit.css';
import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import { enableFanpage, enableFanpages, getAuthorizedFanpages } from 'api/api';
import classNames from 'classnames';
import Avatar from 'component/Avatar';
import BaseModal from 'component/BaseComponent/BaseModal';
import ListData from 'component/ListData';
import { handleApi } from 'helper/helper';
import Card from 'MUI_Kit/components/Card/Card';
import Warning from 'MUI_Kit/components/Typography/Warning';
import React, { createRef, forwardRef, useEffect, useState } from "react";
import { connect, useDispatch } from 'react-redux';
import { getAllChannels } from 'redux/action/apiAction';
import Routes from 'router/routes';
import { xPath } from 'testingClasses';
import DV from 'variables/DV';
import { Spin } from "antd";
import { useApi } from "../../../../hook/utils";
import { isEmpty } from "lodash";

export const Content = forwardRef(({ onChange, accessToken, selectedFanpages, setSelectedFanpages, adding }) => {
  const { doFetch, loading } = useApi();
  const [data, setData] = useState();
  const [error, setError] = useState()
  const dispatch = useDispatch();

  const getData = async () => {
    let response = await doFetch(getAuthorizedFanpages({ fb_access_token: accessToken }));
    if (error) {
      setError(response.error)
      return
    }
    setData(response.data)
  }

  useEffect(() => {
    getData()
  }, []);

  const handleClick = (item) => {
    if (selectedFanpages.includes(item.id)) {
      setSelectedFanpages(selectedFanpages.filter(i => i !== item.id))
      return
    }
    setSelectedFanpages([...selectedFanpages, item.id])
  };


  const renderItem = (item, index) => {
    const { name, fb_id, avatar, warning, id } = item;
    return (
      <Card
        style={{
          width: '100%',
          marginBottom: 15,
          padding: 10,
          overflow: 'initial',
          marginTop: index === 0 ? 10 : 0,
        }}
        key={index}
        className={classNames(xPath.item, xPath.item_(index), 'cursor-pointer', { 'card-active': selectedFanpages.includes(id) })}
        onClick={() => handleClick(item)}>
        <div className="flex-center-row" style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar user={{ avatar, name, fb_id }} size={45} margin={false} />
            </Grid>
            <Grid item xs>
              <Grid container>
                <Grid item xs={12} sm={6} className="flex-start-row bold">
                  {name}
                </Grid>
                <Grid item xs={12} sm={4} className="flex-start-col">
                  <Warning useTooltip>{warning || ''}</Warning>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  };

  return (
    <>
      <div>{t`Chọn những fanpage cần tích hợp.`}</div>
      <Spin spinning={adding || loading}>
        <ListData
          data={data ?? []}
          renderItem={renderItem}
          emptyTitle={t`Bạn chưa có tài khoản Fanpage nào cần tích hợp`}
          loading={loading}
          loadingTitle={t`Đang tải dữ liệu...`}
          error={!!error}
          errorTitle={error}
          errorButton={t`Thử lại`}
          errorAction={() => {
            getData()
          }}
        />
      </Spin>
    </>
  );
});

class FanpageSelectModal extends BaseModal {
  constructor(props) {
    super(props);
    const stateFromRouter =this.getDataFromRouter()
    if(!stateFromRouter.accessToken) this.close(false);
    this.state = {
      open: true,
      title: t`Chọn Fanpage`,
      loading: false,
      name: '',
      selectedFanpages: [],
      ...stateFromRouter,
    };
    this.refreshOnClose = false;

    this.goPathWhenClose = this.props.openFromChannels ? Routes.channel.path : Routes.fanpage.path;
    this.modal = createRef();


    this.shouldRefresh = false;
  }

  onClose = (shouldRefresh = this.shouldRefresh) => {
    if (shouldRefresh) this.props.dispatch(getAllChannels());
  };

  renderBody() {
    const { accessToken, selectedFanpages, adding } = this.state
    return <Content
      adding={adding}
      accessToken={accessToken}
      selectedFanpages={selectedFanpages}
      setSelectedFanpages={(items) => {
        this.setState({
          selectedFanpages: items
        })
      }}
      ref={this.modal}
    />;
  }

  const
  handleSubmit = async () => {
    const { selectedFanpages } = this.state;
    if(isEmpty(selectedFanpages)) {
      showSuccessSnackbar(t`Vui lòng chọn ít nhất một fanpage`)
      return
    }

    this.setState({
      adding: true
    })
    let { success, error } = await handleApi(enableFanpages({ page_ids: selectedFanpages }));
    this.setState({
      adding: false
    })

    if (success) {
      showSuccessSnackbar(t`Kích hoạt thành công`);
      this.close(true)
    } else {
      let { is_shared_doopage_to_facebook } = DV.company;
      if (error && error.includes(t`nâng cấp gói cước`) && !is_shared_doopage_to_facebook)
        DV.showWarningLimitModal();
      else showErrorSnackbar(error || t`Có lỗi xảy ra`);
    }
  }

  renderFooter() {
    return (
      <Button onClick={this.handleSubmit} color="info" loading={this.state.adding}>
        {t`Kích hoạt Fanpages`}
      </Button>
    );
  }
}

export default connect()(FanpageSelectModal);
