import { TextField } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { Input } from '@doopage/react-ui-kit';

const SelectTime = ({ value, defaultValue, onChange, ...props }) => {
  defaultValue = defaultValue ?? '00:00';
  const handleChange = (e) => {
    return onChange(e.target.value);
  };
  const commitChange = () => {
    let [h, m] = (value ?? '').split(':');
    h = moment(0).utc().hour(parseInt(h)).format('HH');
    m = moment(0).utc().minute(parseInt(m)).format('mm');
    onChange(`${h}:${m}`);
  };
  return (
    <Input {...props} value={value ?? defaultValue} onChange={handleChange} onBlur={commitChange} />
  );
};

export default SelectTime;
