import { t } from '@lingui/macro';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { SERVER_BASE_URL, WITH_CREDENTIALS } from 'config/Env';
import { trans } from 'config/LinguiConfig';
import store from 'redux/store';
import DV from 'variables/DV';
import { getBuildNumber } from '../helper/helper';
import {isTokenExpired, renewToken} from "../helper/token";

export const BASE_URL = SERVER_BASE_URL + '/api/';
export const BASE_URL_V2 = SERVER_BASE_URL + '/api/v2.0/';
export const TIME_OUT = 180000;

export const getBaseUrl = (apiVersion) => {
  if (!apiVersion) return BASE_URL;
  return SERVER_BASE_URL + `/api/v${apiVersion}/`;
};

const mapLanguage = { vi: 'vi-vn', en: 'en-us' };

const baseFetchApi = async (
  endPoint,
  data = null,
  method = 'get',
  headers = {},
  apiVersion,
  cancelToken,
) => {
  if(DV.token && DV.refreshToken && isTokenExpired() && !endPoint.includes('token')) {
    await renewToken();
  }
  let token = DV.token;
  if (token) {
    headers = {
      ...headers,
      Authorization: 'Bearer ' + token,
      'Accept-Language': mapLanguage[store.getState().language],
      'X-DooPage-Build': getBuildNumber(),
    };

    let doopage_fake_owner_id = localStorage.getItem('doopage_fake_owner_id');
    if (doopage_fake_owner_id) headers = { ...headers, 'Doopage-Owner-Id': doopage_fake_owner_id };
  }

  return axios({
    baseURL: getBaseUrl(apiVersion),
    url: endPoint,
    method,
    headers,
    data,
    withCredentials: WITH_CREDENTIALS,
    timeout: TIME_OUT,
    cancelToken: cancelToken,
  });
};

export const fetchApi = (
  endPoint,
  data = null,
  method = 'get',
  headers = {},
  apiVersion,
  cancelToken,
) => {
  return baseFetchApi(endPoint, data, method, headers, apiVersion, cancelToken)
    .then((response) => {
      if (response) {
        const { status, data } = response;
        if (typeof data !== 'undefined') return data;
        switch (status) {
          // case 200: return data;
          case 201:
            return true;
          case 202:
            return true;
          case 204:
            return {};
        }
        if (status === 200) return {};
        throw t`Có lỗi xảy ra do một nguyên nhân chưa rõ, vùi lòng thử tải lại trang hoặc liên lạc với chúng tôi để được giúp đỡ.`;
      }
    })
    .catch(async (error) => {
      if (!DV.network)
        throw t`Không thể kết nối với máy chủ, vui lòng kiểm tra lại đường truyền internet và thử lại.`;

      logApiError(error);

      const { response, message } = error;
      console.log('error', { response, message });
      if (response) {
        const { status, data } = response;

        if (data) {
          const { error, detail, message, code } = data;
          if(code ==='token_expired') {
            await renewToken()
            return fetchApi(endPoint, data, method, headers, apiVersion)
          }
          if (detail) throw detail;
          if (code && error) throw { code, error };
          if (error) throw error;
          if (message) throw message;
        }
        if (status >= 500)
          throw t`Có lỗi không mong muốn xảy ra, vui lòng thử tải lại trang hoặc liên hệ với chúng tôi để được giúp đỡ.`;
        if (status >= 400)
          throw t`Có lỗi do truy cập vào tài nguyên không được cấp phép, vui lòng tải lại trang hoặc liên hệ với chúng tôi để được giúp đỡ.`;
      }
      if (message) {
        if (error.message.includes('timeout of'))
          throw t`Không thể kết nối với máy chủ, vui lòng kiểm tra lại đường truyền internet và thử lại.`;

        if (message.toString().includes('Network Error')) throw t`Lỗi kết nối, vui lòng thử lại`;

        if (message.toString().toLowerCase().includes('resources'))
          throw t`Máy tính của bạn không đủ tài nguyên để xử lý, vui lòng tắt bớt các ứng dụng không cần thiết và thử lại`;

        throw message;
      }

      if (error.toString().includes('Network Error')) throw t`Lỗi kết nối, vui lòng thử lại`;
      throw error;
    });
};

export const fetchApiRawResponse = (
  endPoint,
  data = null,
  method = 'get',
  headers = {},
  apiVersion,
  cancelToken,
) => {
  return baseFetchApi(endPoint, data, method, headers, apiVersion, cancelToken)
    .then((response) => {
      if (response) {
        const { data } = response;
        if (data) return data;
      }
      return null;
    })
    .catch((error) => {
      const { response, message } = error;
      let data = response;
      if (data) {
        data = response.data;
      } else {
        data = message;
      }
      throw data;
    });
};

export const logApiError = (axiosError) => {
  const { response, request, config } = axiosError;
  let requestPayload = {};

  if (typeof response?.config?.data === 'string')
    requestPayload = JSON.parse(response?.config?.data || '{}');

  const tags = {
    _errorType: 'Api Error',
    _statusCode: response?.status,
    _apiMethod: requestPayload?.method,
  };

  axiosError.name = config.url;
  axiosError.message = `Api fail with status ${response?.status}`;

  if (!response && request) {
    tags.error = 'Empty Response';
    axiosError.message = `Api not response`;
  }

  Sentry.setExtra('Request', requestPayload);
  Sentry.setExtra('Response', response?.data);
  Sentry.captureException(axiosError, { tags });
};
