import React from 'react';
import ReactTable from 'react-table';
import { t } from '@lingui/macro';
const tablesHeader = [t`Số điện thoại`, t`Họ và tên`, t`Địa chỉ`, t`Ghi chú`, t`Link FB`];

const getColumn = (data) => {
  let keys = Object.keys(data?.[0] ?? {});
  keys = keys.slice(0, 5);
  return keys.map((key, index) => {
    return {
      Header: tablesHeader[index],
      accessor: key,
    };
  });
};

const ReviewTable = React.memo(({ data }) => {
  const columns = getColumn(data);

  return (
    <ReactTable
      manual
      minRows={5}
      data={data}
      sortable={false}
      columns={columns}
      className="-striped -highlight"
      paging={false}
      showPagination={false}
    />
  );
});

export default ReviewTable;
