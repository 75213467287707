import { SERVER_BASE_URL } from 'config/Env';
import { createSmartAction } from 'redux-smart-actions';
import DV from 'variables/DV';
import { setElements } from './scenarioFlowAction';
import { createNodes, createEdges, createScenariosWithUUID } from '../../screen/ScenarioFlow/utils';

const BASE_URL = `${SERVER_BASE_URL}/api`;
const BASE_URL_V2 = BASE_URL + '/v2.0';
const BASE_URL_V3 = BASE_URL + '/v3.0';

export const getScenarios = createSmartAction(() => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/automation-scenarios/`,
    },
    meta: {
      onSuccess: (response, requestAction, store) => {
        const scenarios = createScenariosWithUUID(response.data);
        const edges = createEdges(scenarios);
        const nodes = createNodes(scenarios, edges);

        store.dispatch(
          setElements({
            nodes,
            edges,
          }),
        );

        return response;
      },
    },
  };
});

export const createScenario = createSmartAction((data) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/automation-scenarios/`,
      method: 'post',
      data,
    },
    meta: {
      onSuccess: (response, requestAction, store) => {
        store.dispatch(getScenarios());
        return response;
      },
    },
  };
});

export const bulkCreateScenarios = createSmartAction((data) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/automation-scenarios/bulk-create/`,
      method: 'post',
      data,
    },
    meta: {
      onSuccess: (response, requestAction, store) => {
        store.dispatch(getScenarios());
        return response;
      },
    },
  };
});

export const getScenario = createSmartAction((id) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/automation-scenarios/${id}`,
      method: 'get',
    },
  };
});

export const updateScenario = createSmartAction((id, data) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/automation-scenarios/${id}`,
      method: 'patch',
      data,
    },
    meta: {
      onSuccess: (response, requestAction, store) => {
        store.dispatch(getScenarios());
        return response;
      },
    },
  };
});

export const deleteScenario = createSmartAction((id) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/automation-scenarios/${id}`,
      method: 'delete',
    },
    meta: {
      onSuccess: (response, requestAction, store) => {
        store.dispatch(getScenarios());
        return response;
      },
    },
  };
});

export const uploadScenarioAttachments = createSmartAction((id, data) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/automation-scenarios/${id}/attachments/`,
      method: 'post',
      data,
    },
  };
});

export const getChannels = createSmartAction(() => {
  return {
    request: {
      url: `${BASE_URL}/seller/company/${DV.company.id}/channels/`,
    },
  };
});

export const getZnsTemplates = createSmartAction((params) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/zns-templates/`,
      params,
    },
  };
});

export const getFanpages = createSmartAction((params) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/fb-pages/`,
      params,
    },
  };
});

export const getFanpage = createSmartAction((id, params) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/fb-pages/${id}/`,
      params,
    },
  };
});

export const updateFanpage = createSmartAction((id, data) => {
  return {
    request: {
      method: 'patch',
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/fb-pages/${id}/`,
      data,
    },
    meta: {
      onSuccess: (request, requestAction, store) => {
        store.dispatch(getFanpages());
        store.dispatch(getFanpage(id));
        return request;
      },
    },
  };
});

export const getInstagrams = createSmartAction((params) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/instagrams/`,
      params,
    },
  };
});

export const getInstagram = createSmartAction((id, params) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/instagrams/${id}/`,
      params,
    },
  };
});

export const updateInstagram = createSmartAction((id, data) => {
  return {
    request: {
      method: 'patch',
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/instagrams/${id}/`,
      data,
    },
    meta: {
      onSuccess: (request, requestAction, store) => {
        store.dispatch(getInstagrams());
        return request;
      },
    },
  };
});

export const getAllChannels = createSmartAction((params) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/`,
      params,
    },
  };
});

export const getAllApplications = createSmartAction((params) => {
  return {
    request: {
      url: `${BASE_URL_V3}/applications/?company_id=${DV.company.id}`,
      params,
    },
  };
});

export const deleteChannel = createSmartAction(({ id, channel_type }) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/${channel_type}/${id}`,
      method: 'delete',
    },
  };
});

//const FLAGR_URL = "https://app.hotfix.doopage.com";
const FLAGR_URL = process.env.REACT_APP_FLAGR_URL;
export const getFlags = createSmartAction((data) => {
  return {
    request: {
      url: `${FLAGR_URL}/flagr/api/v1/evaluation/batch`,
      method: 'post',
      data,
    },
    meta: {
      asMutation: false,
    },
  };
});

export const addTelegram = createSmartAction((data) => {
  data.status = 'publish';
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/telegrams/`,
      data,
      method: 'post',
    },
    meta: {
      asMutation: false,
    },
  };
});

export const updateTelegram = createSmartAction((id, data) => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/telegrams/${id}/`,
      data,
      method: 'patch',
    },
    meta: {
      asMutation: false,
    },
  };
});

export const addFacebookGroup = createSmartAction(() => {
  return {
    request: {
      url: `${BASE_URL_V3}/companies/${DV.company.id}/channels/fb-groups/`,
      method: 'post',
    },
    meta: {
      asMutation: false,
    },
  };
});

export const getSystemNotification = createSmartAction((params) => {
  return {
    request: {
      url: `${BASE_URL_V3}/system-notifications/`,
      params,
    },
  };
});
