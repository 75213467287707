import { withStyles } from '@material-ui/core';
import React, { memo } from 'react';
import Radio from '@material-ui/core/Radio';
import FiberManualRecord from '@material-ui/icons/FiberManualRecord';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import customCheckboxRadioSwitch from '../MUI_Kit/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import DV from '../variables/DV';
import PropTypes from 'prop-types';

class CustomRadio extends React.PureComponent {
  render() {
    const classes = DV.classes;
    const { onChange, label, checked, ...rest } = this.props;

    return (
      <FormControlLabel
        classes={{
          label: classes.label,
        }}
        control={
          <Radio
            checked={!!checked}
            onChange={onChange}
            icon={<FiberManualRecord className={classes.radioUnchecked} />}
            checkedIcon={<FiberManualRecord className={classes.radioChecked} />}
            classes={{ checked: classes.radio }}
            {...rest}
          />
        }
        label={label}
      />
    );
  }
}

CustomRadio.propTypes = {
  onChange: PropTypes.func,
  label: PropTypes.any,
  checked: PropTypes.bool,
};

CustomRadio.defaultProps = {
  label: '',
  checked: false,
};

export default withStyles(customCheckboxRadioSwitch)(memo(CustomRadio));
