import {Input, Select} from '@doopage/react-ui-kit';
import {t} from '@lingui/macro';
import {AccordionDetails} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import {addWebsites, getWebsiteSetting, updateWebsiteSetting} from 'api/api';
import BaseModal from 'component/BaseComponent/BaseModal';
import {handleApi} from 'helper/helper';
import {cloneDeep, keys, unset} from 'lodash';
import Card from 'MUI_Kit/components/Card/Card';
import CardBody from 'MUI_Kit/components/Card/CardBody';
import CardHeader from 'MUI_Kit/components/Card/CardHeader';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import Routes from 'router/routes';
import ListGreetingMessage from 'screen/Channel/Website/components/ListGreetingMessage';
import SelectLanguage from 'screen/Channel/Website/components/SelectLanguage';
import DV from 'variables/DV';
import ChannelSetting from './ModalWebsiteSetting/ChannelSetting';
import {goBack} from '../../../../router/helpers';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import {getAllLanguageCode} from 'languages/languages';

const timeUnitOptions = [
  {id: 'minutes', name: 'phút'},
  {id: 'hours', name: 'giờ'},
  {id: 'days', name: 'ngày'},
  {id: 'weeks', name: 'tuần'},
];

const defaultNativeChannels = [
  {platform: 'apple_business'},
  {platform: 'google_business'},
  // {platform: 'website'},
  {platform: 'zalo_oa'},
  {platform: 'fb'},
  {platform: 'instagram'},
  {platform: 'telegram'},
];

class ViewWebsiteSettingModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      open: true,
      id: props.match.params.id,
      title: this.getTitle(),
      domain: '',
      languages_selected: [],
      greeting_messages: {},
      answered_in_value: 0,
      answered_in_unit: timeUnitOptions[0].id,
      native_channels: cloneDeep(defaultNativeChannels),
      ...this.getDataFromRouter(),
    };

    this.refreshOnClose = false;
    this.goPathWhenClose = this.props.openFromChannels ? Routes.channel.path : Routes.website.path;

    // if (!this.state.id) {
    //   this.redirectTo(this.goPathWhenClose);
    // }
  }

  getTitle() {
    const {id} = this.getDataFromRouter();
    return id ? t`Cài đặt Website` : t`Thêm website`;
  }

  close = () => {
    const {history} = this.props;
    this.setState({open: false});
    goBack(history, this.goPathWhenClose);
  };

  componentDidMount = async () => {
    const {id} = this.state;
    if (id) {
      const {success, data, error} = await handleApi(getWebsiteSetting(id));
      if (success) {
        const {domain, greeting_messages, answered_in, default_platform} = data;
        const languages_selected = keys(greeting_messages);
        const native_channels = data?.native_channels?.length < 1 ? cloneDeep(defaultNativeChannels) : data?.native_channels;
        for (const item of defaultNativeChannels) {
          if (!native_channels.find(x => x.platform === item.platform)) {
            native_channels.push(cloneDeep(item));
          }
        }
        this.setState({
          domain,
          languages_selected,
          greeting_messages,
          answered_in_value: answered_in?.value,
          answered_in_unit: answered_in?.unit,
          native_channels,
          default_platform,
        });
      } else {
        this.close();
        DV.showNotify(error, 'danger');
      }
    } else {
      const languages_selected = getAllLanguageCode().filter(item => navigator.languages.indexOf(item) >= 0);
      this.setState({
        default_platform: 'website',
        languages_selected,
      });
    }
  };

  renderBody() {
    const {
      id,
      domain,
      languages_selected,
      greeting_messages,
      answered_in_value,
      answered_in_unit,
      native_channels,
      default_platform,
    } = this.state;
    return (
      <Grid container direction={'column'} spacing={3}>
        <Grid item sm={12}>
          <CustomInput
            labelText={t`Tên miền`}
            inputProps={{
              value: domain || '',
              disabled: !!id,
              placeholder: 'example.com',
              onChange: this.handleChange('domain'),
            }}
          />
          <ChannelSetting
            updateNativeChannels={this.handleChange('native_channels')}
            updateDefaultPlatform={this.handleChange('default_platform')}
            platforms={native_channels}
            defaultPlatform={default_platform ?? ''}
          />
        </Grid>

        <Grid item sm={12}>
          <Card style={{boxShadow: 'none', margin: 0}}>
            <CardBody style={{padding: 0}}>
              <Grid item sm={12} justify="center">
                <SelectLanguage
                  value={languages_selected}
                  multiple
                  label={t`Ngôn ngữ`}
                  onChange={this.handleChange('languages_selected')}
                />
              </Grid>
            </CardBody>
          </Card>
        </Grid>

        {!!languages_selected.length && <Grid item sm={12}>
          <Card style={{boxShadow: 'none', margin: 0}}>
            <CardHeader>
              <h4>{t`Lời chào`}</h4>
            </CardHeader>
            <CardBody style={{padding: 0}}>
              <AccordionDetails>
                <ListGreetingMessage
                  languages={languages_selected}
                  value={greeting_messages}
                  onChange={this.handleChange('greeting_messages')}
                />
              </AccordionDetails>
            </CardBody>
          </Card>
        </Grid>}

        <Grid item sm={12}>
          <Card style={{boxShadow: 'none', margin: 0, marginLeft: -10}}>
            <CardHeader>
              <h4>{t`Khác`}</h4>
            </CardHeader>
            <CardBody style={{padding: 0}}>
              <Grid container item justify="center">
                <Grid item sm={12}>
                  <AccordionDetails>
                    <div className="mr-2">{t`Thường trả lời trong:`}</div>
                    <Input
                      value={answered_in_value}
                      onChange={this.handleChange('answered_in_value')}
                      className="w-[50px] mx-1"
                      type="number"
                    />
                    <Select
                      value={answered_in_unit}
                      options={timeUnitOptions}
                      onChange={this.handleChange('answered_in_unit')}
                    />
                  </AccordionDetails>
                </Grid>
              </Grid>
            </CardBody>
          </Card>
        </Grid>
      </Grid>
    );
  }

  renderFooter() {
    const {id} = this.state;
    return [
      <Button loading={this.state.loading} onClick={this.handleSubmit} color="info">
        {id ? t`Lưu` : t`Thêm`}
      </Button>,
    ];
  }

  handleSubmit = async () => {
    const {domain, languages_selected, greeting_messages, answered_in_value, native_channels} = this.state;
    for (const language of keys(greeting_messages)) {
      if (languages_selected.indexOf(language) === -1) {
        unset(greeting_messages, language);
      }
    }
    if (domain.length < 1) {
      DV.showNotify(t`Phải có tên miền`, 'danger');
      return;
    }
    if (!/^[a-z0-9.-]+$/.test(domain) || domain.split('.').length < 2) {
      DV.showNotify(t`Tên miền không hợp lệ`, 'danger');
      return;
    }
    if (languages_selected.length < 1 || keys(greeting_messages).length < 1) {
      DV.showNotify(t`Phải có ít nhất một ngôn ngữ được chọn`, 'danger');
      return;
    }
    if (0 > answered_in_value || answered_in_value > 999) {
      DV.showNotify(t`Thời gian trả lời không hợp lệ`, 'danger');
      return;
    }
    this.setState({loading: true});
    await this.update();
    this.setState({loading: false});
  };

  update = async () => {
    let {id} = this.state;
    const {
      domain,
      greeting_messages,
      answered_in_value,
      answered_in_unit,
      native_channels,
      default_platform,
    } = this.state;

    let isCreated = false;
    if (!id) {
      let {success, error, data} = await handleApi(addWebsites({domain}));
      if (success) {
        id = data.id;
        this.setState({id});
        isCreated = true;
      } else {
        DV.showNotify(error, 'danger');
        return;
      }
    }

    let {success, error} = await handleApi(
      updateWebsiteSetting(id, {
        greeting_messages,
        answered_in: {
          value: answered_in_value,
          unit: answered_in_unit,
        },
        native_channels,
        default_platform,
      }),
    );

    if (success) {
      if (isCreated) {
        DV.showNotify(t`Thêm website thành công`);
      }
      // this.close(true);
      this.redirectToModal(Routes.channel.website.detail.path.replace(':id', id));
    } else DV.showNotify(error, 'danger');
  };
}

export default ViewWebsiteSettingModal;
