import * as Sentry from '@sentry/browser';
import { AssetImage } from 'assets/Asset';
import classNames from 'classnames';
import 'config/SentryConfig';
import { showReportDialog } from 'config/SentryConfig';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React, { Component } from 'react';
import { xPath } from 'testingClasses';
import axios from 'axios';

export default class CatchErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    console.log({ error, errorInfo });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  async componentDidMount() {
    AssetImage.Error = await this.downloadToLocal(AssetImage.Error);
  }

  async downloadToLocal(url) {
    try {
      const res = await axios.get(url);
      const blob = new Blob([res.data], { type: res.headers['content-type'] });
      return URL.createObjectURL(blob);
    } catch (e) {
      return url;
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div
          className={classNames('flex-center-col', xPath.stateView.error)}
          style={{ height: '100vh' }}>
          <img src={AssetImage.Error} alt={'img'} className="listdata-img" />

          <div style={{ margin: 50 }} className="bold">
            {'Có lỗi xảy ra, vui lòng tải lại trang'}
          </div>
          <div className="flex-center-row">
            <Button
              color="success"
              onClick={() => {
                window.location.reload();
              }}
              round
              margin>
              Tải lại trang
            </Button>

            <Button
              color="info"
              onClick={() => {
                showReportDialog(this.state.eventId);
              }}
              round>
              Báo lỗi
            </Button>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}
