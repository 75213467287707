import React from 'react';
import { TwitterPicker } from 'react-color';
import { COLOR_OBJECT } from '../../variables/color';

export default class TwitterColorPicker extends React.PureComponent {
  render() {
    const { onChange } = this.props;
    const color_array = Object.keys(COLOR_OBJECT).map((key) => COLOR_OBJECT[key]);
    return (
      <TwitterPicker
        triangle={'hide'}
        width={'100%'}
        colors={color_array}
        onChange={(color) => onChange(color.hex)}
      />
    );
  }
}
