import React from 'react';
import Dropzone from 'react-dropzone';
import './CustomDropzone.css';
import addFileIcon from 'assets/images/add-file-icon.svg';
import 'css/classes.css';
import DV from '../../variables/DV';
import { trans } from '../../config/LinguiConfig';
import { t } from '@lingui/macro';

const onDropRejected = (rejectedFiles, event) => {
  DV.showNotify(t`File không hợp lệ`, 'danger');
};
const CustomDropzone = React.memo(({ children, disabled, handleDrop, accept }) => {
  const onDrop = (acceptedFiles, rejectedFiles, event) => {
    console.log('acceptedFiles', acceptedFiles[0]);
    handleDrop(acceptedFiles[0]);
  };
  return (
    <Dropzone
      onDrop={onDrop}
      disabled={disabled}
      onDropRejected={onDropRejected}
      noDrag={false}
      maxSize={10 * 1024 * 1024}
      accept={accept}
      noKeyboard>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <div
          className="flex-full"
          {...getRootProps({
            onClick: (event) => event.stopPropagation(),
          })}>
          <input {...getInputProps()} />
          {children}
          {isDragActive && (
            <div className="drag-active flex-center-col">
              <img src={addFileIcon} width={'35%'} alt={'Drop here'} />
              <h3>Tải file lên</h3>
            </div>
          )}
        </div>
      )}
    </Dropzone>
  );
});

export default CustomDropzone;
