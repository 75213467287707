const Config = process.env;

export const WITH_CREDENTIALS = true;
export const SERVER_BASE_URL = Config.REACT_APP_API_URL || '';
export const CLID = Config.REACT_APP_CLIENT_ID;
export const FACEBOOK_APP_ID = Config.REACT_APP_FACEBOOK_APP_ID;
export const DOOPAGE_FACEBOOK_PAGE_ID = Config.REACT_APP_DOOPAGE_FACEBOOK_PAGE_ID;
export const DOOPAGE_ZALO_OA_ID = Config.REACT_APP_DOOPAGE_ZALO_OA_ID;
export const GA_ID = Config.REACT_APP_GA;
export const GTM_ID = Config.REACT_APP_GTMID;
export const DOOPAGE_CENTER = Config.REACT_APP_DOOPAGE_CENTER;
export const ENV = Config.REACT_APP_BASE;
export const SOCKET = Config.REACT_APP_SOCKET;
export const LIVECHAT = Config.REACT_APP_LIVECHAT;
export const DISABLE_SENTRY = !!Config.REACT_APP_DISABLE_SENTRY;
export const ZALO_REDIRECT_URL = Config.REACT_APP_ZALO_REDIRECT_URL || `${SERVER_BASE_URL}/dashboard/`;

export const CLIENT_BASE_URL = window.location.origin.toString().includes('localhost')
  ? 'https://dashboard.doopage.dev3.youthdev.net'
  : window.location.origin;
export const IS_SERVER_LOCAL = SERVER_BASE_URL.toString().includes('localhost');
export const ZALO_APP_ID = '';
