import React, { useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import { Button, Input, Space } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { useDispatch, useSelector } from 'react-redux';
import { getValue } from '../../../../../variables/utils';
import { Types } from '../../../../../redux/type';
import { fetchData } from '../../../../../redux/action/fetchAction';
import { getZnsTemplateButtons } from '../../../../../api/api';
import SelectButtonType from './SelectButtonType';
import { Divider } from '@material-ui/core';

const TemplateButton = ({ text, tag, data, onChange, onRemove }) => {
  const dispatch = useDispatch();
  console.log('TemplateButton', { text, tag, data });
  // Get buttons type
  const { data: buttonTypes, loading } = useSelector(getValue(Types.znsTemplateButtons, {}));
  useEffect(() => {
    dispatch(fetchData(Types.znsTemplateButtons, getZnsTemplateButtons()));
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedBtn, setSelectedBtn] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setSelectedBtn(null);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleChange = (key) => (e) => {
    const value = e?.target?.value;
    onChange({ text, tag, data, [key]: value });
  };

  useEffect(() => {
    if (tag === 2) {
      onChange({ text, tag, data: undefined });
    }
  }, [tag]);

  return (
    <>
      <div className="relative w-full flex flex-row">
        <Button color="info" noneUpcase fullWidth simple onClick={handleClick}>
          {text || t`Chưa có tiêu đề`}
        </Button>
        {!open && (
          <Button color="danger" noneUpcase justIcon simple size="small" onClick={onRemove}>
            <i className="fal fa-times" />
          </Button>
        )}
      </div>
      <Divider />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <div className="w-[300px] flex flex-col p-5">
          <Space direction={'vertical'} spaceHeight={10}>
            <Input label={t`Tiêu đề nút`} value={text || ''} onChange={handleChange('text')} />
            <SelectButtonType value={tag} onChange={handleChange('tag')} />
            {tag !== 2 && (
              <Input
                label={tag === 1 ? t`Số điện thoại` : 'Url'}
                value={data || ''}
                onChange={handleChange('data')}
              />
            )}
          </Space>
        </div>
      </Popover>
    </>
  );
};

export default TemplateButton;
