import React from 'react';
import PropTypes from 'prop-types';
import { COLOR_LETTER_AVATAR } from '../variables/color';

class LetterAvatar extends React.PureComponent {
  render() {
    const { content, size, radius, style } = this.props;
    const letter = content ? content.toString().substr(0, 1).toUpperCase() : 'A';
    let color = COLOR_LETTER_AVATAR[letter.charCodeAt(0) % COLOR_LETTER_AVATAR.length].join(',');
    return (
      <div
        className="imgAvatar"
        style={{
          background: 'linear-gradient(to right bottom, ' + color + ')',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          width: size,
          height: size,
          borderRadius: radius,
          // backgroundColor: "#75d69c",
          ...style,
        }}>
        <div
          style={{
            color: '#fff',
            fontSize: this.props.size - 10,
          }}>
          {letter}
        </div>
      </div>
    );
  }
}

LetterAvatar.propTypes = {
  size: PropTypes.number.isRequired,
  content: PropTypes.string.isRequired,
  radius: PropTypes.number.isRequired,
  style: PropTypes.object,
};

export default LetterAvatar;
