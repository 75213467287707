import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import { inviteStaffs } from 'api/api.js';
import AddPermission from 'component/Modal/Staff/AddPermission.js';
import { setState } from 'config/Core';
import { trans } from 'config/LinguiConfig';
import { handleApi } from 'helper/helper.js';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Routes from 'router/routes.js';
import { infoColor, primaryColor } from 'variables/color';
import DV from 'variables/DV';
import BaseModal from '../../BaseComponent/BaseModal';

class InvitationLinkModal extends BaseModal {
  constructor(props) {
    super(props);
    this.showCloseButton = false;
    this.state = {
      open: true,
      title: t`Tạo đường dẫn`,
      background: primaryColor,
      color: '#fff',
      error: null,
      permission: ['seller'],
      link: null,
    };
    this.goPathWhenClose = Routes.staff.path;
  }

  createInviteLink = async () => {
    this.setState({ link: null });
    let { data, error } = await handleApi(
      inviteStaffs({
        invite_type: 'link',
        emails: [],
        ...this.permission.getData(),
      }),
    );
    if (error) {
      DV.showNotify(error, 'danger');
      return;
    }

    let { link, code } = data;
    this.setState({ link: link });
    // const a = queryString.parseUrl(link);
    // const linkData = {
    //     campaign: 'Invite code',
    //     channel: 'dashboard',
    //     tags: ['invite_code'],
    //     data: {
    //         inviteCode: code || get(a, 'query.invite_code', ''),
    //     },
    // };
    //
    // window.branch.link(linkData, (err, link) =>
    // {
    //     console.log('window.branch ', window.branch, err, link);
    //     this.setState({link: link});
    // });
  };

  componentDidMount() {
    setTimeout(() => this.createInviteLink(), 0);
  }

  handleAdd =
    (name) =>
    async (...chips) => {
      chips = chips.map((chip) => (chip.trim && chip.trim()) || chip);
      await setState(this)({
        [name]: [...this.state[name], ...chips],
      });
    };

  handleDelete = (name) => async (deletedChip) => {
    await setState(this)({
      [name]: this.state[name].filter((c) =>
        c && c.name ? c.name !== deletedChip : c !== deletedChip,
      ),
    });
  };

  renderBody() {
    const { link } = this.state;
    return (
      <Grid container>
        <Grid container className="flex-start-row">
          <div style={{ marginRight: 10 }}>{t`Phân quyền:`}</div>
          <AddPermission ref={(ref) => (this.permission = ref)} onChange={this.createInviteLink} />
        </Grid>

        <Grid item xs={12} className="flex_center_col">
          <CopyToClipboard
            text={link}
            onCopy={() => DV.showNotify(t`Đã sao chép đường dẫn`, 'info')}>
            <div
              style={{
                fontSize: 20,
                color: infoColor,
                margin: 15,
                textAlign: 'center',
              }}>
              {link || t`Đang tải link`}
            </div>
          </CopyToClipboard>
          <div style={{ fontStyle: 'italic' }}>
            {t`Lưu ý: Đường dẫn sẽ hết hạn sử dụng sau 24 giờ nhằm đảm bảo tính bảo mật.`}
          </div>
        </Grid>
      </Grid>
    );
  }

  renderFooter() {
    const { link } = this.state;
    return (
      <CopyToClipboard text={link} onCopy={() => DV.showNotify(t`Đã sao chép đường dẫn`, 'info')}>
        <Button color="info">{t`Sao chép đường dẫn`}</Button>
      </CopyToClipboard>
    );
  }
}

export default InvitationLinkModal;
