import { createReducer } from 'redux/utils';

const initState = {
  open: false,
};

export default (type) =>
  createReducer(initState, {
    [`${type}_OPEN_MODAL`]: (state, action) => {
      return {
        key: new Date().getTime(),
        open: true,
        ...action.payload,
      };
    },
    [`${type}_CLOSE_MODAL`]: (state, action) => {
      return {
        open: false,
      };
    },
  });
