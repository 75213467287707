export const CLASS_INTRO_FANPAGE = 'intro-fanpage';
export const CLASS_INTRO_GROUP = 'intro-group';
export const CLASS_INTRO_PERSONAL = 'intro-personal';
export const CLASS_INTRO_ZALO = 'intro-zalo';
export const CLASS_INTRO_INSTAGRAM = 'intro-instagram';
export const CLASS_INTRO_WEBSITE = 'intro-website';
export const CLASS_INTRO_STAFF = 'intro-staff';
export const CLASS_INTRO_ORDER = 'intro-order';
export const CLASS_INTRO_CUSTOMER = 'intro-customer';
export const CLASS_INTRO_CENTER = 'intro-center';
export const CLASS_INTRO_RULE = 'intro-rule';
export const CLASS_INTRO_ADD_RULE_GUIDE = 'intro-add-rule-guide';
