import { t } from '@lingui/macro';
import React from 'react';
import { createPaymentMethod, updatePaymentMethod } from '../../api/api';
import { trans } from '../../config/LinguiConfig';
import { handleApi } from '../../helper/helper';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import CustomInput from '../../MUI_Kit/components/CustomInput/CustomInput';
import Routes from '../../router/routes';
import DV from '../../variables/DV';
import BaseModal from '../BaseComponent/BaseModal';

class PaymentMethodModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      name: '',
      description: '',
      ...this.getDataFromRouter(),
    };
    this.goPathWhenClose = Routes.paymentMethod.path;
  }

  add = async (data) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(createPaymentMethod(data));
    this.setState({ loading: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Thêm phương thức thanh toán thành công`);
    } else DV.showNotify(error, 'danger');
  };

  update = async (id, data) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(updatePaymentMethod(id, data));
    this.setState({ loading: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Cập nhật phương thức thanh toán thành công`);
    } else DV.showNotify(error, 'danger');
  };

  getTitle() {
    const { id } = this.getDataFromRouter();
    if (id) return t`Cập nhật phương thức`;
    else return t`Thêm phương thức`;
  }

  validate = () => {
    const { name } = this.state;
    if (name < 3) {
      DV.showNotify(t`Vui lòng nhập tên phương thức`, 'danger');
      return false;
    }
    return true;
  };

  submit = () => {
    const { name, description, id } = this.state;
    if (this.validate()) {
      if (id) this.update(id, { name, description });
      else this.add({ name, description });
    }
  };

  renderFooter() {
    return (
      <Button loading={this.props.loading} onClick={this.submit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }

  renderBody() {
    return (
      <>
        <CustomInput
          labelText={t`Tên phương thức`}
          inputProps={{
            type: 'text',
            value: this.state.name || '',
            onChange: this.handleChange('name'),
          }}
        />
        <CustomInput
          labelText={t`Mô tả`}
          inputProps={{
            multiline: true,
            rowsMax: '4',
            type: 'text',
            value: this.state.description || '',
            onChange: this.handleChange('description'),
          }}
        />
      </>
    );
  }
}

export default PaymentMethodModal;
