export const createReducer =
  (initialState, reducerMap) =>
  (state = initialState, action) => {
    const reducer = reducerMap[action.type];
    if (!reducer) return state;
    return reducer(state, action);
  };

export const getTypeSuccess = (type) => `${type}_SUCCESS`;
export const getTypeLoading = (type) => `${type}_LOADING`;
export const getTypeFail = (type) => `${type}_FAIL`;
export const getTypeUpdate = (type) => `${type}_UPDATE`;
export const getTypeClear = (type) => `${type}_CLEAR`;
