import { AutoComplete } from '@doopage/react-ui-kit';
import { getListAppleBusiness } from 'api/api';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData } from 'redux/action/fetchAction';
import { Types } from 'redux/type';
import { getValue } from 'variables/utils';
import { useQuery } from '@redux-requests/react';
import { getAllChannels } from '../../redux/action/apiAction';

const SelectAppleBusiness = ({ onChange, ...props }) => {
  // const { data = [], error, loading } = useSelector(getValue(Types.selectABM, {}));
  // const dispatch = useDispatch();
  //
  // const getOptions = (value = '') => {
  //   dispatch(
  //     fetchData(
  //       Types.selectABM,
  //       getListAppleBusiness('?status=publish&page=1&page_size=10&q=' + value),
  //     ),
  //   );
  // };
  const { data: result, loading, error } = useQuery({ type: getAllChannels });
  const data = result?.data?.filter((item) => item.channel_type === 'apple_business') ?? [];
  return (
    <AutoComplete
      style={{ minWidth: 240 }}
      onChange={(value, newValue) => {
        onChange(newValue);
      }}
      options={data.map(({ id, name, image_url }) => ({
        id,
        name,
        image: image_url,
      }))}
      // getData={getOptions}
      loading={loading}
      error={error}
      {...props}
    />
  );
};

export default SelectAppleBusiness;
