import { Button } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import classNames from 'classnames';
import { trans } from 'config/LinguiConfig';
import React, { memo } from 'react';
import ButtonsAction from 'screen/ScenarioFlow/modals/action/ButtonsAction';
import ChangeSegmentAction from 'screen/ScenarioFlow/modals/action/ChangeSegmentAction';
import ChangeTagAction from 'screen/ScenarioFlow/modals/action/ChangeTagAction';
import ImageAction from 'screen/ScenarioFlow/modals/action/ImageAction';
import SettingAction from 'screen/ScenarioFlow/modals/action/SettingAction';
import TextAction from 'screen/ScenarioFlow/modals/action/TextAction';
import CarouselAction from '../action/CarouselAction';
import SendOrderAction from '../action/SendOrderAction';
import ZNSAction from '../action/ZNSAction';
import RunScenarioAction from '../action/RunScenarioAction';
import DelayAction from '../action/DelayAction';
import QuickReplyAction from './QuickReplyAction';
import TimePickerAction from './TimePickerAction';

const getTextActionHeader = (replyType) => {
  if (replyType === 'reply_comment') return t`Trả lời comment`;
  if (replyType === 'input') return t`Nhập liệu`;
  if (replyType === 'reply_review') return t`Trả lời đánh giá`;
  if (replyType === 'text') return t`Văn bản`;
  if (replyType === 'quick_reply') return t`Trả lời nhanh`;
  return null;
};

const Index = memo(
  ({ action, index, onChange, onRemove, updatePosition, isLast, isFirst, scenarioId }) => {
    const {
      reply_type,
      id,
      carousel = {
        elements: [
          {
            title: '',
            subtitle: '',
            image_url: '',
            buttons: [],
            hashtag_image: '',
          },
        ],
      },
      message = {},
      config = {},
      action_data = {},
    } = action;

    const updateConfig = (path, value) => {
      onChange(index, {
        ...action,
        config: { ...action.config, [path]: value },
      });
    };

    const updateMessage = (path, value) => {
      onChange(index, {
        ...action,
        message: { ...action.message, [path]: value },
      });
    };

    const updateCarousel = (carousel) => {
      onChange(index, {
        ...action,
        carousel: { elements: carousel },
      });
    };

    const updateActionData = (path, value) => {
      onChange(index, {
        ...action,
        action_data: { ...action.action_data, [path]: value },
      });
    };

    const getWidthAction = () => {
      if (reply_type === 'card' || reply_type === 'image') return 300;
      if (reply_type === 'carousel') return 342;
      return '';
    };
    return (
      <div className="flex mt-2" key={id}>
        <div className="w-10/12" style={{ width: getWidthAction() }}>
          <div className="flex justify-end">
            <div className="mr-1">
              <SettingAction
                config={config}
                onChange={updateConfig}
                updateActionData={updateActionData}
                actionData={action_data}
              />
            </div>
            <Button
              justIcon
              size="small"
              helperText={trans(t`Xoá hành động`)}
              color="danger"
              onClick={() => onRemove(index)}>
              <i className="fad fa-times" />
            </Button>
          </div>
          <div className="flex">
            <div
              className={classNames('w-full flex-col', {
                'w-[307px]': reply_type === 'carousel',
              })}>
              <div className="bg-gray-100 hover:shadow-md transition-all rounded-2xl overflow-hidden">
                <ImageAction
                  hide={!['card', 'image'].includes(reply_type)}
                  isHashtag={message.hashtag_image}
                  url={message.image_url}
                  scenarioId={scenarioId}
                  onChange={updateMessage}
                />
                <TextAction
                  header={getTextActionHeader(reply_type)}
                  input={action_data?.input}
                  showInputTitle={reply_type === 'card'}
                  showInputType={reply_type === 'input'}
                  hide={
                    ![
                      'card',
                      'input',
                      'text',
                      'reply_review',
                      'reply_comment',
                      'time_picker',
                    ].includes(reply_type)
                  }
                  title={message.title}
                  subtitle={message.subtitle}
                  onChange={updateMessage}
                  updateActionData={updateActionData}
                />
                <ButtonsAction
                  hide={!['card', 'text'].includes(reply_type)}
                  buttons={message.buttons}
                  onChange={updateMessage}
                />

                <TextAction
                  header={getTextActionHeader(reply_type)}
                  input={action_data?.input}
                  hide={reply_type !== 'quick_reply'}
                  subtitle={action_data?.quick_reply?.text}
                  onChange={(path, value) => {
                    updateActionData('quick_reply', {
                      ...action_data?.quick_reply,
                      text: value,
                    });
                  }}
                  updateActionData={updateActionData}
                />
                <QuickReplyAction
                  hide={reply_type !== 'quick_reply'}
                  value={action_data?.quick_reply}
                  onChange={updateActionData}
                />
                <TimePickerAction
                  hide={reply_type !== 'time_picker'}
                  items={action_data?.time_picker?.items}
                  onChange={(path, value) => {
                    updateActionData('time_picker', {
                      ...action_data?.time_picker,
                      [path]: value,
                    });
                  }}
                />
                {reply_type === 'hide_comment' && (
                  <div className="p-3 flex flex-col justify-center items-center">
                    <i className="fad fa-eye-slash text-3xl" />
                    {trans(t`Ẩn Comment`)}
                  </div>
                )}
                {reply_type === 'react_comment' && (
                  <div className="p-1 flex justify-center items-center">
                    <div style={{ width: 60, height: 80 }} className="flex justify-center -mt-4">
                      <div className="button-emoji">
                        <div className="emoji  emoji--like">
                          <div className="emoji__hand">
                            <div className="emoji__thumb" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-xl ml-2">{trans(t`Thích`)}</div>
                  </div>
                )}
                {reply_type === 'message_typing' && (
                  <div className="p-1 flex justify-center items-center" id="wave">
                    <span className="dot" />
                    <span className="dot" />
                    <span className="dot" />
                  </div>
                )}
                <ChangeSegmentAction
                  hide={reply_type !== 'update_segment'}
                  value={action_data.segment}
                  action={action_data.action}
                  onChange={updateActionData}
                />
                <ChangeTagAction
                  hide={reply_type !== 'update_tag'}
                  value={action_data.tag}
                  action={action_data.action}
                  onChange={updateActionData}
                />
                <SendOrderAction
                  hide={reply_type !== 'send_order_form'}
                  value={action_data.order_form}
                  onChange={updateActionData}
                />
                <ZNSAction
                  hide={reply_type !== 'zns_template'}
                  value={action_data.zns_template}
                  onChange={updateActionData}
                />
                <RunScenarioAction
                  hide={reply_type !== 'execute_scenario'}
                  value={action_data.scenario}
                  onChange={updateActionData}
                />
                <DelayAction
                  hide={reply_type !== 'delay'}
                  value={action_data?.delay?.time ?? {}}
                  onChange={updateActionData}
                />
              </div>
              <CarouselAction
                scenarioId={scenarioId}
                carousel={carousel.elements}
                onChange={updateCarousel}
                hide={reply_type !== 'carousel'}
              />
            </div>
            <div className="flex flex-col ml-1">
              <Button
                hide={isFirst}
                justIcon
                size="small"
                helperText={trans(t`Đẩy lên`)}
                color="gray"
                onClick={() => updatePosition(index, index - 1)}>
                <i className="fad fa-arrow-up" />
              </Button>
              <Button
                hide={isLast}
                justIcon
                size="small"
                helperText={trans(t`Đẩy xuống`)}
                color="gray"
                onClick={() => updatePosition(index, index + 1)}>
                <i className="fad fa-arrow-down" />
              </Button>
            </div>
          </div>
        </div>
        <div className="flex flex-1 justify-end items-center text-4xl bold text-gray opacity-70 pr-5">
          #{index + 1}
        </div>
      </div>
    );
  },
);

export default memo(Index);
