import { AutoComplete } from '@doopage/react-ui-kit';
import React, { useMemo } from 'react';
import {
  ORDER_PAYMENT,
  ORDER_PAYMENT_ARRAY,
  ORDER_PICKING,
  ORDER_PICKING_ARRAY,
} from 'variables/staticValue';

const SelectOrderStatus = (props) => {
  const data = useMemo(() => {
    const pickingStatus = ORDER_PICKING(true);
    const paymentStatus = ORDER_PAYMENT();

    return [
      ...ORDER_PICKING_ARRAY(true).map(({ key }) => ({
        id: key,
        name: pickingStatus[key]?.name,
        icon: (
          <div>
            <i className="fad fa-truck text-base mx-1" />
          </div>
        ),
        color: pickingStatus[key]?.color,
      })),
      ...ORDER_PAYMENT_ARRAY().map(({ key }) => ({
        id: key,
        name: paymentStatus[key]?.name,
        icon: (
          <div>
            <i className="fad fa-money-bill text-base mx-1" />
          </div>
        ),
        color: paymentStatus[key]?.color,
      })),
    ];
  }, []);

  return (
    <AutoComplete
      options={data}
      chipProps={{
        outlined: false,
      }}
      {...props}
    />
  );
};

export default SelectOrderStatus;
