import { t } from '@lingui/macro';
import BaseModal from 'component/BaseComponent/BaseModal.js';
import React from 'react';
import Routes from 'router/routes.js';
import { trans } from '../../config/LinguiConfig';
import Button from '../../MUI_Kit/components/CustomButtons/Button';

class RedirectToTopshipModal extends BaseModal {
  constructor(props) {
    super(props);
    this.showCloseButton = false;

    this.timer = 0;
    this.goPathWhenClose = Routes.shipmentServices.path;
    this.state = {
      open: true,
      time: 5,
      seconds: 5,
      ...this.getDataFromRouter(),
    };
  }

  componentDidMount() {
    this.setState({ time: this.state.seconds });
    this.startTimer();
  }

  startTimer = () => {
    if (this.timer === 0 && this.state.seconds > 0) {
      this.timer = setInterval(this.countDown, 1000);
    }
  };

  countDown = () => {
    // Remove one second, set state so a re-render happens.
    let seconds = this.state.seconds - 1;
    this.setState({
      time: seconds,
      seconds: seconds,
    });

    // Check if we're at zero.
    if (seconds === 0) {
      clearInterval(this.timer);
      window.location.href = this.state.url;
    }
  };

  renderBody() {
    let { open } = this.state;
    return (
      <div>
        {t`Bạn sẽ được chuyển sang website của Topship (etop.vn) để hoàn thành các bước cuối cùng của việc tích hợp.`}
      </div>
    );
  }

  renderFooter() {
    return (
      <Button
        onClick={() => {
          window.location.href = this.state.url;
        }}
        color="info">
        {t`CHUYỂN ĐẾN TOPSHIP` + ' (' + this.state.time + ')'}
      </Button>
    );
  }
}

export default RedirectToTopshipModal;
