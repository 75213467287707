import { t } from '@lingui/macro';
import { Grid } from '@material-ui/core';
import { getScenarioResults, retryScenario } from 'api/api.js';
import BaseModal from 'component/BaseComponent/BaseModal.js';
import ListDataModal from 'component/Modal/ListDataModal.js';
import { trans } from 'config/LinguiConfig';
import { handleApi } from 'helper/helper.js';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import Routes from 'router/routes.js';
import ScenarioResult from 'screen/Scenario/components/ScenarioResult';
import DV from 'variables/DV.js';

class ScenarioResultsModal extends BaseModal {
  constructor(props) {
    super(props);
    this.maxWidth = 'lg';
    let { id, name, isV2 } = this.getDataFromRouter();
    this.goPathWhenClose = isV2 ? Routes.scenario.path : Routes.scenario_deprecated.path;
    if (!id) this.redirectTo(this.goPathWhenClose);
    this.state = {
      open: true,
      page: 1,
      paging: null,
      error: null,
      loading: false,
      data: [],
      title: name,
      ...this.getDataFromRouter(),
    };
  }

  retry = async () => {
    this.setState({
      loading: true,
    });
    const { error } = await handleApi(retryScenario(this.state.id));
    this.setState({
      loading: false,
    });
    if (error) DV.showNotify(error, 'danger');
    else DV.showNotify(t`Gửi lại kịch bản thành công`, 'success');
  };

  getData = async (page) => {
    this.setState({
      loading: true,
    });
    let params = '?page=' + page + '&page_size=100';
    let { success, data, error } = await handleApi(getScenarioResults(this.state.id, params));
    this.setState({
      loading: false,
    });
    if (success) {
      this.setState({
        data: data.data,
        paging: data.paging,
        error: null,
      });
    } else {
      this.setState({
        error,
      });
    }
  };

  componentDidMount() {
    this.getData(1);
  }

  renderFooter() {
    return (
      <Button loading={this.state.loading} onClick={this.retry} color="info">
        {t`Thử lại`}
      </Button>
    );
  }

  renderItem = (item) => {
    return <ScenarioResult {...item} />;
  };

  goToPage = async (page) => {
    if (!page) {
      page = this.state.page;
    } else {
      this.setState({ page: page });
    }
    await this.getData(page);
  };

  renderBody() {
    const { data, loading, error, paging, name } = this.state;
    return (
      <Grid container style={{ paddingRight: 10, paddingLeft: 10 }}>
        <ListDataModal
          data={data || []}
          renderItem={this.renderItem}
          loading={loading}
          loadingTitle={t`Đang tải dữ liệu...`}
          error={error}
          errorTitle={error}
          errorButton={t`Thử lại`}
          errorAction={this.getData}
          paging={paging}
          goToPage={this.goToPage}
        />
      </Grid>
    );
  }
}

export default ScenarioResultsModal;
