import { t } from '@lingui/macro';
import classNames from 'classnames';
import React, { memo } from 'react';
import { AssetImage } from '../assets/Asset';
import { trans } from '../config/LinguiConfig';
import Button from '../MUI_Kit/components/CustomButtons/Button';
import { xPath } from '../testingClasses';

const ErrorUI = ({ title, buttonLabel, buttonAction }) => {
  return (
    <div className={classNames('content', 'flex-center-col', xPath.stateView.error)}>
      <img src={AssetImage.Error} className="listdata-img" alt="img" />
      <div style={{ height: 30 }} />
      <div className="description-text">
        <div>{title || 'Có lỗi xảy ra'}</div>
        <div>{t`Vui lòng nhấn Ctrl+F5 hoặc Cmd+F5 để tải lại trang`}</div>
      </div>
      <div style={{ height: 20 }} />
      {buttonLabel && (
        <Button color="info" onClick={buttonAction} round>
          {buttonLabel}
        </Button>
      )}
    </div>
  );
};

export default memo(ErrorUI);
