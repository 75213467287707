import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FACEBOOK_APP_ID } from '../../config/Env';
import { getParamsFromObject } from '../../helper/helper';

const FacebookLogin = (props) => {
  const {
    children,
    isMobile,
    scope,
    appId,
    returnScopes,
    responseType,
    redirectUri,
    disableMobileRedirect,
    authType,
    state,
    onFailure,
    callback,
    language,
    fields,
    version,
  } = props;
  const [isSdkLoaded, setSdkLoaded] = useState(true);

  useEffect(() => {
    const { appId, xfbml, cookie, version } = props;

    if (document.getElementById('facebook-jssdk')) {
      setSdkLoaded(true);
    } else {
      window.fbAsyncInit = () => {
        window.FB.init({
          version,
          appId,
          xfbml,
          cookie,
        });

        setSdkLoaded(true);
      };

      ((d, s, id) => {
        const element = d.getElementsByTagName(s)[0];
        const fjs = element;
        let js = element;
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = `https://connect.facebook.net/${language}/sdk.js`;
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  }, []);

  const checkLoginState = ({ authResponse, status }) => {
    if (authResponse) {
      try {
        window.FB.api(
          '/me',
          {
            locale: language,
            fields: fields,
          },
          (me) => callback({ ...me, ...authResponse }),
        );
      } catch (e) {
        callback(authResponse);
      }
    } else {
      if (onFailure) onFailure({ status });
      else callback({ status });
    }
  };

  const handleLogin = () => {
    const params = {
      client_id: appId,
      redirect_uri: redirectUri,
      state,
      return_scopes: returnScopes,
      scope,
      response_type: responseType,
      auth_type: authType,
      version,
    };

    if (isMobile && !disableMobileRedirect) {
      window.location.href = `https://www.facebook.com/dialog/oauth${getParamsFromObject(params)}`;
    } else {
      if (!window.FB) {
        if (onFailure) onFailure({ status: 'facebookNotLoaded' });
        else callback({ status: 'facebookNotLoaded' });
      } else window.FB.login(checkLoginState, params);
    }
  };

  return children({
    onClick: handleLogin,
    disabled: !isSdkLoaded,
  });
};

FacebookLogin.propTypes = {
  isDisabled: PropTypes.bool,
  callback: PropTypes.func.isRequired,
  appId: PropTypes.string.isRequired,
  xfbml: PropTypes.bool,
  cookie: PropTypes.bool,
  authType: PropTypes.string,
  scope: PropTypes.string,
  state: PropTypes.string,
  responseType: PropTypes.string,
  returnScopes: PropTypes.bool,
  redirectUri: PropTypes.string,
  autoLoad: PropTypes.bool,
  disableMobileRedirect: PropTypes.bool,
  isMobile: PropTypes.bool,
  fields: PropTypes.string,
  version: PropTypes.string,
  language: PropTypes.string,
  onClick: PropTypes.func,
  onFailure: PropTypes.func,
};

FacebookLogin.defaultProps = {
  appId: FACEBOOK_APP_ID,
  redirectUri: typeof window !== 'undefined' ? window.location.href : '/',
  scope: 'public_profile,email',
  returnScopes: true,
  xfbml: false,
  cookie: false,
  authType: '',
  fields: 'name',
  version: 'v12.0',
  disableMobileRedirect: true,
  isMobile: isMobile,
  onFailure: null,
  state: 'facebookdirect',
  language: 'en_US',
  responseType: 'code',
};

export default FacebookLogin;
