import { t } from '@lingui/macro';
import { Hidden } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { cancelOrder, confirmOrder, getOrders } from 'api/api';
import Receipt from 'component/Modal/Order/Receipt';
import ShipmentService from 'component/Modal/Order/ShipmentService';
import { trans } from 'config/LinguiConfig';
import { handleApi, toCurrency } from 'helper/helper';
import _ from 'lodash';
import moment from 'moment';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomSelect from 'MUI_Kit/components/CustomSelect/CustomSelect';
import CustomSwitch from 'MUI_Kit/components/Switch/CustomSwitch';
import Info from 'MUI_Kit/components/Typography/Info';
import React from 'react';
import ReactToPrint from 'react-to-print';
import Routes from 'router/routes';
import DV from 'variables/DV';
import {
  ADVANCE_ORDER_OPTION,
  LIST_DOMAIN,
  ORDER_PAYMENT_ARRAY,
  ORDER_PICKING_ARRAY,
  ORDER_TYPE,
} from 'variables/staticValue';
import { getData } from '../../../redux/action';
import store from '../../../redux/store';
import { Types } from '../../../redux/type';
import Avatar from '../../Avatar';
import BaseModal from '../../BaseComponent/BaseModal';
import SelectPickAddress from '../../Select/SelectPickAddress';
import SelectShipmentAccount from '../../Select/SelectShipmentAccount';

const { normal } = ORDER_TYPE;

const mapCreateMessage = () => ({
  compensation: t`đã tạo đơn bù hàng`,
  exchange: t`đã tạo đơn đổi hàng`,
  exchange_return: t`đã tạo đơn thu hàng`,
  refund: t`đã tạo đơn trả hàng hoàn tiền`,
  normal: t`đã tạo đơn hàng`,
});

class ViewOrderModal extends BaseModal {
  constructor(props) {
    super(props);

    this.maxWidth = 'lg';
    this.goPathWhenClose = Routes.order.path;
    this.isSaveData = true;

    let order = this.getDataFromRouter();
    if (_.isEmpty(order)) this.returnOrderList();

    this.state = {
      open: true,
      account: null,
      include_insurance:
        (order.shipment_service && order.shipment_service.include_insurance) || false,
      shipping_payment_type: 'shop-paid',
      picking_type: 'pick-up',
      ...order,
      ...this.getSaveData(),
    };

    this.advanceOption = {
      compensation: this.openCompensationModal,
      refund: this.openReturnRefundModal,
      exchange: this.openReturnExchangeModal,
    };
  }

  reloadListOrder = () => {
    store.dispatch(getData(getOrders(this.state?.urlParams), Types.GET_ORDERS));
  };

  returnOrderList() {
    const { location } = this.props;
    const detail = parseInt(/\/([0-9]+)$/.exec(location.pathname)[1]);
    this.redirectTo(this.goPathWhenClose, { detail });
  }

  handleChange = (name) => (event) => {
    let value = '';
    if (event.target && event.target.value) value = event.target.value;
    else if (event.target && !event.target.value) value = '';
    else value = event;
    this.setState({
      [name]: value,
    });
  };

  renderBody() {
    const { id, shipment_service, shipment_code } = this.state;
    if (!id) return null;

    return (
      <Grid container spacing={2} style={{ overflow: 'hidden' }}>
        <Grid item xs={12}>
          {this.renderInfoOrder()}
        </Grid>
        <Grid item xs={12} md={6}>
          {this.renderProducts()}
        </Grid>
        <Grid item xs={12} md={6}>
          {this.renderPickAddresses()}
          {this.renderShipmentAccounts()}
          {this.renderBuyEnsure()}
          {this.renderShipmentServices()}
          {!!shipment_code && (
            <div>
              Mã đơn hàng: <span style={{ textDecoration: 'underline' }}>{shipment_code}</span>
            </div>
          )}
          {this.renderShippingOption()}
          {this.renderNote()}
          <Grid
            item
            className={'sheet print-page print-only'}
            ref={(ref) => (this.receiptRef = ref)}>
            <Receipt {...this.state} />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderFooter() {
    const { id } = this.state;
    if (!id) return null;
    return this.renderAction();
  }

  renderInfoOrder = () => {
    const {
      recipient_name: name,
      phone,
      address,
      customer,
      picking_status,
      payment_status,
      id,
      is_cancelled,
      shipment_account_id,
      isBackorderManagementEnable,
    } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={8} md={9}>
          <Grid container>
            <Grid item xs={12} sm={3} md={2} className="flex-center-col-xs">
              <Avatar user={customer} size={70} margin={false} />
            </Grid>
            <Hidden smUp>
              <Grid item xs={12} style={{ height: 10 }} />
            </Hidden>
            <Grid item xs={12} sm={9} md={10} className="flex-center-col-xs text-align-center-xs">
              <div className="text bold">{name}</div>
              <div className="text">{phone}</div>
              <div className="text">{address}</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={4} md={3} container>
          <Grid item xs container justify="flex-end">
            <Grid item xs={12}>
              <div
                className="text bold flex-end-row flex-center-col-xs"
                style={{ fontSize: '1.1rem' }}>
                #{id}
              </div>
            </Grid>
            <Grid item xs={12} className="flex_end_col">
              <CustomSelect
                label={t`Trạng thái giao nhận`}
                data={ORDER_PICKING_ARRAY(isBackorderManagementEnable)}
                value={picking_status || ''}
                disabled={shipment_account_id > 0 && (picking_status !== 'pending' || is_cancelled)}
                onChange={async (pickingStatus) => {
                  let success = await this.updateOrderStatus(
                    pickingStatus,
                    this.state.payment_status,
                  );
                  if (success) {
                    this.handleChange('picking_status')(pickingStatus);
                    this.reloadListOrder();
                  }
                }}
              />
              <CustomSelect
                label={t`Trạng thái thanh toán`}
                data={ORDER_PAYMENT_ARRAY(isBackorderManagementEnable)}
                value={payment_status || ''}
                onChange={async (paymentStatus) => {
                  let success = await this.updateOrderStatus(
                    this.state.picking_status,
                    paymentStatus,
                  );
                  if (success) {
                    this.handleChange('payment_status')(paymentStatus);
                    this.reloadListOrder();
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderBuyEnsure = () => {
    const {
      include_insurance,
      shipment_account_id,
      picking_status,
      pick_address,
      is_cancelled,
      account,
    } = this.state;
    if (
      shipment_account_id &&
      shipment_account_id > 0 &&
      pick_address &&
      (account?.service === 'topship' || account?.service === 'ghn')
    ) {
      return (
        <Grid container xs={12} justify="flex-end" className="flex-end-row">
          <Info>{t`Mua bảo hiểm`}</Info>
          <CustomSwitch
            checked={!!include_insurance}
            onChange={(event) => this.handleChange('include_insurance')(event.target.checked)}
            color={'info'}
            disabled={picking_status !== 'pending' || is_cancelled}
          />
        </Grid>
      );
    }

    return null;
  };

  renderProducts = () => {
    const {
      products: data,
      value,
      seller,
      created_at,
      discount_value,
      discount_percentage,
      final_value,
      cod_amount,
      order_type,
      different_value,
    } = this.state;
    let discountValue = discount_value;
    let displayDiscount = toCurrency(discountValue);
    let sumQty = 0;
    return (
      <div>
        <div style={{ maxHeight: 400, overflow: 'auto' }}>
          {data.map((product, index) => {
            const {
              image: avatar,
              name,
              qty,
              value,
              unit,
              code,
              sku,
              attribute_description,
            } = product;
            sumQty += qty;
            return (
              <div
                style={{
                  backgroundColor: index % 2 === 0 ? '#fff' : '#faebcc',
                  padding: 7,
                }}>
                <Grid container className="flex-center-row">
                  <Grid item xs={2} justify={'center'}>
                    <Avatar user={{ avatar, name }} margin={false} size={50} border={4} />
                  </Grid>
                  <Grid item xs={4}>
                    <div className="text bold">
                      {name} &nbsp;
                      {/*{attribute_description ? `(${attribute_description})` : ''} &nbsp;*/}
                      {/*{sku ? `[${sku}]` : ''}*/}
                    </div>
                    {/*<div className="text text-overflow">{attribute_description}</div>*/}
                    {/*<div className="text">{code}</div>*/}
                  </Grid>
                  <Grid item xs={2}>
                    <div className="text bold" style={{ fontSize: 'larger' }}>
                      x{qty}
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="text">{unit}</div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className="text bold flex-end-row">{toCurrency(value)}</div>
                  </Grid>
                </Grid>
              </div>
            );
          })}
        </div>
        <Divider />
        <Grid container className="flex-center-row" style={{ marginTop: 7 }}>
          <Grid item xs={6} />
          <Grid item xs={6} className="flex-start-row bold">
            <Grid container spacing={1}>
              <Grid item xs={12} container justify="space-between">
                <Grid item>{t`Tổng cộng:`}</Grid>

                <Grid item>
                  <div className="text bold flex-end-row">{toCurrency(value)}</div>
                </Grid>
              </Grid>

              {order_type === normal && (
                <Grid item xs={12} container justify="space-between">
                  <Grid item>{t`Được giảm giá:`}</Grid>

                  <Grid item className="flex-start-row bold">
                    <div className="text bold flex-end-row">{displayDiscount}</div>
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} container justify="space-between">
                <Grid item>{t`Thanh toán:`}</Grid>

                <Grid item>
                  <div className="text bold flex-end-row">{toCurrency(final_value)}</div>
                </Grid>
              </Grid>

              {order_type === normal && (
                <Grid item xs={12} container justify="space-between">
                  <Grid item>{t`Tiền thu hộ:`}</Grid>

                  <Grid item>
                    <div className="text bold flex-end-row">{toCurrency(cod_amount)}</div>
                  </Grid>
                </Grid>
              )}

              {different_value && (
                <Grid item xs={12} container justify="space-between">
                  <Grid item>{t`Chênh lệch:`}</Grid>

                  <Grid item>
                    <div className="text bold flex-end-row">{toCurrency(different_value)}</div>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Divider />
        <div style={{ fontStyle: 'italic', fontSize: '0.85rem' }} className="flex-end-row">
          {seller} {mapCreateMessage()[order_type]}&nbsp;
          <span className="bold">{moment(created_at).fromNow()}</span>
        </div>
      </div>
    );
  };

  renderFooterLeft() {
    const { picking_status, is_cancelled, order_type } = this.state;

    if (order_type === 'normal' && (picking_status !== 'pending' || is_cancelled))
      return (
        <Grid container className="flex-start-row">
          <Grid item xs={2} className="text bold" style={{ marginTop: 15 }}>
            {t`Nâng cao`}
          </Grid>
          <Grid item xs={4}>
            <CustomSelect
              label={t`Chọn hành động`}
              data={ADVANCE_ORDER_OPTION() || []}
              formControlProps={{ fullWidth: true }}
              onChange={this.changeAdvancedOptions}
            />
          </Grid>
        </Grid>
      );
  }

  changeAdvancedOptions = async (option) => {
    this.advanceOption[option]();
  };

  setDefaultPickAddress = async (data) => {
    const { picking_status, is_cancelled } = this.state;
    const disable = picking_status !== 'pending' || is_cancelled;
    if (disable) return;
    if (data.length > 0) {
      this.setState({ pick_address: data[0].id });
    }
  };

  renderPickAddresses = () => {
    const { picking_status, pick_address, is_cancelled, order_type } = this.state;
    const disable = picking_status !== 'pending' || is_cancelled;
    return (
      <SelectPickAddress
        label={order_type === 'refund' ? t`Điểm nhận hàng` : t`Điểm lấy hàng`}
        key={disable + '-PickAddresses'}
        value={pick_address || ''}
        onChange={this.handleChange('pick_address')}
        disabled={disable}
        redirectToModal={this.redirectToModal}
        setDefaultPickAddress={this.setDefaultPickAddress}
      />
    );
  };

  setDefaultShipmentAccount = async (data) => {
    const { picking_status, is_cancelled } = this.state;
    const disable = picking_status !== 'pending' || is_cancelled;
    if (disable) return;
    if (data.length > 0) {
      this.setState({ shipment_account_id: data[0].id, account: data[0] });
    }
  };

  renderShipmentAccounts = () => {
    const { shipment_account_id, picking_status, is_cancelled, order_type, return_medthod } =
      this.state;
    const disable = picking_status !== 'pending' || is_cancelled;

    if (order_type === 'refund' && return_medthod === 'customer') return null;
    return (
      <SelectShipmentAccount
        key={disable + '-ShipmentAccount'}
        value={shipment_account_id || -1}
        onChange={(shipment_account_id, account) => {
          this.setState({
            shipment_account_id,
            account,
          });
        }}
        disabled={disable}
        redirectToModal={this.redirectToModal}
        setDefaultShipmentAccount={this.setDefaultShipmentAccount}
      />
    );
  };

  renderShipmentServices = () => {
    const {
      shipment_service,
      picking_status,
      shipment_account_id,
      is_cancelled,
      pick_address,
      include_insurance,
      id,
      order_type,
      return_medthod,
      account,
    } = this.state;

    if (
      !shipment_account_id ||
      shipment_account_id < 0 ||
      (order_type === 'refund' && return_medthod === 'customer')
    )
      return null;

    const isHCMPOST = account && (account.service === 'hcmpost' || account.service === 'vnpost');
    return (
      <ShipmentService
        onChange={this.handleChange('shipment_service')}
        key={`${pick_address}-${shipment_account_id}-${include_insurance}`}
        shipment_service={shipment_service}
        picking_status={picking_status}
        is_cancelled={is_cancelled}
        pick_address_id={pick_address}
        shipment_account_id={shipment_account_id}
        include_insurance={include_insurance}
        id={id}
        isHCMPOST={isHCMPOST}
      />
    );
  };

  renderNote = () => {
    let { note, picking_status, is_cancelled } = this.state;
    return (
      <div>
        <TextField
          fullWidth
          label={t`Ghi chú`}
          value={note || ''}
          margin={'normal'}
          onChange={this.handleChange('note')}
          disabled={picking_status !== 'pending' || is_cancelled}
        />
      </div>
    );
  };

  renderShippingOption = () => {
    let { shipping_payment_type, picking_type, picking_status, is_cancelled, account } = this.state;
    const disable = picking_status !== 'pending' || is_cancelled;
    const isTopshipService = account && account.service === 'topship';
    if (isTopshipService) picking_type = 'pick-up';
    return (
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <CustomSelect
            label={t`Người trả phí`}
            data={[
              { key: 'shop-paid', value: t`Shop trả phí` },
              {
                key: 'customer-paid',
                value: t`Khách hàng trả phí`,
              },
            ]}
            value={shipping_payment_type || ''}
            onChange={this.handleChange('shipping_payment_type')}
            disabled={disable}
          />
        </Grid>

        <Grid item xs={6}>
          <CustomSelect
            label={t`Tùy chọn điểm lấy hàng`}
            data={[
              { key: 'pick-up', value: t`Lấy hàng tận nơi` },
              {
                key: 'drop-off',
                value: t`Gửi hàng tại bưu cục`,
              },
            ]}
            value={picking_type || ''}
            onChange={this.handleChange('picking_type')}
            disabled={disable || isTopshipService}
          />
        </Grid>
      </Grid>
    );
  };

  openReturnRefundModal = () => {
    const { products, recipient_name, address, phone, customer, id } = this.state;
    const payload = {
      products,
      recipient_name,
      address,
      phone,
      customer,
      id,
      type: 'refund',
    };
    this.redirectToModal(Routes.order.returnRefund.path, payload);
  };

  openReturnExchangeModal = () => {
    let { products, recipient_name, address, phone, customer, id } = this.state;
    products = products.map((product) => {
      return { ...product, price: product.value };
    });
    const payload = {
      products,
      recipient_name,
      address,
      phone,
      customer,
      id,
      type: 'exchange',
    };
    this.redirectToModal(Routes.order.returnRefund.path, payload);
  };

  openCompensationModal = () => {
    const { products, recipient_name, address, phone, customer, id } = this.state;
    let selectItem = products.map((product) => {
      return { ...product, price: product.value };
    });
    const payload = {
      selectItem,
      recipient_name,
      address,
      phone,
      customer,
      id,
    };
    this.redirectToModal(Routes.order.compensation.path, payload);
  };

  renderAction = () => {
    const {
      loading,
      index,
      is_cancelled,
      picking_status,
      id,
      order_type,
      return_medthod,
      shipment_service,
    } = this.state;
    if (is_cancelled) return null;
    const hideCancelBtn =
      (order_type === 'refund' && return_medthod === 'customer') ||
      (shipment_service && shipment_service.carrier && shipment_service.carrier === 'hcmpost');
    return [
      !hideCancelBtn && (
        <Button
          loading={loading}
          color="danger"
          margin
          onClick={() => this.cancelOrder(id, index, is_cancelled)}>
          {t`Hủy đơn hàng`}
        </Button>
      ),
      picking_status === 'pending' && (
        <Button loading={loading} color="success" margin onClick={this.confirmOrder}>
          {t`Xác nhận`}
        </Button>
      ),
      picking_status === 'shipping' && (
        <div>
          <ReactToPrint
            trigger={() => (
              <Button loading={loading} color="rose">
                {t`In Phiếu`}
              </Button>
            )}
            content={() => this.receiptRef}
            pageStyle={LIST_DOMAIN[DV.partner].printFormat}
          />
        </div>
      ),
    ];
  };

  cancelOrder = async (id) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(cancelOrder(id));
    this.setState({ loading: false });
    if (success) {
      this.close();
      this.reloadListOrder();
      DV.showNotify(t`Hủy đơn hàng thành công`);
    } else {
      DV.showNotify(error, 'danger');
    }
  };

  confirmOrder = async () => {
    let {
      note,
      include_insurance,
      pick_address,
      shipment_account_id,
      shipment_service,
      id,
      payment_status,
      picking_status,
      shipping_payment_type,
      picking_type,
    } = this.state;
    if ((!shipment_service || _.isEmpty(shipment_service)) && shipment_account_id > 0) {
      DV.showNotify(t`Vui lòng chọn gói dịch vụ vận chuyển`, 'danger');
      return;
    }
    if (picking_status === 'pending') picking_status = 'shipping';

    let payload = {
      payment_status: payment_status,
      picking_status: picking_status,
      shipping_payment_type: shipping_payment_type || null,
      picking_type: picking_type || null,
      note,
      include_insurance,
      pick_address_id: pick_address || null,
      shipment_account_id: shipment_account_id > 0 ? shipment_account_id : null,
      shipment_service,
    };
    this.setState({ loading: true });
    let { success, error } = await handleApi(confirmOrder(id, payload));
    this.setState({ loading: false });

    if (success) {
      DV.showNotify('Cập nhật thành công');
      this.close();
      this.reloadListOrder();
    } else {
      DV.showNotify(error, 'danger');
    }
  };

  updateOrderStatus = async (pickingStatus, paymentStatus) => {
    let {
      note,
      include_insurance,
      pick_address,
      shipment_account_id,
      shipment_service,
      id,
      shipping_payment_type,
      picking_type,
    } = this.state;
    let payload = {
      payment_status: paymentStatus,
      picking_status: pickingStatus,
      shipping_payment_type: shipping_payment_type || null,
      picking_type: picking_type || null,
      note,
      include_insurance,
      shipment_account_id: shipment_account_id > 0 ? shipment_account_id : null,
      shipment_service,
    };
    if (pickingStatus === 'pending') {
      payload.pick_address_id = pick_address ? pick_address : null;
    }
    this.setState({ loading: true });
    let { success, error } = await handleApi(confirmOrder(id, payload));
    this.setState({ loading: false });
    if (success) DV.showNotify('Cập nhật thành công');
    else DV.showNotify(error, 'danger');
    return success;
  };
}

export default ViewOrderModal;
