import { Button, Input, Popup, Radio, Select, Space } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { RadioGroup, TextField } from '@material-ui/core';
import Separator from 'component/Separator';
import React, { memo, useEffect, useState } from 'react';
import SelectScenario from '../../components/SelectScenario';
import moment from "moment";

const TimePickerItem = React.memo(({startTimestamp = new Date().getTime(), duration = 0, onChange}) => {
const from = moment(startTimestamp).format('YYYY-MM-DDTHH:mm');
const to =  moment(startTimestamp + duration*1000).format('YYYY-MM-DDTHH:mm');

return <div className="w-full flex flex-row pr-[35px]">
  <TextField
    InputProps={{style:{
        width: 200
      }}}
    id="from"
    label={t`Từ`}
    variant="outlined"
    type="datetime-local"
    margin="dense"
    InputLabelProps={{
      shrink: true,
    }}
    value={from}
    onChange={ e => {
      onChange({start_timestamp: new Date(e.target.value).getTime(), duration: 3600})
    }}
  />
  <Separator width={10} />
  <TextField
    InputProps={{style:{
      width: 200
      }}}
    id="to"
    label={t`Đến`}
    variant="outlined"
    type="datetime-local"
    InputLabelProps={{
      shrink: true,
    }}
    margin="dense"
    value={to}
    onChange={ e => {
      onChange({start_timestamp: startTimestamp, duration: moment(e.target.value).diff(moment(startTimestamp), 'seconds')})
    }}
  />
</div>

});

const TimePickerButton = ({ items = [], onChange, hide }) => {
  if (hide) return null;

  return (
    <div className="w-full p-3 pt-2">
      <Space direction="vertical" spaceHeight={8}>
        {items.map((item, index) => {
          const { start_timestamp, duration } = item;

          const updateItem = (value) => {
            const cloneitems = [...items];
            cloneitems[index] = value;
            onChange('items', cloneitems);
          };

          return (
            <div
              key={index}
              className="cursor-pointer p-2 rounded-xl w-full text-center transition bg-gray-200 hover:bg-gray-300 font-bold relative">
              <TimePickerItem startTimestamp={start_timestamp} duration={duration} onChange={updateItem}/>
              <div className="absolute overflow-hidden" style={{top: 16, right: 0}}>
                <div
                  className="flex justify-center items-center overflow-hidden"
                  style={{ height: 33 }}>
                  <Button
                    justIcon
                    color="transparent"
                    onClick={(e) => {
                      e.stopPropagation();
                      onChange(
                        'items',
                        items.filter((_, i) => i !== index),
                      );
                    }}>
                    <i className="fad fa-times" />
                  </Button>
                </div>
              </div>
            </div>
          );
        })}
        {items.length < 3 && (
          <div
            className="cursor-pointer border-2 border-gray-400 border border-dashed p-1 rounded-xl w-full text-center transition hover:border-info hover:text-info"
            onClick={() =>
              onChange('items', [
                ...items,
                {
                  start_timestamp: new Date().getTime(),
                  duration:  3600,
                },
              ])
            }>
            <i className="fad fa-plus mr-1" />
            {t`Thêm thời gian`}
          </div>
        )}
      </Space>
    </div>
  );
};

export default memo(TimePickerButton);
