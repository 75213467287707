import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import typographyStyle from '../../assets/jss/material-dashboard-pro-react/components/typographyStyle';
import * as PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';
import { t } from '@lingui/macro';

function Warning({ ...props }) {
  const { classes, children, help, onClick, useTooltip } = props;
  if (!children || children.length === 0) return null;

  const content = (
    <div
      className={
        classes.defaultFontStyle + ' ' + classes.warningText + ' ' + (help ? classes.helpText : '')
      }
      onClick={onClick}
      style={{ marginTop: 5 }}>
      <i className="fas fa-exclamation-triangle" style={{ marginTop: 1.5, marginRight: 3 }} />
      {useTooltip ? t`Lỗi` : children}
    </div>
  );

  if (useTooltip) {
    return <Tooltip title={children}>{content}</Tooltip>;
  } else {
    return <div>{content}</div>;
  }
}

Warning.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(typographyStyle)(Warning);
