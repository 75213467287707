import React, { useEffect, useState } from 'react';
import { trans } from 'config/LinguiConfig';
import { t } from '@lingui/macro';
import Button from 'MUI_Kit/components/CustomButtons/Button';

const time = 5;
const BtnRedirectToShopee = React.memo(({ link }) => {
  const [seconds, setSeconds] = useState(time);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    setTimeout(() => {
      window.location.href = link;
      clearInterval(interval);
    }, time * 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Button href={link} color="info">
      {t`Chuyển đến Shopee` + ` (${seconds})`}
    </Button>
  );
});
export default BtnRedirectToShopee;
