import {
  createReducer,
  getTypeClear,
  getTypeFail,
  getTypeLoading,
  getTypeSuccess,
} from 'redux/utils';
import { Types } from '../../../redux/type';

export const baseFetchObj = {
  loading: false,
  error: null,
  success: null, //null -> not loading yet, true -> loading success, false -> loading error
  data: {
    name: '',
    steps: [],
    configs: {},
  },
  listConditionsActive: [],
};

const type = Types.detailBroadcast;
export default createReducer(baseFetchObj, {
  [getTypeLoading(type)]: (state, action) => {
    return {
      ...state,
      ...baseFetchObj,
      loading: true,
      data: state.data,
    };
  },
  [getTypeSuccess(type)]: (state, action) => {
    const data = action.payload;
    const { conditions } = data.configs;

    return {
      ...state,
      ...baseFetchObj,
      data: {
        ...action.payload,
        steps: action.payload.steps.map((item) => ({ ...item, tempId: new Date().getTime() })),
      },
      success: true,
      listConditionsActive: conditions?.map(({ type, operator }) => `${type}/${operator}`) ?? [],
    };
  },
  [getTypeFail(type)]: (state, action) => {
    return {
      ...state,
      ...baseFetchObj,
      error: action.payload,
      success: false,
    };
  },
  [`${type}_UPDATE_INFO`]: (state, action) => {
    return {
      ...state,
      data: {
        ...state.data,
        ...action.payload,
      },
    };
  },
  [`${type}_UPDATE_STEPS`]: (state, action) => {
    return {
      ...state,
      data: {
        ...state.data,
        steps: [...action.payload],
      },
    };
  },
  [`${type}_UPDATE_CONFIGS`]: (state, action) => {
    return {
      ...state,
      data: {
        ...state.data,
        configs: {
          ...state.data.configs,
          ...action.payload,
        },
      },
    };
  },
  [`${type}_UPDATE_CONDITIONS`]: (state, action) => {
    const { type, operator, value } = action.payload;
    const conditions = [...(state?.data?.configs?.conditions ?? [])];
    const index = conditions.findIndex((item) => item.type === type && item.operator === operator);
    if (index < 0) conditions.push({ type, operator, value });
    else conditions[index] = { type, operator, value };

    return {
      ...state,
      data: {
        ...state.data,
        configs: {
          ...state.data.configs,
          conditions,
        },
      },
    };
  },
  [`${type}_UPDATE`]: (state, action) => {
    return {
      ...state,
      ...action.payload,
    };
  },
  [getTypeClear(type)]: () => {
    return {
      ...baseFetchObj,
    };
  },
});
