import { Select, Space } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import SelectSegment from 'component/Select/SelectSegment';
import React, { memo } from 'react';

const ChangeSegmentAction = ({ hide, action, value, onChange }) => {
  if (hide) return null;

  return (
    <div className="p-3">
      <Space direction="vertical" spaceHeight={10}>
        <Select
          fullWidth
          label={t`Cách chuyển phễu`}
          options={[
            { id: 'up', name: t`Nâng phễu` },
            { id: 'down', name: t`Hạ phễu` },
            { id: 'any', name: t`Bất kì` },
            { id: 'update', name: t`Cập nhật` },
          ]}
          value={action}
          onChange={(e) => onChange('action', e.target.value)}
        />
        <SelectSegment
          fullWidth
          value={value?.id}
          onChange={(_, value) => {
            onChange('segment', value);
          }}
          label={t`Sang phễu`}
        />
      </Space>
    </div>
  );
};

export default memo(ChangeSegmentAction);
