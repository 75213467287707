import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import { inviteStaffs } from 'api/api.js';
import AddPermission from 'component/Modal/Staff/AddPermission.js';
import { setState } from 'config/Core';
import { trans } from 'config/LinguiConfig';

import { handleApi, validateEmail } from 'helper/helper';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import Routes from 'router/routes.js';
import { primaryColor } from 'variables/color';
import DV from 'variables/DV';
import BaseModal from '../../BaseComponent/BaseModal';
import CustomChipBlue from '../../CustomChip/CustomChipBlue';

class SendInvitationEmail extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: t`Gửi Email`,
      error: null,
      permission: ['seller'],
      loading: false,
      background: primaryColor,
      color: '#fff',
      emails: [],
    };
    this.goPathWhenClose = Routes.staff.path;
  }

  handleAdd =
    (name) =>
    async (...chips) => {
      chips = chips.map((chip) => (chip.trim && chip.trim()) || chip);
      await setState(this)({
        [name]: [...this.state[name], ...chips],
      });
    };

  handleDelete = (name) => async (deletedChip) => {
    await setState(this)({
      [name]: this.state[name].filter((c) =>
        c && c.name ? c.name !== deletedChip : c !== deletedChip,
      ),
    });
  };

  checkEmptyEmail = () => {
    this.setState({ isEmailEmpty: this.state.emails.length === 0 });
  };

  addChipsEmail = async (chip) => {
    if (!validateEmail(chip)) {
      DV.showNotify(t`Email vừa nhập không hợp lệ`, 'danger');
      return;
    }
    await this.handleAdd('emails')(chip);
  };

  renderBody() {
    const { emails } = this.state;
    return (
      <Grid container>
        <Grid item xs={12}>
          <CustomChipBlue
            label={t`Email`}
            placeholder={t`Nhập những địa chỉ email bạn muốn gửi lời mời`}
            value={emails}
            onAdd={this.addChipsEmail}
            onDelete={this.handleDelete('emails')}
            fullWidth
            fullWidthInput
            key={'emails'}
          />
        </Grid>
        <Grid container className="flex-start-row" style={{ marginTop: 15 }}>
          <div style={{ marginRight: 10 }}>{t`Phân quyền:`}</div>
          <AddPermission ref={(ref) => (this.permission = ref)} />
        </Grid>
      </Grid>
    );
  }

  handleSubmit = async () => {
    const { emails } = this.state;
    this.setState({ loading: true });
    let { error } = await handleApi(
      inviteStaffs({
        invite_type: 'email',
        emails,
        ...this.permission.getData(),
      }),
    );
    this.setState({ loading: false });
    if (error) DV.showNotify(error, 'danger');
    else {
      DV.showNotify(t`Gửi email thành công`);
      this.close();
    }
  };

  renderFooter() {
    return (
      <Button loading={this.state.loading} onClick={this.handleSubmit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }
}

export default SendInvitationEmail;
