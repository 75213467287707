import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import { trans } from 'config/LinguiConfig';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import routes from 'router/routes';
import BaseModal from '../BaseComponent/BaseModal';

const style = {
  background: 'rgb(252, 248, 227)',
  border: '1px rgb(252, 248, 227)',
  padding: '10px 23px',
};

const styleIcon = {
  color: 'rgb(245, 165, 3)',
  marginRight: 5,
};

class DisableTagSegmentModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: '',
      loading: false,
      ...this.getDataFromRouter(),
    };
  }

  close() {
    const { onClose } = this.props;
    onClose();
  }

  handleSubmit() {
    this.props.history.push({
      pathname: routes.subscriptions.path,
    });
  }

  renderFooter() {
    return (
      <Button onClick={this.handleSubmit.bind(this)} color="info">
        {t`Kích hoạt ngay`}
      </Button>
    );
  }

  renderBody() {
    return (
      <Grid container spacing={1} alignContent="center">
        <Grid item style={style}>
          <i className="fas fa-info-circle" style={styleIcon} />
          {t`Bạn cần kích hoạt gói cước`}
          &nbsp;<b>Automation Marketing</b>&nbsp;
          {t`để sử dụng tính năng này`}
        </Grid>
      </Grid>
    );
  }
}

export default DisableTagSegmentModal;
