import ModalWrapper from 'MUI_Kit/components/Wrapper/ModalWrapper';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Types } from 'redux/type';
import { getValue } from 'variables/utils';
import { t, Trans } from '@lingui/macro';
import ReactCrop from 'react-image-crop';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import { closeModal } from '../../redux/action/modalAction';
import 'react-image-crop/dist/ReactCrop.css';
import { resizeImage } from '../../helper/helper';

const downloadCropedImage = (canvas, crop) => {
  if (!crop || !canvas) {
    return;
  }

  canvas.toBlob(
    (blob) => {
      const previewUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.download = 'cropPreview.png';
      anchor.href = URL.createObjectURL(blob);
      anchor.click();

      window.URL.revokeObjectURL(previewUrl);
    },
    'image/png',
    1,
  );
};

const getFile = (canvas, crop) => {
  if (!crop || !canvas) {
    return;
  }
  return new Promise((resolve, reject) => {
    canvas.toBlob((blob) => {
      const file = new File([blob], 'logo.png', { type: 'image/png' });
      const url = window.URL.createObjectURL(blob);
      resolve({ file, url });
    }, 'image/png');
  });
};

const ModalCropImage = () => {
  const {
    open,
    key,
    id,
    file,
    url,
    onChange,
    crop: cropSize,
  } = useSelector(getValue(Types.modalCropImage, {}));

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({
    aspect: 400 / 96,
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const dispatch = useDispatch();

  const onImageLoaded = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    //Reset crop when close end open;
    setCrop({
      unit: 'px',
      aspect: cropSize?.width / cropSize?.height,
      width: cropSize?.width,
    });
  }, [open]);

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );
  }, [completedCrop]);

  const renderFooter = () => {
    return (
      <Button
        color="info"
        onClick={async () => {
          const { file, url } = await getFile(previewCanvasRef.current, completedCrop);
          const resizeFile = await resizeImage(file, cropSize.width, cropSize.height, 'png', 50);
          onChange(resizeFile, URL.createObjectURL(resizeFile));
          dispatch(closeModal(Types.modalCropImage));
        }}>
        <Trans>Tiếp tục</Trans>
      </Button>
    );
  };

  return (
    <ModalWrapper
      key={key}
      keepMounted={false}
      fullWidth
      maxWidth={'lg'}
      open={open}
      title={t`Chọn hình ảnh`}
      footer={renderFooter()}
      type={Types.modalCropImage}>
      <div className="w-full flex flex-col  text-base items-center justify-center">
        <div className="App flex flex-col">
          <ReactCrop
            src={url}
            onImageLoaded={onImageLoaded}
            crop={crop}
            onChange={(c) => setCrop(c)}
            onComplete={(c) => setCompletedCrop(c)}
          />
          <div style={{ display: 'none' }}>
            <canvas
              ref={previewCanvasRef}
              // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
              style={{
                width: Math.round(completedCrop?.width ?? 0),
                height: Math.round(completedCrop?.height ?? 0),
              }}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  );
};

export default memo(ModalCropImage);
