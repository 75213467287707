import { showErrorSnackbar, showSuccessSnackbar } from "@doopage/react-ui-kit";
import "@doopage/react-ui-kit/dist/doopage-ui-kit.css";
import { t } from "@lingui/macro";
import Grid from "@material-ui/core/Grid/Grid";
import { getFacebookGroupAfterRenewToken, getFacebookGroups, updateFacebookGroup } from "api/api";
import Avatar from "component/Avatar";
import BaseModal from "component/BaseComponent/BaseModal";
import ListData from "component/ListData";
import { handleApi } from "helper/helper";
import Card from "MUI_Kit/components/Card/Card";
import React, { createRef } from "react";
import { connect } from "react-redux";
import Routes from "router/routes";
import { getData } from "../../redux/action";
import { Types } from "../../redux/type";
import { openModal } from "../../redux/action/modalAction";
import { compose } from "redux";
import ModalInstallDooPageAppForGroup from "../Channel/Group/ModalInstallDooPageAppForGroup";
import Danger from "../../MUI_Kit/components/Typography/Danger";
import CustomSwitch from "../../MUI_Kit/components/Switch/CustomSwitch";

const mapStatus = {
  disable: false,
  publish: true,
};

class EnableGroupModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: t`Kích hoạt group`,
      loading: false,
      name: ""
    };
    this.refreshOnClose = true;
    this.maxWidth = 'lg';
    this.goPathWhenClose = Routes.channel.path;
    this.modal = createRef();
  }

  static getDerivedStateFromProps(props) {
    return {
      ...props.fbGroups,
      fbGroups: props.fbGroups.data,
    };
  }

  changeStatus = async (index, status) => {
    let fbGroups = [...this.state.fbGroups];
    fbGroups[index].status = status ? 'publish' : 'disable';
    this.setState({ fbGroups });
  };

  handleSwitchChange =
    ({ index, id }) =>
      async (event) => {
        const isEnable = event.target.checked;
        this.changeStatus(index, isEnable);
        let { success, error, code } = await handleApi(
          updateFacebookGroup(id, { status: isEnable ? 'publish' : 'disable' }),
        );
        if (success) {
          this.getData();
          showSuccessSnackbar(t`Kích hoạt thành công`);
        } else {
          this.changeStatus(index, !isEnable);

          if (code === 'GROUP_NOT_INSTALLED') {
            this.props.openModalInstallDooPageApp({
              id,
              fb_id: this.state.fbGroups[index]?.fb_id,
              data: { status: isEnable ? 'publish' : 'disable' },
              onActiveSuccess: () => {
                this.changeStatus(index, true);
              },
            });
          } else {
            showErrorSnackbar(error || t`Có lỗi xảy ra`);
          }
        }
      };

  onClose = () => {
    this.modal.current?.close?.();
  };

  getQueryParamString = () => {
    return this.props.location.search;
  };

  async componentDidMount() {
    await this.props.getData(this.getQueryParamString());
  }

  renderAccountItem = ({ id, name, status, avatar, error, open, groups }, index) => {
    return (
      <Card
        style={{
          marginTop: index === 0 ? 10 : 0,
          width: '100%',
          marginBottom: 15,
          padding: 10,
          overflow: 'initial',
        }}
        key={index}>
        <div className="flex-center-row" style={{ paddingLeft: 10, paddingRight: 10 }}>
          <Grid container className="flex-center-row">
            <Grid item xs={2} sm={1} md={1} className="flex-start-row flex-center-row-xs">
              <Avatar user={{ avatar, name }} size={window.innerWidth < 600 ? 32 : 45} margin={false} />
            </Grid>

            <Grid item xs={8} sm={7} md={7}>
              <Grid container>
                <Grid item xs={12} sm={6} md={6} className="bold">
                  {name}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Danger useTooltip>{error || ''}</Danger>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2} sm={4} md={4} className="flex-center-row">
              <Grid container justify="space-around">
                <CustomSwitch
                  checked={mapStatus[status]}
                  onChange={this.handleSwitchChange({ id, index })}
                  color={error ? 'danger' : 'primary'}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Card>
    );
  };

  renderBody() {
    return( <>
      <ListData
        data={this.state.fbGroups || []}
        renderItem={this.renderAccountItem}
        emptyTitle={t`Bạn chưa có Facebook group nào`}
        loading={this.state.loading}
        loadingTitle={t`Đang tải dữ liệu...`}
        error={this.state.isSuccess === false}
        errorTitle={this.state.error}
        errorButton={t`Thử lại`}
        errorAction={() => {
          this.props.getData();
        }}
      />
      <ModalInstallDooPageAppForGroup />
    </>)
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    getData: (params = '') =>
      dispatch(getData(getFacebookGroups(params), Types.GET_FACEBOOK_GROUPS)),
    getFacebookGroupAfterRenewToken: (params = '') =>
      dispatch(getData(getFacebookGroupAfterRenewToken(), Types.GET_FACEBOOK_GROUPS)),
    openModalInstallDooPageApp: (payload) =>
      dispatch(openModal(Types.modalInstallDooPageAppForGroup, payload)),
  };
};

const mapStateToProps = (state) => {
  return {
    fbGroups: state.common.fbGroups,
    company: state.common.company,
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(EnableGroupModal);
