import { getParamsFromObject } from 'helper/helper';
import Routes from 'router/routes';
import DV from 'variables/DV';
import { ALL_PERMISSION } from 'variables/staticValue';

export const getListAllowRoutes = (listRoutes = Routes) => {
  let routes = [];
  for (let key in listRoutes) {
    if (!['company', 'login', 'joinCompany'].includes(key)) {
      const route = listRoutes[key];
      if (!!route.permission && checkPermission(route.permission, DV.permission))
        routes.push(route);
    }
  }

  return routes;
};

export const checkPermission = (required = [], user = []) => {
  if (DV.isOwner) return true;
  if (user.length === 0 && required.length === 0) return true;
  if (user.length === 0 && required.length === ALL_PERMISSION.length) return true;
  return required.some((r) => user.some((i) => i === r));
};

export const getNextPath = (url = '') => {
  const routesPathArray = getListAllowRoutes();

  let pathName = routesPathArray[0].path;
  let index = routesPathArray.findIndex((item) => url && url.includes(item.path));
  if (index >= 0) {
    const nextPath = routesPathArray[index].path;
    if (
      nextPath !== Routes.company.path &&
      nextPath !== Routes.joinCompany.path &&
      nextPath !== Routes.empty.path &&
      nextPath !== Routes.login.path
    )
      pathName = nextPath;
  }
  const params = getParamsFromObject(DV.params);
  if (url.includes(pathName)) {
    if (params) return url + params;
    else return url;
  }

  return pathName;
};
