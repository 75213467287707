import CardActionArea from '@material-ui/core/CardActionArea';
import Grid from '@material-ui/core/Grid';
import Avatar from 'component/Avatar.js';
import { toCurrency } from 'helper/helper.js';
import Card from 'MUI_Kit/components/Card/Card.jsx';
import IconButton from 'MUI_Kit/components/CustomButtons/IconButton.jsx';
import React from 'react';

export default class ProductItem extends React.PureComponent {
  render() {
    const { data, isSelected, onClick } = this.props;
    const { name, image, price, unit, attribute_description, id } = data;
    return (
      <Grid item xs={12} md={6} key={id} onClick={onClick}>
        <Card style={{ margin: 0, overflow: 'hidden' }}>
          <CardActionArea>
            <div style={{ display: 'flex', padding: 10 }}>
              <Avatar user={{ name, avatar: image }} border={12} size={73} margin={true} />

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className="text bold">{name}</div>
                <div>{attribute_description}</div>

                <Grid item className="flex-start-row">
                  <div style={{ fontSize: 15, fontWeight: 500 }}>{toCurrency(price)}</div>
                  &nbsp; {unit && '/' + unit}
                </Grid>
              </div>
            </div>
          </CardActionArea>
          <div style={{ position: 'absolute', right: 5, bottom: 0 }}>
            {isSelected && (
              <IconButton color="success" size="sm" margin={false}>
                <i className="fal fa-check" />
              </IconButton>
            )}
          </div>
        </Card>
      </Grid>
    );
  }
}
