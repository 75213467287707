import { t } from '@lingui/macro';
import React, { memo } from 'react';
import { Radio } from '@doopage/react-ui-kit';
import TimeRangeInput from './TimeRangeInput';
import TimeCycleInput from './TimeCycleInput';
import { RadioGroup } from '@material-ui/core';
import './styles.scss';
import Cronbuilder from './CronBuilder';

const TimeCondition = ({ timeRange, timeCycle, onChange }) => {
  const radioValue = timeRange?.active ? 'time_range' : timeCycle?.active ? 'time_cycle' : 'all';

  const update = (path, value) => {
    onChange(`trigger_conditions.${path}`, value);
  };

  return (
    <div className="flex flex-row !w-full time-condition">
      <div className="mr-10 pt-2 text-base w-[80px]">{t`Thời hạn: `}</div>
      <RadioGroup
        onChange={(e) => {
          const value = e.target.value;
          if (value === 'all') {
            update(`time_range.active`, false);
            update(`time_cycle.active`, false);
          } else if (value === 'time_range') {
            update(`time_range.active`, true);
            update(`time_cycle.active`, false);
          } else if (value === 'time_cycle') {
            update(`time_range.active`, false);
            update(`time_cycle.active`, true);
          }
        }}
        value={radioValue}
        style={{ width: '100%' }}>
        <div className="flex flex-col w-full" style={{ marginLeft: 5 }}>
          <Radio
            value="all"
            classes={'w-full'}
            onClick={() => {
              update(`time_range.active`, false);
              update(`time_cycle.active`, false);
            }}>{t`Không giới hạn`}</Radio>

          <Radio value="time_range">
            <TimeRangeInput timeRange={timeRange} onChange={update} />
          </Radio>

          <div className="radio-time-cycle">
            <Radio value="time_cycle">
              <TimeCycleInput timeCycle={timeCycle} onChange={update} />
            </Radio>
          </div>
        </div>
      </RadioGroup>
    </div>
  );
};

export default memo(TimeCondition);
