import React from 'react';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import BaseModal from '../../BaseComponent/BaseModal';
import { trans } from 'config/LinguiConfig';
import { t } from '@lingui/macro';
import DV from 'variables/DV';
import Routes from 'router/routes';
import { handleApi } from 'helper/helper';
import { addManualChannel, updateManualChannel } from 'api/api';
import { showErrorSnackbar, showSuccessSnackbar } from '@doopage/react-ui-kit';

class ManualChannelModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: t`Thêm kênh`,
      loading: false,
      name: '',
      ...this.getDataFromRouter(),
    };

    this.goPathWhenClose = !this.props.openFromChannels ? Routes.manual.path : Routes.channel.path;
  }

  getTitle() {
    const { id } = this.getDataFromRouter();
    if (id) return t`Cập nhật`;
    else return t`Thêm kênh`;
  }

  validName = (name) => {
    if (name && name.length < 1) {
      DV.showNotify(t`Vui lòng nhập tên kênh`, 'danger');
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { name, id } = this.state;
    if (this.validName(name)) {
      if (!id) this.add({ name: name });
      else {
        this.update(id, { name });
      }
    }
  };

  add = async (data) => {
    this.setState({ isLoadingModal: true });
    let { success, error } = await handleApi(addManualChannel(data));
    this.setState({ isLoadingModal: false });
    if (success) {
      showSuccessSnackbar(t`Thêm kênh thành công`);
      this.close(true);
    } else showErrorSnackbar(error || 'Có lỗi xảy ra');
  };

  update = async (data) => {
    this.setState({ isLoadingModal: true });
    let { success, error } = await handleApi(updateManualChannel(data));
    this.setState({ isLoadingModal: false });
    if (success) {
      showSuccessSnackbar(t`Cập nhật kênh thành công`);
      this.close(true);
    } else showErrorSnackbar(error || 'Có lỗi xảy ra');
  };

  renderFooter() {
    return (
      <Button loading={this.state.loading} onClick={this.handleSubmit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }

  renderBody() {
    return (
      <CustomInput
        labelText={t`Tên kênh`}
        inputProps={{
          type: 'text',
          value: this.state.name,
          onChange: this.handleChange('name'),
        }}
      />
    );
  }
}

export default ManualChannelModal;
