import { AutoComplete } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { useQuery } from '@redux-requests/react';
import { trans } from 'config/LinguiConfig';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getScenarios } from 'redux/action/apiAction';

const SelectScenario = (props) => {
  const { data, error, loading } = useQuery({ type: getScenarios });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScenarios());
  }, []);

  return (
    <AutoComplete
      key={`${data?.length ?? 'init'}`}
      label={trans(t`Chọn kịch bản`)}
      options={data || []}
      loading={loading}
      error={error}
      {...props}
    />
  );
};

export default SelectScenario;
