import { dangerColor } from 'MUI_Kit/assets/jss/material-dashboard-pro-react.jsx';
import React from 'react';
import { successColor } from 'variables/color.js';

export default class ValueChange extends React.PureComponent {
  render() {
    let { value, fontSize, style } = this.props;
    fontSize = fontSize || 18;

    if (value > 0)
      return (
        <div className="bold flex-end-row" style={{ fontSize, ...style }}>
          <i className="fas fa-chevron-double-up" style={{ color: successColor, marginRight: 3 }} />{' '}
          {value}%
        </div>
      );
    else if (value < 0)
      return (
        <div className="bold flex-end-row" style={{ fontSize, ...style }}>
          <i
            className="fas fa-chevron-double-down"
            style={{ color: dangerColor, marginRight: 3 }}
          />{' '}
          {Math.abs(value)}%
        </div>
      );
    return null;
  }
}
