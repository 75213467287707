import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import DV from '../../variables/DV';

export const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (DV.token) return <Component {...props} />;
        else {
          return (
            <Redirect
              to={{
                search: window.location.search,
                pathname: '/',
                state: { from: props.location },
              }}
            />
          );
        }
      }}
    />
  );
};
