import { Button } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { trans } from 'config/LinguiConfig';
import 'css/classes.css';
import React, { memo } from 'react';
import Slider from 'react-slick';
import ButtonsAction from './ButtonsAction';
import ImageAction from './ImageAction';
import TextAction from './TextAction';

export const ArrowNext = memo(({ onClick }) => {
  return (
    <Button
      color="transparent"
      justIcon
      round
      onClick={onClick}
      size={'lg'}
      style={{
        position: 'absolute',
        top: '43%',
        right: -15,
        zIndex: 1000,
      }}>
      <i className="fas fa-chevron-right text-gray-400" />
    </Button>
  );
});

export const ArrowPrev = memo(({ onClick }) => {
  return (
    <Button
      color="transparent"
      justIcon
      round
      onClick={onClick}
      size={'lg'}
      style={{
        position: 'absolute',
        top: '43%',
        left: -15,
        zIndex: 1000,
      }}>
      <i className="fas fa-chevron-left text-gray-400" />
    </Button>
  );
});

const CarouselAction = ({ hide, carousel = [], onChange, scenarioId }) => {
  const remove = (index) => {
    let newData = [...carousel];
    newData.splice(index, 1);
    onChange(newData);
  };

  const add = () => {
    onChange([
      ...carousel,
      {
        title: '',
        subtitle: '',
        image_url: '',
        buttons: [],
        hashtag_image: '',
      },
    ]);
  };

  const update = (index, path, value) => {
    let newData = [...carousel];
    newData[index] = { ...newData[index], [path]: value };
    onChange(newData);
  };

  if (hide) return null;

  return (
    <div className="px-4 w-[365px]">
      <div className="w-[320px]">
        <Slider
          dots={true}
          infinite={false}
          centerMode={true}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          centerPadding={'20px'}
          className={'center'}
          nextArrow={<ArrowNext />}
          prevArrow={<ArrowPrev />}>
          {carousel.map((item, index) => {
            const { hashtag_image, image_url, id, title, subtitle, buttons } = item;

            return (
              <div className="flex" key={id}>
                <Button
                  hide={carousel.length === 1}
                  color="danger"
                  simple
                  round
                  justIcon
                  size="sm"
                  helperText={t`Xoá`}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 5,
                    zIndex: 10,
                  }}
                  onClick={() => remove(index)}>
                  <i className="fas fa-times" />
                </Button>

                <div className="bg-gray-100 hover:shadow-md transition-all rounded-2xl overflow-hidden">
                  <ImageAction
                    isHashtag={hashtag_image}
                    url={image_url}
                    scenarioId={scenarioId}
                    onChange={(path, value) => update(index, path, value)}
                  />
                  <TextAction
                    showInputTitle={true}
                    showInputType={false}
                    title={title}
                    subtitle={subtitle}
                    onChange={(path, value) => update(index, path, value)}
                  />
                  <ButtonsAction
                    buttons={buttons}
                    onChange={(path, value) => update(index, path, value)}
                  />
                </div>
              </div>
            );
          })}

          <div
            className="h-full !flex justify-center items-center min-h-[260px] border-2 rounded-2xl border-gray-200 cursor-pointer"
            onClick={add}>
            <Button color="white" round size="lg" justIcon helperText={t`Thêm thẻ mới`}>
              <i className="fas fa-plus" />
            </Button>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default memo(CarouselAction);
