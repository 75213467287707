import {t} from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import {addGBMLocation} from 'api/api';
import {setState} from 'config/Core';
import {loadGoogleMap} from 'config/Map';
import {handleApi} from 'helper/helper';
import _ from 'lodash';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import Routes from 'router/routes';
import DV from 'variables/DV';
import BaseModal from '../../BaseComponent/BaseModal';
import './GBMLocationModal.scss';

class GBMLocationModal extends BaseModal {
    constructor(props) {
        super(props);

        if (_.isEmpty(DV.currentGBM)) this.redirectTo(Routes.gbm.path);
        const {id: gbmId} = DV.currentGBM || {};

        this.state = {
            open: true,
            title: this.getTitle(),
            loading: false,
            place: null,
            gbmId,
            ...this.getDataFromRouter(),
        };
        this.goPathWhenClose = Routes.gbmLocation.path;
    }

    async componentDidMount() {
        const google = await loadGoogleMap();
        // noinspection JSUnresolvedVariable
        this.map = new google.maps.Map(this.mapEl, {
            center: {
                lat: 16,
                lng: 105,
            },
            zoom: 4.7,
            clickableIcons: false,
        });

        this.mapAutoComplete = new google.maps.places.Autocomplete(this.searchEl);
        this.mapAutoComplete.bindTo('bounds', this.map);
        this.mapAutoComplete.setFields(['place_id', 'geometry', 'formatted_address', 'name']);
        this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(this.searchEl);

        this.mapInfoWindow = new google.maps.InfoWindow();
        this.mapInfoWindow.setContent(this.infoEl);
        this.mapInfoWindow.addListener('closeclick', () => {
            this.mapMarker.setVisible(false);
            this.searchEl.value = '';
            this.setState({place: null});
        });

        this.mapMarker = new google.maps.Marker({
            map: this.map,
        });
        this.mapMarker.addListener('click', () => {
            this.mapInfoWindow.open(this.map, this.mapMarker);
        });

        this.mapAutoComplete.addListener('place_changed', () => {
            this.mapInfoWindow.close();
            const place = this.mapAutoComplete.getPlace();

            if (!place.geometry || !place.geometry.location) {
                return;
            }

            this.setState({place});

            if (place.geometry.viewport) {
                this.map.fitBounds(place.geometry.viewport);
            } else {
                this.map.setCenter(place.geometry.location);
                this.map.setZoom(20);
            }
            this.mapMarker.setPlace({
                placeId: place.place_id,
                location: place.geometry.location,
            });
            this.mapMarker.setVisible(true);
            this.mapInfoWindow.open(this.map, this.mapMarker);
        });
    }

    getTitle() {
        const {id} = this.getDataFromRouter();
        if (id) return t`Cập nhật vị trí`;
        else return t`Thêm vị trí`;
    }

    add = async (data) => {
        await setState(this)({loading: true});
        let {success, error} = await handleApi(addGBMLocation(data));
        await setState(this)({loading: false});
        if (success) {
            this.close(true);
        } else DV.showNotify(error, 'danger');
    };

    handleSubmit = async () => {
        const {id, place} = this.state;
        if (!place) {
            DV.showNotify(t`Không có địa điểm nào được chọn`, 'danger');
            return;
        }
        if (id) {
            return;
        }
        const data = {
            place_id: place.place_id,
            name: place.name,
            address: place.formatted_address,
        };
        await this.add(data);
    };

    renderBody() {
        const {place} = this.state;

        return [
            <Grid container direction="column" spacing={2} style={{marginTop: 16}}>
                <Grid container item sm={12} justify="center">
                    <div style={{
                        display: 'none',
                    }}>
                        <input
                            placeholder={t`Tìm kiếm ...`}
                            type="text"
                            ref={el => this.searchEl = el}
                            className="map-search-input"
                        />
                        <div ref={el => this.infoEl = el}>
                            {place && <>
                                <b>{place.name}</b><br/>
                                <small>{place.formatted_address}</small>
                            </>}
                        </div>
                    </div>
                    <div ref={el => this.mapEl = el} style={{
                        height: 300,
                        width: '100%',
                    }}/>
                </Grid>
            </Grid>,
        ];
    }

    renderFooter() {
        const {loading, gbmId, id, place} = this.state;
        return [
            <Button onClick={this.handleSubmit} color="info" loading={loading} disabled={!gbmId || !place?.place_id}>
                {id ? t`Hoàn tất` : t`Thêm Vị trí`}
            </Button>,
        ];
    }
}

export default GBMLocationModal;
