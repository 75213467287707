import React from 'react';
import NumberFormat from 'react-number-format';

export default class CustomNumberFormat extends React.PureComponent {
  render() {
    const { inputRef, onChange, ...other } = this.props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        thousandSeparator
        suffix=" đ"
      />
    );
  }
}
