import {
  Button,
  showErrorSnackbar,
  showSuccessSnackbar,
  Space,
  Switch,
} from '@doopage/react-ui-kit';
import '@doopage/react-ui-kit/dist/doopage-ui-kit.css';
import { t } from '@lingui/macro';
import { useQuery } from '@redux-requests/react';
import BaseModal from 'component/BaseComponent/BaseModal';
import CustomFlipMove from 'component/CustomFlipMove';
import produce from 'immer';
import { set } from 'lodash';
import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { getScenario, getScenarios, updateScenario } from 'redux/action/apiAction';
import Routes from 'router/routes';
import Action from 'screen/ScenarioFlow/modals/action';
import SelectActions from 'screen/ScenarioFlow/components/SelectActions';
import { showDebugObj } from 'screen/ScenarioFlow/utils';
import '../styles.scss';
import ChannelCondition from './conditions/ChannelCondition';
import { resetRequests } from '@redux-requests/core';
import DV from '../../../variables/DV';
import DisableTagSegmentModal from '../../../component/Modal/DisableTagSegmentModal';
import Modal from '@material-ui/core/Modal';
import { useHistory } from 'react-router-dom';
import { trans } from '../../../config/LinguiConfig';

class ScenarioActionModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: t`Cập nhật hành động`,
      loading: false,
      name: '',
    };
    this.refreshOnClose = true;
    this.goPathWhenClose = Routes.scenario.path;
    this.maxWidth = !showDebugObj ? 'md' : 'lg';
    this.scenarioDetail = createRef();
    this.modal = createRef();
    this.styleDialogContent = {
      overflow: 'hidden',
      height: '72vh',
    };
  }

  onClose = () => {
    this.props.dispatch(resetRequests([getScenario]));
  };

  renderBody() {
    return (
      <Content
        closeModal={this.close}
        ref={this.modal}
        id={this.props.match.params.id}
        onLoading={(value) => this.setState({ loading: value })}
      />
    );
  }

  renderFooter() {
    return (
      <Button
        onClick={() => {
          this.modal.current.submit();
        }}
        loading={this.state.loading}
        color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }
}

export default connect()(ScenarioActionModal);

export const Content = forwardRef(({ closeModal, id, onLoading }, ref) => {
  const { data, loading, error } = useQuery({ type: getScenario });
  const [scenario, setScenario] = useState({
    name: '',
    trigger_conditions: {},
    actions: [],
  });
  const dispatch = useDispatch();
  const bottomRef = useRef(null);
  const { trigger_conditions, actions } = scenario;
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const isProUser = useMemo(() => {
    const isProFromMainSubscription =
      DV.currentSubscription?.main_subscription?.automation_marketing_active;
    const isPropFromCouponSubscription = !!DV.currentSubscription?.coupon_subscriptions?.find(
      (sub) => sub.automation_marketing_active,
    );
    return isProFromMainSubscription || isPropFromCouponSubscription;
  }, []);

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) closeModal();
  }, [error]);

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const payload = {
        ...scenario,
        actions: actions.map((item, index) => {
          return {
            ...item,
            step: index,
            id: item.id.includes?.('create_') ? undefined : item.id,
          };
        }),
      };
      onLoading(true);
      const { error } = await dispatch(updateScenario(id, payload));
      onLoading(false);
      if (!error) {
        closeModal();
        showSuccessSnackbar(t`Cập nhật thành công`);
      } else showErrorSnackbar(error);

      dispatch(getScenarios());
    },
  }));

  useEffect(() => {
    dispatch(getScenario(id));
  }, [id]);

  useEffect(() => {
    if (data) setScenario(data);
  }, [data]);

  const update = useCallback((path, value) => {
    setScenario(
      produce((draftState) => {
        set(draftState, path, value);
      }),
    );
  }, []);

  const updatePosition = useCallback((srcIndex, desIndex) => {
    setScenario(
      produce((draftState) => {
        let temp = draftState.actions[srcIndex];
        draftState.actions[srcIndex] = draftState.actions[desIndex];
        draftState.actions[desIndex] = temp;
      }),
    );
  }, []);

  const addAction = useCallback((action) => {
    if (!isProUser && (action.key === 'update_segment' || action.key === 'update_tag'))
      setOpen(true);
    else
      setScenario(
        produce((draftState) => {
          draftState.actions.push({
            id: `create_${new Date().getTime()}`,
            is_modified: true,
            reply_type: action.key,
          });
        }),
      );

    setTimeout(() => {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }, []);

  const updateAction = useCallback((index, value) => {
    setScenario(
      produce((draftState) => {
        draftState.actions[index] = value;
      }),
    );
  }, []);

  const removeAction = useCallback((index) => {
    setScenario(
      produce((draftState) => {
        draftState.actions.splice(index, 1);
      }),
    );
  }, []);

  return (
    <Space direction="vertical">
      <ChannelCondition onChange={update} channels={trigger_conditions?.channels ?? []} />
      <div className="flex relative" style={{ height: 'calc(72vh - 72px)' }}>
        <SelectActions addAction={addAction} scenario={scenario} />
        <div className="flex-1 ml-7 pr-2 overflow-auto">
          <div className="flex justify-end items-center">
            <div className="text-white p-2 px-3 rounded-full w-auto bg-info">
              {t`Tin nhắn của khách hàng`}
            </div>
          </div>
          <CustomFlipMove className="flex flex-col">
            {actions.map((item, index) => (
              <div key={item.id}>
                <Action
                  scenarioId={id}
                  onChange={updateAction}
                  onRemove={removeAction}
                  updatePosition={updatePosition}
                  action={item}
                  index={index}
                  isLast={index === actions.length - 1}
                  isFirst={index === 0}
                />
              </div>
            ))}
          </CustomFlipMove>
          <div className="flex justify-center items-center mt-[10px] w-10/12">
            <Switch
              value={trigger_conditions?.is_last}
              label={<div>{t`Thoát chuỗi kịch bản`}</div>}
              onChange={(e) => {
                update('trigger_conditions.is_last', e.target.checked);
              }}
            />
            <Button
              justIcon
              size="tiny"
              className="!ml-[-10px]"
              helperText={trans(
                t`Ngắt kích hoạt của các kịch bản chạy sau đối với khách hàng này trong phiên này (nếu khách hàng có hành động mới sẽ được tính là một phiên mới).`,
              )}
              color="transparent"
              round>
              <i className="fal fa-question-circle" />
            </Button>
          </div>
          <div ref={bottomRef} style={{ width: '100%', height: 2 }} />
        </div>
      </div>
      <Modal open={isOpen} onClose={() => setOpen(false)}>
        <DisableTagSegmentModal onClose={() => setOpen(false)} history={history} />
      </Modal>
    </Space>
  );
});
