import {
  getTypeClear,
  getTypeFail,
  getTypeLoading,
  getTypeSuccess,
  getTypeUpdate,
} from 'redux/utils';

export const fetchData = (type, func, actionSuccess, actionFail, preventMergeData) => {
  return (dispatch) => {
    dispatch(fetchLoading(type));
    return func()
      .then((data) => {
        if (Array.isArray(data) || preventMergeData) data = { data };
        dispatch(fetchSuccess(type, data));

        if (typeof actionSuccess === 'function') dispatch(actionSuccess());
        if (typeof actionSuccess === 'object') dispatch(actionSuccess);
      })
      .catch((error) => {
        dispatch(fetchError(type, error));
        console.log('actionFail', actionFail);
        if (typeof actionFail === 'function') dispatch(actionFail(error));
        if (typeof actionFail === 'object') dispatch(actionFail);
      });
  };
};

export const fetchLoading = (type) => {
  return { type: getTypeLoading(type) };
};

export const fetchSuccess = (type, payload) => {
  return { type: getTypeSuccess(type), payload };
};

export const fetchError = (type, payload) => {
  return { type: getTypeFail(type), payload };
};

export const updateAction = (type, payload) => {
  return { type: getTypeUpdate(type), payload };
};

export const clearAction = (type) => {
  return { type: getTypeClear(type) };
};
