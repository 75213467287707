import Button from 'MUI_Kit/components/CustomButtons/Button';
import PropTypes from 'prop-types';
import React from 'react';

function IconButton({ icon, color, helperText, onClick, loading, children, ...rest }) {
  return (
    <Button
      color={color}
      round
      justIcon
      loading={loading}
      helperText={helperText}
      margin
      onClick={onClick}
      {...rest}>
      {children}
    </Button>
  );
}

IconButton.propTypes = {
  color: PropTypes.oneOf([
    'gray',
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'twitter',
    'facebook',
    'google',
    'linkedin',
    'pinterest',
    'youtube',
    'tumblr',
    'github',
    'behance',
    'dribbble',
    'reddit',
    'transparent',
  ]),
  helperText: PropTypes.string,
  loading: PropTypes.bool,
};

export default React.memo(IconButton);
