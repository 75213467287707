import {t} from '@lingui/macro';
import BaseModal from 'component/BaseComponent/BaseModal';
import {LIVECHAT} from 'config/Env';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import React from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {docco} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import Routes from 'router/routes';
import DV from 'variables/DV';
import {LIST_DOMAIN} from 'variables/staticValue.js';
import {handleApi} from '../../../../helper/helper';
import {getWebsiteSetting} from '../../../../api/api';

class ViewWebsiteModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      open: true,
      id: null,
      title: t`Mã nhúng`,
      configs: {},
      ...this.getDataFromRouter()
    };

    this.refreshOnClose = false;
    this.goPathWhenClose = this.props.openFromChannels ? Routes.channel.path : Routes.website.path;
  }

  close = () => {
    const {history} = this.props;
    this.setState({open: false});
    this.redirectTo(this.goPathWhenClose);
  };

  async componentDidMount() {
    const id = this.state.id || this.props.match.params.id;
    this.setState({loading: true});
    const {success, data} = await handleApi(getWebsiteSetting(id));
    this.setState({loading: false});
    if (success) {
      this.setState({configs: data});
    }
  }

  renderBody() {
    const {name: domainWebsite, loading, configs} = this.state;
    const cfg = {
      greeting_messages: configs.greeting_messages,
      answered_in: configs.answered_in,
      default_platform: configs.default_platform,
      native_channels: configs.native_channels?.map((item) => {
        const mapPlatforms = {
          fb: 'facebook',
          zalo_oa: 'zalo',
        };
        const fieldIds = {
          fb: 'fb_id',
          zalo_oa: 'oa_id',
        };
        const v = {
          platform: mapPlatforms[item.platform] ?? item.platform,
          id: fieldIds[item.platform] ? item[fieldIds[item.platform]] : item.id,
        };
        if (v.platform === 'website') {
          delete v.id;
        }
        return v;
      }).filter(r => r.platform === 'website' || r.id),
    };
    const code = `
<!-- DooPage -->
<script>
window.doopageAsyncInit = function(doopage) {
  doopage.init({
    companyId: ${DV.company.id},
    websiteId: ${this.state.id || this.props.match.params.id},
    configs: ${JSON.stringify(cfg, null, 2).replace(/^/gm, ' '.repeat(4)).trim()}
  });
  doopage.detector.init();
  doopage.widget.init();
};
</script>
<script async defer src="${LIVECHAT}"></script>
<!-- End DooPage -->
        `.trim();

    console.log(code);

    return <>
      <CustomInput
        required
        labelText="Domain Website"
        inputProps={{
          disabled: true,
          type: 'text',
          value: domainWebsite ?? configs.domain ?? ''
        }}
      />
      {!loading && <>
        <CopyToClipboard text={code} onCopy={() => DV.showNotify(t`Đã sao chép Mã nhúng`, 'info')}>
          <SyntaxHighlighter language="javascript" style={docco}>
            {code}
          </SyntaxHighlighter>
        </CopyToClipboard>
        <div>
          <i>{t`Bạn hãy chèn đoạn mã ở trên vào cuối website`}</i>
        </div>
      </>}
    </>;
  }

  renderFooter() {
    return [
      <Button
        helperText={t`Hướng dẫn tích hợp Chatbox`}
        href={LIST_DOMAIN[DV.partner].guide.website}
        color="info"
        simple
        target="_blank"
        rel="noopener noreferrer">
        {t`Hướng dẫn`}
      </Button>
    ];
  }
}

export default ViewWebsiteModal;
