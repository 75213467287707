import { Input, Select, Space } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import React, { memo } from 'react';
import SelectScenario from '../../components/SelectScenario';

const RunScenarioAction = ({ hide, value: scenario = {}, onChange }) => {
  const { id, conditions, fallback_scenario } = scenario;
  const { property, expression, operator, value } = conditions?.[0] || {};

  if (hide) return null;

  const handleChangeConditions = (key, value) => {
    onChange('scenario', {
      ...scenario,
      conditions: [{ ...(conditions?.[0] ?? []), [key]: value }],
    });
  };

  return (
    <div className="p-3">
      <Space direction="vertical" spaceHeight={10}>
        <div className="font-bold">
          <i className="fad fa-bolt mr-2" />
          {t`Kích hoạt kịch bản khác`}
        </div>
        <SelectScenario
          label={t`Chọn hoặc kéo trong sơ đồ`}
          value={id || ''}
          onChange={(id) => {
            onChange('scenario', { ...scenario, id });
          }}
        />
        <div>
          <div className="font-bold mt-[10px] ml-[3px]">{t`Điều kiện`}</div>
          <Space direction="vertical" spaceHeight={10}>
            <Select
              label={t`Chọn điều kiện`}
              fullWidth
              options={[
                { id: 'always', name: t`Luôn đúng` },
                { id: 'expression', name: t`Expression` },
                { id: 'customer.name', name: 'customer.name' },
              ]}
              value={property}
              onChange={(e) => {
                handleChangeConditions('property', e.target.value);
              }}
            />
            <Input
              hide={property !== 'expression'}
              multiline
              fullWidth
              label={t`Nội dung đặt tả`}
              value={expression || ''}
              onChange={(e) => {
                handleChangeConditions('expression', e.target.value);
              }}
            />
            <Select
              hide={property === 'always'}
              fullWidth
              options={[
                { id: '==', name: t`bằng` },
                { id: '!=', name: t`khác` },
                { id: 'in', name: t`thuộc` },
                { id: 'not in', name: t`không thuộc` },
                { id: '<', name: t`bé hơn` },
                { id: '<=', name: t`bé hơn bằng` },
                { id: '>', name: t`lớn hơn` },
                { id: '>=', name: t`lớn hơn bằng` },
              ]}
              value={operator}
              onChange={(e) => {
                handleChangeConditions('operator', e.target.value);
              }}
            />
            <Input
              hide={property === 'always'}
              fullWidth
              value={value}
              onChange={(e) => {
                handleChangeConditions('value', e.target.value);
              }}
            />
          </Space>
        </div>
        {property !== 'always' && (
          <div>
            <div className="font-bold mt-[10px] ml-[3px]">{t`Ngược lại`}</div>
            <Space direction="vertical" spaceHeight={10}>
              <SelectScenario
                label={t`Chọn hoặc kéo trong sơ đồ`}
                value={fallback_scenario?.id || ''}
                onChange={(id) => {
                  onChange('scenario', { ...scenario, fallback_scenario: { id } });
                }}
              />
            </Space>
          </div>
        )}
      </Space>
    </div>
  );
};

export default memo(RunScenarioAction);
