import { withStyles } from '@material-ui/core/styles/index';
import { getCompanyDetail, getCurrentSubscription, getOwnerProfile, getToken } from 'api/api.js';
import { IS_SERVER_LOCAL } from 'config/Env';
import LocalStorage from 'config/LocalStorage.js';
import PropTypes from 'prop-types';
import React from 'react';
import { v4 as uuid } from 'uuid';
import DV from '../variables/DV';
import { MESSAGE_CONTENT_KEY, SCOPE } from '../variables/staticValue';
import XLSX from 'xlsx';
import Resizer from 'react-image-file-resizer';

const pjson = require('../../package.json');

export const resizeImage = (
  file,
  maxWidth = 400,
  maxHeight = 300,
  compressFormat = 'PNG',
  quality = 50,
  rotation = 0,
  outputType = 'file',
) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxWidth,
      maxHeight,
      compressFormat,
      quality,
      rotation,
      (uri) => {
        resolve(uri);
      },
      outputType,
    );
  });

export const keymirror = (obj, prefix = '') => {
  let ret = {};
  let key;
  if (!(obj instanceof Object && !Array.isArray(obj))) {
    throw new Error('keyMirror(...): Argument must be an object.');
  }
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      ret[key] = prefix + key;
      if (key.includes('GET_')) {
        ret[key + '_SUCCESS'] = prefix + key + '_SUCCESS';
        ret[key + '_FAIL'] = prefix + key + '_FAIL';
      }
    }
  }
  return ret;
};

export const handleApi = async (func) => {
  return func()
    .then((data) => {
      return { success: true, data: data };
    })
    .catch((errorMsg) => {
      if (typeof errorMsg === 'object') return { success: false, ...errorMsg };
      return { success: false, error: errorMsg };
    });
};

export const toCurrency = (value, currency) => {
  currency = currency || DV.currency || 'VND';

  if (!value) return '0';
  try {
    if (value !== 0)
      return Number(value).toLocaleString('en-US', {
        style: 'currency',
        currency,
      });
  } catch (e) {
    return value;
  }

  return value;
};

export const thousandSeparate = (value) => {
  if (!value) return '0';
  try {
    return value.toLocaleString('en-US');
  } catch (e) {
    return value;
  }
};

export const getFormData = (data) => {
  console.log('getFormData', data);
  if (!data) return null;
  let formData = new FormData();

  Object.keys(data).forEach((key) => {
    if (data[key]) {
      let value = data[key];
      if (typeof value === 'object' && !(value instanceof File)) value = JSON.stringify(value);
      formData.append(key, value);
    }
  });

  return formData;
};

export const getFullFormData = (obj, form, namespace) => {
  let fd = form || new FormData();
  let formKey;

  for (let property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
        getFormData(obj[property], fd, property);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
};

export const validateDomain = (domain) => {
  let reDomain = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
  return reDomain.test(domain);
};

export const validateURL = (url) => {
  let regexp =
    /((http(s)?(\:\/\/))+(www\.)?([\w\-\.\/])*(\.[a-zA-Z]{2,3}\/?))[^\s\b\n|]*[^.,;:\?\!\@\^\$ -]$/;

  return regexp.test(url);
};

export const validateEmail = (email) => {
  let re = /\S+@\S+\.\S+/;
  return re.test(email);
};

export const validatePhone = (phone) => {
  let re = /[ +0-9]/;
  return re.test(phone);
};

export const validateName = (name) => {
  return name && typeof name === 'string' && name.length >= 2;
};

export const validateHTTP = (url) => {
  let pattern = /^((http|https|ftp):\/\/)/;
  return !!(url && pattern.test(url.toString()));
};

export const getObjectFromParams = (params) => {
  let obj = {};
  try {
    if (params && params.length > 1)
      obj = JSON.parse(
        '{"' + decodeURI(params.substr(1).replace(/&/g, '","').replace(/=/g, '":"')) + '"}',
      );
  } catch (e) {
    console.log('getObjectFromParams error: ', e.message);
  }
  return obj;
};

export const getParamsFromObject = (obj) => {
  if (!obj) return '';
  let str = [];
  for (let key in obj)
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '')
      str.push(encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]));

  return str.length > 0 ? '?' + str.join('&') : '';
};

export const getNewQueryParams = (newParams) => {
  let searchParams = getObjectFromParams(window.location.search);
  let newParamsObj = { ...searchParams, ...newParams };
  if (!!newParams.q) {
    delete newParamsObj.page;
  }
  return getParamsFromObject(newParamsObj);
};

export const change_alias = (alias) => {
  let str = alias;
  str = str.toLowerCase();
  str = str.replace(/[àáạảãâầấậẩẫăằắặẳẵ]/g, 'a');
  str = str.replace(/[èéẹẻẽêềếệểễ]/g, 'e');
  str = str.replace(/[ìíịỉĩ]/g, 'i');
  str = str.replace(/[òóọỏõôồốộổỗơờớợởỡ]/g, 'o');
  str = str.replace(/[ùúụủũưừứựửữ]/g, 'u');
  str = str.replace(/[ỳýỵỷỹ]/g, 'y');
  str = str.replace(/đ/g, 'd');
  str = str.replace(/[!@%^*()+=<>?\/,.:;'"&#\[\]~$_`\-{}|\\]/g, ' ');
  str = str.replace(/ + /g, ' ');
  str = str.trim();
  return str;
};

export const getScope = (isAdPostEnable) => {
  let scope = '';

  Object.keys(SCOPE).forEach((key, index) => {
    if (SCOPE[key]) {
      if (index !== 0) scope += ',';
      scope += key;
    }
  });

  if (isAdPostEnable) {
    scope += ',pages_manage_ads,ads_management';
  }

  return scope;
};

export const convertHttpToHttps = (url) => {
  if (!!!url) return url;
  if (IS_SERVER_LOCAL) return url;
  try {
    if (url.includes('http') && !url.includes('https')) {
      url = url.replace('http', 'https');
    }
  } catch (e) {
    console.log('co loi xay ra convertHttpToHttps');
  }
  return url;
};

export const numLikesFormat = (num) => {
  return num > 999999
    ? (num / 1000000).toFixed(1) + 'tr'
    : num > 999
    ? (num / 1000).toFixed(1) + 'k'
    : num;
};

export const compareStatus = (a, b) => {
  if (a.status < b.status) return 1;
  if (a.status > b.status) return -1;
  return 0;
};

export const arrayMove = (arr, old_index, new_index) => {
  if (new_index >= arr.length) {
    let k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

export const createStyled = (styles, options) => {
  function Styled(props) {
    const { children, ...other } = props;
    return children(other);
  }

  Styled.propTypes = {
    children: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
  };
  return withStyles(styles, options)(Styled);
};

export const getHeightModal = (height = 0) => {
  return window.innerHeight * 0.9 - 57 - 40 - 24 - 5 - height;
};

export const renderBreakLine = (value) => {
  if (!!!value) return '';

  return value.split('\n').map((item, key) => {
    return (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
};

export const convertCardStepsFromServerToClient = (steps) => {
  if (!steps || !Array.isArray(steps)) {
    steps = [];
  }
  steps.forEach((step) => {
    step = step || {};
    step.id = uuid();
    if (step.type === MESSAGE_CONTENT_KEY.card) {
      let { url } = step.message.attachment.payload;
      if (step.message.attachment.type === 'image') {
        step.message = {
          title: '',
          subtitle: '',
          image_url: url,
          buttons: [],
        };
      } else {
        let { title, subtitle, image_url, buttons } = step.message.attachment.payload.elements[0];
        step.message = { title, subtitle, image_url, buttons };
      }
    }
  });
  return steps;
};

export const CapitalizeString = (s = '') => {
  if (typeof s !== 'string') s = s.toString();
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getPathUrl = () => {
  return window.location.pathname;
};

export const isInViewport = (element, offset = 0) => {
  if (!element) return false;
  const top = element.getBoundingClientRect().top;
  return top + offset >= 0 && top - offset <= window.innerHeight;
};

export const getCurrentSubs = async () => {
  let { data, error } = await handleApi(getCurrentSubscription());
  if (error) {
    DV.showNotify(error, 'danger');
    return false;
  }
  DV.currentSubscription = data;
  return true;
};

export const getCompanyDetailInfo = async () => {
  let { error, data } = await handleApi(getCompanyDetail());
  if (error) {
    DV.showNotify(error, 'danger');
    return false;
  }
  DV.company = { ...DV.company, ...data };
  return true;
};

export const getOwnerInfo = async () => {
  let deviceId = DV.params.device_id || (await LocalStorage.getStaffDeviceId());
  if (DV.params.device_id) LocalStorage.setStaffDeviceId(DV.params.device_id);
  DV.staffDeviceId = deviceId;
  let params = '';
  if (deviceId) params = '?device_id=' + DV.staffDeviceId;
  let { error, data } = await handleApi(getOwnerProfile(params));
  if (error) return { error };

  if (
    !localStorage.getItem('doopage_fake_owner_id') &&
    !localStorage.getItem('doopage_fake_owner_token') &&
    DV.staffDeviceId
  ) {
    const myDomain = data?.last_staff?.company?.partner?.my_domain ?? '';
    if (
      myDomain &&
      !window.location.href.includes('localhost') &&
      !window.location.href.startsWith(myDomain)
    )
      window.location.href = myDomain + '?device_id=' + DV.staffDeviceId;
  }

  DV.owner = data;
  if (data?.last_staff?.open_notification_subscribe_dialog) {
    setTimeout(() => {
      DV.showSubscribeSocialModal();
    }, 1000);
  }

  LocalStorage.setUser(data);
  return { data };
};

export const refreshToken = async (fbToken) => {
  let { data, error } = await handleApi(getToken(fbToken));
  if (error) {
    DV.showNotify(error, 'danger');
    return false;
  }
  let { access_token } = data;
  DV.token = access_token;
  LocalStorage.setToken(access_token);
  return true;
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};

export const getBuildNumber = () => {
  const version = pjson.version || '';
  const temp = version.split('.');
  return temp[temp.length - 1];
};

const getHeaderRow = (sheet) => {
  const headers = [];
  const range = XLSX.utils.decode_range(sheet['!ref']);
  let C,
    R = range.s.r; /* start in the first row */
  /* walk every column in the range */
  for (C = range.s.c; C <= range.e.c; ++C) {
    const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]; /* find the cell in the first row */

    let hdr = undefined; // <-- replace with your desired default
    if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);

    if (hdr) headers.push(hdr);
  }
  return headers;
};

export const readXLSXFile = (file, callbackFn) => {
  try {
    const reader = new FileReader();
    reader.onload = function (e) {
      const file = e.target.result;
      let workbook = XLSX.read(file, { type: 'binary' });
      let sheet = workbook?.Sheets?.[workbook?.SheetNames?.[0]];
      const headers = getHeaderRow(sheet);
      const data = XLSX.utils.sheet_to_json(sheet);
      console.log('readXLSXFile ', { headers, data });
      callbackFn({ headers, data });
    };
    reader.readAsBinaryString(file);
  } catch (e) {
    console.log('readXLSXFile error', e);
  }
};

export const downloadFileFromUrl = (url) => {
  const anchor = document.createElement('a');
  anchor.download = 'cropPreview.png';
  anchor.href = url;
  anchor.click();
};

export const calcPercent = (value, total) => {
  if (!total) return 0;
  const percent = (value / total) * 100;
  return Math.round(percent * 10) / 10;
};

export const getAgentName = () => {
  const userAgent = navigator.userAgent;
  let browserName = 'Unknown';
  let browserVersion = 'Unknown';
  let osName = 'Unknown';
  let osVersion = 'Unknown';

  // Detect browser name and version
  if (userAgent.indexOf('Firefox') > -1) {
    browserName = 'Firefox';
    browserVersion = userAgent.match(/Firefox\/([0-9]+(?:\.[0-9]+)?)/)[1];
  } else if (userAgent.indexOf('Chrome') > -1) {
    browserName = 'Chrome';
    browserVersion = userAgent.match(/Chrome\/([0-9]+(?:\.[0-9]+)?)/)[1];
  } else if (userAgent.indexOf('Safari') > -1) {
    browserName = 'Safari';
    browserVersion = userAgent.match(/Version\/([0-9]+(?:\.[0-9]+)?)/)[1];
  } else if (userAgent.indexOf('MSIE') > -1 || userAgent.indexOf('Trident') > -1) {
    browserName = 'Internet Explorer';
    browserVersion = userAgent.match(/(?:MSIE |rv:)([0-9]+(?:\.[0-9]+)?)/)[1];
  }

  // Detect OS name and version
  if (userAgent.indexOf('Windows NT') > -1) {
    osName = 'Windows';
    osVersion = userAgent.match(/Windows NT ([0-9]+(?:\.[0-9]+)?)/)[1];
  } else if (userAgent.indexOf('Mac OS X') > -1) {
    osName = 'Mac OS';
    osVersion = userAgent.match(/Mac OS X ([0-9_]+)/)[1].replace(/_/g, '.');
  } else if (userAgent.indexOf('Linux') > -1) {
    osName = 'Linux';
    osVersion = 'Unknown';
  }

return `${osName} ${osVersion},${browserName} ${browserVersion}`.substring(0, 100);
};

