import { Accordion, Button, showErrorSnackbar, Space, Switch } from '@doopage/react-ui-kit';
import '@doopage/react-ui-kit/dist/doopage-ui-kit.css';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import { resetRequests } from '@redux-requests/core';
import { useQuery } from '@redux-requests/react';
import BaseModal from 'component/BaseComponent/BaseModal';
import produce from 'immer';
import { set } from 'lodash';
import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { getFanpage, updateFanpage } from 'redux/action/apiAction';
import Routes from 'router/routes';
import GreetingEditor from 'screen/Channel/Fanpage/components/GreetingEditor';
import IceBreakerEditor from 'screen/Channel/Fanpage/components/IceBreakerEditor';
import MenuEditor from 'screen/Channel/Fanpage/components/MenuEditor';

class FanpageConfigModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: t`Nút bắt đầu/gợi ý`,
      loading: false,
      name: '',
    };
    this.refreshOnClose = false;

    this.goPathWhenClose = !this.props.openFromChannels ? Routes.fanpage.path : Routes.channel.path;
    this.modal = createRef();
  }

  onClose = () => {
    this.modal.current?.close?.();
    this.props.dispatch(resetRequests([getFanpage]));
  };

  renderBody() {
    return (
      <Content
        closeModal={this.close}
        ref={this.modal}
        id={this.props.match.params.id}
        onLoading={(value) => this.setState({ loading: value })}
      />
    );
  }

  renderFooter() {
    return (
      <Button
        onClick={() => {
          this.modal.current?.submit?.();
        }}
        loading={this.state.loading}
        color="success">
        {t`Hoàn tất`}
      </Button>
    );
  }
}

export default connect()(FanpageConfigModal);

export const Content = forwardRef(({ closeModal, id, onLoading }, ref) => {
  const { data, loading, error } = useQuery({ type: getFanpage });
  const [{ greeting, ice_breakers, persistent_menu, started_button }, setData] = useState({});
  const messengerConfig = data?.messenger_config;
  const dispatch = useDispatch();

  useEffect(() => {
    onLoading(loading);
  }, [loading]);

  useEffect(() => {
    if (error) {
      showErrorSnackbar(error);
      closeModal();
    }
  }, [error]);

  useEffect(() => {
    dispatch(getFanpage(id));
  }, [id]);

  useEffect(() => {
    if (messengerConfig) {
      setData(messengerConfig);
    }
  }, [messengerConfig]);

  const formatData = () => {
    const config = { greeting, ice_breakers, persistent_menu, started_button };

    return produce(config, ({ greeting, ice_breakers, persistent_menu, started_button }) => {
      if (greeting.active && !greeting.elements.some((item) => !!item.text)) {
        greeting.active = false;
      }

      if (
        persistent_menu.active &&
        !persistent_menu.menu.some((item) => !!item.call_to_actions.some((i) => !!i.title))
      ) {
        persistent_menu.active = false;
      }

      if (ice_breakers.active && !ice_breakers.buttons.some((item) => !!item.title)) {
        ice_breakers.active = false;
      }
    });
  };

  useImperativeHandle(ref, () => ({
    submit: async () => {
      const { error } = await dispatch(
        updateFanpage(id, {
          messenger_config: formatData(),
        }),
      );

      if (!error) {
        closeModal();
      } else showErrorSnackbar(error);
    },
  }));

  const update = useCallback((path, value) => {
    setData(
      produce((draftState) => {
        set(draftState, path, value);
      }),
    );
  }, []);

  return (
    <Space direction="vertical">
      <Card>
        <Accordion
          expanded={!!started_button?.active}
          title={<>{t`Nút bắt đầu`}</>}
          expandIcon={<Switch checked={!!started_button?.active} />}
          rotateExpandIcon={false}
          onChange={() => update('started_button.active', !started_button?.active)}>
          <div>
            <Switch
              checked={!!persistent_menu?.active}
              label={<div className="text-lg font-bold">{t`Menu`}</div>}
              onChange={(e) => {
                update(`persistent_menu.active`, e.target.checked);
              }}
            />
            {!!persistent_menu?.active && (
              <MenuEditor
                menu={persistent_menu?.menu ?? []}
                update={update}
                show={persistent_menu?.active}
              />
            )}
          </div>
        </Accordion>
      </Card>
      <Card>
        <Accordion
          expanded={!!greeting?.active}
          title={<>{t`Lời chào`}</>}
          expandIcon={<Switch checked={!!greeting?.active} />}
          rotateExpandIcon={false}
          onChange={() => update('greeting.active', !greeting?.active)}>
          {!!greeting && (
            <GreetingEditor show={greeting.active} update={update} elements={greeting.elements} />
          )}
        </Accordion>
      </Card>
      <Card>
        <Accordion
          expanded={!!ice_breakers?.active}
          title={<>{t`Gợi ý`}</>}
          expandIcon={<Switch checked={!!ice_breakers?.active} />}
          rotateExpandIcon={false}
          onChange={() => update('ice_breakers.active', !ice_breakers?.active)}>
          {!!ice_breakers && (
            <IceBreakerEditor
              show={ice_breakers.active}
              update={update}
              buttons={ice_breakers?.buttons ?? []}
            />
          )}
        </Accordion>
      </Card>
    </Space>
  );
});
