import React, { PureComponent } from 'react';
import MainLogo from '../MainLogo';

export default class LoadableLoading extends PureComponent {
  render() {
    return (
      <div className="flex-center-col" style={{ height: '100vh', background: 'white' }}>
        <MainLogo />
      </div>
    );
  }
}
