import { handleApi } from 'helper/helper';
import { useEffect, useState } from 'react';

export const useApi = (resetArray) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState('');

  useEffect(
    () => {
      setLoading(false);
      setError('');
    },
    Array.isArray(resetArray) ? resetArray : [resetArray],
  );

  const doFetch = async (func) => {
    setError('');
    setLoading(true);
    const { error, data, ...rest } = await handleApi(func);
    setLoading(false);

    if (error) setError(error);
    else setData(data);

    return { error, data, ...rest };
  };

  return { loading, error, data, doFetch };
};
