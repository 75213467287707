import { Grid } from '@material-ui/core';
import React from 'react';
import SelectDay from './SelectDay';
import SelectTime from './SelectTime';
import SelectTimeZone from './SelectTimezone';
import { t } from '@lingui/macro';

const Text = ({ children }) => {
  return (
    <span
      style={{
        position: 'relative',
        top: 3,
      }}>
      &nbsp; {children} &nbsp;
    </span>
  );
};

const Availability = (props) => {
  const {
    timezone,
    onChangeTimezone,
    fromDay,
    onChangeFromDay,
    toDay,
    onChangeToDay,
    fromTime,
    onChangeFromTime,
    toTime,
    onChangeToTime,
  } = props;
  return (
    <>
      <Grid item md={4} sm={12} xs={12}>
        <SelectTimeZone value={timezone} onChange={onChangeTimezone} label={t`Time Zone`} />
      </Grid>
      <Grid item md={2} sm={4} xs={6}>
        <SelectDay value={fromDay} onChange={onChangeFromDay} label={t`Từ`} />
      </Grid>
      <Grid item md={2} sm={4} xs={6}>
        <SelectDay value={toDay} onChange={onChangeToDay} label={t`đến`} />
      </Grid>
      <Grid item md={2} sm={2} xs={6}>
        <SelectTime value={fromTime} onChange={onChangeFromTime} label={t`Từ`} />
      </Grid>
      <Grid item md={2} sm={2} xs={6}>
        <SelectTime value={toTime} onChange={onChangeToTime} label={t`đến`} />
      </Grid>
    </>
  );
};

export default Availability;
