import { setLogStyle, useLogStyle } from 'config/LogConfig';
import { dangerColor, infoColor, successColor, warningColor } from 'variables/color';
import DV from 'variables/DV';
import { getPermantlyIP } from "../variables/utils";

export default class LocalStorage {
  static clearLocal = () => {
    console.log(useLogStyle + 'LocalStorage CLEAR', setLogStyle(warningColor));
    try {
      localStorage.clear();
    } catch (error) {
      console.log(
        useLogStyle + 'LocalStorage CLEAR \n ERROR:',
        setLogStyle(dangerColor),
        error.message,
      );
    }
  };

  static removeLocal = (key) => {
    console.log(useLogStyle + 'LocalStorage REMOVE ' + key, setLogStyle(warningColor));
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.log(
        useLogStyle + 'LocalStorage REMOVE \n ERROR:',
        setLogStyle(dangerColor),
        error.message,
      );
    }
  };

  static setToLocal = (key, value) => {
    console.log(
      useLogStyle + 'LocalStorage SET ' + key + ' TO LOCAL',
      setLogStyle(infoColor),
      value,
    );
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.log(
        useLogStyle + 'LocalStorage SET ' + key + ' TO LOCAL \n ERROR:',
        setLogStyle(dangerColor),
        error.message,
      );
    }
  };

  static getFromLocal = (key) => {
    let data = null;
    try {
      let res = localStorage.getItem(key);
      data = JSON.parse(res);
      console.log(
        useLogStyle + 'LocalStorage GET ' + key + ' FROM LOCAL',
        setLogStyle(successColor),
        data,
      );
    } catch (error) {
      console.log(
        useLogStyle + 'LocalStorage GET ' + key + ' FROM LOCAL \n ERROR:',
        setLogStyle(dangerColor),
        error.message,
      );
    }

    return data;
  };

  static getStatisticDateRange = () => {
    return LocalStorage.getFromLocal('company/' + DV.company.id + '/statistic') || {};
  };

  static setStatisticDateRange = (dateRange = null) => {
    LocalStorage.setToLocal('company/' + DV.company.id + '/statistic', dateRange);
  };

  static getStaffDeviceId = () => {
    return LocalStorage.getFromLocal('deviceId') || '';
  };

  static setStaffDeviceId = (deviceId = null) => {
    LocalStorage.setToLocal('deviceId', deviceId);
  };

  static getQuickReplyData = () => {
    return LocalStorage.getFromLocal('quick_reply_data');
  };
  static setQuickReplyData = (data = null) => {
    LocalStorage.setToLocal('quick_reply_data', data);
  };

  static getRuleData = () => {
    return LocalStorage.getFromLocal('rule_data') || {};
  };
  static setRuleData = (data = {}) => {
    LocalStorage.setToLocal('rule_data', data);
  };

  static getCompany = () => {
    return LocalStorage.getFromLocal('company') || {};
  };
  static setCompany = (data = null) => {
    LocalStorage.setToLocal('company', data);
  };

  static getCurrency = () => {
    let value = LocalStorage.getFromLocal('currency');
    return value || 'VND';
  };
  static setCurrency = (data = null) => {
    LocalStorage.setToLocal('currency', data);
  };

  static getBroadcastData = () => {
    return LocalStorage.getFromLocal('broadcast_data');
  };
  static setBroadcastData = (data = null) => {
    LocalStorage.setToLocal('broadcast_data', data);
  };

  static getToken = () => {
    return LocalStorage.getFromLocal('token');
  };
  static setToken = (token = null) => {
    LocalStorage.setToLocal('token', token);
  };

  static getLanguage = () => {
    return LocalStorage.getFromLocal('language');
  };

  static setLanguage = (language = 'vi') => {
    LocalStorage.setToLocal('language', language);
  };

  static setFakeOwnerId = (id) => {
    LocalStorage.setToLocal('doopage_fake_owner_id', parseInt(id));
  };

  static setUser = (data = {}) => {
    LocalStorage.setToLocal('user', data);
  };

  static getParentNodes = () => {
    return LocalStorage.getFromLocal('company/' + DV.company.id + '/parentNodes') || [];
  };

  static setParentNodes = (data = null) => {
    LocalStorage.setToLocal('company/' + DV.company.id + '/parentNodes', data);
  };

  static getPermanentIP = () => {
    return LocalStorage.getFromLocal('ipInfo_v2') || {};
  };

  static setPermanentIP = (data = null) => {
    LocalStorage.setToLocal('ipInfo_v2', data);
  };
}
