import { Accordion, Checkbox, ChipInput, Popup, Select, Space } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import React, { memo } from 'react';
import SelectScenario from '../../components/SelectScenario';

const SettingAction = ({ onChange, config, actionData,updateActionData }) => {
  const { keywords, exclude_keywords, message = {}, fallback } = config;

  return (
    <Popup
      label={<i className="fad fa-cog" />}
      buttonProps={{
        justIcon: true,
        color: 'info',
        size: 'small',
        helperText: t`Cài đặt`,
      }}>
      <div style={{ width: 320 }}>
        {/*<div className="p-4 pb-0">*/}
        {/*  <Space direction="vertical">*/}
        {/*    <ChipInput*/}
        {/*      fullWidth*/}
        {/*      placeholder={t`Nhập keyword`}*/}
        {/*      label={t`Có chứa keyword:`}*/}
        {/*      onChange={(value) => onChange('keywords', value)}*/}
        {/*      value={keywords ?? []}*/}
        {/*      chipProps={{*/}
        {/*        outlined: false,*/}
        {/*        rounded: true,*/}
        {/*        color: 'success',*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    <ChipInput*/}
        {/*      fullWidth*/}
        {/*      placeholder={t`Nhập keyword`}*/}
        {/*      label={t`Không chứa keyword:`}*/}
        {/*      onChange={(value) => onChange('exclude_keywords', value)}*/}
        {/*      value={exclude_keywords ?? []}*/}
        {/*      chipProps={{*/}
        {/*        outlined: false,*/}
        {/*        rounded: true,*/}
        {/*        color: 'danger',*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Space>*/}
        {/*</div>*/}
        <Accordion
          title={t`Nâng cao`}
          defaultExpanded={['continue', 'other_scenario'].includes(fallback?.action)}>
          <Space direction="vertical">
            <Select
              label={t`Nếu thực hiện thất bại`}
              options={[
                { id: 'stop', name: t`Dừng kịch bản` },
                { id: 'continue', name: t`Chạy tiếp kịch bản` },
                {
                  id: 'other_scenario',
                  name: t`Kích hoạt kịch bản khác`,
                },
              ]}
              value={fallback?.action ?? 'stop'}
              onChange={(e) => onChange('fallback', { ...fallback, action: e.target.value })}
            />
            {fallback?.action === 'other_scenario' && (
              <SelectScenario
                value={fallback?.scenario_id}
                onChange={(value) => onChange('fallback', { ...fallback, scenario_id: value })}
              />
            )}
          </Space>
        </Accordion>
        <Accordion title={t`Facebook`} className="-mt-5" defaultExpanded={!!message?.type}>
          <Space direction="vertical">
            <Select
              label={t`Mục đích`}
              options={[
                {
                  id: 'response-',
                  name: t`Phản hồi (Giới hạn 24h)`,
                  description: t`Trả lời tin nhắn thắc mắc của khách hàng.`,
                },
                {
                  id: 'update-',
                  name: t`Quảng cáo (Giới hạn 24h)`,
                  description: t`Chủ động gửi tin giới thiệu thông tin sản phẩm.`,
                },
                {
                  id: 'message_tag-event_update',
                  name: t`Nhắc nhở, thay đổi lịch`,
                  description: t`Nhắc nhở khách về lịch hay sự kiện mà khách hàng tham gia, hay thông báo thay đổi lịch, cập nhật địa điểm.`,
                },
                {
                  id: 'message_tag-purchase_update',
                  name: t`Báo tình trạng đơn hàng`,
                  description: t`Thông báo hay xác nhận đơn hàng, thanh toán, tình trạng giao hàng hay có vấn đề đối với đơn hàng.`,
                },
                {
                  id: 'message_tag-account_update',
                  name: t`Báo sự cố`,
                  description: t`Thông báo có sự cố về tài khoản của khách hàng hay có sự cập nhật bắt buộc về tài khoản của khách hàng.`,
                },
              ]}
              value={message?.type ? `${message?.type}-${message?.tag || ''}` : ''}
              onChange={(e) => {
                const [type, tag] = e.target.value?.split('-');
                onChange('message', { type, tag });
              }}
            />
          </Space>
        </Accordion>

        <div className={"px-[15px] pb-[15px]"}>
          <Checkbox color="primary" checked={actionData?.input?.active} onChange={e => {
            updateActionData('input', {active: e.target.checked});
          }}>
           <div className="text-base">  {t`Tạm dừng đến khi khách hàng trả lời`}</div>
          </Checkbox>
        </div>
      </div>
    </Popup>
  );
};

export default memo(SettingAction);
