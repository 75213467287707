import {
  Accordion,
  Button,
  ChipInput,
  Select,
  showInfoSnackbar,
  Space,
  Switch,
} from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import { useQuery } from '@redux-requests/react';
import { getFanpageQr } from 'api/api';
import { trans } from 'config/LinguiConfig';
import { handleApi } from 'helper/helper';
import React, { memo, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getChannels } from 'redux/action/apiAction';

const MmeLinkCondition = ({ channels: selectedChannels, referral, onChange, show = true }) => {
  const { active = false, exclude_keywords = [], keywords = [] } = referral;
  const [ref, setRef] = useState();
  const [page, setPage] = useState({});
  const { data: channels } = useQuery({ type: getChannels });

  const pageOptions = useMemo(() => {
    return (
      channels?.page?.reduce?.((arr, item) => {
        const { name, avatar } = item;
        const id = item?.id;

        if (selectedChannels.some((el) => el?.id.toString() === id.toString()))
          arr.push({
            ...item,
            id,
            name,
          });

        return arr;
      }, []) ?? []
    );
  }, [channels, selectedChannels]);

  const update = (path, value) => {
    onChange(`trigger_conditions.referral.${path}`, value);
  };

  const handleDownloadQr = async () => {
    const { success, data } = await handleApi(getFanpageQr(page.id, `?direct=0&ref=${ref}`));
    if (success && data.url) {
      window.open(data.url);
    }
  };

  if (!show) return null;
  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <i className="fad fa-hand-point-up mr-3 text-2xl" />
            {t`Khi vào m.me/<page>?ref=<ref>`}
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('active', !active)}>
        <Space direction="vertical">
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 30 }} className="text-lg opacity-70">
              {t`Với`}
            </div>
            <ChipInput
              fullWidth
              label={t`Ref có chứa keywords:`}
              placeholder={t`Ref phải có chứa một trong những keyword này`}
              onChange={(value) => {
                setRef(undefined);
                update('keywords', value);
              }}
              value={keywords ?? []}
              chipProps={{
                outlined: false,
                rounded: true,
                color: 'success',
              }}
            />
          </div>
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 30 }} className="text-lg opacity-70">
              {t`Và`}
            </div>
            <ChipInput
              fullWidth
              label={t`Ref không chứa keywords:`}
              placeholder={t`Ref không chứa một trong những keyword này`}
              onChange={(value) => update('exclude_keywords', value)}
              value={exclude_keywords ?? []}
              chipProps={{
                outlined: false,
                rounded: true,
                color: 'danger',
              }}
            />
          </div>
          <div className="flex items-center">
            <span>Mẫu: https://m.me/</span>
            <Select
              style={{ flex: 1 }}
              options={pageOptions}
              value={page.id}
              onChange={(e) => setPage(pageOptions.find((item) => item.id === e.target.value))}
            />
            <span>?ref=</span>
            <Select
              style={{ width: 80 }}
              disabled={keywords.length === 0}
              options={keywords}
              value={ref}
              onChange={(e) => setRef(e.target.value)}
            />
            <CopyToClipboard
              className="opacity-70"
              text={`https://m.me/${page.fb_id}?ref=${encodeURIComponent(ref)}`}
              onCopy={() => showInfoSnackbar(t`Đã sao chép m.me link`)}>
              <Button
                justIcon
                helperText="Sao chép m.me"
                color="transparent"
                disabled={!page.fb_id || !ref}>
                <i className="fad fa-copy" />
              </Button>
            </CopyToClipboard>
            <Button
              className="opacity-70"
              justIcon
              color="transparent"
              helperText={t`Tải QR`}
              onClick={handleDownloadQr}
              disabled={!page}>
              <i className="fad fa-qrcode" />
            </Button>
          </div>
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(MmeLinkCondition);
