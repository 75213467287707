import { Input, Select, Space } from '@doopage/react-ui-kit';
import { t, Trans } from '@lingui/macro';
import React, { memo } from 'react';
import SuggestionInput from 'component/SuggestionInput';

const TextAction = ({
  showInputType,
  title,
  subtitle,
  onChange,
  showInputTitle,
  hide,
  updateActionData,
  header,
  input,
}) => {
  if (hide) return null;

  return (
    <div className="p-3">
      {header && <div className="mb-2 font-bold">{header}</div>}
      <Space direction="vertical" spaceHeight={10}>
        {showInputTitle && (
          <div className="py-2 px-3 w-full rounded-md font-bold bg-gray-200 hover:bg-gray-300">
            <SuggestionInput
              placeholder={t`Nhập tiêu đề`}
              defaultValue={title}
              onChange={(value) => {
                onChange('title', value);
              }}
            />
          </div>
        )}
        <div className="w-full">
          <div className="py-2 px-3 w-full rounded-md bg-gray-200 hover:bg-gray-300 text-sm">
            <SuggestionInput
              placeholder={t`Nhập nội dung`}
              defaultValue={subtitle}
              onChange={(value) => {
                onChange('subtitle', value);
              }}
            />
          </div>
        </div>
        {showInputType && (
          <div className="w-full">
            <Select
              fullWidth
              label={t`Yêu cầu khách hàng nhập`}
              options={[
                { id: 'text', name: t`Văn bản` },
                { id: 'phone', name: t`Số điện thoại` },
                { id: 'email', name: t`Email` },
                { id: 'location', name: t`Vị trí` },
              ]}
              value={input?.input_type ?? 'text'}
              onChange={(e) =>
                updateActionData('input', {
                  ...input,
                  input_type: e.target.value,
                })
              }
            />
            <div className="flex items-end mt-3">
              <Trans>Lưu vào: customer.</Trans>
              <Input
                value={input?.customer_property ?? ''}
                onChange={(e) =>
                  updateActionData('input', {
                    ...input,
                    customer_property: e.target.value,
                  })
                }
              />
            </div>
          </div>
        )}
      </Space>
    </div>
  );
};

export default memo(TextAction);
