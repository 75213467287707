import { t } from "@lingui/macro";
import { createDriver } from "@redux-requests/axios";
import { handleRequests } from "@redux-requests/core";
import * as Sentry from "@sentry/browser";
import axios from "axios";
import { genLogColor, isDebug } from "config/LogConfig";
import { getBuildNumber } from "helper/helper";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { createLogger } from "redux-logger";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";
import createFetchReducer from "redux/reducer/fetchReducer";
import createModalReducer from "redux/reducer/modalReducer";
import common from "redux/reducer/oldReducer";
import scenarioFlow from "redux/reducer/scenarioFlowReducer";
import credits from "screen/Channel/Credit/redux/creditsReducer";
import statistic from "screen/Statitic/redux/StatisticReducer";
import { Types } from "redux/type";
import broadcastReducer from "screen/BroadcastDetail/redux/broadcastReducer";
import subscriptionReducer from "screen/SubscriptionManagementV2/redux/subscriptionReducer";
import { dangerColor, infoColor, successColor } from "variables/color";
import DV from "variables/DV";
import { enableReduxPersist } from "variables/staticValue";

const mapLanguage = { vi: "vi-vn", en: "en-us" };
const onRequest = (request, requestAction, store) => {
  const { type, showSpinner: spinner } = requestAction;
  const { headers } = request;

  console.log(...genLogColor(`== Fetch ${type}`, infoColor), request);
  console.log(request.url);

  if (spinner) {
    // showSpinner();
  }

  return {
    ...request,
    headers: {
      "Accept-Language": mapLanguage[store.getState().language],
      "X-DooPage-Build": getBuildNumber(),
      "Content-Type": "application/json; charset=utf-8",
      Authorization: `Bearer ${DV.token}`,
      ...headers
    }
  };

  return request;
};

const onSuccess = (response, requestAction, store) => {
  const { type, showSpinner } = requestAction;
  console.log(...genLogColor(`== Fetch Success ${type}`, successColor), response);
  console.log(requestAction.request.url);

  if (showSpinner) {
    // hideSpinner();
  }

  return response;
};

const onError = async (error, requestAction, store) => {
  const { type, showSpinner, showError = true } = requestAction;
  const { response, message, errors } = error;
  console.log(...genLogColor(`== Fetch Fail ${type}`, dangerColor), {
    response,
    message,
    errors
  });
  console.log(requestAction.request.url);

  if (showSpinner) {
    // hideSpinner();
  }

  Sentry.captureException(error);

  try {
    if (response) {
      const { status, data } = response;

      // Unauthorite
      if (
        !window.location.href.includes("/login") &&
        (requestAction.request.url.includes("users/me/") || status === 401)
      )
      {
        window.location.href = `/login`;
      }

      if (data) {
        const { error, detail, message } = data;
        if (detail) throw detail;
        if (error) throw error;
        if (message) throw message;
      }
      if (status >= 500)
        throw t`Có lỗi không mong muốn xảy ra, vui lòng thử tải lại trang hoặc liên hệ với chúng tôi để được giúp đỡ.`;
      if (status >= 400)
        throw t`Có lỗi do truy cập vào tài nguyên không được cấp phép, vui lòng tải lại trang hoặc liên hệ với chúng tôi để được giúp đỡ.`;
    }

    if (message) {
      if (error.message.includes("timeout of"))
        throw t`Không thể kết nối với máy chủ, vui lòng kiểm tra lại đường truyền internet và thử lại.`;

      if (message.toString().includes("Network Error")) throw t`Lỗi kết nối, vui lòng thử lại`;

      if (message.toString().toLowerCase().includes("resources"))
        throw t`Máy tính của bạn không đủ tài nguyên để xử lý, vui lòng tắt bớt các ứng dụng không cần thiết và thử lại`;

      throw message;
    }

    if (error.toString().includes("Network Error")) throw t`Lỗi kết nối, vui lòng thử lại`;
    throw error;
  } catch (e) {
    if (showError) {
      //showAlert({message: e.toString()});
    }
    throw e;
  }
};

const { requestsReducer, requestsMiddleware } = handleRequests({
  driver: createDriver(axios),
  onRequest,
  onError,
  onSuccess
});

const persistConfig = {
  key: "root",
  storage
};

const reducer = combineReducers({
  common,
  credits,
  statistic,
  scenarioFlow,
  requests: requestsReducer,
  [Types.detailScenario]: createFetchReducer(Types.detailScenario),
  [Types.selectSegment]: createFetchReducer(Types.selectSegment),
  [Types.selectChannel]: createFetchReducer(Types.selectChannel),
  [Types.selectTag]: createFetchReducer(Types.selectTag),
  [Types.selectProduct]: createFetchReducer(Types.selectProduct),
  [Types.selectWebsite]: createFetchReducer(Types.selectWebsite),
  [Types.selectZaloOA]: createFetchReducer(Types.selectZaloOA),
  [Types.selectABM]: createFetchReducer(Types.selectABM),
  [Types.selectGBM]: createFetchReducer(Types.selectGBM),
  [Types.broadcastQualifiedPeople]: createFetchReducer(Types.broadcastQualifiedPeople),
  [Types.detailBroadcast]: broadcastReducer,
  [Types.subscription]: subscriptionReducer,
  [Types.pricingPolicy]: createFetchReducer(Types.pricingPolicy),
  [Types.recentWebhookErrors]: createFetchReducer(Types.broadcastQualifiedPeople),
  [Types.znsTemplateButtons]: createFetchReducer(Types.znsTemplateButtons),

  [Types.modalCoupon]: createModalReducer(Types.modalCoupon),
  [Types.modalCheckout]: createModalReducer(Types.modalCheckout),
  [Types.modalPaymentInfo]: createModalReducer(Types.modalPaymentInfo),
  [Types.modalInstallDooPageAppForGroup]: createModalReducer(Types.modalInstallDooPageAppForGroup),
  [Types.modalCustomPlan]: createModalReducer(Types.modalCustomPlan),
  [Types.modalCropImage]: createModalReducer(Types.modalCropImage),
  [Types.modalWebsiteSetting]: createModalReducer(Types.modalWebsiteSetting)
});

const persistedReducer = persistReducer(persistConfig, reducer);
const middleware = isDebug()
  ? applyMiddleware(thunk, createLogger(), ...requestsMiddleware)
  : applyMiddleware(thunk, ...requestsMiddleware);

const store = createStore(isDebug() && enableReduxPersist ? persistedReducer : reducer, middleware);
export const persistor = persistStore(store);
export default store;
