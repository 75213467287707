import LocalStorage from '../../config/LocalStorage';
import { compareStatus } from '../../helper/helper';
import DV from '../../variables/DV';
import { ALERT_ERROR_SHOW_TIME, ALERT_SHOW_TIME } from '../../variables/staticValue';
import { Types } from '../type';

export const loading = (type) => {
  return {
    type,
  };
};

export const getSuccess = (payload, type) => {
  return {
    type: type + '_SUCCESS',
    payload,
  };
};

export const getError = (payload, type) => {
  return {
    type: type + '_FAIL',
    payload,
  };
};

export const getData = (func, type) => {
  return (dispatch) => {
    dispatch(loading(type));
    return func()
      .then((data) => {
        if (data instanceof Array) data = { data };
        if (data.data instanceof Array && type !== Types.GET_SUBSCRIPTIONS)
          data.data = data.data.sort(compareStatus);
        dispatch(getSuccess(data, type));
      })
      .catch((errorMsg) => {
        dispatch(getError(errorMsg?.error ?? errorMsg, type));
      });
  };
};

export const clearData = (key, toLoading = false) => {
  return (dispatch) => {
    dispatch({ type: Types.CLEAR_DATA, key, toLoading });
  };
};

//todo: show notification/alert
export const hideNotify = () => {
  return {
    type: Types.HIDE_NOTYFY,
  };
};

export const showNotify = (msg = '', color = 'success', second = -1) => {
  if (second < 0) {
    second = color === 'danger' ? ALERT_ERROR_SHOW_TIME : ALERT_SHOW_TIME;
  }
  return (dispatch) => {
    dispatch({ type: Types.SHOW_NOTYFY, msg, color });
    setTimeout(() => {
      dispatch(hideNotify());
    }, second * 1000);
  };
};

export const handleConnection = (payload) => {
  return (dispatch) => {
    dispatch({ type: Types.SET_CONNECTION, payload });
  };
};

export const changeLanguage = (payload) => {
  DV.language = payload;
  LocalStorage.setLanguage(payload);
  return (dispatch) => {
    dispatch({ type: Types.SET_LANGUAGE, payload });
  };
};

export const changeCompany = (payload) => {
  DV.company = payload;

  LocalStorage.setCompany(payload);
  return (dispatch) => {
    dispatch({ type: Types.SET_COMPANY, payload });
  };
};
