import { t } from '@lingui/macro';
import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import ListConversationStarter from './ListConversationStarter';
import { Input } from '@doopage/react-ui-kit';

const Conversational = (props) => {
  const {
    welcomeMessage,
    onChangeWelcomeMessage,
    offlineMessage,
    onChangeOfflineMessage,
    privacyPolicy,
    onChangePrivacyPolicy,
    conversationStarters,
    onChangeConversationStarters,
  } = props;
  return (
    <>
      <Grid item sm={12}>
        <Input
          label={t`Tin nhắn chào`}
          type="text"
          size="small"
          value={welcomeMessage || ''}
          onChange={(e) => onChangeWelcomeMessage(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item sm={12}>
        <Input
          label={t`Tin nhắn ngoại tuyến`}
          type="text"
          size="small"
          value={offlineMessage || ''}
          onChange={(e) => onChangeOfflineMessage(e.target.value)}
          fullWidth
        />
      </Grid>
      <Grid item sm={12}>
        <Input
          label={t`Chính sách`}
          placeholder="https://your-website.com/privacy-policy"
          type="text"
          size="small"
          value={privacyPolicy || ''}
          onChange={(e) => onChangePrivacyPolicy(e.target.value)}
          fullWidth
        />
      </Grid>
      <ListConversationStarter
        value={conversationStarters || []}
        onChange={onChangeConversationStarters}
        style={{
          marginTop: 10,
        }}
      />
    </>
  );
};

export default Conversational;
