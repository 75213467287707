import { t, Trans } from '@lingui/macro';
import ModalWrapper from 'MUI_Kit/components/Wrapper/ModalWrapper';
import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { closeModal } from 'redux/action/modalAction';
import { Types } from 'redux/type';
import { getValue } from 'variables/utils';
import { useApi } from '../../../hook/utils';
import { showErrorSnackbar, Space } from '@doopage/react-ui-kit';
import Button from '../../../MUI_Kit/components/CustomButtons/Button';
import ViewList from '@material-ui/icons/ViewList';
import { updateFacebookGroup } from '../../../api/api';
import { trans } from '../../../config/LinguiConfig';

const type = Types.modalInstallDooPageAppForGroup;
const ModalInstallDooPageAppForGroup = () => {
  const { open, key, id, data, fb_id, onActiveSuccess } = useSelector(getValue(type));
  const dispatch = useDispatch();

  const history = useHistory();
  const { doFetch, loading } = useApi();

  const close = () => {
    dispatch(closeModal(type));
  };

  const renderFooter = () => {
    return (
      <Button
        color="info"
        loading={loading}
        onClick={async () => {
          const { error } = await doFetch(updateFacebookGroup(id, data));
          if (error) {
            showErrorSnackbar(error || trans(t`Có lỗi xảy ra`));
          } else {
            onActiveSuccess();
          }
        }}>
        <Trans>KÍCH HOẠT GROUP</Trans>
      </Button>
    );
  };

  return (
    <ModalWrapper
      key={key}
      keepMounted={false}
      fullWidth
      maxWidth={'sm'}
      open={open}
      type={type}
      title={<Trans>Group chưa được cài ứng dụng!</Trans>}
      loading={loading}
      footer={renderFooter()}>
      <div className="flex flex-col w-full">
        <Trans>Bạn cần cài đặt ứng dụng DooPage vào group trước khi kích hoạt được group.</Trans>
        <ul style={{ listStyle: 'disc', marginLeft: 20, marginTop: 10 }}>
          <Space direction={'vertical'} spaceHeight={10}>
            <li>
              <Trans>Bước 1: Truy cập</Trans>{' '}
              <a
                target={'_blank'}
                className="underline"
                href={` https://www.facebook.com/groups/${fb_id}/apps/store/category/?category=BUSINESS`}>
                {`https://www.facebook.com/groups/${fb_id}/apps/store/category/?category=BUSINESS`}
              </a>
            </li>
            <li>
              <Trans>
                Bước 2: Trong khung tìm kiếm ở cột bên trái (không phải khung tìm kiếm trên đầu
                trang), tìm ứng dụng DooPage.
              </Trans>
            </li>
            <li>
              <Trans>Bước 3: Nhấn vào ứng dụng DooPage và làm tiếp theo hướng dẫn.</Trans>
            </li>
            <li>
              <Trans>Bước 4: Nhấn vào nút bấm màu xanh dưới đây.</Trans>
            </li>
          </Space>
        </ul>
      </div>
    </ModalWrapper>
  );
};

export default memo(ModalInstallDooPageAppForGroup);
