export const PLATFORM_SUPPORT = [
  'fb',
  'personal_fb',
  'group_fb',
  'instagram',
  'sendo',
  'shopee',
  'email',
  'zalo',
  'zalo_oa',
  'pbx',
  'google_business',
  'website',
  'agent',
  'manual',
  'kiot_viet',
  // 'unknown',
];

export const PLATFORM_SUPPORT_AUTOMATION = [
  'fb',
  'zalo_oa',
  'website',
  'instagram',
  'manual',
  'google_business',
  'kiot_viet',
];
