import {t} from '@lingui/macro';
import {Select, Space} from '@doopage/react-ui-kit';
import SelectZaloOA from 'component/Select/SelectZaloOA';
import SelectFanpage from 'component/Select/SelectFanpage';
import SelectInstagram from 'component/Select/SelectInstagram';
import SelectTelegram from 'component/Select/SelectTelegram';
import React, {useEffect, useRef} from 'react';
import Grid from '@material-ui/core/Grid';
import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider, useDrag, useDrop} from 'react-dnd';
import SelectAppleBusiness from 'component/Select/SelectAppleBusiness';
import {useDispatch} from 'react-redux';
import SelectWebsite from '../../../../../component/Select/SelectWebsite';
import {AssetImage} from '../../../../../assets/Asset';
import {getAllChannels} from '../../../../../redux/action/apiAction';
import {useQuery} from '@redux-requests/react';

const mapChannel = {
  apple_business: {
    name: t`Apple Messages for Business`,
    SelectComponent: SelectAppleBusiness,
    icon: AssetImage.AppleLogo,
  },
  // google_business: {
  //   name: t`Google business`,
  //   SelectComponent: SelectGBM,
  //   icon: AssetImage.GBMIcon,
  // },
  website: {
    name: t`Website`,
    SelectComponent: SelectWebsite,
    icon: AssetImage.Web_image,
  },
  zalo_oa: {
    name: t`Zalo OA`,
    SelectComponent: SelectZaloOA,
    icon: AssetImage.Zalo_image,
  },
  fb: {
    name: t`Fanpage`,
    SelectComponent: SelectFanpage,
    icon: AssetImage.Facebook_image,
  },
  instagram: {
    name: t`Instagram`,
    SelectComponent: SelectInstagram,
    icon: AssetImage.Instagram_image,
  },
  telegram: {
    name: t`Telegram`,
    SelectComponent: SelectTelegram,
    icon: AssetImage.Telegram_image,
  },
};

const PlatformItem = ({data, onChange, movePlatform, index, hideSelect = false}) => {
  const {platform, id} = data;
  const {name, SelectComponent} = mapChannel[platform];
  const ref = useRef();
  const [, drop] = useDrop({
    accept: 'card-platform',
    hover(item, monitor) {
      if (!ref.current) return;

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) return;

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      movePlatform(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{isDragging}, drag] = useDrag({
    type: 'card-platform',
    item: {type: 'card-platform', id: platform, index},
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Grid
      container
      className="flex-center-row items-center cursor-pointer"
      ref={ref}
      style={{opacity}}>
      <Grid item xs={12} sm={6} md={3} className="flex flex-row items-center w-[200px]">
        <i className="fas fa-bars"/>
        <div className="text-[14px] ml-5">{name}:</div>
      </Grid>
      <Grid item xs={12} sm={6} md={9} className="flex flex-1 bg-red">
        {!hideSelect && <SelectComponent onChange={onChange} value={id || ''}/>}
      </Grid>
    </Grid>
  );
};


const getDefaultPlatformOptions = () => {
  return Object.keys(mapChannel).map((key) => {
    return {id: key, name: mapChannel[key].name, image: mapChannel[key].icon};
  });
};

const ChannelSetting = React.memo(
  ({updateNativeChannels, platforms, defaultPlatform, updateDefaultPlatform}) => {
    const {data, error, loading, pristine} = useQuery({type: getAllChannels});

    const updatePlatform = (index) => (value) => {
      const newPlatforms = [...platforms];
      if (index < newPlatforms.length) {
        newPlatforms[index] = {...newPlatforms[index], id: value?.id};
      } else {
        newPlatforms.push({...newPlatforms[index], id: value?.id});
      }
      updateNativeChannels(newPlatforms.filter(r => r.platform in mapChannel));
    };

    const movePlatform = async (dragIndex, hoverIndex) => {
      const [item] = platforms.splice(dragIndex, 1);
      platforms.splice(hoverIndex, 0, item);

      updateNativeChannels(platforms);

      // let orderPlatforms = [];
      //
      // for (let i = 0; i < platforms?.length; i++) {
      //   orderPlatforms.push({ ...platforms[i], ordinal_number: i + 1 });
      // }
    };

    const dispatch = useDispatch();

    useEffect(() => {
      if (loading || !!data) return;
      dispatch(getAllChannels());
    }, [loading, data]);

    const defaultPlatformOptions = getDefaultPlatformOptions();

    console.log('ChannelSetting', {
      platforms,
      defaultPlatform,
      defaultPlatformOptions
    });
    return (
      <div>
        <h4>{t`Đa kênh`}</h4>
        <div className="px-10">
          <DndProvider backend={HTML5Backend}>
            <Space direction={'vertical'} spaceHeight={10}>
              {platforms.map((item, index) => {
                if (!item.platform || !(item.platform in mapChannel) || item.platform === 'website') {
                  return null;
                }
                return (
                  <PlatformItem
                    key={item.platform}
                    data={item}
                    onChange={updatePlatform(index)}
                    movePlatform={movePlatform}
                    index={index}
                    hideSelect={item.platform === 'website'}
                  />
                );
              })}
            </Space>
          </DndProvider>
          <Select
            label={t`Kênh mặc định`}
            options={defaultPlatformOptions}
            value={defaultPlatform || ''}
            onChange={(e) => {
              updateDefaultPlatform(e.target.value);
            }}
            fullWidth
            style={{margin: 0, marginTop: 10}}
          />
        </div>
      </div>
    );
  },
);

export default ChannelSetting;
