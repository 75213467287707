import { Accordion, AutoComplete, showErrorSnackbar, Space, Switch } from '@doopage/react-ui-kit';
import { t, Trans } from '@lingui/macro';
import { Card } from '@material-ui/core';
import React, { memo, useEffect, useMemo, useState } from 'react';
import ChannelLogo from '../../../../assets/ChannelLogo';
import { useDispatch } from 'react-redux';
import { getInstagrams, updateInstagram } from '../../../../redux/action/apiAction';
import { useQuery } from '@redux-requests/react';
import { dangerColor } from '../../../../variables/color';

const IceBreakersInstagramCondition = ({ startedInstagram, onChange, show, channels }) => {
  const { active = false, button_hash = [], action } = startedInstagram;
  const dispatch = useDispatch();
  const { data } = useQuery({ type: getInstagrams });
  const instagrams = useMemo(() => data?.data ?? [], [data?.data]);
  const selectedInstagrams = useMemo(() => {
    return instagrams.reduce((arr, item) => {
      const isSelected = channels.some(
        (el) => el.platform === 'instagram' && `${item.id}` === `${el.id}`,
      );
      if (!isSelected) return arr;
      return [...arr, item];
    }, []);
  }, [instagrams, channels]);
  const [nonActivePage, setNonActivePage] = useState([]);
  const [iceBreakerOptions, setIceBreakerOptions] = useState([]);

  const update = (path, value) => {
    onChange(`trigger_conditions.started_instagram.${path}`, value);
  };

  useEffect(() => {
    if (active) {
      dispatch(getInstagrams());
    }
  }, [active, channels]);

  useEffect(() => {
    if (!action) return;

    let nonActivePage = [];
    let options = {};
    selectedInstagrams.forEach((item) => {
      const { ice_breakers } = item.messenger_config || {};

      if (!ice_breakers.active) nonActivePage.push(item);

      const { buttons = [] } = ice_breakers;
      buttons.forEach((btn) => {
        options[btn.hash] = btn;
      });
    });

    setNonActivePage(nonActivePage);
    setIceBreakerOptions(
      Object.keys(options).map((key) => ({
        id: key,
        name: options[key].title,
      })),
    );
  }, [instagrams, action]);

  const activeMessengerPageConfig = async (type) => {
    const results = await Promise.all(
      nonActivePage.map((item) => {
        item.messenger_config[type].active = true;
        return dispatch(updateInstagram(item.id, item));
      }),
    );

    if (results.some((item) => item.status !== 200)) {
      showErrorSnackbar('Có lỗi xảy ra vui lòng thử lại');
      return;
    }

    setNonActivePage([]);
  };

  const createStartedButton = async (text) => {
    const results = await Promise.all(
      selectedInstagrams.map((item) => {
        const { id, messenger_config = {} } = item;
        const { ice_breakers, started_button } = messenger_config;

        return dispatch(
          updateInstagram(id, {
            messenger_config: {
              ice_breakers: {
                ...ice_breakers,
                buttons: [...ice_breakers.buttons, { title: text }],
              },
              started_button,
            },
          }),
        );
      }),
    );

    if (results.some((item) => item.status !== 200)) {
      showErrorSnackbar('Có lỗi xảy ra vui lòng thử lại');
      return;
    }

    return results[0].data.messenger_config.ice_breakers.buttons.find((item) => item.title === text)
      .hash;
  };

  const renderError = () => {
    if (!nonActivePage.length) return null;

    const listPage = nonActivePage.map((item) => `"${item.name}"`).join(', ');
    return (
      <div style={{ color: dangerColor }}>
        <Trans>
          Trang {listPage} chưa cài đặt chọn gợi ý.{' '}
          <span
            className="underline cursor-pointer"
            onClick={() => activeMessengerPageConfig('ice_breakers')}>
            Khắc phục ngay
          </span>
        </Trans>
      </div>
    );
  };

  console.log(selectedInstagrams, nonActivePage);
  if (!show) return null;
  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <ChannelLogo.Instagram style={{ width: 20, height: 20 }} />
            <span className="ml-2">{t`Khi chọn gợi ý Instagram`} </span>
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => {
          update('active', !active);
          update('action', 'ice_breaker');
        }}>
        <Space direction="vertical">
          {nonActivePage.length === 0 && (
            <div className="flex items-end" style={{ marginRight: 8 }}>
              <div style={{ width: 50 }} className="text-lg opacity-70">
                {t`Chọn`}
              </div>
              <AutoComplete
                key={iceBreakerOptions.length || 'init'}
                allowCreate
                label={t`gợi ý`}
                placeholder={t`Vui lòng chọn gợi ý hoặc nhập gợi ý mới`}
                fullWidth
                options={iceBreakerOptions}
                value={button_hash}
                onChange={async (value, { inputValue }) => {
                  if (inputValue) {
                    const hash = await createStartedButton(inputValue);
                    update('button_hash', hash);
                  } else update('button_hash', value);
                }}
                setCreateText={(value) => <Trans>Thêm {value}</Trans>}
              />
            </div>
          )}
          {renderError()}
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(IceBreakersInstagramCondition);
