import { t } from '@lingui/macro';
import React, { memo } from 'react';
import { AssetImage } from '../assets/Asset';
import { trans } from '../config/LinguiConfig';
import classNames from 'classnames';

const EmptyUI = ({ title, action, imageStyle }) => {
  return (
    <div className={classNames('content', ' flex-center-col')}>
      <img src={AssetImage.Empty} className="listdata-img" style={imageStyle} alt="img" />
      <div className="description-text mt-3">{title || t`Không có dữ liệu`}</div>
      {action?.()}
    </div>
  );
};

export default memo(EmptyUI);
