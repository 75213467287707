import loadable from '@loadable/component';
import LoadableBaseScreen from 'component/LoadableScreen/LoadableBaseScreen';
import LoadableLoading from 'component/LoadableScreen/LoadableLoading';
import React from 'react';

export const Loadable_BroadcastDetail = loadable(
  () => import('screen/BroadcastDetail/BroadcastDetail'),
  {
    fallback: LoadableBaseScreen,
  },
);
export const Loadable_Broadcast = loadable(() => import('screen/Broadcast/Broadcast'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Company = loadable(() => import('screen/Company/Company'), {
  fallback: LoadableLoading,
});
export const Loadable_JoinCompany = loadable(() => import('screen/Company/JoinCompany'), {
  fallback: LoadableLoading,
});
export const Loadable_Attribute = loadable(() => import('screen/Channel/Attribute'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Center = loadable(() => import('screen/Channel/Center'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Customer = loadable(() => import('screen/Channel/Customer'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Empty = loadable(() => import('screen/Channel/Empty'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Fanpage = loadable(() => import('screen/Channel/Fanpage/Fanpage'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Channel = loadable(() => import('screen/Channels/Channel'), {
  fallback: LoadableBaseScreen,
});

export const Loadable_Applications = loadable(() => import('screen/Applications/Applications'), {
  fallback: LoadableBaseScreen,
});

export const Loadable_KiotVietSetting = loadable(
  () => import('screen/Applications/KiotVietSetting'),
  {
    fallback: LoadableBaseScreen,
  },
);

export const Loadable_Group = loadable(() => import('screen/Channel/Group/Group'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Instagram = loadable(() => import('screen/Channel/Instagram/Instagram'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Order = loadable(() => import('screen/Order/Order'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Payment = loadable(() => import('screen/Payment/Payment'), {
  fallback: LoadableBaseScreen,
});

export const Loadable_SubscriptionManagementV2 = loadable(
  () => import('screen/SubscriptionManagementV2/SubscriptionManagement'),
  {
    fallback: LoadableBaseScreen,
  },
);
export const Loadable_PaymentHistory = loadable(() => import('screen/Channel/PaymentHistory'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Credit = loadable(() => import('screen/Channel/Credit'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Personal = loadable(() => import('screen/Channel/Personal'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_PickupAddress = loadable(() => import('screen/Channel/PickupAddress'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Segment = loadable(() => import('screen/Segment/Segment'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Tag = loadable(() => import('screen/Channel/Tag'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Settings = loadable(() => import('screen/Setting/Settings'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_ShipmentServices = loadable(() => import('screen/Channel/ShipmentServices'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Shopee = loadable(() => import('screen/Channel/Shopee'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Sendo = loadable(() => import('screen/Channel/Sendo'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Email = loadable(() => import('screen/Channel/Email'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_PersonalZalo = loadable(() => import('screen/Channel/PersonalZalo'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Staff = loadable(() => import('screen/Channel/Staff'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Agent = loadable(() => import('screen/Channel/Agent/Agent'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Statistic = loadable(() => import(`screen/Statitic/Statistic.js`), {
  fallback: LoadableBaseScreen,
});
export const Loadable_GBM = loadable(() => import('screen/Channel/GBM/GBM'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_GBMLocations = loadable(() => import('screen/Channel/GBM/GBMLocation'), {
  fallback: LoadableLoading,
});

export const Loadable_Graphs = loadable(() => import(`screen/Statitic/components/graphsScreen`), {
  fallback: LoadableBaseScreen,
});
export const Loadable_DetailChart = loadable(
  () => import('screen/Statitic/Detail/DetailChart.jsx'),
  {
    fallback: LoadableBaseScreen,
  },
);
export const Loadable_SyncProducts = loadable(() => import('screen/Channel/SyncProducts'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Website = loadable(() => import('screen/Channel/Website/Website'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_ZaloOA = loadable(() => import('screen/Channel/ZaloOA'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Product = loadable(() => import('screen/Product/Product'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Variant = loadable(() => import('screen/Product/Variant'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_AddQuickReply = loadable(() => import('screen/QuickReply/AddQuickReply'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_QuickReply = loadable(() => import('screen/QuickReply/QuickReply'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_ScenarioDetail = loadable(
  () => import('screen/ScenarioDetail/ScenarioDetail.jsx'),
  {
    fallback: LoadableBaseScreen,
  },
);
export const Loadable_Scenario = loadable(() => import('screen/ScenarioFlow/ScenarioFlow.jsx'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Scenario_deprecated = loadable(() => import('screen/Scenario/Scenario.jsx'), {
  fallback: LoadableBaseScreen,
});
export const Loadable_Root = loadable(() => import('screen/RootScreen'), {
  fallback: LoadableLoading,
});
export const Loadable_Loading = loadable(() => import('screen/Loading/Loading'), {
  fallback: LoadableLoading,
});

export const Loadable_Login = loadable(() => import('screen/Login/Login'), {
  fallback: LoadableLoading,
});

export const Loadable_Public_Subscription = loadable(
  () => import('screen/SubscriptionManagementV2/PublicSubscription'),
  {
    fallback: LoadableLoading,
  },
);

export const Loadable_PaymentMethod = loadable(() => import('screen/Channel/PaymentMethod'), {
  fallback: LoadableBaseScreen,
});

export const Loadable_PBX = loadable(() => import('screen/Channel/PBX/PBX'), {
  fallback: LoadableLoading,
});

export const Loadable_CallAgents = loadable(() => import('screen/Channel/PBX/CallAgent'), {
  fallback: LoadableLoading,
});

export const Loadable_Manual_Channels = loadable(() => import('screen/Channel/ManualChannel'), {
  fallback: LoadableLoading,
});

export const Loadable_Integrate_Application = loadable(
  () => import('screen/Applications/Applications'),
  {
    fallback: LoadableLoading,
  },
);

export const Loadable_Integrate_API = loadable(
  () => import('screen/Channel/IntegrateAPI/IntegrateAPI'),
  {
    fallback: LoadableLoading,
  },
);

export const Loadable_Zns = loadable(() => import('screen/Channel/ZnsTemplates'), {
  fallback: LoadableLoading,
});

export const Loadable_Apple_Business = loadable(
  () => import('screen/Channel/AppleBusiness/AppleBusiness'),
  {
    fallback: LoadableBaseScreen,
  },
);

export const Loadable_OAuth2 = loadable(() => import('screen/OAuth2/OAuth2Screen'), {
  fallback: LoadableBaseScreen,
});
