import React from 'react';
import BaseModal from '../../../component/BaseComponent/BaseModal';
import Routes from 'router/routes';
import { AssetImage } from '../../../assets/Asset';
import 'css/classes.css';
import { handleApi, toCurrency } from '../../../helper/helper';
import { trans } from '../../../config/LinguiConfig';
import { t } from '@lingui/macro';
import Button from '../../../MUI_Kit/components/CustomButtons/Button';
import { updateSubscription } from '../../../api/api';
import DV from '../../../variables/DV';
import LocalStorage from "../../../config/LocalStorage";
import {withRouter} from 'react-router';

class ThanksModal extends BaseModal {
  constructor(props) {
    super(props);
    this.showCloseButton = false;
    this.disableBackdropClick = true;
    const params = this.getDataFromRouter();
    this.maxWidth = 'md';
    // if (_.isEmpty(params)) this.close();
    this.state = {
      open: true,
      step: 1,
      ...params,
    };
  }

  goToStep2 = async () => {
    await this.setState({ step: 2 });
    if (this.props.history) {
      this.props.history.replace({
        pathname: Routes.subscriptions.thanks2.path,
        state: {isModal: true, ...this.state},
        hash: `#${this.state.finalValue}`,
      });
      handleApi(updateSubscription(this.state.id, {is_open_thanks_dialog: false}));
    }
  };

  handleClose = () => {
    const { currentPath } = this.state
    this.setState({ open: false });
    LocalStorage.setToLocal('hideThanksModal', true)
    if (this.props.history) {
      if(currentPath) {
        this.props.history.replace(currentPath)
      } else {
        this.props.history.goBack();
      }
    }
  };

  renderContent() {
    const { step, finalValue } = this.state;
    if (step === 1) {
      return (
        <>
          <div className={'premium-text'}>
            {t`Bạn vừa kích hoạt thành công gói cước có giá trị:`}
          </div>
          <div id={'premium-value'} data-premium-value={finalValue} className={'premium-value'}>
            {toCurrency(finalValue)}
          </div>

          <Button color="info" onClick={this.goToStep2} className={'premium-btn'}>
            {t`Tiếp tục`}
          </Button>
        </>
      );
    }
    if (step === 2) {
      return (
        <>
          <div className={'premium-text'}>
            {t`Chân thành cảm ơn bạn đã tin tưởng và sử dụng dịch vụ của chúng tôi!`}
          </div>
          <Button color="info" onClick={this.handleClose} className={'premium-btn'}>
            {t`Đóng`}
          </Button>
        </>
      );
    }
  }

  renderBody() {
    return (
      <div className={'flex-center-col'} style={{ padding: 10 }}>
        <div className={'flex-center-row'} style={{ marginBottom: 20 }}>
          <img
            src={AssetImage.PremiumIcon}
            style={{ width: 64, height: 64, margin: 10 }}
            alt="img"
          />
          <div className={'premium-title bold'}>Premium</div>
        </div>
        {this.renderContent()}
      </div>
    );
  }
}

export default withRouter(ThanksModal);
