import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import { updateCustomer } from 'api/api.js';
import AddressInput from 'component/AddressInput/AddressInput.js';
import moment from 'moment';
import React from 'react';
import { getSegments } from 'api/api';
import { trans } from 'config/LinguiConfig';
import { handleApi } from 'helper/helper';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomDateTime from 'MUI_Kit/components/CustomDate/CustomDateTime';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import Routes from 'router/routes';
import { xPath } from 'testingClasses';
import DV from 'variables/DV';
import Avatar from '../../Avatar';
import BaseModal from '../../BaseComponent/BaseModal';
import CustomerHistory from './components/CustomerHistory/CustomerHistory';
import SegmentSelect from './components/SegmentSelect';
import _ from 'lodash';

class CustomerModal extends BaseModal {
  constructor(props) {
    super(props);
    let params = this.getDataFromRouter();
    if (_.isEmpty(params)) this.redirectTo(Routes.customer.path);
    let {
      id,
      full_name,
      phone,
      date,
      fb_profile_link,
      address,
      note,
      tags,
      segment,
      birthday,
      ...rest
    } = params;
    let province = address && address.province ? address.province : null;
    let district = address && address.district ? address.district : null;
    let ward = address && address.ward ? address.ward : null;

    this.state = {
      id,
      open: true,
      title: t`Thông tin khách hàng`,
      provinceData: [],
      districtData: [],
      wardData: [],
      name: full_name || '',
      phone: phone || '',
      date: date || new Date(),
      fbProfile: fb_profile_link || '',
      address: address && address.address ? address.address : '',
      province,
      district,
      ward,
      note: note || '',
      tags: tags,
      segment:
        segment && Object.keys(segment).length > 0
          ? segment
          : {
              id: -1,
              name: 'Chưa xác định',
              color: '#999999',
            },
      birthday,
      ...rest,
    };

    this.goPathWhenClose = Routes.customer.path;
    // this.maxWidth = 'lg';
  }

  async componentDidMount() {
    let res = await handleApi(getSegments());
    if (res.success) this.setState({ segmentData: res.data });
  }

  handleSelectedChange = (name) => async (event) => {
    this.setState({
      [name]: event,
    });
  };

  getTitle() {
    return t`Thông tin khách hàng`;
  }

  renderBody = () => {
    const { name, avatar, segmentData, segment } = this.state;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} className="flex-start-row">
          <Avatar user={{ avatar, name }} size={85} margin={false} />
          <div style={{ marginLeft: 20, width: '50%' }}>
            <CustomInput
              labelText={t`Họ tên`}
              inputProps={{
                type: 'text',
                value: name || '',
                onChange: this.handleChange('name'),
              }}
            />
            <SegmentSelect
              segments={segmentData}
              segment={segment}
              updateSegment={this.handleSelectedChange('segment')}
              isTookThisInbox={true}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          {this.renderBodyLeft()}
        </Grid>
        {/*<Grid item xs={8}>*/}
        {/*    {this.renderBodyRight()}*/}
        {/*</Grid>*/}
      </Grid>
    );
  };

  renderBodyRight = () => {
    return <CustomerHistory />;
  };

  renderBodyLeft = () => {
    const { ward, district, province, phone, birthday, fbProfile, note, address } = this.state;

    return (
      <Grid container>
        <Grid item xs={12}>
          <CustomInput
            labelText={t`Số điện thoại`}
            inputProps={{
              type: 'text',
              value: phone || '',
              onChange: this.handleChange('phone'),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomDateTime
            label={t`Ngày sinh`}
            onChange={(value) => this.handleChange('birthday')(value)}
            value={birthday ? moment(birthday) : ''}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            labelText={t`Facebook Profile`}
            inputProps={{
              type: 'text',
              value: fbProfile || '',
              onChange: this.handleChange('fbProfile'),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <AddressInput
            ref={(ref) => (this.addressInput = ref)}
            data={{ address, province, district, ward }}
            allowEmpty={true}
          />
        </Grid>
        <Grid item xs={12}>
          <CustomInput
            labelText={t`Ghi chú`}
            inputProps={{
              value: note || '',
              onChange: this.handleChange('note'),
            }}
          />
        </Grid>
        {/*<Grid item xs={12}>*/}
        {/*    <CustomInput*/}
        {/*        labelText={(t`Nhãn khách hàng (Tag)`)}*/}
        {/*        inputProps={{*/}
        {/*            value: tag || '',*/}
        {/*            onChange: this.handleChange('tag'),*/}
        {/*        }}*/}
        {/*    />*/}
        {/*</Grid>*/}
      </Grid>
    );
  };

  renderFooter() {
    return [
      <Button
        className={xPath.element.submit_button}
        loading={this.state.loading}
        onClick={this.update}
        color="info">
        {t`Hoàn tất`}
      </Button>,
    ];
  }

  validateData = () => {
    let { name } = this.state;
    if (name.length < 3) {
      DV.showNotify(t`Họ tên phải có ít nhất 3 ký tự`, 'danger');
      return false;
    }
    return this.addressInput.validate();
  };

  update = async () => {
    let { name, phone, birthday, note, fbProfile, tags, id, segment, ward } = this.state;
    if (this.validateData()) {
      let payload = {
        address: this.addressInput.getData(),
        phone,
        full_name: name,
        birthday: birthday ? moment(birthday).format('YYYY-MM-DD') : birthday,
        note,
        fb_profile_link: fbProfile,
        tags,
        segment: segment.id < 0 ? null : segment.id,
      };
      this.setState({ loading: true });
      let { success, error } = await handleApi(updateCustomer(id, payload));
      this.setState({ loading: false });
      if (success) {
        DV.showNotify(t`Cập nhật thành công`);
        this.close(true);
      } else {
        DV.showNotify(error, 'danger');
      }
    }
  };
}

export default CustomerModal;
