import { Trans } from '@lingui/macro';
import React from 'react';
import { formatVND } from '../../../variables/utils';

const ToolTipChannelPerformance = ({ item }) => {
  const { x, y, r, name, percent } = item;
  return (
    <div style={{ fontSize: 13, padding: 5 }}>
      <div>{name}</div>
      <div>
        <Trans>Doanh thu:</Trans>&nbsp;{formatVND(y)}
      </div>
      <div>
        <Trans>Khách hàng:</Trans>&nbsp;{x}
      </div>
      <div>
        <Trans>Chốt:</Trans>&nbsp;{percent}%
      </div>
    </div>
  );
};

export default ToolTipChannelPerformance;
