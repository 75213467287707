import { Button, Popup, Radio } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { RadioGroup } from '@material-ui/core';
import SelectProduct from 'component/Select/SelectProduct';
import { trans } from 'config/LinguiConfig';
import { getFormData } from 'helper/helper';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { uploadScenarioAttachments } from 'redux/action/apiAction';

const ImageAction = ({ isHashtag, url, onChange, scenarioId, hide }) => {
  const [value, setValue] = useState('device');
  const [selectedProduct, setSelectedProduct] = useState();
  const [loading, setLoading] = useState();
  const inputRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (value === 'hashtag') onChange('hashtag_image', true);
    else onChange('hashtag_image', false);
  }, [value]);

  const getImage = () => {
    if (isHashtag) return <i className="fad fa-hashtag text-6xl opacity-80 text-primary" />;
    if (url)
      return <img src={url} className="object-cover block w-full" style={{ maxHeight: 160 }} />;
    return <i className="fad fa-image-polaroid text-6xl opacity-50" />;
  };

  const selectLocalImage = async (event) => {
    let file = event?.target?.files?.[0];

    if (file) {
      setLoading(true);
      const { data } = await dispatch(
        uploadScenarioAttachments(scenarioId, getFormData({ image: file })),
      );
      if (data?.image_url) onChange('image_url', data.image_url);
      setLoading(false);
    }
  };

  if (hide) return null;
  return (
    <Popup
      button={
        <div
          style={{ height: 160 }}
          className="flex w-full justify-center items-center bg-gray-200">
          {getImage()}
        </div>
      }>
      <div className="p-4">
        <RadioGroup
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}>
          <div className="flex flex-col">
            <Radio value="device">{t`Chọn từ máy`}</Radio>
            <Radio value="product">{t`Chọn từ sản phẩm`}</Radio>
            <Radio value="hashtag">{t`Nhận diện qua hashtag #sku`}</Radio>
          </div>
        </RadioGroup>
        <Button
          hide={value !== 'device'}
          fullWidth
          color="info"
          onClick={() => inputRef.current.click()}
          loading={loading}>
          {t`Chọn hình ảnh`}
        </Button>
        <SelectProduct
          hide={value !== 'product'}
          fullWidth
          label={t`Chọn sản phẩm`}
          placeholder={t`chọn sản phẩm`}
          onChange={(value) => {
            console.log('select product', value);
            if (value?.image) onChange('image_url', value?.image);
            setSelectedProduct(value);
          }}
          value={selectedProduct?.id}
        />
        <input
          style={{ display: 'none' }}
          type="file"
          onChange={selectLocalImage}
          ref={inputRef}
          accept=" .jpeg, .png, .jpg, .gif"
        />
      </div>
    </Popup>
  );
};

export default memo(ImageAction);
