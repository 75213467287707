import { Snackbar } from '@doopage/react-ui-kit';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CatchErrorBoundary from 'addOnComponent/CatchErrorBoundary';
import 'config/FirebaseConfig';
import 'config/GoogleAnalyticsConfig';
import 'config/LogConfig';
import 'config/progressBarConfig';
import 'config/SentryConfig';
import { MuiTheme } from 'config/Theme';
import 'css/classes-responsive.css';
import 'css/classes.css';
import 'css/fbEmoji.css';
import 'css/react-joyride.css';
import 'css/scenario.css';
import 'css/transition-group.css';
import 'MUI_Kit/assets/scss/material-dashboard-pro-react.scss';
import React from 'react';
import { render } from 'react-dom';
import HttpsRedirect from 'react-https-redirect';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from 'redux/store';
import App from 'screen/App/App';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { isDebug } from 'config/LogConfig';
import * as serviceWorker from './serviceWorker';
import { enableReduxPersist } from 'variables/staticValue';

const CustomPersistGate = ({ children }) => {
  if (isDebug() && enableReduxPersist)
    return (
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    );
  return children;
};

render(
  <HttpsRedirect>
    <CatchErrorBoundary>
      <Provider store={store}>
        <CustomPersistGate>
          <MuiThemeProvider theme={MuiTheme}>
            <BrowserRouter>
              <Route component={App} />
              <Snackbar />
            </BrowserRouter>
          </MuiThemeProvider>
        </CustomPersistGate>
      </Provider>
    </CatchErrorBoundary>
  </HttpsRedirect>,
  document.getElementById('root'),
);

serviceWorker.unregister();
