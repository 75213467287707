import { t } from '@lingui/macro';
import { TextField } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { addAppleBusiness, updateAppleBusiness } from 'api/api';
import { setState } from 'config/Core';
import { handleApi } from 'helper/helper';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import Routes from 'router/routes';
import DV from 'variables/DV';
import BaseModal from '../../BaseComponent/BaseModal';
import { Select } from '@doopage/react-ui-kit';
import LocalStorage from '../../../config/LocalStorage';
import { isEmpty } from 'lodash';

class AppleBusinessModal extends BaseModal {
  constructor(props) {
    super(props);

    const dataFromRouter = this.getDataFromRouter();
    const dataFromLocalStorage = dataFromRouter?.id
      ? LocalStorage.getFromLocal(`appleBusinessMessage/${dataFromRouter?.id}/config`)
      : {};
    const dataFromUrlParam = isEmpty(DV.params)
      ? {}
      : {
          businessId: DV.params?.id,
          avatar: DV.params?.logo,
          name: DV.params?.name,
        };
    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      name: '',
      avatar: '',
      businessId: dataFromRouter?.external_id || dataFromRouter?.business_id,
      clientId: '',
      clientSecret: '',
      provider: null, // google || facebook || linkedin
      ...dataFromLocalStorage,
      ...dataFromRouter,
      ...dataFromUrlParam,
    };
    this.imageUpload = null;

    this.goPathWhenClose = !this.props.openFromChannels
      ? Routes.appleBusinesses.path
      : Routes.channel.path;
  }

  getTitle() {
    const { id } = this.getDataFromRouter();
    if (id) return t`Cập nhật tài khoản Apple Messages for Business`;
    else return t`Thêm tài khoản Apple Messages for Business`;
  }

  update = async (data, id) => {
    const { avatar, businessId, name, ...rest } = data;
    await setState(this)({ loading: true });
    let { success, error } = await handleApi(
      updateAppleBusiness(id, { name, business_id: businessId, avatar }),
    );
    await setState(this)({ loading: false });

    LocalStorage.setToLocal(`appleBusinessMessage/${id}/config`, data);
    if (success) {
      this.close(true);
      DV.showNotify(t`Đã cập nhật thành công`);
    } else DV.showNotify(error, 'danger');
  };

  add = async ({ avatar, businessId, name, ...rest }) => {
    await setState(this)({ loading: true });
    let {
      success,
      data: result,
      error,
    } = await handleApi(addAppleBusiness({ avatar, business_id: businessId, name }));
    await setState(this)({ loading: false });
    if (success) {
      this.close(true);
    } else DV.showNotify(error, 'danger');
  };

  handleSubmit = async () => {
    const { id, name, avatar, businessId, provider, clientId, clientSecret } = this.state;
    // if (!name) {
    //   DV.showNotify(t`Hãy nhập tên Business Message For Apple`, 'danger');
    //   return;
    // }
    // if (!avatar) {
    //   DV.showNotify(t`Hãy chọn một avatar`, 'danger');
    //   return;
    // }

    const data = this.state;
    if (id) {
      await this.update(data, id);
    } else await this.add(data);
  };

  handleImageChange = (file, url) => {
    this.setState({
      image: url,
      image_field: file,
    });
  };

  renderBody() {
    const {
      name,
      avatar,
      businessId,
      clientId,
      clientSecret,
      provider,
      id,
      merchantId,
      verifiedDomain,
      applePayName,
      merchantName,
      pemFile,
      paymentGateway,
      baseDomainUrl,
      oauthTitle,
      responseType,
      scope,
      appId,
      appName,
      bidUrl,
    } = this.state;
    return [
      <Grid container direction="column" spacing={2} style={{ marginTop: 0 }}>
        <Grid container item justify="center">
          {!id && !!avatar && (
            <Grid container item sm={10} spacing={1} style={{ marginBottom: 16 }}>
              <img src={avatar} className="rounded-[50%] h-[100px] w-[100px]" />
            </Grid>
          )}

          <Grid container item sm={10} direction="column" spacing={1}>
            <Grid item>
              <TextField
                label={t`Apple Messages for Business ID`}
                type="text"
                value={businessId || ''}
                fullWidth
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid container item sm={10} direction="column" spacing={1}>
            <Grid item>
              <TextField
                label={t`Tên tài khoản`}
                type="text"
                value={name || ''}
                onChange={this.handleChange('name')}
                fullWidth
              />
            </Grid>
          </Grid>

          {id && (
            <>
              <Grid container item sm={10} direction="column" spacing={1}>
                <div className="text-xl font-bold mt-10">{t`Apple Pay:`}</div>
                <div style={{ paddingLeft: 20 }}>
                  <Grid item>
                    <TextField
                      label={t`Merchant ID`}
                      type="text"
                      value={merchantId || ''}
                      onChange={this.handleChange('merchantId')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t`Verified Domain`}
                      type="text"
                      value={verifiedDomain || ''}
                      onChange={this.handleChange('verifiedDomain')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t`Name`}
                      type="text"
                      value={applePayName || ''}
                      onChange={this.handleChange('applePayName')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t`Merchant Name`}
                      type="text"
                      value={merchantName || ''}
                      onChange={this.handleChange('merchantName')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item style={{ marginTop: 10 }}>
                    <div className="tex-[16px] text-[#0000008a]">{t`PEM file:`}</div>
                    <input
                      type="file"
                      onChange={this.handleChange('pemFile')}
                      ref={(ref) => (this.fileInput = ref)}
                      style={{ display: 'none' }}
                    />
                    <div className="flex-start-row">
                      <Button
                        color="info"
                        round
                        onClick={() => this.fileInput.click()}
                        size="sm"
                        className="mr-5">
                        {t`Chọn tệp tin`}
                      </Button>
                      {pemFile && <div className="text-[16px]">{pemFile.split('\\').pop()}</div>}
                    </div>
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t`Payment Gateway`}
                      type="text"
                      value={paymentGateway || ''}
                      onChange={this.handleChange('paymentGateway')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t`Base Domain URL`}
                      type="text"
                      value={baseDomainUrl || ''}
                      onChange={this.handleChange('baseDomainUrl')}
                      fullWidth
                    />
                  </Grid>
                </div>
              </Grid>
              <Grid container item sm={10} direction="column" spacing={1}>
                <div className="text-xl font-bold mt-10">{t`OAuth:`}</div>
                <div style={{ paddingLeft: 20 }}>
                  <Grid container item direction="column" spacing={1}>
                    <Grid item>
                      <TextField
                        label={t`Client ID`}
                        type="text"
                        value={clientId || ''}
                        onChange={this.handleChange('clientId')}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid container item direction="column" spacing={1}>
                    <Grid item>
                      <TextField
                        label={t`Client secret`}
                        type="password"
                        value={clientSecret || ''}
                        onChange={this.handleChange('clientSecret')}
                        fullWidth
                      />
                    </Grid>
                  </Grid>

                  <Grid container item direction="column" spacing={1}>
                    <Grid item>
                      <Select
                        options={[
                          { id: 'google', name: 'Google' },
                          { id: 'facebook', name: 'Facebook' },
                          { id: 'linkedin', name: 'Linkedin' },
                        ]}
                        label="Provider"
                        fullWidth
                        value={provider}
                        onChange={this.handleChange('provider')}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t`Title`}
                      type="text"
                      value={oauthTitle || ''}
                      onChange={this.handleChange('oauthTitle')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t`Response Type`}
                      type="text"
                      value={responseType || ''}
                      onChange={this.handleChange('responseType')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t`Scope`}
                      type="text"
                      value={scope || ''}
                      onChange={this.handleChange('scope')}
                      fullWidth
                    />
                  </Grid>
                </div>
              </Grid>
              <Grid container item sm={10} direction="column" spacing={1}>
                <div className="text-xl font-bold mt-10">{t`iMessageApp:`}</div>
                <div style={{ paddingLeft: 20 }}>
                  <Grid item>
                    <TextField
                      label={t`App ID`}
                      type="text"
                      value={appId || ''}
                      onChange={this.handleChange('appId')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t`App name`}
                      type="text"
                      value={appName || ''}
                      onChange={this.handleChange('appName')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      label={t`BID URL`}
                      type="text"
                      value={bidUrl || ''}
                      onChange={this.handleChange('bidUrl')}
                      fullWidth
                    />
                  </Grid>
                </div>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>,
    ];
  }

  renderFooter() {
    const { loading, id } = this.state;
    return [
      <Button onClick={this.handleSubmit} color="info" loading={loading}>
        {t`Hoàn tất`}
      </Button>,
    ];
  }
}

export default AppleBusinessModal;
