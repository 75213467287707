import { Accordion, Chip, Space, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import SelectTag from 'component/Select/SelectTag';
import React, { memo } from 'react';
import SelectTime from 'screen/ScenarioFlow/components/SelectTime';

const TagCondition = ({ tags, onChange }) => {
  const { active = false, add_ids = [], remove_ids = [], time } = tags;

  const update = (path, value) => {
    onChange(`trigger_conditions.tags.${path}`, value);
  };

  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <i className="fad fa-tags mr-3 text-2xl" />
            {t`Khi cập nhật tag`}{' '}
            <Chip className="ml-1 transform scale-75" color="primary" square>
              PRO
            </Chip>
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('active', !active)}>
        <Space direction="vertical">
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 50 }} className="text-lg opacity-70">
              {t`Khi`}
            </div>
            <SelectTag
              multiple
              fullWidth
              label={t`thêm một trong các tag:`}
              placeholder={t`chọn tag`}
              onChange={(value) => {
                update('add_ids', value);
              }}
              value={add_ids}
            />
          </div>
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 50 }} className="text-lg opacity-70">
              {t`Và`}
            </div>
            <SelectTag
              multiple
              fullWidth
              label={t`xoá một trong các tag:`}
              placeholder={t`chọn tag`}
              onChange={(value) => {
                update('remove_ids', value);
              }}
              value={remove_ids}
            />
          </div>
          <div className="flex justify-between items-end">
            <div className="opacity-70 text-lg">{t`Sau:`}</div>
            <SelectTime onChange={update} time={time} />
          </div>
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(TagCondition);
