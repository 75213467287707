import { keymirror } from '../helper/helper';

export const Types = keymirror(
  {
    GET_TOKEN: null,
    GET_OWNER_PROFILE: null,
    GET_FANPAGES_V2: null,
    GET_ZALO_OA: null,
    GET_PRODUCT: null,
    GET_STAFFS: null,
    GET_WEBSITES: null,
    GET_RULES: null,
    GET_ORDERS: null,
    GET_PICK_ADDRESSES: null,
    GET_SUBSCRIPTIONS: null,
    GET_PRODUCT_ATTRIBUTES: null,
    GET_VARIANTS: null,
    GET_INSTAGRAMS: null,
    GET_SHOPEE: null,
    GET_SENDO: null,
    GET_EMAIL: null,
    GET_PERSONAL_ZALO: null,
    GET_PERSONAL_FACEBOOKS: null,
    GET_FACEBOOK_GROUPS: null,
    GET_CUSTOMERS: null,
    GET_SOURCE_SYNC_PRODUCT: null,
    GET_SEGMENTS: null,
    GET_TAGS: null,
    GET_SHIPMENT_SERVICES: null,
    GET_BROADCAST: null,
    GET_QUICK_REPLIES: null,
    GET_PAYMENT_METHODS: null,
    GET_LIST_PBX: null,
    GET_CALL_AGENTS: null,
    GET_AGENTS: null,
    GET_MANUAL_CHANNELS: null,
    GET_ZNS_TEMPLATES: null,
    GET_GBMS: null,
    GET_GBM_LOCATIONS: null,

    GET_APPLE_BUSINESSES: null,

    CREATE_COMPANY: null,

    SET_TOKEN: null,
    SET_FANPAGES: null,
    SET_CURRENT_PRODUCT: null,

    SHOW_NOTYFY: null,
    HIDE_NOTYFY: null,

    SHOW_WARNING_PAYMENT_MODAL: null,
    HIDE_WARNING_PAYMENT_MODAL: null,

    SET_CONNECTION: null,
    CLEAR_DATA: null,

    SET_LANGUAGE: null,
    SET_COMPANY: null,
    SET_GRAPHS: null,
    SET_STATISTIC_DATE_RANGE: null,
    CONNECT_SOCKET: null,

    selectSegment: 'selectSegment',
    selectTag: 'selectTag',
    selectProduct: 'selectProduct',
    selectChannel: 'selectChannel',
    SelectFanpage: 'selectFanpage',
    selectZaloOA: 'selectZaloOA',
    selectGBM: 'selectGBM',
    selectABM: 'selectABM',
    selectWebsite: 'selectWebsite',

    detailScenario: 'detailScenario',
    detailBroadcast: 'detailBroadcast',
    broadcastQualifiedPeople: 'broadcastQualifiedPeople',

    subscription: 'subscription',
    pricingPolicy: 'pricingPolicy',
    modalCoupon: 'modalCoupon',
    modalCheckout: 'modalCheckout',
    modalPaymentInfo: 'modalPaymentInfo',
    modalInstallDooPageAppForGroup: 'modalInstallDooPageAppForGroup',
    modalCustomPlan: 'modalCustomPlan',
    modalCropImage: 'modalCropImage',
    modalWebsiteSetting: 'modalWebsiteSetting',

    publicSubscription: 'publicSubscription',

    statistic: 'statistic',

    creditsAmount: 'creditsAmount',
    creditsTransactions: 'creditsTransactions',
    creditsTransactionsChartData: 'creditsTransactionsChartData',

    recentWebhookErrors: 'recentWebhookErrors',

    scenarios: 'scenarios',

    znsTemplateButtons: 'znsTemplateButtons',
  },
  '', //todo: this is prefix
);

console.log('>>', Types);
