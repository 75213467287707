import { Accordion, ChipInput, Select, Space, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import { trans } from 'config/LinguiConfig';
import React, { memo, useMemo } from 'react';

const MessageCondition = ({ message, onChange }) => {
  const {
    active = false,
    exclude_keywords = [],
    keywords = [],
    trigger_on_same_inbox_offset_time = 0,
  } = message;

  const update = (path, value) => {
    onChange(`trigger_conditions.message.${path}`, value);
  };

  const timeOptions = useMemo(
    () => [
      { id: 0, name: t`0 giờ` },
      { id: 3600, name: t`1 giờ` },
      { id: 14400, name: t`4 giờ` },
      { id: 43200, name: t`12 giờ` },
      { id: 86400, name: t`24 giờ` },
      { id: 259200, name: t`3 ngày` },
      { id: 604800, name: t`7 ngày` },
    ],
    [],
  );

  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <i className="fad fa-comment-lines mr-3 text-2xl" />
            {t`Khi nhận tin nhắn`}
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('active', !active)}>
        <Space direction="vertical">
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 30 }} className="text-lg opacity-70">
              {t`Với`}
            </div>
            <ChipInput
              fullWidth
              label={t`Inbox phải có chứa một trong những keyword này`}
              placeholder={t`Nhập keyword`}
              onChange={(value) => {
                update('keywords', value);
              }}
              value={keywords ?? []}
              chipProps={{
                outlined: false,
                rounded: true,
                color: 'success',
              }}
            />
          </div>
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 30 }} className="text-lg opacity-70">
              {t`Và`}
            </div>
            <ChipInput
              fullWidth
              label={t`Inbox không chứa một trong những keyword này`}
              placeholder={t`Nhập keyword`}
              onChange={(value) => update('exclude_keywords', value)}
              value={exclude_keywords ?? []}
              chipProps={{
                outlined: false,
                rounded: true,
                color: 'danger',
              }}
            />
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="opacity-70 text-lg">
              {t`Và không lặp lại với cùng một người trong vòng:`}
            </div>
            <Select
              style={{ width: 80 }}
              options={timeOptions}
              value={trigger_on_same_inbox_offset_time}
              onChange={(e) => update('trigger_on_same_inbox_offset_time', e.target.value)}
            />
          </div>
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(MessageCondition);
