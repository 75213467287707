import { Trans } from '@lingui/macro';
import React from 'react';

const ToolTipStaffMessageDirection = ({ item }) => {
  const { x, y, r, name } = item;
  return (
    <div style={{ fontSize: 13, padding: 5 }}>
      <div>{name}</div>
      <div>
        <Trans>Gửi:</Trans>&nbsp;{y}
      </div>
      <div>
        <Trans>Nhận:</Trans>&nbsp;{x}
      </div>
    </div>
  );
};

export default ToolTipStaffMessageDirection;
