import React, { memo } from 'react';
import { Button } from '@doopage/react-ui-kit';
import { SPACE } from '../../../../../variables/staticValue';
import { t } from '@lingui/macro';
import TemplateButton from './TemplateButton';

const baseButtons = {
  text: '',
  tag: null,
  data: '',
};

const ListTemplateButtons = ({ onChange, buttons }) => {
  const handleChange = (index) => (value) => {
    let newButtons = [...buttons];
    newButtons[index] = value;
    onChange('buttons')(newButtons);
    console.log('ListTemplateButtons handle change', { index, value, buttons, newButtons });
  };

  const handleRemoveButton = (index) => () => {
    let newButtons = [...buttons];
    newButtons.splice(index, 1);
    onChange('buttons')(newButtons);
  };

  return (
    <div className="w-full flex flex-col">
      {buttons?.map((btn, index) => (
        <TemplateButton
          onChange={handleChange(index)}
          onRemove={handleRemoveButton(index)}
          {...btn}
        />
      ))}
      {buttons?.length < 2 && (
        <Button
          color="info"
          noneUpcase
          fullWidth
          simple
          onClick={() => {
            onChange('buttons')([...(buttons ?? []), baseButtons]);
          }}>
          <i className="far fa-plus" /> {SPACE}
          {t`Thêm nút`}
        </Button>
      )}
    </div>
  );
};

export default memo(ListTemplateButtons);
