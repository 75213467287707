import React, {useRef} from "react";
import {Button, Input} from "@doopage/react-ui-kit";
import Grid from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon/Icon";
import { createPortal } from 'react-dom';
const AudioSelect = ({onChange, label, placeholder, helper, file}) => {
    const fileInputRef = useRef();
    return    <Grid container item style={{width: "100%", paddingTop: 0, paddingBottom: 0}}>
        <div className="flex flex-row items-center  w-full">
            <Input
                variant="standard"
                label={label}
                placeholder={placeholder}
                onChange={() => {}}
                onClick={() => {fileInputRef.current.click()}}
                value={ file?.name || ""}
                style={{flex: 1}}
            />
            <input
                ref={(ref) => {
                    fileInputRef.current = ref
                }}
                accept=".wav"
                style={{ display: 'none' }}
                type="file"
                onChange={onChange}
            />
          <div className="pt-2">
              <Button justIcon color="gray" simple onClick={() => onChange(undefined)} disabled={!file}>
                  <i className="fas fa-times" />
              </Button>
              <Button justIcon color="info" simple disabled={!file} onClick={() => {
                  const url = file?.url ? file.url :  URL.createObjectURL(file);
                  const playerRef = document.querySelector("#pbx-modal-player");
                  playerRef.src= url
                  playerRef.play()
              }}>
                  <i className="fas fa-volume" />
              </Button>

          </div>
        </div>
        <div>{helper}</div>
    </Grid>
}

export default AudioSelect
