import React, { memo } from 'react';
import { Button, Input, Space } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import produce from 'immer';

const IceBreakerEditor = ({ buttons = [], show = true, update }) => {
  if (!show) return null;

  return (
    <Space direction="vertical">
      {buttons.map((item, index) => (
        <Button color="white" round style={{ color: '#000' }} key={index} disableRipple>
          <Input
            className="w-full text-center border-b"
            placeholder={t`Nhập nội dung nút`}
            value={item.title}
            onChange={(e) => {
              update(
                'ice_breakers.buttons',
                produce(buttons, (draftState) => {
                  draftState[index].title = e.target.value;
                }),
              );
            }}
          />
          {buttons.length > 1 && (
            <Button
              justIcon
              size="tiny"
              round
              color="danger"
              simple
              onClick={() => {
                update(
                  'ice_breakers.buttons',
                  produce(buttons, (draftState) => {
                    draftState.splice(index, 1);
                  }),
                );
              }}>
              <i className="fal fa-times" />
            </Button>
          )}
        </Button>
      ))}
      <Button
        round
        upcaseText
        color="transparent"
        onClick={() => {
          update(
            'ice_breakers.buttons',
            produce(buttons, (draftState) => {
              draftState.push({ title: '' });
            }),
          );
        }}
        fullWidth
        className="!border-dashed !border-gray-200">
        {t`Thêm gợi ý`}
      </Button>
    </Space>
  );
};

export default memo(IceBreakerEditor);
