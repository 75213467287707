import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText/index';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { setState } from 'config/Core.js';
import { sendSelectAction } from 'config/GoogleAnalyticsConfig';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput.jsx';
import React from 'react';
import { xPath } from '../../../testingClasses';
import styles from './styles';

class CustomSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.sendSelectAction = sendSelectAction(this.props.label);
    this.state = {
      keyword: '',
    };
  }

  getItem = (item) => {
    if (item instanceof Object) return { ...item, id: item.id || item.key };
    return { id: item, value: item };
  };

  handleSearch = (event) => {
    setState(this)({
      keyword: event && event.target ? event.target.value : event,
    });
  };

  getData = (data) => {
    if (this.props.filterData) {
      return this.props.filterData(this.state.keyword);
    }
    return data;
  };

  renderListItem = (data) => {
    const { renderItem, classes, filterData } = this.props;
    if (!data) return null;
    let result = this.getData(data).map((item, key) => {
      const { id, value, description } = this.getItem(item);
      if (renderItem) return renderItem(item, key, classes);
      return (
        <MenuItem
          id={`select-menu-item-${key}`}
          key={key}
          value={id}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected,
          }}>
          <div id={`select-menu-item-${key}-1`}>
            <div
              id={`select-menu-item-${key}-2`}
              className={classNames(xPath.select_(key), {
                bold: !!description,
              })}>
              {value}
            </div>
            {!!description && <div style={{ opacity: 0.7 }}>{description}</div>}
          </div>
        </MenuItem>
      );
    });

    if (filterData)
      result.unshift(
        <CustomInput
          required
          inputProps={{
            type: 'text',
            value: this.state.keyword || '',
            onChange: this.handleSearch,
            className: 'search-select',
            placeholder: 'Tìm kiếm',
          }}
          containerStyle={{
            paddingTop: 10,
            paddingBottom: 10,
            paddingLeft: 20,
            paddingRight: 20,
          }}
        />,
      );
    return result;
  };

  renderValue = (value) => {
    const item = this.props?.data?.find((item) => this.getItem(item)?.id === value);

    return <div>{item?.value ?? item}</div>;
  };

  renderAddButton = () => {
    const { addButton, classes } = this.props;
    if (!addButton) return null;

    const { name } = addButton;
    return (
      <MenuItem
        button
        value={'addbutton'}
        classes={{
          root: classes.selectMenuItem,
          selected: classes.selectMenuItemSelected,
        }}>
        <span>{name}</span>
      </MenuItem>
    );
  };

  findValueFromId = (Id) => {
    const { data } = this.props;
    let result = null;
    for (let i = 0, length = data.length; i < length; i++) {
      const { id, value } = this.getItem(data[i]);
      if (id === Id) {
        result = value;
        break;
      }
    }

    return result;
  };

  handleChange = (event) => {
    const { onChange, addButton } = this.props;
    event.stopPropagation();
    if (event.target.value === 'addbutton') {
      const { name, onClick } = addButton;
      this.sendSelectAction(name);
      onClick && onClick();
    } else {
      this.sendSelectAction(this.findValueFromId(event.target.value));
      console.log('change select ', event.target.value);
      onChange(event.target.value);
    }
  };

  render() {
    const { classes, data, value, disabled, fullWidth, helpText, label, renderItem, renderValue } =
      this.props;
    return (
      <FormControl
        id={'select-ne'}
        fullWidth={!(fullWidth === false)}
        className={classNames(classes.selectFormControl, xPath.select)}>
        {label && (
          <InputLabel htmlFor="simple-select" className={classes.label}>
            {label}
          </InputLabel>
        )}
        <Select
          MenuProps={{
            className: classes.selectMenu,
          }}
          classes={{
            select: classes.select,
          }}
          value={value}
          onChange={this.handleChange}
          disabled={disabled || false}
          renderValue={!renderItem ? this.renderValue : renderValue || undefined}>
          {this.renderListItem(data)}
          {this.renderAddButton()}
        </Select>
        {!!helpText ? <FormHelperText id={'date-text'}>{helpText}</FormHelperText> : null}
      </FormControl>
    );
  }
}

export default withStyles(styles)(CustomSelect);
