export const setElements = (payload = { nodes: [], edges: [] }) => {
  return { type: "SET_ELEMENTS", payload };
};

export const setNodes = (payload = []) => {
  return { type: "SET_NODES", payload };
};

export const setEdges = (payload = []) => {
  return { type: "SET_EDGES", payload };
};
