import { t } from '@lingui/macro';
import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import ListConversationStarter from 'screen/Channel/Website/components/ListConversationStarter';

const GreetingMessage = (props) => {
  const {
    welcomeMessage,
    onChangeWelcomeMessage,
    offlineMessage,
    onChangeOfflineMessage,
    errorMessage,
    onChangeErrorMessage,
    conversationStarters,
    onChangeConversationStarters,
  } = props;
  return (
    <Grid container item sm={12} spacing={2}>
      <Grid item sm={12} style={{ padding: 0 }}>
        <TextField
          label={t`Tin nhắn chào`}
          type="text"
          size="small"
          value={welcomeMessage || ''}
          onChange={(e) => onChangeWelcomeMessage(e.target.value)}
          fullWidth
        />
      </Grid>
      <div style={{ minWidth: 240, width: '100%' }}>
        <h5 style={{ marginBottom: 4 }}>{t`Gợi ý`}</h5>
        <ListConversationStarter
          value={conversationStarters || []}
          onChange={onChangeConversationStarters}
          style={{
            marginTop: 10,
          }}
        />
      </div>
    </Grid>
  );
};

export default GreetingMessage;
