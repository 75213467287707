import _ from 'lodash';
import React from 'react';
import Routes from 'router/routes.js';
import BaseModal from '../../BaseComponent/BaseModal';
import { trans } from 'config/LinguiConfig';
import { t } from '@lingui/macro';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import Dropzone from 'react-dropzone';
import { getFormData, handleApi, readXLSXFile } from 'helper/helper';
import ReviewTable from './ReviewTable';
import { importManualChannel } from '../../../api/api';
import { showErrorSnackbar, showSuccessSnackbar } from '@doopage/react-ui-kit';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { SERVER_BASE_URL } from '../../../config/Env';

class ImportManualChannelModal extends BaseModal {
  constructor(props) {
    super(props);
    let params = this.getDataFromRouter();
    if (_.isEmpty(params)) this.redirectTo(Routes.manual.path);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      file: null,
      data: [],
      channelId: params?.id,
    };
    this.goPathWhenClose = !this.props.openFromChannels ? Routes.manual.path : Routes.channel.path;
    this.showCloseButton = false;
  }

  getTitle() {
    if (this.state.data?.length > 0)
      return (
        <div className="flex flex-row items-center">
          <IconButton
            aria-label="Toggle password visibility"
            onClick={() => {
              this.setState({ data: [], file: null });
            }}>
            <i className="fas fa-arrow-left" />
          </IconButton>
          <div>{trans(t`Xem trước`)}</div>
        </div>
      );
    else return trans(t`Chọn file`);
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value.trim(),
    });
  };

  renderBody() {
    const { data, file, loading } = this.state;
    return (
      <div className="flex flex-col w-full">
        {data?.length > 0 ? (
          <ReviewTable data={data} />
        ) : (
          <Dropzone
            accept={'.xls,.xlsx'}
            maxFiles={1}
            onDrop={(acceptedFiles) => {
              this.setState({ file: acceptedFiles[0] });
            }}>
            {({ getRootProps, getInputProps }) => (
              <section className="w-full">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="flex justify-center items-center border-[#a8a8a8] hover:border-[#2979ff] text-[#a8a8a8] hover:text-[#2979ff]  rounded-[15px] border-2 border-dashed  h-[100px] cursor-pointer">
                    {file ? (
                      <div className="flex flex-col items-center">
                        <i className="fas fa-file text-[40px] mb-[10px]" />
                        <div className="text-[16px] ">{file?.name}</div>
                      </div>
                    ) : (
                      <>
                        <i className="fas fa-upload text-[30px] mr-[10px]" />
                        <div className="text-[16px] ">{t`Click để chọn file`}</div>
                      </>
                    )}
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        )}
        {data?.length > 0 ? (
          <div className="mt-[10px] mb-[20px] italic">
            {t`Bảng xem trước chỉ hiển thị 5 cột và 5 dòng đầu tiên`}
          </div>
        ) : (
          <div className="mt-[10px] mb-[20px] italic">
            {' '}
            {t`Hỗ trợ định dạng`} *.xls, *.xlsx, *.csv (
            <a
              className="underline"
              href="https://app.doopage.com/static/channels/sample_files/manual_channel_import_demo.xlsx">
              {t`tải file mẫu`}
            </a>
            )
          </div>
        )}
        <div className="flex w-full justify-center">
          {data?.length > 0 ? (
            <Button color="info" disabled={loading} onClick={this.handleSubmit}>
              {t`HOÀN TẤT`}
            </Button>
          ) : (
            <Button
              color="info"
              disabled={!file}
              onClick={() => {
                readXLSXFile(file, ({ headers, data }) => {
                  this.setState({ data: data.slice(0, 5) });
                });
              }}>
              {t`TIẾP TỤC`}
            </Button>
          )}
          <Button onClick={() => this.close(this.refreshOnClose)} color="danger" simple>
            {trans(t`HỦY`)}
          </Button>
        </div>
      </div>
    );
  }

  handleSubmit = async () => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(
      importManualChannel(this.state.channelId, getFormData({ file: this.state.file })),
    );

    this.setState({ loading: false });
    if (!success) {
      showErrorSnackbar(error || 'Có lỗi xảy ra');
    } else {
      showSuccessSnackbar('Upload thành công');
      this.close(true);
    }
  };
}

export default ImportManualChannelModal;
