import SelectChannel from 'component/Select/SelectChannel';
import React, {memo} from 'react';

const ChannelCondition = ({channels, onChange, filterBy = () => true}) => {
  const update = (path, value) => {
    onChange(`trigger_conditions.${path}`, value);
  };

  return (
    <SelectChannel
      fullWidth
      onChange={(value) => {
        update('channels', value);
      }}
      value={channels}
      filterBy={filterBy}
    />
  );
};

export default memo(ChannelCondition);
