import PropTypes from 'prop-types';
import React from 'react';
import defaultImage from '../../assets/img/image_placeholder.jpg';
import defaultAvatar from '../../assets/img/placeholder.jpg';

import Button from '../../components/CustomButtons/Button.jsx';
import { connect } from 'react-redux';
import { Types } from '../../../redux/type';
import { openModal } from '../../../redux/action/modalAction';

class ImageUpload extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      imagePreviewUrl: this.props.imageSubmitAddress ? this.props.imageSubmitAddress : defaultImage,
    };
  }

  handleImageChange = (e) => {
    this.setState({ error: null });
    e.preventDefault();
    try {
      const reader = new FileReader();
      const file = e.target.files[0];

      if (!file) return;

      const maxSize = 10 * 1024 * 1024;
      if (file.size > maxSize) {
        this.setState({ error: 'Kích cõ ảnh phải nhỏ hơn 10Mb' });
      } else {
        reader.onloadend = () => {
          if (this.props.crop) {
            console.log('open modal', this.props.crop);
            this.props.openModal(Types.modalCropImage, {
              file,
              url: reader.result,
              crop: this.props.crop,
              onChange: (file, url) => {
                this.setState({
                  file,
                  imagePreviewUrl: url,
                });
                this.props.onChange(file, url);
              },
            });
          } else {
            this.setState({
              file,
              imagePreviewUrl: reader.result,
            });
            this.props.onChange(file, reader.result);
          }
        };
        reader.readAsDataURL(file);
      }
    } catch (error) {
      this.setState({
        error: 'Có lỗi xảy ra, vui lòng thử lại.\nMã lỗi: ' + error.toString(),
      });
    }
  };

  handleClick = () => {
    this.fileInput.click();
  };

  handleRemove = () => {
    this.setState({
      imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
      error: null,
    });
    this.fileInput.value = null;
  };

  render() {
    const { imagePreviewUrl } = this.state;
    const { imageSubmitAddress, borderRadius, thumbnailStyle, hideButton } = this.props;
    const error = this.state?.error || this.props.error;
    const size = this.props.size ?? 150;

    return (
      <div className="fileinput text-center flex_center_col" style={{ display: 'flex' }}>
        <input
          type="file"
          onChange={this.handleImageChange}
          ref={(ref) => (this.fileInput = ref)}
          accept=" .jpeg, .png, .jpg, .gif"
          style={{ display: 'none' }}
        />
        {error ? (
          <div>
            <code style={{ textAlign: 'center' }}> {error} </code>
            <br />
          </div>
        ) : null}
        <div className="thumbnail cursor-pointer" onClick={this.handleClick}>
          <img
            src={imagePreviewUrl || defaultImage}
            alt="..."
            style={{
              height: size,
              objectFit: 'cover',
              width: size,
              borderRadius: `${borderRadius}%`,
              ...thumbnailStyle,
            }}
          />
        </div>
        {!imageSubmitAddress && !hideButton && (
          <Button color="info" round onClick={() => this.handleClick()} size="sm">
            Chọn hình
          </Button>
        )}
        {imageSubmitAddress && !hideButton && (
          <Button size="sm" color="warning" round onClick={() => this.handleClick()}>
            Thay đổi
          </Button>
        )}
        <div>{}</div>
      </div>
    );
  }
}

ImageUpload.propTypes = {
  avatar: PropTypes.bool,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  submitButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
  size: PropTypes.number,
  borderRadius: PropTypes.number,
};

const mapDispatchToProps = (dispatch) => {
  return {
    openModal: (type, payload) => dispatch(openModal(type, payload)),
  };
};

export default connect(null, mapDispatchToProps)(ImageUpload);
