import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import { trans } from 'config/LinguiConfig';
import moment from 'moment';
import React from 'react';
import { handleApi } from 'helper/helper.js';
import { getScenarioResults } from 'api/api.js';
import DV from 'variables/DV.js';

class ScenarioResult extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: false,
      isLoadedDetail: false,
      isLoadingDetail: false,
      detail_data: [],
    };
  }

  onExpand = async (e) => {
    let { message_id, scenario_id } = this.props;
    let { isExpand, isLoadedDetail } = this.state;
    this.setState({ isExpand: !isExpand });
    if (!isLoadedDetail) {
      this.setState({ isLoadingDetail: true });
      let params = `?message_id=${message_id}`;
      let { success, data, error } = await handleApi(getScenarioResults(scenario_id, params));
      if (success) {
        this.setState({
          detail_data: data.data,
          isLoadedDetail: !isLoadedDetail,
          isLoadingDetail: false,
        });
      } else {
        DV.showNotify(error, 'danger');
        this.setState({ isExpand: !!isExpand, isLoadingDetail: false });
      }
    }
  };

  renderDetailItem(item, index) {
    let { icon, color, text } = item;
    return (
      <div style={{ display: 'flex', marginBottom: 3, marginTop: 3 }}>
        <i className={icon} style={{ color: color, marginTop: 2, marginRight: 10 }}></i>
        <div dangerouslySetInnerHTML={{ __html: text }} className="break-word-overflow" />
      </div>
    );
  }

  render() {
    const { icon, color, text, last_attempt_at, status, object_link } = this.props;
    let { detail_data, isExpand, isLoadingDetail } = this.state;
    let expandIconClass = isLoadingDetail
      ? 'fas fa-spinner'
      : isExpand
      ? 'far fa-minus-square'
      : 'far fa-plus-square';
    let displayTime = t`Đang xử lý`;
    if (last_attempt_at) displayTime = moment(last_attempt_at).fromNow();
    else {
      if (status === 'reject') displayTime = t`Đã hủy`;
    }
    return (
      <Grid container style={{ 'padding-bottom': '3px' }}>
        <Grid
          item
          xs={1}
          sm={1}
          md={1}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}>
          <i className={icon} style={{ color: color, marginTop: 2, marginRight: 10 }}></i>
        </Grid>
        <Grid item xs={8} sm={8} md={8} className="flex-center-row-xs">
          <div dangerouslySetInnerHTML={{ __html: text }} className="break-word-overflow" />
          <div>
            {isExpand ? detail_data.map((item, index) => this.renderDetailItem(item, index)) : null}
          </div>
        </Grid>
        <Grid item xs={3} sm={3} md={3}>
          <div style={{ 'text-align': 'right' }}>
            {displayTime}{' '}
            <i
              disabled={isLoadingDetail}
              onClick={this.onExpand}
              className={expandIconClass}
              style={{ color: 'gray', marginTop: 2, marginRight: 10 }}></i>
          </div>
        </Grid>
      </Grid>
    );
  }
}

export default ScenarioResult;
