import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { compose } from 'redux';
import DV from 'variables/DV';
import { change_alias } from '../helper/helper';
import CustomPagination from '../MUI_Kit/components/Pagination/CustomPagination';
import CustomFlipMove from './CustomFlipMove';
import EmptyUI from './EmptyUI';
import ErrorUI from './ErrorUI';
import LoadingUI from './LoadingUI';

class ListData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: Number(props.currentPage) || 1,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    // giảm lag khi nhập liệu vào ô search
    return !(nextProps.keyword !== this.props.keyword);
  }

  static getDerivedStateFromProps(props, state) {
    if (!!state.language && props.language !== state.language) {
      props.errorAction && props.errorAction();
      return {
        language: props.language,
      };
    }
    return null;
  }

  renderLoading = () => {
    const { loadingTitle, loading } = this.props;
    if (!loading) return null;
    return <LoadingUI title={loadingTitle} />;
  };

  renderEmpty = () => {
    const { emptyTitle, emptyButton } = this.props;
    const { data: oData, keyword } = this.props;
    const data = this.filterData(oData, keyword);
    if (data.length === 0) return <EmptyUI title={emptyTitle} action={emptyButton} />;
    return null;
  };

  renderError = () => {
    const { error, errorButton, errorAction, errorTitle } = this.props;
    if (error)
      return <ErrorUI title={errorTitle} buttonLabel={errorButton} buttonAction={errorAction} />;
    return null;
  };

  goToPage = (page) => {
    if (this.props.goToPage) {
      this.props.goToPage(page);
      this.setState({ currentPage: page });
    }
  };

  renderPageSize = (params) => {
    const { pageSize, onUpdatePageSize, pageSizeText, pageSizeUnits } = this.props;

    if (!pageSize || !onUpdatePageSize) return;
    return (
      <div>
        {pageSizeText}
        <Select
          {...params}
          value={pageSize}
          onChange={onUpdatePageSize}
          displayEmpty
          className={DV.classes.selectEmpty}
          style={{ margin: '0 10px' }}>
          {pageSizeUnits.map((size) => (
            <MenuItem {...params} value={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </div>
    );
  };

  renderData = () => {
    const {
      data: oData,
      renderItem,
      keyword,
      paging,
      disabledFlipMove,
      listClassName,
    } = this.props;
    let data = this.filterData(oData, keyword);
    const totalPage = paging?.total_page ?? 1;

    if (data.length > 0)
      return (
        <div>
          <Grid container>
            <Grid item md={8}>
              <CustomPagination
                totalPage={totalPage}
                goToPage={this.goToPage}
                currentPage={this.state.currentPage}
              />
            </Grid>
            <Grid item md={4} className="flex-end-row text-end">
              {this.renderPageSize()}
            </Grid>
          </Grid>
          <CustomFlipMove disabled={disabledFlipMove} className="flex flex-wrap">
            {data.map((item, index) => {
              return (
                <div key={item.id} className={classNames('w-full', listClassName)}>
                  {renderItem(item, index)}
                </div>
              );
            })}
          </CustomFlipMove>
        </div>
      );

    return null;
  };

  render() {
    console.log('zxcv ListData', this.props)
    return this.renderLoading() || this.renderError() || this.renderEmpty() || this.renderData();
  }

  filterData = (data, keyword) => {
    return data;

    if (this.props.filter === false || !!!keyword) return data;
    if (!data) return [];
    let new_keyworld = change_alias(keyword);
    let nData = data.filter((item) => {
      let { name, domain, oa_id, username, full_name } = item;
      let text = name || domain || oa_id || username || full_name || '';
      return change_alias(text).includes(new_keyworld);
    });
    return nData;
  };
}

ListData.propTypes = {
  data: PropTypes.array,
  content: PropTypes.string,
  emptyTitle: PropTypes.string,
  emptyButton: PropTypes.func,
  emptyUrl: PropTypes.string,
  keyword: PropTypes.string,
  filter: PropTypes.bool,
  pageSizeUnits: PropTypes.array,
  pageSizeText: PropTypes.string,
  listClassName: PropTypes.any,
};

ListData.defaultProps = {
  pageSizeUnits: [10, 20, 50, 100],
  pageSizeText: 'Số item mỗi trang',
};

const mapStateToProps = (state) => {
  return {
    language: state.common.language,
  };
};

export default compose(connect(mapStateToProps, null))(ListData);
