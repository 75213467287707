import React, { memo, useState } from 'react';
import { Button, Input, SelectLocale, Space } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { getLocale } from 'variables/utils';
import produce from 'immer';

const MenuEditor = ({ menu, update }) => {
  const [selectedLocale, setSelectedLocale] = useState('default');

  const getSelectedLocale = () => {
    const result = menu?.reduce((arr, item) => {
      const locale = getLocale(item.locale);

      if (locale && !arr.some((i) => i.id === item.locale)) return [...arr, locale];
      return arr;
    }, []);

    if (result.length === 0) result.push(getLocale('default'));

    return result;
  };

  return (
    <Space direction="vertical">
      <SelectLocale
        selectProps={{
          label: t`Ngôn ngữ`,
          placeholder: t`Vui lòng chọn ngôn ngữ`,
        }}
        defaultSelectedLocale={selectedLocale}
        onSelectedChange={setSelectedLocale}
        defaultOptions={getSelectedLocale()}
        onOptionsChange={(newLocales) => {
          update('persistent_menu', {
            active: true,
            menu: newLocales.reduce((arr, ele) => {
              const data = menu.find((item) => item.locale === ele.id) || {
                locale: ele.id,
                call_to_actions: [{ type: 'postback', title: '' }],
              };
              return [...arr, data];
            }, []),
          });
        }}
      />
      {menu.map((item, indexMenu) => {
        if (item.locale !== selectedLocale) return null;

        return (
          <React.Fragment key={indexMenu}>
            {item?.call_to_actions?.map((item, indexAction) => {
              return (
                <Button color="white" style={{ color: '#000' }} key={indexAction} disableRipple>
                  <Input
                    className="w-full text-center border-b"
                    placeholder={t`Nhập nội dung nút`}
                    value={item.title}
                    onChange={(e) => {
                      update(
                        'persistent_menu.menu',
                        produce(menu, (draftState) => {
                          draftState[indexMenu].call_to_actions[indexAction].title = e.target.value;
                        }),
                      );
                    }}
                  />
                  {menu.filter((item) => item.locale === selectedLocale).length > 1 && (
                    <Button
                      justIcon
                      size="tiny"
                      round
                      color="danger"
                      simple
                      onClick={() => {
                        update(
                          'persistent_menu.menu',
                          produce(menu, (draftState) => {
                            draftState[indexMenu].call_to_actions.splice(indexAction, 1);
                          }),
                        );
                      }}>
                      <i className="fal fa-times" />
                    </Button>
                  )}
                </Button>
              );
            })}
          </React.Fragment>
        );
      })}
      <Button
        upcaseText
        color="transparent"
        onClick={() => {
          update(
            'persistent_menu.menu',
            produce(menu, (draftState) => {
              draftState
                .find((item) => item.locale === selectedLocale)
                .call_to_actions.push({
                  type: 'postback',
                  title: '',
                });
            }),
          );
        }}
        fullWidth
        className="!border-dashed !border-gray-200">
        {t`Thêm menu`}
      </Button>
    </Space>
  );
};

export default memo(MenuEditor);
