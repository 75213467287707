import { t } from '@lingui/macro';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Conversational from './Conversational';
import SelectLanguage from './SelectLanguage';

const ListConversational = ({ languages, value, onChange, ...props }) => {
  const [language, setLanguage] = useState(languages[0]);
  const handleChange = (language, propKey) => {
    return (newValue) => {
      value[language][propKey] = newValue;
      onChange(value);
    };
  };
  useEffect(() => {
    let isHaveChange = false;
    for (const language of languages) {
      if (!value[language]) {
        value[language] = {
          welcome_message: '',
          offline_message: '',
          privacy_policy: '',
          conversation_starters: [],
        };
        isHaveChange = true;
      }
    }
    if (isHaveChange) {
      onChange(value);
    }
  });
  return (
    <Grid container xs={12} spacing={1} {...props}>
      <Grid container item sm={12}>
        <Grid item sm={6} xs={12} md={4}>
          <SelectLanguage
            value={language}
            options={languages}
            label={t`Ngôn ngữ`}
            onChange={setLanguage}
          />
        </Grid>
      </Grid>
      {value[language] && (
        <Conversational
          welcomeMessage={value[language].welcome_message}
          onChangeWelcomeMessage={handleChange(language, 'welcome_message')}
          offlineMessage={value[language].offline_message}
          onChangeOfflineMessage={handleChange(language, 'offline_message')}
          privacyPolicy={value[language].privacy_policy}
          onChangePrivacyPolicy={handleChange(language, 'privacy_policy')}
          conversationStarters={value[language].conversation_starters}
          onChangeConversationStarters={handleChange(language, 'conversation_starters')}
        />
      )}
    </Grid>
  );
};

export default ListConversational;
