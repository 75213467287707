import { AutoComplete } from '@doopage/react-ui-kit';
import React, { memo, useMemo } from 'react';
import StarIcon from '@material-ui/icons/Star';
import { warningColor } from '../../variables/color';

const SelectRating = (props) => {
  const data = useMemo(() => {
    return [1, 2, 3, 4, 5].map((item) => {
      return {
        id: item,
        name: `x ${item}`,
        icon: <StarIcon style={{ color: warningColor }} />,
      };
    });
  }, []);

  return <AutoComplete {...props} options={data} />;
};

export default memo(SelectRating);
