import { combineReducers } from 'redux';
import { Types } from 'redux/type';
import createFetchReducer from 'redux/reducer/fetchReducer';

const reducer = combineReducers({
  [Types.creditsAmount]: createFetchReducer(Types.creditsAmount),
  [Types.creditsTransactions]: createFetchReducer(Types.creditsTransactions),
  [Types.creditsTransactionsChartData]: createFetchReducer(Types.creditsTransactionsChartData),
});

export default reducer;
