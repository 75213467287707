import {Avatar, Input, showErrorSnackbar, showSnackbar} from "@doopage/react-ui-kit";
import {t} from "@lingui/macro";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Search from "@material-ui/icons/Search";
import {getListHotlines, patchPBX, unlockPbx} from "api/api";
import classNames from "classnames";
import {handleApi} from "helper/helper";
import _ from "lodash";
import CustomPagination from "MUI_Kit/components/Pagination/CustomPagination";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {compose} from "redux";
import {AssetImage} from "../../assets/Asset";
import Card from "../../MUI_Kit/components/Card/Card";
import Button from "../../MUI_Kit/components/CustomButtons/Button";
import Routes from "../../router/routes";
import {xPath} from "../../testingClasses";
import DV from "../../variables/DV";
import BaseModal from "../BaseComponent/BaseModal";
import EmptyUI from "../EmptyUI";


const PBX_TYPE = {
  zaloOA: "zaloOA",
  selectNewHotLine: "selectNewHotLine",
  existedHotline: "existedHotline",
};

class PbxUnlockModal extends BaseModal {
  maxWidth = "xs";
  showCloseButton = false;

  constructor(props) {
    super(props);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      color: "",
      step: 1,
      pbxType: "",
      hotlineNumber: "",
      ...this.getDataFromRouter(),
    };

    this.goPathWhenClose = !this.props.openFromChannels ? Routes.pbx.path : Routes.channel.path;
    this.searchDebounce = _.debounce(this.search, 500);
  }

  getTitle() {
    return t`Mở khóa tổng đài`;
  }

  async componentDidMount() {
    const raw = await fetch("https://cloudflare-dns.com/dns-query?name=" + process.env.REACT_APP_DOOPAGE_SIP_DOMAIN, {
      headers: {
        "accept": "application/dns-json",
      },
    });
    const data = await raw.json();
    const hostAddresses = data?.Answer?.filter(item => item.type === 1);
    this.setState({
      primaryAddress: hostAddresses?.[0]?.data,
      secondaryAddress: hostAddresses?.[1]?.data,
    });
  }

  async fetchHotlines() {
    this.setState({
      loading: true,
    });
    const {page, keyword} = this.state;
    let res = await handleApi(getListHotlines({page, keyword, page_size: 10}));
    if (res.success) {
      this.setState({
        hotlines: res.data, loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async search() {
    this.setState({page: 0});
    await this.fetchHotlines();
  }

  renderStep1() {
    const {zaloOAAccounts} = this.props;
    const hasZaloOAAccount = zaloOAAccounts.length > 0;
    return <div>
      <div>{t`Để mở khóa tổng đài, bạn cần đấu nối tổng đài với một số hotline. Hãy chọn loại hotline bạn muốn đấu nối:`}</div>
      <div style={!hasZaloOAAccount ? {
        cursor: "default",
        opacity: 0.3,
      } : undefined}
           className="rounded-[4px] shadow my-[16px] py-[14px] px-[40px] flex flex-row items-center cursor-pointer"
           onClick={hasZaloOAAccount ? () => {
             this.setState({
               step: 2,
               pbxType: PBX_TYPE.zaloOA,
             });
           } : undefined}>
        <img
          src={AssetImage.Zalo_new}
          className="mr-[36px] h-[45px] w-[45px] rounded-[10px]"
          alt={"Zalo OA"}
        />
        Zalo OA
      </div>
      <div className="rounded-[4px] shadow my-[16px] py-[14px] px-[40px] flex flex-row items-center cursor-pointer"
           onClick={() => {
             this.setState({
               keyword: "",
               page: 1,
               step: 2,
               pbxType: PBX_TYPE.selectNewHotLine,
             });
             this.fetchHotlines();
           }}>
        <img src={AssetImage.PhoneBlue} className="mr-[36px] h-[45px] w-[45px] rounded-[10px]" alt={"Zalo OA"}/>
        {t`Chọn hotline mới`}
      </div>
      <div className="rounded-[4px] shadow my-[16px] py-[14px] px-[40px] flex flex-row items-center cursor-pointer"
           onClick={() => {
             this.setState({
               keyword: "",
               page: 1,
               step: 2,
               pbxType: PBX_TYPE.existedHotline,
             });
           }}>
        <img src={AssetImage.PhoneBlue} className="mr-[36px] h-[45px] w-[45px] rounded-[10px]" alt={"Zalo OA"}/>
        {t`Dùng hotline sẵn có`}
      </div>
    </div>;
  }

  renderItem = (item, index) => {
    const {id, name, image_url} = item || {};
    const {id: pbxId} = this.props.match.params;

    return (
      <Card
        style={{
          width: "100%",
          marginBottom: 15,
          padding: 10,
          overflow: "initial",
          marginTop: index === 0 ? 10 : 0,
        }}
        key={id}
        className={classNames(xPath.item, xPath.item_(index), "cursor-pointer", {
          "active:scale-[0.98] transition": !this.state.unlocking,
        })}
        onClick={async () => {
          this.setState({unlocking: true});
          const {success, error} = await handleApi(unlockPbx(pbxId, {
            link_channel_type: "zalo_oa",
            link_channel_id: id,
          }));
          if (success) {
            showSnackbar(t`Mở khóa tổng đài thành công`);
            this.close(false);
            return;
          }
          showErrorSnackbar(error || t`Mở khóa tổng đài thất bại`);
          this.setState({unlocking: false});
        }}
      >
        <div className="flex-center-row"
             aria-disabled={this.state.unlocking}
             style={{paddingLeft: 10, paddingRight: 10, opacity: this.state.unlocking ? 0.7 : 1}}
        >
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <Avatar user={{avatar: image_url, name}} size={45} margin={false}/>
            </Grid>
            <Grid item xs>
              <Grid container>
                <Grid item xs={12} sm={12} className="flex-start-row bold">
                  {name}
                </Grid>
              </Grid>
            </Grid>
            {this.state.unlocking &&
            <Grid item>
              <CircularProgress size='20px'/>
            </Grid>}
          </Grid>
        </div>
      </Card>
    );
  };

  renderStep2 = () => {
    let {loading, keyword, hotlines, step, pbxType, hotlineNumber, primaryAddress, secondaryAddress} = this.state;
    const {id: pbxId} = this.props.match.params;
    const {zaloOAAccounts} = this.props;

    if (loading) {
      return (<div className="flex-full flex-center-col" style={{height: 200}}>
        <CircularProgress/>
      </div>);
    }

    if (pbxType === PBX_TYPE.zaloOA) {
      return <div className="flex-full flex-col p-1">
        <div>{t`Bạn hãy chọn Zalo OA cần đấu nối:`}</div>
        {zaloOAAccounts.map(this.renderItem)}
      </div>;
    }

    if (pbxType === PBX_TYPE.existedHotline) {
      return <div className="flex-full flex-col p-1">
        <Grid container alignItems="flex-end" className="mb-5">
          <Grid item xs style={{marginBottom: 16}}>
            <Input
              fullWidth
              disabled={loading}
              loading={loading}
              value={hotlineNumber || ""}
              label={t`Số hotline`}
              inputMode="tel"
              inputProps={{
                type: "number",
              }}
              onChange={(e) => {
                this.handleChange("hotlineNumber")(e);
              }}
            />
          </Grid>
        </Grid>
        <div className="mb-5">
          {t`Để đấu nối với số hotline sẵn có, bạn vui lòng liên hệ đơn vị cung cấp hotline và thực hiện yêu cầu trỏ hotline tới địa chỉ SIP:`}
        </div>
        <Grid container sm={12} spacing={2} className="font-bold">
          <Grid item sm={6} className="flex justify-end">
            Primary:
          </Grid>
          <Grid item sm={6}>
            {primaryAddress}
          </Grid>
        </Grid>
        <Grid container sm={12} spacing={2} className="font-bold">
          <Grid item sm={6} className="flex justify-end">
            Secondary:
          </Grid>
          <Grid item sm={6}>
            {secondaryAddress}
          </Grid>
        </Grid>
      </div>;
    }
    return (<div>
      <div style={{marginBottom: 16}}>{t`Bạn hãy chọn hotline cần đấu nối:`}</div>
      <Grid container alignItems="flex-end" className="mb-5">
        <Grid item xs style={{marginBottom: 16}}>
          <Input
            fullWidth
            disabled={loading}
            loading={loading}
            value={keyword || ""}
            label={t`Tìm kiếm`}
            endIcon={<Search/>}
            onChange={(e) => {
              this.handleChange("keyword")(e);
              this.searchDebounce();
            }}
          />
        </Grid>
        {this.renderSelectHotlines(this.state.hotlines)}
      </Grid>
    </div>);
  };

  renderFooter() {
    const {loading, unlocking, step, pbxType, hotlineNumber} = this.state;

    return (
      <div>
        {
          step > 1 &&
          <Button disabled={loading || unlocking} onClick={() => this.setState({step: 1})} color="info" simple>
            {t`Quay lại`}
          </Button>
        }
        <Button disabled={loading || unlocking} onClick={() => this.close(false)} color="danger" simple>
          {t`Đóng`}
        </Button>
        {
          step > 1 && pbxType === PBX_TYPE.existedHotline && <Button disabled={loading || unlocking || !hotlineNumber}
                                                                     onClick={() => this.handleSelectHotline(hotlineNumber)}
                                                                     color="info">
            {t`Cập nhật`}
          </Button>
        }
      </div>
    );
  }

  renderBody() {
    if (this.state.step === 1) {
      return this.renderStep1();
    }
    return this.renderStep2();
  }

  async handleSelectHotline(hotline) {
    const {id} = this.props.match.params;
    this.setState({loading: true});
    let {success, data, error} = await handleApi(patchPBX(id, {expected_phone_number: hotline}));
    this.setState({loading: false});
    if (success) {
      DV.showNotify(t`Đã cập nhật thành công`);
      this.close();
    } else if (error) {
      DV.showNotify(error, "danger");
    }
  }

  renderSelectHotlines({page, total_page, data} = {}) {
    return <>
      <div className={"flex w-full flex-col"}>
        {
          data?.length > 0 ? data.map((item, index) => {
            return <div key={item} onClick={() => this.handleSelectHotline(item)}
                        className={classNames("text-xl w-full flex items-center justify-center py-5 cursor-pointer hover:bg-gray-300", {
                          "bg-gray-200": index % 2 === 0,
                        })}>{item}</div>;
          }) : <EmptyUI imageStyle={{width: 200}}/>
        }
      </div>
      <CustomPagination
        currentPage={page}
        totalPage={total_page}
        goToPage={next_page => {
          this.setState({
            page: next_page,
          });
          this.fetchHotlines();
        }
        }
      />
    </>;
  }

}


const mapStateToProps = (state) => {
  const allChannels = state.requests?.queries?.["GET_ALL_CHANNELS"]?.data?.data ?? [];
  const zaloOAAccounts = allChannels.filter(item => item.channel_type === "zalo_oa");
  return {
    zaloOAAccounts,
  };
};

const connectedModal = compose(connect(mapStateToProps))(PbxUnlockModal);

export default withRouter(connectedModal);
