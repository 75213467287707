import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid';
import { requestVerificationGBM } from 'api/api';
import { setState } from 'config/Core';
import { handleApi } from 'helper/helper';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import React from 'react';
import Routes from 'router/routes';
import DV from 'variables/DV';
import BaseModal from '../../BaseComponent/BaseModal';

class GBMVerificationModal extends BaseModal {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      contact_email_address: '',
      contact_name: '',
      ...this.getDataFromRouter(),
    };
    this.goPathWhenClose = !this.props.openFromChannels ? Routes.gbm.path : Routes.channel.path;
  }

  getTitle() {
    return t`Xác minh Google Business Message`;
  }

  componentDidMount() {
    const { open, id } = this.state;
    if (open && !id) {
      this.close();
    }
  }

  requestVerification = async (id, data) => {
    await setState(this)({ loading: true });
    let { success, error } = await handleApi(requestVerificationGBM(id, data));
    await setState(this)({ loading: false });
    if (success) {
      this.close(true);
    } else DV.showNotify(error, 'danger');
  };

  handleSubmit = async () => {
    const { id, contact_email_address, contact_name } = this.state;
    if (!contact_email_address) {
      DV.showNotify(t`Hãy nhập Email của người quản lý Google Business Message`, 'danger');
      return;
    }
    if (!contact_name) {
      DV.showNotify(t`Hãy nhập tên của người quản lý Google Business Message`, 'danger');
      return;
    }
    const data = { contact_email_address, contact_name };
    await this.requestVerification(id, data);
  };

  renderBody() {
    const { image, display_name, url, contact_email_address, contact_name } = this.state;

    return (
      <Grid container direction="column" spacing={1} style={{ marginTop: 16 }}>
        <Grid container item justify="center">
          <Grid item sm={3}>
            <img src={image} width={100} height={100} alt={display_name} />
          </Grid>
          <Grid container item sm={3} direction="column" justify="center">
            <Grid item>
              <p style={{ fontSize: 18, fontWeight: 'bold' }}>{display_name}</p>
            </Grid>
            <Grid item>
              <p style={{ fontSize: 13 }}>{url}</p>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item justify="center">
          <Grid item sm={10}>
            <CustomInput
              labelText={t`Email người quản lý Google Business Message`}
              inputProps={{
                type: 'text',
                value: contact_email_address || '',
                onChange: this.handleChange('contact_email_address'),
              }}
            />
          </Grid>
        </Grid>
        <Grid container item justify="center">
          <Grid item sm={10}>
            <CustomInput
              labelText={t`Tên người quản lý Google Business Message`}
              inputProps={{
                type: 'text',
                value: contact_name || '',
                onChange: this.handleChange('contact_name'),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderFooter() {
    const { loading } = this.state;
    return [
      <Button onClick={this.handleSubmit} color="info" loading={loading}>
        {t`Xác minh`}
      </Button>,
    ];
  }
}

export default GBMVerificationModal;
