import { Input, Select, Space } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import React, { memo, useEffect } from 'react';

const DelayAction = ({ onChange, hide, value: delay }) => {
  const { value, unit } = delay;

  useEffect(() => {
    if (!hide && !value) {
      onChange('delay', { time: { value: 5, unit: 'sec' } });
    }
  }, [hide]);

  if (hide) return null;

  return (
    <div className="p-3">
      <div className="font-bold mb-[10px]">
        <i className="fad fa-clock mr-[5px]" />
        {t`Trì hoãn`}
      </div>
      <Space>
        <Input
          style={{ width: 150 }}
          value={value}
          onChange={(e) => onChange('delay', { time: { value: e.target.value, unit } })}
          className="w-[50px] mx-1"
          type="number"
        />
        <Select
          key={unit}
          style={{ width: 100 }}
          value={unit}
          options={[
            { id: 'sec', name: t`giây` },
            { id: 'min', name: t`phút` },
            { id: 'hour', name: t`giờ` },
            { id: 'day', name: t`ngày` },
            { id: 'month', name: t`tháng` },
          ]}
          onChange={(e) => onChange('delay', { time: { value, unit: e.target.value } })}
        />
      </Space>
    </div>
  );
};

export default memo(DelayAction);
