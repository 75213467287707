import React from 'react';
import PropTypes from 'prop-types';
import Pagination from './Pagination';
import { PAGING } from '../../../variables/staticValue';

const getNode = (page, text, func, active = false) => {
  return {
    text,
    onClick: () => func && func(page),
    active,
  };
};

function CustomPagination({ ...props }) {
  let { totalPage, goToPage, currentPage } = props;
  //totalPage = 20;
  let paging = [];
  const PAGING_CONST = PAGING();

  // nút về trang trước
  if (currentPage - PAGING_CONST.size / 2 > 1) {
    paging.push(getNode(1, PAGING_CONST.head, goToPage));
    paging.push(getNode(currentPage - 1, PAGING_CONST.previous, goToPage));
    paging.push(getNode(0, PAGING_CONST.hidden));
  }

  // các nút phân trang số
  if (totalPage > 1) {
    const fromPage = Math.max(currentPage - ~~(PAGING_CONST.size / 2), 1);
    const endPage = Math.min(currentPage + ~~(PAGING_CONST.size / 2), totalPage);
    for (let i = fromPage; i <= endPage; i++) {
      paging.push(getNode(i, i, goToPage, i === currentPage));
    }
  }

  // nút về trang sau
  if (currentPage + PAGING_CONST.size / 2 < totalPage) {
    paging.push(getNode(0, PAGING_CONST.hidden));
    paging.push(getNode(currentPage + 1, PAGING_CONST.next, goToPage));
    paging.push(getNode(totalPage, PAGING_CONST.tall, goToPage));
  }

  return totalPage > 1 && <Pagination color="info" pages={paging} />;
}

CustomPagination.propTypes = {
  totalPage: PropTypes.number.isRequired,
  goToPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
};

export default React.memo(CustomPagination);
