import { I18nProvider } from '@lingui/react';
import { withStyles } from '@material-ui/core';
import withMobileDialog from '@material-ui/core/withMobileDialog/withMobileDialog';
import GTMConnect from 'addOnComponent/GTMConnect';
import Network from 'addOnComponent/Network';
import MuiSnackbar from 'addOnComponent/Snackbar';
import TouristIntro from 'addOnComponent/TouristIntro';
import SubscribeSocialModal from 'component/Modal/SubscribeSocialModal';
import WarningLimitModal from 'component/Modal/Warning/WarningLimitModal';
import { ENV, GA_ID } from 'config/Env';
import {
  Loadable_Company,
  Loadable_JoinCompany,
  Loadable_Loading,
  Loadable_Login,
  Loadable_OAuth2,
  Loadable_Public_Subscription,
  Loadable_Root,
  Loadable_Scenario,
} from 'config/LoadableConfig';
import LocalStorage from 'config/LocalStorage';
import { setLogStyle, useLogStyle } from 'config/LogConfig';
import { getObjectFromParams } from 'helper/helper';
import buttonStyle from 'MUI_Kit/assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx';
import customCheckboxRadioSwitch from 'MUI_Kit/assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch';
import dashboardStyle from 'MUI_Kit/assets/jss/material-dashboard-pro-react/views/dashboardStyle';
import loginPageStyle from 'MUI_Kit/assets/jss/material-dashboard-pro-react/views/loginPageStyle';
import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Analytics from 'react-router-ga';
import { compose } from 'redux';
import { changeLanguage } from 'redux/action';
import { PrivateRoute } from 'router/components/PrivateRoute';
import Routes, { parentPath } from 'router/routes';
import { dangerColor } from 'variables/color';
import DV from 'variables/DV';
import { ALL_PERMISSION, LIST_DOMAIN } from 'variables/staticValue';
import { i18n } from '@lingui/core';
import { getDefaultLanguage, loadLanguage } from '../../config/LinguiConfig';

const styles = () => ({
  ...dashboardStyle,
  ...loginPageStyle,
  ...customCheckboxRadioSwitch,
  ...buttonStyle,
  paper: {
    borderRadius: 20,
  },
});

const fullHeightPaths = [Routes.publicSubscription.path, Routes.oauth2.path];

class App extends PureComponent {
  constructor(props) {
    super(props);

    let domain = window.location.href.toString();
    if (ENV !== 'prod' && ENV !== 'beta') {
      DV.partner = localStorage.getItem('@@partner@@');
    }

    if (!DV.partner) {
      DV.partner = Object.keys(LIST_DOMAIN).find((key) => domain.includes(key));
    }

    DV.classes = this.props.classes;
    DV.params = getObjectFromParams(window.location.search);
    DV.url = window.location.pathname;
    DV.permission = ALL_PERMISSION;
  }

  // componentWillUnmount() {
  //     this.hostStorage.close();
  // }

  async componentDidMount() {
    if (DV.params.doopage_fake_owner_id)
      LocalStorage.setFakeOwnerId(DV.params.doopage_fake_owner_id);
    if (DV.params.doopage_fake_owner_token)
      LocalStorage.setToken(DV.params.doopage_fake_owner_token);
    let language = LocalStorage.getLanguage();
    this.props.setLanguage(language);
    DV.showWarningLimitModal = () => this.warningLimitModal.open();
    DV.showSubscribeSocialModal = () => this.subscribeSocialModal?.open();

    DV.withMobileDialog = this.props.fullScreen;
    DV.waitingCursor = {
      add: () => document.body.classList.add('waiting'),
      remove: () => document.body.classList.remove('waiting'),
    };
    // this.getGeoInfo();

    await loadLanguage(getDefaultLanguage());
  }

  componentWillUnmount() {
    DV.showWarningLimitModal = () => {};
    DV.waitingCursor = {};
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    DV.withMobileDialog = this.props.fullScreen;
  }

  render() {
    //todo: should map sellerInviteCode
    const { language, currentCompany } = this.props;
    let logo = window.location.origin + LIST_DOMAIN[DV.partner].indexLogo;

    let title = LIST_DOMAIN[DV.partner].indexTitle;

    console.log(useLogStyle + 'render App', setLogStyle(dangerColor), language, currentCompany?.id);
    const fullHeightStyle = { height: '100vh', overflow: 'hidden' };

    return (
      <I18nProvider i18n={i18n}>
        <Helmet>
          <title>{title}</title>
          <link rel="shortcut icon" href={logo} />
        </Helmet>

        <div
          style={fullHeightPaths.includes(window.location?.pathname) ? {} : fullHeightStyle}
          key={`${currentCompany.id}-${this.props.fullScreen}`}>
          <MuiSnackbar />

          <Analytics id={GA_ID}>
            <Switch>
              <Route path={Routes.login.path} component={Loadable_Login} />
              <Route path={Routes.loginByPassword.path} component={Loadable_Login} />
              <Route
                path={Routes.publicSubscription.path}
                component={Loadable_Public_Subscription}
              />
              <Route path={Routes.scenarioView.path} component={Loadable_Scenario} />
              <PrivateRoute path={Routes.oauth2.path} component={Loadable_OAuth2} />
              <PrivateRoute path={Routes.company.path} component={Loadable_Company} />
              <PrivateRoute path={Routes.joinCompany.path} component={Loadable_JoinCompany} />
              <PrivateRoute path={parentPath} component={Loadable_Root} />
              <Route path="/" component={Loadable_Loading} />
            </Switch>
          </Analytics>

          <WarningLimitModal ref={(ref) => (this.warningLimitModal = ref)} />

          <TouristIntro />
          <Network />
          <GTMConnect />
        </div>

        <SubscribeSocialModal ref={(ref) => (this.subscribeSocialModal = ref)} />
      </I18nProvider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentCompany: state.common.currentCompany,
    language: state.common.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setLanguage: (lang) => dispatch(changeLanguage(lang)),
  };
};

export default compose(
  withMobileDialog(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
)(App);
