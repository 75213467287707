import CircularProgress from '@material-ui/core/CircularProgress/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';
import Zoom from '@material-ui/core/Zoom/Zoom';
import { SERVER_BASE_URL } from 'config/Env';
import PropTypes from 'prop-types';
import React from 'react';
import ImageLoader from 'react-load-image';
import { convertHttpToHttps } from '../helper/helper';
import LetterAvatar from './LetterAvatar';

const sizeImg = 35;

export default class Avatar extends React.PureComponent {
  renderLoad = () => {
    const { round, border, size } = this.props;
    return (
      <div
        className="flex-center-col"
        style={{
          width: size,
          height: size,
          borderRadius: border ? border : size / 2,
          backgroundColor: '#eeeeee',
        }}>
        <CircularProgress size={size / 2} style={{ color: 'rgb(0, 132, 255)' }} />
      </div>
    );
  };

  renderImage = (url) => {
    url = convertHttpToHttps(url);
    const { round, border, size } = this.props;

    return (
      <ImageLoader src={url}>
        <img
          referrerPolicy={'no-referrer'}
          className="imgAvatar"
          src={url}
          style={{
            width: size,
            height: size,
            borderRadius: border ? border : size / 2,
          }}
          alt="img"
        />
        {this.renderLetter()}
        {this.renderLoad()}
      </ImageLoader>
    );
  };

  renderLetter = () => {
    const { round, border, size, user } = this.props;
    let { name } = user;
    return (
      <LetterAvatar
        content={name || 'NA'}
        size={size}
        radius={border ? border : round ? size / 2 : border}
      />
    );
  };

  renderAvatar = () => {
    const { user } = this.props;
    let { fb_id, avatar, image } = user;

    if (avatar) {
      if (
        !/^(f|ht)tps?:\/\//i.test(avatar) &&
        !avatar.includes('data:image') &&
        !avatar.includes('/static/media/')
      ) {
        avatar = SERVER_BASE_URL.slice(0, -4) + avatar;
      }
      return this.renderImage(avatar);
    }

    if (image) {
      if (!/^(f|ht)tps?:\/\//i.test(image)) {
        image = SERVER_BASE_URL.slice(0, -4) + image;
      }
      return this.renderImage(image);
    }

    if (fb_id) {
      const url = 'https://graph.facebook.com/' + fb_id + '/picture?type=large';
      return this.renderImage(url);
    }

    return this.renderLetter();
  };

  render() {
    const { margin: m, tooltipProps, size, style } = this.props;
    let margin = m ? { marginLeft: 10, marginRight: 10 } : {};
    if (tooltipProps && this.props.display)
      return (
        <Tooltip {...tooltipProps} TransitionComponent={Zoom}>
          <div style={{ width: size, height: size, ...margin, ...style }}>
            {this.props.display && this.renderAvatar()}
          </div>
        </Tooltip>
      );

    return (
      <div style={{ width: size, height: size, ...margin, ...style }}>
        {this.props.display && this.renderAvatar()}
      </div>
    );
  }
}

Avatar.propTypes = {
  user: PropTypes.object,
  position: PropTypes.string,
  display: PropTypes.bool,
  size: PropTypes.number,
  round: PropTypes.bool,
  margin: PropTypes.bool,
  border: PropTypes.number,
  style: PropTypes.object,
  tooltipProps: PropTypes.object,
};

Avatar.defaultProps = {
  display: true,
  size: sizeImg,
  round: true,
  margin: true,
};
