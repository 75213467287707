import Logo123CS from './images/123cs_logo.png';
import AddBlue from './images/add_blue.png';
import AddGreen from './images/add_green.png';
import DooPageLogin from './images/artboard.png';
import ATPCareLogo from './images/atpcare-origin.png';
import ATPCareHeaderLogo from './images/atpcare-white.png';
import AutomationMarketingIcon from './images/automation-marketing.svg';
import Chatbot from './images/chatbot.png';
import Web_image from './images/chrome.svg';
import DefaultProduct from './images/default_product.svg';
import DoopageLogoBlue from './images/doopage_logo_blue.png';
import DoopageLogo from './images/doopage_logo_color.png';
import Doopage from './images/doopage_logo_gray.svg';
import DoopageHeaderLogo from './images/doopage_logo_white.png';
import EditThisCookieExportImage from './images/edit_this_cookie_export.jpg';
import Email_image from './images/email.png';
import Facebook_image from './channelsIcon/fb.png';
import FB_Profile_image from './images/fb_profile_icon.svg';
import GHN from './images/ghn.png';
import GHNIcon from './images/ghn-icon.png';
import GHTK from './images/ghtk.png';
import GHTKIcon from './images/ghtk-icon.png';
import Group_image from './images/group_image.svg';
import Zalo from './images/icon-zalo.png';
import InstagramChallengeImage from './images/instagram_challenge.jpg';
import Instagram_image from './images/instagram_image.svg';
import Telegram_image from './channelsIcon/telegram.png';
import JAndT from './images/j_and_t.png';
import KiotViet from './images/kiotviet.png';
import NhanhVN from './images/nhanhvn.png';
import Logo from './images/logo.svg';
import VnPostHeaderLogo from './images/logo_vnpost.png';
import MessengerIcon from './images/messenger.png';
import NoImageAvailable from './images/no_image_available.svg';
import Phone from './images/phone.svg';
import PhoneBlue from './images/phone_blue.svg';
import PremiumIcon from './images/premium.svg';
import Sendo_image from './images/sendo.png';
import ShopeeInboxIcon from './images/shopee-inbox.svg';
import Shopee_image from './images/shopee.png';
import TatisaleLogo from './images/tatisale-origin.png';
import TatisaleHeaderLogo from './images/tatisale-white.png';
import TopshipLogo from './images/topship-origin.png';
import TopshipHeaderLogo from './images/topship-white.png';
import TopshipIcon from './images/topship-icon.png';
import Empty from './images/undraw_empty.svg';
import Error from './images/undraw_error.svg';
import Loading from './images/undraw_loading.svg';
import QA from './images/undraw_QA.svg';
import VnPostLogo from './images/vnpost.jpg';
import VTPOST from './images/vtpost.jpeg';
import Zalo_black from './images/zalo-black.svg';
import PersonalZalo_image from './images/zalo.png';
import Zalo_image from './images/zalo_image.svg';
import Zalo_new from './channelsIcon/zalo.png';
import iTopLoginBackground from './itop/login_background.jpg';
import iTopLoginLap from './itop/login_lap.png';
import iTopHeaderLogo from './itop/logo_header.jpg';
import iTopLogo from './itop/logo_main.png';
import iTopLogoSquare from './itop/logo_square.png';
import DhlLogo from './images/dhl-logo.jpg';
import NinjavanLogo from './images/ninjavan-logo.png';
import MomaLogo from './images/moma-origin.png';
import MomaHeaderLogo from './images/moma-white.png';
import ManualIcon from './images/manual.svg';
import BulbIcon from './images/bulb.svg';
import SuccessIcon from './images/success.svg';
import WarningIcon from './images/warning.svg';
import ErrorIcon from './images/error.svg';
import KidofaLogo from './images/kidofa-origin.png';
import KidofaHeaderLogo from './images/kidofa-white.png';
import CNVInboxLogo from './images/cnvinbox-origin.png';
import CNVInboxHeaderLogo from './images/cnvinbox-white.png';
import GBMIcon from './images/gbm.svg';
import AppleLogo from './images/apple-logo.png';
import PbxLogo from './channelsIcon/pbx.svg';

export const AssetImage = {
  KiotViet,
  NhanhVN,
  GHTK,
  GHTKIcon,
  GHN,
  GHNIcon,
  VTPOST,
  Logo,
  DoopageLogo,
  Zalo,
  Zalo_black,
  Doopage,
  DoopageHeaderLogo,
  Empty,
  QA,
  Loading,
  Error,
  Logo123CS,
  Facebook_image,
  Zalo_image,
  Web_image,
  Instagram_image,
  Telegram_image,
  Shopee_image,
  Sendo_image,
  Email_image,
  PersonalZalo_image,
  Group_image,
  FB_Profile_image,
  NoImageAvailable,
  DoopageLogoBlue,
  DefaultProduct,
  AddBlue,
  AddGreen,
  VnPostHeaderLogo,
  VnPostLogo,
  TopshipIcon,
  TopshipHeaderLogo,
  TopshipLogo,
  iTopHeaderLogo,
  iTopLogo,
  iTopLogoSquare,
  iTopLoginLap,
  iTopLoginBackground,
  PremiumIcon,
  TatisaleLogo,
  TatisaleHeaderLogo,
  DooPageLogin,
  AutomationMarketingIcon,
  InstagramChallengeImage,
  EditThisCookieExportImage,
  Phone,
  ATPCareLogo,
  ATPCareHeaderLogo,
  JAndT,
  Chatbot,
  MessengerIcon,
  ShopeeInboxIcon,
  DhlLogo,
  NinjavanLogo,
  MomaLogo,
  MomaHeaderLogo,
  ManualIcon,
  BulbIcon,
  SuccessIcon,
  WarningIcon,
  ErrorIcon,
  KidofaLogo,
  KidofaHeaderLogo,
  CNVInboxLogo,
  CNVInboxHeaderLogo,
  GBMIcon,
  AppleLogo,
  Zalo_new,
  PhoneBlue,
  PbxLogo
};
