import Grid from '@material-ui/core/Grid';
import Avatar from 'component/Avatar.js';
import 'component/CartItem/CartItem.css';
import { toCurrency } from 'helper/helper.js';
import Card from 'MUI_Kit/components/Card/Card.jsx';
import Button from 'MUI_Kit/components/CustomButtons/Button.jsx';
import React from 'react';
import 'css/classes.css';

export default class CartItem extends React.Component {
  decreaseQTY = () => {
    const { data, handleChangeQTY, index } = this.props;
    handleChangeQTY(index)(data.qty - 1);
  };

  increaseQTY = () => {
    const { data, handleChangeQTY, index } = this.props;
    handleChangeQTY(index)(data.qty + 1);
  };

  remove = () => {
    const { removeItem, data, index } = this.props;
    removeItem(data, index);
  };

  render() {
    const { data, handleChangeQTY, index } = this.props;
    const { name, image, price, unit, qty, id, attribute_description } = data;
    return (
      <Grid item xs={12} key={id}>
        <Card style={{ margin: 0, overflow: 'hidden' }}>
          <Grid container style={{ padding: 15 }}>
            <Grid item style={{ marginRight: 10 }}>
              <Avatar user={{ name, avatar: image }} border={12} size={80} margin={false} />
            </Grid>
            <Grid item xs>
              <div className="TagProducts-item-text bold">{name}</div>
              {attribute_description && <div>{attribute_description}</div>}
              <div style={{ fontSize: 15, fontWeight: 500 }}>
                {toCurrency(price)}
                {unit && <span>{'/' + unit}</span>}
              </div>
              <div className="flex-start-row">
                <Button
                  color="warning"
                  round
                  justIcon
                  size="sm"
                  disabled={qty < 1}
                  onClick={this.decreaseQTY}>
                  <i className="fal fa-minus" />
                </Button>

                <input
                  type="number"
                  min="0"
                  step="0.1"
                  value={qty}
                  className="input-border"
                  onChange={handleChangeQTY(index)}
                />

                <Button
                  color="success"
                  round
                  justIcon
                  size="sm"
                  disabled={qty + 1 > 100}
                  onClick={this.increaseQTY}>
                  <i className="fal fa-plus" />
                </Button>
              </div>
              <Grid container>
                <div>
                  = &nbsp; <span className="bold">{toCurrency(qty * price)}</span>
                </div>
                <Button
                  color="transparent"
                  simple
                  round
                  size="sm"
                  justIcon
                  style={{
                    top: 0,
                    right: 0,
                    position: 'absolute',
                    opacity: 0.7,
                  }}
                  onClick={this.remove}>
                  <i className="fal fa-times" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
}
