import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import { inviteStaffs } from 'api/api.js';
import AddPermission from 'component/Modal/Staff/AddPermission.js';
import { setState } from 'config/Core';
import { trans } from 'config/LinguiConfig';
import { handleApi } from 'helper/helper.js';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Routes from 'router/routes.js';
import { infoColor, primaryColor } from 'variables/color';
import DV from 'variables/DV';
import BaseModal from '../../BaseComponent/BaseModal';

class InvitationCodeModal extends BaseModal {
  constructor(props) {
    super(props);
    this.showCloseButton = false;
    this.state = {
      open: true,
      background: primaryColor,
      color: '#fff',
      error: null,
      title: t`Tạo mã gia nhập`,
      permission: ['seller'],
      code: t`Đang lấy mã gia nhập`,
    };
    this.goPathWhenClose = Routes.staff.path;
  }

  createInviteCode = async () => {
    let { data, error } = await handleApi(
      inviteStaffs({
        invite_type: 'code',
        emails: [],
        ...this.permission.getData(),
      }),
    );
    if (error) DV.showNotify(error, 'danger');
    else {
      this.setState({ code: data.code });
    }
  };

  componentDidMount() {
    setTimeout(() => this.createInviteCode(), 200);
  }

  handleAdd =
    (name) =>
    async (...chips) => {
      chips = chips.map((chip) => (chip.trim && chip.trim()) || chip);
      await setState(this)({
        [name]: [...this.state[name], ...chips],
      });
    };

  handleDelete = (name) => async (deletedChip) => {
    await setState(this)({
      [name]: this.state[name].filter((c) =>
        c && c.name ? c.name !== deletedChip : c !== deletedChip,
      ),
    });
  };

  renderBody() {
    const { code } = this.state;
    return (
      <Grid container>
        <Grid container className="flex-start-row">
          <div style={{ marginRight: 10 }}>{t`Phân quyền:`}</div>
          <AddPermission ref={(ref) => (this.permission = ref)} onChange={this.createInviteCode} />
        </Grid>

        <Grid item xs={12} className="flex_center_col">
          <CopyToClipboard
            text={code}
            onCopy={() => DV.showNotify(t`Đã sao chép mã gia nhập`, 'info')}>
            <div style={{ fontSize: 20, color: infoColor, margin: 15 }}>{code}</div>
          </CopyToClipboard>
          <div style={{ fontStyle: 'italic' }}>
            {t`Lưu ý: Mã gia nhập sẽ hết hạn sử dụng sau 24 giờ nhằm đảm bảo tính bảo mật.`}
          </div>
        </Grid>
      </Grid>
    );
  }

  renderFooter() {
    const { code } = this.state;

    return (
      <CopyToClipboard text={code} onCopy={() => DV.showNotify(t`Đã sao chép mã gia nhập`, 'info')}>
        <Button color="info">{t`Sao chép mã gia nhập`}</Button>
      </CopyToClipboard>
    );
  }
}

export default InvitationCodeModal;
