import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import { getProducts } from 'api/api';
import CartItem from 'component/CartItem/CartItem.js';
import ProductItem from 'component/ProductItem/ProductItem.js';
import { setState } from 'config/Core';
import { trans } from 'config/LinguiConfig';
import { getHeightModal, handleApi, toCurrency } from 'helper/helper';
import _ from 'lodash';
import extendedTablesStyle from 'MUI_Kit/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import Routes, { createPath } from 'router/routes';
import { DEBOUNCE_TIME } from 'variables/staticValue';
import BaseModal from '../../BaseComponent/BaseModal';
import ListDataModal from '../ListDataModal';
import './TagProducts.css';

const styles = {
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
    borderRadius: 12,
  },
  ...extendedTablesStyle,
};

class TagProducts extends BaseModal {
  constructor(props) {
    super(props);
    this.maxWidth = 'lg';
    const dataFromRouter = this.getDataFromRouter();
    this.goPathWhenClose =
      dataFromRouter.goPathWhenClose || Routes.order.path + dataFromRouter?.searchParams;
    this.isSaveData = true;

    this.state = {
      open: true,
      products: [],
      initdata: [],
      check: true,
      page_number: 1,
      selectItem: [],
      paging: null,
      keyword: '',
      error: null,
      isCreateOrder: true,
      allowEmpty: false,
      ...this.getDataFromRouter(),
      ...this.getSaveData(),
    };
    this.searchDebounce = _.debounce(this.search, DEBOUNCE_TIME);
  }

  componentDidMount() {
    this.getListProduct(1);
  }

  updateProductsAndClose() {
    window.document.dispatchEvent(
      new CustomEvent('postback_order', {
        detail: this.state,
      }),
    );
    this.close();
  }

  search = async (keyword) => {
    await setState(this)({ keyword });
    this.getListProduct(this.state.page_number);
  };

  renderProductItem = (item, index) => {
    return (
      <ProductItem
        data={item}
        onClick={() => this.addItem(item, index)}
        isSelected={this.isSelect(item)}
      />
    );
  };

  renderBody() {
    const height = getHeightModal();
    let selectItem = this.state.selectItem || [];
    return (
      <Grid container style={{ height, overflow: 'hidden' }} spacing={2}>
        {this.renderLeft()}
        {selectItem.length > 0 && this.renderRight()}
      </Grid>
    );
  }

  renderLeft = () => {
    return (
      <Grid item xs container alignContent="flex-start">
        <Grid item xs={12}>
          <div style={{ fontSize: 20, marginBottom: 15 }} className="bold">
            Danh sách sản phẩm
          </div>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <ListDataModal
            heightList={getHeightModal(180)}
            data={this.state.products || []}
            renderItem={this.renderProductItem}
            emptyTitle={t`Bạn chưa có sản phẩm nào nào, vui lòng thêm sản phẩm`}
            loading={this.state.loading}
            loadingTitle={t`Đang tải dữ liệu...`}
            error={this.state.error}
            errorTitle={this.state.error}
            errorButton={t`Thử lại`}
            errorAction={() => this.getListProduct(this.state.page_number)}
            keyword={this.state.keyword || ''}
            paging={this.state.paging}
            goToPage={this.goToPage}
            search={this.searchDebounce}
            linkBelowSearch={{
              url: Routes.product.create.path,
              display: t`Quản lý sản phẩm / tạo sản phẩm mới`,
            }}
          />
        </Grid>
      </Grid>
    );
  };

  renderRight = () => {
    let selectItem = this.state.selectItem || [];
    return (
      <Grid item xs container alignContent="flex-start">
        <Grid
          item
          xs={12}
          className="flex-start-row"
          style={{ justifyContent: 'space-between', zIndex: 10 }}>
          <div style={{ fontSize: 20, marginBottom: 15 }} className="bold">
            {t`Giỏ hàng`}
          </div>
          <Button color="danger" onClick={this.removeAllItem}>
            {t`Xoá tất cả`}
          </Button>
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <ListDataModal
            heightList={getHeightModal(180)}
            data={selectItem}
            renderItem={this.renderCartItem}
            emptyTitle={t`Bạn chưa có sản phẩm nào trong giỏ hàng`}
          />
        </Grid>
        <Grid item xs={12}>
          {selectItem.length > 0 && (
            <div className="flex-end-row bold" style={{ fontSize: 20, marginTop: 10 }}>
              {t`Tổng cộng`}: {this.getTotal()}
            </div>
          )}
        </Grid>
      </Grid>
    );
  };

  getTotal = () => {
    const { selectItem } = this.state;
    let total = 0;
    selectItem &&
      selectItem.map((item) => {
        if (item && item.price && item.qty) total += item.price * item.qty;
      });
    return toCurrency(total);
  };

  handleChangeQTY = (index) => (event) => {
    let num = parseFloat(event.target ? event.target.value : event);
    if (num < 0) num = 1;
    let selectItem = [...this.state.selectItem];
    selectItem[index].qty = num;
    this.setState({
      selectItem,
    });
  };

  renderCartItem = (item, index) => {
    return (
      <CartItem
        data={item}
        handleChangeQTY={this.handleChangeQTY}
        index={index}
        removeItem={this.removeItem}
      />
    );
  };

  renderFooter() {
    const { loading } = this.props;
    const { isCreateOrder, allowEmpty, searchParams } = this.state;
    let selectItem = this.state.selectItem;
    return [
      ((selectItem && selectItem.length > 0) || allowEmpty) && (
        <Button
          onClick={async () => {
            if (isCreateOrder) {
              selectItem = selectItem.filter((item) => item.qty > 0);
              return this.redirectToModal(Routes.order.path + createPath, {
                selectItem,
                searchParams,
              });
            }
            return this.updateProductsAndClose();
          }}
          color="success"
          margin
          loading={loading}>
          {isCreateOrder ? t`Tạo đơn hàng` : t`Cập nhật đơn hàng`}
        </Button>
      ),
    ];
  }

  goToPage = async (page) => {
    if (!page) {
      page = this.state.page_number;
    } else {
      this.setState({ page_number: page });
    }
    await this.getListProduct(page);
  };

  getListProduct = async (page_number) => {
    this.setState({
      loading: true,
    });
    let { success, data, error } = await handleApi(
      getProducts('?page=' + page_number + '&page_size=10&q=' + this.state.keyword),
    );
    this.setState({
      loading: false,
    });
    if (success) {
      await this.setState({
        products: data.data,
        initData: data.data,
        paging: data.paging,
        error: null,
      });
      this.checkSelectItem();
    } else {
      this.setState({
        error,
      });
    }
  };

  checkSelectItem() {
    const { products, selectItem } = this.state;
    let is_updated = false;
    const _selectItem = _.clone(selectItem);
    for (const [key, item] of _.toPairs(selectItem)) {
      if (!item.price) {
        for (const product of products) {
          if (product.id === item.id) {
            is_updated = true;
            _selectItem[key] = { ...product, ...item };
            break;
          }
        }
      }
    }
    if (is_updated) {
      this.setState({ selectItem: _selectItem });
    }
  }

  isSelect = (item) => {
    return this.state.selectItem.some((elem) => elem.id === item.id);
  };

  addItem = (item) => {
    let selectItem = [...this.state.selectItem];
    let index = selectItem.findIndex((elem) => elem.id === item.id);
    if (index === -1) {
      item.qty = 1;
      selectItem.unshift(item);
    } else {
      selectItem[index].qty++;
    }
    this.setState({
      selectItem,
    });
  };

  removeItem = (item, index) => {
    let selectItem = [...this.state.selectItem];
    if (index > -1) selectItem.splice(index, 1);
    this.setState({
      selectItem,
    });
  };

  removeAllItem = () => {
    this.setState({
      selectItem: [],
    });
  };
}

export default withStyles(styles)(TagProducts);
