import { Trans } from '@lingui/macro';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { closeModal } from 'redux/action/modalAction';
import Button from '../CustomButtons/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: 20,
  },
}));

const ModalWrapper = ({
  onClose,
  message,
  hasForm,
  footerLeft,
  onChangeFileName,
  onDropFile,
  open,
  title,
  children,
  type,
  footer,
  handleSubmit,
  error,
  loading,
  keepMounted,
  extraView,
  closable,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleClose = () => {
    onClose();
    dispatch(closeModal(type));
  };

  const CustomDialogContent = (
    <DialogContent>
      {/*<ErrorMessage error={error}/>*/}
      {children}
    </DialogContent>
  );

  const CustomDialogActions = (
    <DialogActions
      style={{
        padding: '8px 24px',
        paddingBottom: 14,
        justifyContent: 'space-between',
      }}>
      <div>{footerLeft}</div>
      {(footer || closable) && (
        <div>
          <Button onClick={handleClose} color="danger" simple disabled={loading}>
            <Trans>Đóng</Trans>
          </Button>
          {footer}
        </div>
      )}
    </DialogActions>
  );

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.paper }}
      TransitionComponent={Transition}
      keepMounted={keepMounted}
      onClose={handleClose}
      disableBackdropClick={loading}
      {...rest}>
      {title && <DialogTitle style={{ textTransform: 'none' }}>{title}</DialogTitle>}
      {CustomDialogContent}
      {CustomDialogActions}
    </Dialog>
  );
};

ModalWrapper.propTypes = {
  hasForm: PropTypes.bool,
  footerLeft: PropTypes.node,
  open: PropTypes.bool.isRequired,
  title: PropTypes.node,
  error: PropTypes.node,
  children: PropTypes.node,
  footer: PropTypes.node,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  closable: PropTypes.bool,
};

ModalWrapper.defaultProps = {
  hasForm: true,
  closable: true,
  onClose: () => {},
};

export default memo(ModalWrapper);
