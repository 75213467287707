import React, { memo } from 'react';
import { t } from '@lingui/macro';
import { Space } from '@doopage/react-ui-kit';
import SelectZnsTemplate from '../../../../component/Select/SelectZnsTemplate';
import SuggestionInput from '../../../../component/SuggestionInput';

const ZNSAction = ({ hide, action, value = {}, onChange }) => {
  const { id, name, data = {} } = value;
  if (hide) return null;

  return (
    <div className="p-4 pr-5">
      <Space direction="vertical" spaceHeight={10}>
        <SelectZnsTemplate
          onChange={(_, template) => {
            onChange('zns_template', { ...value, ...template });
          }}
          value={id}
        />
        <div className="border-l-2 pl-3">
          <Space direction="vertical">
            {Object.keys(data).map((key) => {
              return (
                <div
                  className="py-2 px-3 w-full rounded-md bg-gray-200 hover:bg-gray-300 text-sm"
                  key={key}>
                  <div className="font-bold">{key}</div>
                  <SuggestionInput
                    placeholder={t`Nhập nội dung`}
                    defaultValue={data[key]}
                    onChange={(text) => {
                      const newData = { ...data, [key]: text };
                      onChange('zns_template', { ...value, data: newData });
                    }}
                  />
                </div>
              );
            })}
          </Space>
        </div>
      </Space>
    </div>
  );
};

export default memo(ZNSAction);
