import { t } from '@lingui/macro';
import { subscribeNotification } from 'api/api';
import BaseModal from 'component/BaseComponent/BaseModal';
import { DOOPAGE_FACEBOOK_PAGE_ID, DOOPAGE_ZALO_OA_ID, FACEBOOK_APP_ID } from 'config/Env';
import { handleApi } from 'helper/helper';
import _ from 'lodash';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomSwitch from 'MUI_Kit/components/Switch/CustomSwitch';
import React from 'react';
import MessengerPlugin from 'react-messenger-plugin'
import { v4 as uuid } from 'uuid';
import DV from 'variables/DV';

class SubscribeSocialModal extends BaseModal {
  userRef = uuid();
  keepMounted = true;
  state = {
    subscribe_promotion: true,
  };

  open = () =>
    this.setState({
      open: true,
      title: this.getTitle(),
    });

  getTitle() {
    return t`Đăng ký nhận thông báo`;
  }

  handleSubmit = async () => {
    let payload = {
      fb_page_id: DOOPAGE_FACEBOOK_PAGE_ID,
      fb_user_ref: this.userRef,
      subscribe_promotion: false,
      open_notification_subscribe_dialog: false,
    };

    if (this.userZaloOAId)
      payload = {
        ...payload,
        zalo_oa_id: DOOPAGE_ZALO_OA_ID,
        zalo_oa_user_id: this.userZaloOAId,
      };

    this.setState({ isLoadingModal: true });
    const staffId = _.get(DV, 'owner.last_staff.id');
    let { success, error } = await handleApi(subscribeNotification(staffId, payload));
    this.setState({ isLoadingModal: false });

    if (success) {
      this.close();
      DV.showNotify(t`Đăng ký nhận thông báo thành công`);
    } else DV.showNotify(error, 'danger');
  };

  renderFooter() {
    return (
      <>
        <Button
          loading={this.state.isLoadingModal}
          style={{ marginRight: 15 }}
          onClick={this.handleSubmit}
          color="info">
          {t`Hoàn tất`}
        </Button>
      </>
    );
  }

  componentDidMount() {
    window.zaloCallback = ({ data, userId }) => {
      console.log('callback zalo', { data, userId });
      this.userZaloOAId = userId;
    };
  }

  renderBody() {
    return (
      <div>
        {t`Nhận thông báo những vấn đề có thể ảnh hưởng tới vận hành công ty của bạn nhằm phòng tránh hoặc khắc phục nhanh nhất có thể.`}
        <div className="bold flex-start-row" style={{ alignItems: 'flex-start', marginTop: 20 }}>
          <div style={{ marginRight: 10, width: 150 }}>{t`Nhận qua Messenger:`}</div>
         <div className="flex-center-row">
           <MessengerPlugin
             type="fb-messenger-checkbox"
             pageId={DOOPAGE_FACEBOOK_PAGE_ID}
             appId={FACEBOOK_APP_ID}
             origin={window.location.hostname}
             onRef={ref => (this.userRef = ref)}
           />
         </div>
        </div>
        <div className="bold flex-start-row" style={{ alignItems: 'flex-start', marginTop: 20 }}>
          <div style={{ marginRight: 10, width: 150 }}>{t`Nhận qua Zalo:`}</div>
          <div
            dangerouslySetInnerHTML={{
              __html: `<div class="zalo-follow-only-button" data-oaid=${DOOPAGE_ZALO_OA_ID} data-callback="zaloCallback"/>`,
            }}
          />
        </div>
        <div className={'flex-start-row'} style={{ marginTop: 5, marginLeft: -10 }}>
          <CustomSwitch
            checked={!!this.state.subscribe_promotion}
            onChange={(e) => this.handleChange('subscribe_promotion')(e.target.checked)}
            color={'primary'}
          />
          <div className={'bold'}>{t`Tôi muốn nhận thông tin khuyến mãi, ưu đãi.`}</div>
        </div>
      </div>
    );
  }
}

export default SubscribeSocialModal;
