import AddAlert from '@material-ui/icons/AddAlert';
import { setState } from 'config/Core';
import Snackbar from 'MUI_Kit/components/Snackbar/Snackbar';
import React, { PureComponent } from 'react';
import DV from 'variables/DV';
import { ALERT_ERROR_SHOW_TIME, ALERT_SHOW_TIME } from 'variables/staticValue';

class CustomSnackbar extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      notifyMessage: '',
      notifyColor: 'success',
    };
  }

  hideNotify = () => this.setState({ notifyMessage: '' });

  showNotify = async (msg, color = 'success', second = -1) => {
    if (second < 0) {
      second = color === 'danger' ? ALERT_ERROR_SHOW_TIME : ALERT_SHOW_TIME;
    }
    await setState(this)({
      notifyMessage: <span dangerouslySetInnerHTML={{ __html: msg }} />,
      notifyColor: color,
    });
    setTimeout(this.hideNotify, second * 1000);
  };

  async componentDidMount() {
    DV.showNotify = this.showNotify;
  }

  componentWillUnmount() {
    DV.showNotify = () => {};
  }

  render() {
    return (
      <Snackbar
        place="tr"
        icon={AddAlert}
        color={this.state.notifyColor}
        message={this.state.notifyMessage || ''}
        open={!!this.state.notifyMessage}
        closeNotification={this.hideNotify}
        close
      />
    );
  }
}

export default CustomSnackbar;
