import { t } from '@lingui/macro';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import React, { memo } from 'react';
import Avatar from '../../../component/Avatar';
import { trans } from '../../../config/LinguiConfig';
import CustomChart from '../components/CustomChart/CustomChart';
import { createCanvas } from '../utils';
import { thousandSeparate, toCurrency } from '../../../helper/helper';

const getOption = () => ({
  plugins: [ChartAnnotation],
  aspectRatio: 1,
  tooltips: {
    enabled: false,
    position: 'nearest',
    mode: 'nearest',
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    intersect: 0,
  },
  annotation: {
    drawTime: 'beforeDatasetsDraw',
    annotations: [
      {
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: 0,
        borderColor: '#eeeeee',
        borderWidth: 2,
        borderDash: [3, 3],
      },
    ],
  },
  legend: {
    display: false,
  },
  elements: {
    point: {
      pointStyle: (context) => {
        if (!context) return;
        const { avatar, r } = context?.dataset?.data?.[context.dataIndex] || {};
        return avatar ? createCanvas(r + 5, avatar) : 'circle';
      },
    },
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          labelString: t`Số đơn`,
        },
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return thousandSeparate(value);
            }
          },
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          labelString: t`Doanh thu`,
        },
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return toCurrency(value);
            }
          },
        },
      },
    ],
  },
});

const getColumns = () => [
  {
    Header: '',
    accessor: 'avatar',
    className: 'flex-center-row',
    width: 80,
    Cell: ({ value }) => {
      return <Avatar user={{ avatar: value }} size={30} margin={false} />;
    },
  },
  {
    Header: '',
    accessor: 'name',
  },
  {
    Header: t`Doanh thu`,
    width: 100,
    accessor: 'y',
    Cell: ({ value }) => {
      return toCurrency(value);
    },
  },
  {
    Header: t`Đơn hàng`,
    width: 100,
    accessor: 'total_order',
  },
  {
    Header: t`Hiệu suất chốt`,
    width: 100,
    accessor: 'percent',
  },
];

const StaffOrderPerformance = (props) => {
  return <CustomChart getOption={getOption} columns={getColumns()} {...props} />;
};

export default memo(StaffOrderPerformance);
