import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import Icon from '@material-ui/core/Icon/Icon';
import Typography from '@material-ui/core/Typography/Typography';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import { createVariant, getProductAttributes, updateVariants } from 'api/api';
import { setState } from 'config/Core';
import { trans } from 'config/LinguiConfig';
import { handleApi } from 'helper/helper';
import _ from 'lodash';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import CustomSelect from 'MUI_Kit/components/CustomSelect/CustomSelect';
import GridContainer from 'MUI_Kit/components/Grid/GridContainer';
import GridItem from 'MUI_Kit/components/Grid/GridItem';
import React from 'react';
import Routes, { createPath } from 'router/routes';
import { xPath } from 'testingClasses';
import DV from 'variables/DV';
import BaseModal from '../../BaseComponent/BaseModal';
import CustomNumberFormat from '../../CustomNumberFormat';

class VariantModal extends BaseModal {
  constructor(props) {
    super(props);
    const params = this.getDataFromRouter();
    if (_.isEmpty(params)) this.redirectTo(Routes.product.path);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      height: params.package_size?.height || 0,
      width: params.package_size?.width || 0,
      length: params.package_size?.length || 0,
      attr: params?.attributes ?? [{ name: '', value: '' }],
      allAttribute: [],
      ...params,
    };
    this.goPathWhenClose = Routes.product.path;
  }

  getTitle() {
    const { id } = this.getDataFromRouter();
    if (id) return t`Cập nhật biến thể`;
    else return t`Thêm biến thể`;
  }

  async componentDidMount() {
    let { data, error } = await handleApi(getProductAttributes());
    if (error) DV.showNotify(error, 'danger');
    else {
      this.setState({ allAttribute: data });
    }
  }

  update = async (price, sku, attributes, package_size, id) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(
      updateVariants(id, {
        price,
        attributes,
        sku,
        package_size,
      }),
    );
    this.setState({ loading: false });
    if (success) {
      this.redirectTo(Routes.variant.path, DV.currentProduct);
      DV.showNotify(t`Đã cập nhật biến thể`);
    } else DV.showNotify(error, 'danger');
  };

  add = async (price, sku, attributes, package_size) => {
    this.setState({ loading: true });
    let { success, data, error } = await handleApi(
      createVariant(DV.currentProduct.id, {
        price,
        attributes,
        sku,
        package_size,
      }),
    );
    this.setState({ loading: false });
    if (success) {
      this.redirectTo(Routes.variant.path, DV.currentProduct);
      DV.showNotify(t`Thêm biến thể thành công`);
    } else DV.showNotify(error, 'danger');
  };

  handleChangeTypeAttribute = (index) => async (event) => {
    let data = [...this.state.attr];
    data[index].attribute = event;
    await setState(this)({ attr: data });
  };

  handleChangeValueAttribute = (index) => async (event) => {
    let data = [...this.state.attr];
    data[index].value = event.target ? event.target.value : event;
    await setState(this)({ attr: data });
  };

  handleAttribute = (index = -1) => {
    let { allAttribute, attr } = this.state;
    if (index >= 0) {
      attr.splice(index, 1);
    } else {
      if (attr.length < allAttribute.length) attr.push({ attribute: 0, value: '' });
    }

    this.setState({
      attr: [...attr],
    });
  };

  getAttributeSelect = (index) => {
    let dataSelect = [];
    let { allAttribute, attr } = this.state;

    for (let i = 0; i < allAttribute.length; i++) {
      let t = attr.findIndex((elem) => elem.attribute === allAttribute[i].id);
      if (t < 0 || t === index) {
        dataSelect.push({
          key: allAttribute[i].id,
          value: allAttribute[i].name,
        });
      }
    }
    return dataSelect;
  };

  renderAttribute = (data, isAttributesEmpty) => {
    if (isAttributesEmpty) {
      return (
        <GridContainer style={{ justifyContent: 'center', marginTop: 10 }}>
          <Typography variant="title" gutterBottom align="center">
            {t`Hiện chưa có thuộc tính nào. Vui lòng thêm thuộc tính.`}
          </Typography>

          <Button
            color="info"
            round
            helperText={t`Thêm thuộc tính`}
            onClick={() => this.redirectTo(Routes.attribute.path + createPath)}>
            {t`Thêm thuộc tính`}
          </Button>
        </GridContainer>
      );
    }

    return data.map((item, index) => {
      let dataSelect = this.getAttributeSelect(index);
      if (dataSelect.length === 0) return null;
      return (
        <GridContainer key={index}>
          <GridItem xs={5}>
            <div style={{ marginTop: 7, width: '100%' }} className={xPath.select_('attribute')}>
              <CustomSelect
                label={t`Chọn thuộc tính`}
                data={dataSelect || []}
                value={data[index].attribute || ''}
                formControlProps={{ fullWidth: true }}
                onChange={this.handleChangeTypeAttribute(index)}
              />
            </div>
          </GridItem>
          <GridItem xs={5}>
            <CustomInput
              labelText={t`Giá trị`}
              inputProps={{
                value: data[index].value,
                onChange: this.handleChangeValueAttribute(index),
              }}
            />
          </GridItem>
          <GridItem xs={2} className="flex-end-row">
            <Button
              color="danger"
              round
              justIcon
              style={{ marginTop: 15 }}
              helperText={t`Xoá thuộc tính này`}
              onClick={() => {
                this.handleAttribute(index);
              }}>
              <Icon>close</Icon>
            </Button>
          </GridItem>
        </GridContainer>
      );
    });
  };

  renderBody() {
    const { attr, price, sku, allAttribute, width, height, length } = this.state;
    let isAttributesEmpty = !allAttribute || (allAttribute && allAttribute.length === 0);
    return [
      <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
          <CustomInput
            labelText="Giá"
            inputProps={{
              placeholder: t`Nhập vào giá biến thể`,
              required: true,
              value: price || '',
              onChange: this.handleChange('price'),
              inputComponent: CustomNumberFormat,
            }}
          />
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1} xl={1} style={{ marginTop: 35 }}>
          <div style={{ fontSize: 18 }} className="flex-center-row">
            /
          </div>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
          <CustomInput
            labelText="Sku"
            inputProps={{
              placeholder: t`Nhập vào Sku`,
              required: true,
              value: sku || '',
              onChange: this.handleChange('sku'),
            }}
          />
        </Grid>
      </Grid>,
      <Grid container spacing={2} style={{ alignItems: 'flex-end' }}>
        <Grid
          item
          style={{
            position: 'relative',
            bottom: 5,
          }}>
          <span style={{ fontSize: 14 }}>{t`Kích Thước`}</span> &nbsp;
          <small style={{ fontSize: 8 }}>{t`(DxRxC)`}</small> &nbsp; :
        </Grid>
        <Grid item xs={2}>
          <CustomInput
            inputProps={{
              type: 'number',
              required: true,
              value: length || 0,
              onChange: this.handleChange('length'),
              endAdornment: <InputAdornment position="end">Cm</InputAdornment>,
            }}
          />
        </Grid>
        <span style={{ position: 'relative', bottom: 20 }}>x</span>
        <Grid item xs={2}>
          <CustomInput
            inputProps={{
              type: 'number',
              required: true,
              value: width || 0,
              onChange: this.handleChange('width'),
              endAdornment: <InputAdornment position="end">Cm</InputAdornment>,
            }}
          />
        </Grid>
        <span style={{ position: 'relative', bottom: 20 }}>x</span>
        <Grid item xs={2}>
          <CustomInput
            inputProps={{
              type: 'number',
              required: true,
              value: height || 0,
              onChange: this.handleChange('height'),
              endAdornment: <InputAdornment position="end">Cm</InputAdornment>,
            }}
          />
        </Grid>
      </Grid>,
      <Grid container justify="space-between">
        <Grid item>
          <div style={{ fontSize: 18, marginTop: 20 }}>{t`Thuộc tính`}</div>
        </Grid>
        <Grid item>
          {attr.length < allAttribute.length && (
            <Button
              color="success"
              round
              justIcon
              helperText={t`Thêm thuộc tính`}
              style={{ marginTop: 15 }}
              onClick={() => {
                this.handleAttribute();
              }}>
              <Icon>add</Icon>
            </Button>
          )}
        </Grid>
      </Grid>,
      this.renderAttribute(attr, isAttributesEmpty),
    ];
  }

  validate = () => {
    const { attr, price, sku, id } = this.state;
    let invalidAttribute = false;
    for (let i = 0; i < attr.length; i++) {
      if (!attr[i].value || attr[i].value === '') {
        invalidAttribute = true;
        break;
      }
    }
    if (invalidAttribute) {
      DV.showNotify(t`Giá trị thuộc tính không hợp lệ`, 'danger');
      return false;
    }
    if (price === '') {
      DV.showNotify(t`Vui lòng nhập giá tiền tệ`, 'danger');
      return false;
    }
    if (parseInt(price) < 0) {
      DV.showNotify(t`Giá tiền không hợp lệ`, 'danger');
      return false;
    }
    if (sku === '') {
      DV.showNotify(t`Vui lòng nhập Sku`, 'danger');
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { attr, price, sku, id, height, width, length } = this.state;
    const package_size = { height, width, length };
    if (this.validate()) {
      if (id) {
        this.update(price, sku, attr, package_size, id);
      } else {
        this.add(price, sku, attr, package_size);
      }
    }
  };

  renderFooter() {
    return (
      <Button loading={this.state.loading} onClick={this.handleSubmit} color="info">
        {t` Hoàn tất`}
      </Button>
    );
  }
}

export default VariantModal;
