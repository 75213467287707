import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow/Grow';
import Paper from '@material-ui/core/Paper/Paper';
import Popper from '@material-ui/core/Popper/Popper';
import { setState } from 'config/Core';
import React from 'react';
import { checkPermission } from '../../helper/helperPermission';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import DV from '../../variables/DV';

export default class BaseMenuGrow extends React.PureComponent {
  state = {
    open: false,
    anchorEl: null,
  };

  margin = 5;
  marginRight = 5;
  marginLeft = 8;
  placement = 'bottom';
  clickOutsideToClose = true;
  closeOther = false;
  modifiers = {
    preventOverflow: {
      enabled: false,
      boundariesElement: 'scrollParent',
    },
  };
  disablePortal = true;

  constructor(props) {
    super(props);
  }

  handleChange = (name) => async (event) => {
    await setState(this)({
      [name]: event.target ? event.target.value : event,
    });
  };

  handleToggle = async (event) => {
    if (this.props.permission) {
      if (!checkPermission(this.props.permission, DV.permission)) return;
    }
    const { currentTarget } = event;
    let { open } = this.state;
    open = !open;
    await setState(this)({
      anchorEl: currentTarget,
      open,
    });

    console.log('handleToggle', open);
    if (this.closeOther && DV.closeModal && DV.closeModal !== this.handleClose && open)
      DV.closeModal();
    if (open) DV.closeModal = this.handleClose;
  };

  handleClose = (event) => {
    // console.log('handleClose', this.constructor.name);
    const { currentTarget } = event || {};
    if (event && this.state?.anchorEl && this.state?.anchorEl?.contains(event.target)) {
      // this.setState({open: false});
      // DV.closeModal = null;
      return;
    }
    this.setState({ open: false, anchorEl: currentTarget });
    DV.closeModal = null;
  };

  renderContent() {}

  renderButton() {
    const { disabled } = this.props;
    const { open, title } = this.state;
    return (
      <Button
        disabled={disabled}
        aria-owns={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={this.handleToggle}
        color="white"
        style={{ textTransform: 'none', fontSize: 15, fontWeight: 400 }}>
        {title}
      </Button>
    );
  }

  render() {
    const { anchorEl, open } = this.state;
    return (
      <div style={{ zIndex: 5 }}>
        {this.renderButton()}
        <ClickAwayListener
          onClickAway={(e) => {
            const targetID = e?.target?.id ?? '';
            //Do not close popper if click to portal (body element) or select menu
            if (targetID !== 'body' && !targetID.includes('select')) {
              this.clickOutsideToClose && this.handleClose(e);
            }
          }}>
          <Popper
            open={open}
            anchorEl={anchorEl}
            placement={this.placement}
            transition
            disablePortal={this.disablePortal}
            modifiers={this.modifiers}
            style={{ zIndex: 9999 }}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="menu-list-grow"
                style={{
                  transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                }}>
                <Paper
                  style={{
                    margin: this.margin,
                    marginRight: this.marginRight,
                    marginLeft: this.marginLeft,
                    borderRadius: 10,
                    overflow: 'auto',
                    maxHeight: 500,
                  }}>
                  {this.renderContent()}
                </Paper>
              </Grow>
            )}
          </Popper>
        </ClickAwayListener>
      </div>
    );
  }
}
