import { Accordion, Space, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import SelectOrderStatus from 'component/Select/SelectOrderStatus';
import React, { memo } from 'react';

const OrderStatusCondition = ({ orderStatus, onChange }) => {
  const { active = false, from, to } = orderStatus ?? {};

  const update = (path, value) => {
    onChange(`trigger_conditions.order_status.${path}`, value);
  };

  console.log('>> dd', { from, to });

  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <i className="fad fa-exchange-alt mr-3 text-2xl" />
            {t`Khi đơn hàng đổi trạng thái`}
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('active', !active)}>
        <Space direction="vertical">
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 50 }} className="text-lg opacity-70">
              {t`Sang`}
            </div>
            <SelectOrderStatus
              key={to?.length || 'init'}
              multiple
              fullWidth
              value={to}
              onChange={(value) => update('to', value)}
              label={t`một trong các trạng thái`}
              placeholder={t`Chọn trạng thái`}
            />
          </div>
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 50 }} className="text-lg opacity-70">
              {t`Từ`}
            </div>
            <SelectOrderStatus
              key={from?.length || 'init'}
              multiple
              fullWidth
              value={from}
              onChange={(value) => update('from', value)}
              label={t`một trong các trạng thái`}
              placeholder={t`Chọn trạng thái`}
            />
          </div>
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(OrderStatusCondition);
