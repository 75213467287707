import { blue, orange } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

export const MuiTheme = createMuiTheme({
  palette: {
    primary: orange,
    secondary: blue,
  },
  typography: {
    fontFamily: ['"Open Sans"'].join(','),
    fontSize: 14,
    useNextVariants: true,
  },
});
