import {
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  successCardHeader,
  warningCardHeader,
} from '../../../../assets/jss/material-dashboard-pro-react.jsx';

const cardIconStyle = {
  cardIcon: {
    '&$warningCardHeader,&$successCardHeader,&$dangerCardHeader,&$infoCardHeader,&$primaryCardHeader,&$roseCardHeader':
      {
        borderRadius: '3px',
        backgroundColor: '#999',
        padding: 10,
        marginTop: '-20px',
        marginRight: '15px',
        float: 'left',
        height: 58,
      },
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
};

export default cardIconStyle;
