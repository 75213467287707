import { Accordion, Space, Switch } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { Card } from '@material-ui/core';
import { trans } from 'config/LinguiConfig';
import React, { memo } from 'react';
import SelectRating from '../../../../component/Select/SelectRating';

const RatingCondition = ({ rating, onChange, show = true }) => {
  const { active = false, stars = [] } = rating;

  const update = (path, value) => {
    onChange(`trigger_conditions.rating.${path}`, value);
  };

  if (!show) return null;

  return (
    <Card>
      <Accordion
        expanded={!!active}
        title={
          <>
            <i className="fas fa-star mr-3 text-2xl" />
            {trans(t`Khi nhận đánh giá`)}
          </>
        }
        expandIcon={<Switch checked={active} />}
        rotateExpandIcon={false}
        onChange={() => update('active', !active)}>
        <Space direction="vertical">
          <div className="flex items-end" style={{ marginRight: 8 }}>
            <div style={{ width: 60 }} className="text-lg opacity-70">
              {trans(t`Thuộc`)}
            </div>
            <SelectRating
              multiple
              fullWidth
              label={trans(t`một các giá trị`)}
              placeholder={trans(t`chọn giá trị`)}
              onChange={(value) => {
                update('stars', value);
              }}
              value={stars}
            />
          </div>
        </Space>
      </Accordion>
    </Card>
  );
};

export default memo(RatingCondition);
