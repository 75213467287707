import { t } from '@lingui/macro';
import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SelectLanguage from 'screen/Channel/Website/components/SelectLanguage';
import GreetingMessage from 'screen/Channel/Website/components/GreetingMessage';

const ListGreetingMessage = ({ languages, value, onChange, ...props }) => {
  const [language, setLanguage] = useState(languages[0]);
  const handleChange = (language, propKey) => {
    return (newValue) => {
      value[language][propKey] = newValue;
      onChange(value);
    };
  };

  useEffect(() => {
    if(!language) {
      setLanguage(languages[0]);
    }
  }, [languages]);
  useEffect(() => {
    let isHaveChange = false;
    for (const language of languages) {
      if (!value[language]) {
        value[language] = {
          welcome_message: '',
          conversation_starters: [],
        };
        isHaveChange = true;
      }
    }
    if (isHaveChange) {
      onChange(value);
    }
  });
  return (
    <Grid container sm={12} spacing={2} {...props}>
      <Grid container item sm={12}>
        <Grid item sm={4}>
          <SelectLanguage
            value={language ||''}
            options={languages}
            label={t`Ngôn ngữ`}
            onChange={setLanguage}
          />
        </Grid>
      </Grid>
     <div className="pl-5 mt-2">
       {value[language] && (
         <GreetingMessage
           welcomeMessage={value[language].welcome_message}
           onChangeWelcomeMessage={handleChange(language, 'welcome_message')}
           conversationStarters={value[language].conversation_starters}
           onChangeConversationStarters={handleChange(language, 'conversation_starters')}
         />
       )}
     </div>
    </Grid>
  );
};

export default ListGreetingMessage;
