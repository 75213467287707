import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid.js';
import withStyles from '@material-ui/core/styles/withStyles.js';
import { createExtraOrder, getProducts } from 'api/api.js';
import CartItem from 'component/CartItem/CartItem.js';
import ProductItem from 'component/ProductItem/ProductItem.js';
import { trans } from 'config/LinguiConfig.js';
import { getHeightModal, handleApi, toCurrency } from 'helper/helper.js';
import _ from 'lodash';
import extendedTablesStyle from 'MUI_Kit/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx';
import Button from 'MUI_Kit/components/CustomButtons/Button.jsx';
import React from 'react';
import Routes, { getDetailPath } from 'router/routes.js';
import DV from 'variables/DV.js';
import { DEBOUNCE_TIME } from 'variables/staticValue.js';
import Avatar from 'component/Avatar.js';
import BaseModal from 'component/BaseComponent/BaseModal.js';
import ListDataModal from 'component/Modal/ListDataModal.js';
import 'component/Modal/TagProducts/TagProducts.css';

const styles = {
  dialogPaper: {
    minHeight: '90vh',
    maxHeight: '90vh',
    borderRadius: 12,
  },
  ...extendedTablesStyle,
};

class CompensationModal extends BaseModal {
  constructor(props) {
    super(props);
    let data = this.getDataFromRouter();
    if (_.isEmpty(data)) {
      this.redirectTo(Routes.order.path);
    }

    this.maxWidth = 'lg';
    this.searchDebounce = _.debounce(this.search, DEBOUNCE_TIME);

    this.state = {
      open: true,
      products: [],
      initdata: [],
      check: true,
      page_number: 1,
      paging: null,
      keyword: '',
      error: null,
      ...data,
    };

    this.goPathWhenClose = Routes.order.path;
  }

  componentDidMount() {
    this.getListProduct(1);
  }

  search = async (keyword) => {
    await this.setState({ keyword });
    this.getListProduct(this.state.page_number);
  };

  renderProductItem = (item, index) => {
    return (
      <ProductItem
        data={item}
        onClick={() => this.addItem(item, index)}
        isSelected={this.isSelect(item)}
      />
    );
  };

  renderBody() {
    const height = getHeightModal();
    const { customer, phone, address, recipient_name: name } = this.state;
    return (
      <Grid container style={{ height, overflow: 'hidden' }}>
        <Grid item xs={12} className="flex-center-row" style={{ height: 50 }}>
          <div style={{ fontSize: 25, marginBottom: 15 }} className="text bold">
            {t`CHỌN SẢN PHẨM ĐỂ BÙ HÀNG`}
          </div>
        </Grid>
        <Grid container>
          {customer && (
            <Grid item xs={2} md={1}>
              <Avatar user={customer} size={70} margin={false} />
            </Grid>
          )}
          <Grid item xs={10} md={11}>
            <div className="text bold">{name}</div>
            <div className="text">{phone}</div>
            <div className="text">{address}</div>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 20 }}>
          {this.renderLeft()}
          {this.renderRight()}
        </Grid>
      </Grid>
    );
  }

  renderLeft = () => {
    return (
      <Grid item xs={6}>
        <Grid container>
          <Grid item xs={12}>
            <div style={{ fontSize: 20, marginBottom: 15 }}>Danh sách sản phẩm</div>
          </Grid>
          <Grid item xs={12}>
            <ListDataModal
              heightList={getHeightModal(150)}
              data={this.state.products || []}
              renderItem={this.renderProductItem}
              emptyTitle={t`Bạn chưa có sản phẩm nào nào, vui lòng thêm sản phẩm`}
              loading={this.state.loading}
              loadingTitle={t`Đang tải dữ liệu...`}
              error={this.state.error}
              errorTitle={this.state.error}
              errorButton={t`Thử lại`}
              errorAction={() => this.getListProduct(this.state.page_number)}
              keyword={this.state.keyword || ''}
              paging={this.state.paging}
              goToPage={this.goToPage}
              search={this.searchDebounce}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderRight = () => {
    let selectItem = this.state.selectItem || [];

    return (
      <Grid item xs={6} sm={6} md={6} style={{ paddingLeft: 25 }}>
        <div className="flex-center-row" style={{ justifyContent: 'space-between' }}>
          <div style={{ fontSize: 20, marginBottom: 15 }}>{t`Sản phẩm đã chọn`}</div>
          <Button color="danger" onClick={this.removeAllItem}>
            {t`Xoá tất cả`}
          </Button>
        </div>

        <ListDataModal
          heightList={getHeightModal(325)}
          data={selectItem}
          renderItem={this.renderCartItem}
          emptyTitle={t`Bạn chưa có sản phẩm nào trong giỏ hàng`}
        />
        {selectItem.length > 0 && (
          <div style={{ fontSize: 20, marginTop: 10 }}>
            <Grid item className="flex-center-row">
              <Grid item xs={4}></Grid>
              <Grid item xs={4} className="flex-start-row">
                {t`Tổng cộng:`}
              </Grid>
              <Grid item xs={4}>
                <div className="text bold flex-end-row">{this.getTotal()}</div>
              </Grid>
            </Grid>

            {/*<Grid className="flex-center-row">*/}
            {/*    <Grid item xs={4}>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={4} className='flex-start-row'>*/}
            {/*        {(t`Chênh lệch:`)}*/}
            {/*    </Grid>*/}

            {/*    <Grid item xs={4}>*/}
            {/*        <div className="text bold flex-end-row">{this.getTotal()}</div>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}
          </div>
        )}
      </Grid>
    );
  };

  getTotal = () => {
    const { selectItem } = this.state;
    let total = 0;
    selectItem &&
      selectItem.map((item) => {
        if (item && item.price && item.qty) total += item.price * item.qty;
      });
    return toCurrency(total);
  };

  handleChangeQTY = (index) => (event) => {
    let num = parseFloat(event.target ? event.target.value : event);
    if (num < 0) num = 1;
    let selectItem = [...this.state.selectItem];
    selectItem[index].qty = num;
    this.setState({
      selectItem,
    });
  };

  renderCartItem = (item, index) => {
    return (
      <CartItem
        data={item}
        handleChangeQTY={this.handleChangeQTY}
        index={index}
        removeItem={this.removeItem}
      />
    );
  };

  createCompensationOrder = async () => {
    let { selectItem, id } = this.state;
    let products = selectItem.map((item) => ({
      product: item.id,
      qty: item.qty,
    }));
    let payload = {
      products,
      order_type: 'compensation',
    };
    this.setState({ loading: true });
    let { success, error, data } = await handleApi(createExtraOrder(id, payload));
    this.setState({ loading: false });
    if (success) {
      DV.showNotify(t`Tạo đơn bù hàng thành công`);
      this.redirectToModal(Routes.order.path + getDetailPath(id), data);
    } else DV.showNotify(error, 'danger');
  };

  renderFooter() {
    const { loading } = this.state;
    let selectItem = this.state.selectItem;
    return [
      selectItem && selectItem.length > 0 && (
        <Button onClick={this.createCompensationOrder} color="success" margin loading={loading}>
          {t`Tạo đơn bù hàng`}
        </Button>
      ),
    ];
  }

  goToPage = async (page) => {
    if (!page) {
      page = this.state.page_number;
    } else {
      this.setState({ page_number: page });
    }
    await this.getListProduct(page);
  };

  getListProduct = async (page_number) => {
    this.setState({
      loading: true,
    });
    let { success, data, error } = await handleApi(
      getProducts('?page=' + page_number + '&page_size=6&q=' + this.state.keyword),
    );
    this.setState({
      loading: false,
    });
    if (success) {
      this.setState({
        products: data.data,
        initData: data.data,
        paging: data.paging,
        error: null,
      });
    } else {
      this.setState({
        error,
      });
    }
  };

  isSelect = (item) => {
    return this.state.selectItem.some((elem) => elem.id === item.id);
  };

  addItem = (item) => {
    let selectItem = [...this.state.selectItem];
    let index = selectItem.findIndex((elem) => elem.id === item.id);
    if (index === -1) {
      item.qty = 1;
      selectItem.unshift(item);
    } else {
      selectItem[index].qty++;
    }
    this.setState({
      selectItem,
    });
  };

  removeItem = (item, index) => {
    let selectItem = [...this.state.selectItem];
    if (index > -1) selectItem.splice(index, 1);
    this.setState({
      selectItem,
    });
  };

  removeAllItem = () => {
    this.setState({
      selectItem: [],
    });
  };
}

export default withStyles(styles)(CompensationModal);
