import { t } from "@lingui/macro";
import { AssetImage } from "assets/Asset";
import { trans } from "config/LinguiConfig";
import { isDebug } from "config/LogConfig";
import dagre from "dagre";
import produce from "immer";
import LZUTF8 from "lzutf8";
import React from "react";
import { v4 as genUuid } from "uuid";
import { HEIGHT, isLocalhost } from "variables/staticValue";
import { removePunctuation } from "variables/utils";
import LocalStorage from "../../config/LocalStorage";

export const connectedScenarios = {};
export const nodeHeight = 300;
export const nodeWidth = 270;
export const nodeSpacing = 15;
export const debugScenario = isLocalhost && true;

export const getNodeWidth = () => {
  return nodeWidth;
};

export const getTextHeight = (text = "", width = nodeWidth - nodeSpacing * 2 - 10 * 2) => {
  if (!text) return 0;

  const letterWidth = 6;
  const letterHeight = 20;

  const textWidth = text.length * letterWidth;
  const numOfRow = Math.ceil(textWidth / width);

  return numOfRow * letterHeight;
};

export const getNodeHeight = (scenario) => {
  let height = 40; // action

  // calc header height
  const { name, trigger_conditions, comment_keywords, actions } = scenario;
  height += nodeSpacing; //padding
  height += getTextHeight(name, nodeWidth - nodeSpacing * 2); // scenario name

  const { referral, segments, tags } = trigger_conditions;
  height += (!!comment_keywords + !!referral + !!segments + !!tags) * 40;
  height += nodeSpacing; //padding

  actions.map(({ message = {}, reply_type, action_data = {} }) => {
    const { title, subtitle, buttons = [], image_url } = message;
    const { tag, segment } = action_data || {};

    height += nodeSpacing; //padding
    height += 10; //padding
    if (image_url) height += 300 + 10; //image
    height += getTextHeight(title);
    height += 5;
    height += getTextHeight(subtitle);
    buttons.forEach(() => (height += 38));

    height += (!!tag + !!segment + (reply_type === "send_order_form")) * 25;
    height += 10; //padding
    height += nodeSpacing; //padding
  });

  height += nodeSpacing; //padding

  return height;
};

const mapIdWithUUID = {};
const mapUUIDWithId = {};
const existScenarioUUIDs = {};

export const getScenarioId = (uuid) => {
  return mapUUIDWithId[uuid];
};

export const getScenarioUUID = (id) => {
  return mapIdWithUUID[id];
};

export const createScenarioUUID = (scenario) => {
  const { id } = scenario;
  const uuid = scenario?.uuid || getScenarioUUID(id) || genUuid();

  mapUUIDWithId[uuid] = id;
  mapIdWithUUID[id] = uuid;

  return uuid;
};

export const createScenariosWithUUID = (scenarios) => {
  return scenarios.map((scenario) => {
    const uuid = createScenarioUUID(scenario);
    existScenarioUUIDs[uuid] = true;

    return {
      ...scenario,
      uuid,
      actions: scenario.actions.map((item) => ({
        ...item,
        uuid: createScenarioUUID(item)
      }))
    };
  });
};

export const createEdges = (scenarios) => {
  let listEdges = [];

  scenarios.forEach((scenario) => {
    const { actions, uuid: rootUUID } = scenario;

    actions.forEach((action) => {
      const { config, message = {}, carousel, uuid: actionUUID } = action;
      const { scenario_uuid } = config?.fallback || {};

      const source = rootUUID;
      const target = scenario_uuid;

      if (existScenarioUUIDs[target]) {
        connectedScenarios[source] = true;
        connectedScenarios[target] = true;

        const handleId = getHandleId({ type: "fallback", actionUUID });

        listEdges.push({
          id: compressObj({
            source,
            target,
            sourceHandle: handleId
          }),
          source,
          sourceHandle: handleId,
          target,
          data: {
            type: "fallback",
            actionUUID
          }
        });
      }

      const handleButton = (buttons = [], carouselIndex) => {
        buttons.forEach((button, index) => {
          const { scenario_uuid } = button?.doopage || {};

          const source = rootUUID;
          const target = scenario_uuid;

          if (existScenarioUUIDs[target]) {
            connectedScenarios[source] = true;
            connectedScenarios[target] = true;

            const handleId = getHandleId({
              type: carouselIndex == null ? "button" : "carousel",
              actionUUID,
              buttonIndex: index,
              carouselIndex
            });

            listEdges.push({
              id: compressObj({
                source,
                target,
                sourceHandle: handleId
              }),
              source,
              sourceHandle: handleId,
              target,
              data: {
                type: carouselIndex == null ? "button" : "carousel",
                actionUUID,
                buttonIndex: index,
                carouselIndex
              }
            });
          }
        });
      };

      const { buttons } = message;
      handleButton(buttons);

      carousel?.elements?.forEach((item, index) => handleButton(item?.buttons, index));
    });
  });

  return listEdges;
};

export const createNodes = (scenarios = [], edges = []) => {
  const direction = "LR";

  // Create Nodes base on Scenarios from API
  const nodes = scenarios.map((scenario) => {
    const { id, uuid } = scenario;
    return {
      type: "scenario",
      id: getScenarioUUID(id, uuid),
      data: {
        ...scenario,
        uuid: getScenarioUUID(id, uuid),
        actions: scenario.actions.map((item) => ({
          ...item,
          uuid: item.uuid || getScenarioUUID(item.id, item.uuid)
        }))
      }
    };
  });

  // Find nodes position
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph({ rankdir: direction, ranksep: 150 });
  nodes.forEach(({ id, data }) => {
    if (connectedScenarios[id] && !data?.metadata?.position) {
      dagreGraph.setNode(id, {
        width: getNodeWidth(),
        height: getNodeHeight(data)
      });
    }
  });

  edges.forEach(({ source, target }) => {
    dagreGraph.setEdge(source, target);
  });

  dagre.layout(dagreGraph);

  let maxX = 0;
  dagreGraph.edges().forEach((v) => {
    const positionX = dagreGraph.edge(v)?.points?.map((item) => item.x) ?? [];
    maxX = Math.max(maxX, ...positionX);
  });

  let genX = maxX + 100;
  let genY = 100;
  let row = 0;

  const getNodePosition = ({ data, id }) => {

    if (data?.metadata?.position)
      return data?.metadata?.position;

    const graphPosition = dagreGraph.node(id);
    if (connectedScenarios[id])
      return {
        x: graphPosition.x,
        y: graphPosition.y
      };

    let x = genX;
    let y = genY;

    genY += getNodeHeight(data) + 20;
    if (genY > HEIGHT * 2) {
      row = 0;
      genY = 100;
      genX += nodeWidth + 50;
    }

    return {
      x: Math.min(Math.max(10, x), 10000),
      y: Math.min(Math.max(10, y), 10000)
    };
  };

  return [...getAllParentNodes(), ...nodes.map((node) => ({
    ...node,
    position: getNodePosition(node),
    expandParent: true
  }))];
};

export const getHandleId = ({ type, actionUUID, buttonIndex = 0, carouselIndex = 0 }) => {
  return `${type}_${actionUUID}_${carouselIndex}_${buttonIndex}`;
};

export const parseHandleId = (id) => {
  const [type, actionUUID, carouselIndex, buttonIndex] = id?.split("_");
  return { type, actionUUID, buttonIndex: +buttonIndex, carouselIndex: +carouselIndex };
};

export const showDebugObj = isDebug() && false;

export const ALL_PLATFORM = [
  "fb",
  "personal_fb",
  "zalo",
  "personal_zalo",
  "sendo",
  "website",
  "email",
  "instagram"
];

export const getActionGroup = () => [
  {
    name: "",
    actions: [
      {
        platform: ALL_PLATFORM,
        icon: "fa-align-left",
        key: "text",
        label: t`Văn bản`
      },
      {
        platform: ALL_PLATFORM,
        icon: "fa-image",
        key: "image",
        label: t`Hình ảnh`
      },
      {
        platform: ["fb"],
        key: "card",
        icon: "fa-credit-card-blank",
        label: t`Hình và nút`
      },
      {
        platform: ["fb"],
        key: "carousel",
        icon: "fa-window-restore",
        label: t`Carousel`
      },
      {
        platform: ["fb"],
        key: "input",
        icon: "fa-keyboard",
        label: t`Nhập liệu`
      },
      {
        platform: ["fb"],
        key: "react_comment",
        icon: "fa-smile-plus",
        label: t`Tương tác`
      },
      {
        platform: ["fb"],
        key: "reply_comment",
        icon: "fa-comment-alt",
        label: t`Trả lời comment`
      },
      {
        platform: ["fb"],
        key: "hide_comment",
        icon: "fa-eye-slash",
        label: t`Ẩn comment`
      },
      {
        platform: ["shopee"],
        key: "reply_review",
        icon: "fa-stars",
        label: t`Trả lời đánh giá`
      },
      {
        platform: ["zalo"],
        key: "zns_template",
        image: AssetImage.Zalo_image,
        label: trans(t`ZNS`)
      },
      {
        platform: ALL_PLATFORM,
        key: "execute_scenario",
        icon: "fa-link",
        label: t`Chạy kịch bản khác`
      },
      {
        platform: ALL_PLATFORM,
        key: "delay",
        icon: "fa-clock",
        label: t`Trì hoãn`
      },
      {
        platform: ["fb"],
        key: "message_typing",
        icon: "fa-comment-dots",
        label: t`Đang gõ`
      },
      {
        platform: ALL_PLATFORM,
        key: "quick_reply",
        icon: "fa-check-square",
        label: t`Lựa chọn`
      },
      {
        platform: ALL_PLATFORM,
        key: "time_picker",
        icon: "fa-calendar-check",
        label: t`Thời gian`
      }
    ]
  },
  {
    name: t`Đơn hàng`,
    flagKey: "SALE_ENABLED",
    actions: [
      {
        platform: ALL_PLATFORM,
        key: 'send_order_form',
        icon: 'fa-cart-plus',
        label: t`Gửi form chốt đơn`,
        flagKey: "SALE_ENABLED",
      },
    ],
  },
  {
    name: t`Nâng cao`,
    actions: [
      {
        platform: ALL_PLATFORM,
        key: "update_segment",
        label: t`Chuyển phễu`,
        isPro: true,
        icon: "fa-filter"
      },
      {
        platform: [],
        key: "update_tag",
        label: t`Chuyển tag`,
        isPro: true,
        icon: "fa-tags"
      }
      // {
      //     platform: [],
      //     key: 'execute_scenario',
      //     label: (t`Kích hoạt kịch bản`),
      //     isPro: true,
      //     icon: 'fa-bolt',
      // },
    ]
  }
];

export const isIncludesKeyword = (name = "", keyword = "") => {
  if (!name || !keyword) return false;
  return removePunctuation(name?.toLowerCase()).includes(removePunctuation(keyword.toLowerCase()));
};

export const compressObj = (obj) => {
  if (!obj) return "";
  return LZUTF8.compress(JSON.stringify(obj), {
    outputEncoding: "Base64"
  });
};

export const decompressObj = (str) => {
  const json = LZUTF8.decompress(str, {
    inputEncoding: "Base64"
  });

  try {
    return JSON.parse(json);
  } catch (e) {
    console.warn("parse json error", json);
    return undefined;
  }
};

export const genLinkFromScenarios = (data) => {
  if (!data) return "";
  return `${window.location.origin}/scenario-view?scenarios=${compressObj(data)}`;
};

export const getScenariosFromLink = (url = window.location.href) => {
  return decompressObj(url.split("?scenarios=")[1]) || {};
};

export const addConnectToScenario = ({ sourceScenario, targetScenario, sourceHandle }) => {
  if (!sourceScenario?.uuid || !targetScenario?.uuid) return null;

  const { type, actionUUID, buttonIndex, carouselIndex } = parseHandleId(sourceHandle);

  return produce(sourceScenario, (draftState) => {
    const index = sourceScenario.actions.findIndex((item) => item.uuid === actionUUID);
    if (index < 0) return;

    if (type === "fallback") {
      if (!draftState.actions[index].config) draftState.actions[index].config = {};
      draftState.actions[index].config.fallback = {
        action: "other_scenario",
        scenario_uuid: targetScenario.uuid
      };
    }

    if (type === "button") {
      draftState.actions[index].message.buttons[buttonIndex].type = "doo_execute_scenario";
      draftState.actions[index].message.buttons[buttonIndex].doopage = {
        scenario_uuid: targetScenario.uuid
      };
    }

    if (type === "carousel" && carouselIndex) {
      draftState.actions[index].carousel.elements[carouselIndex].buttons[buttonIndex].type =
        "doo_execute_scenario";
      draftState.actions[index].carousel.elements[carouselIndex].buttons[buttonIndex].doopage = {
        scenario_uuid: targetScenario.uuid
      };
    }
  });
};

export const removeScenarioConnection = ({ sourceScenario, sourceHandle }) => {
  if (!sourceScenario?.id || !sourceHandle) return null;

  const { type, actionUUID, buttonIndex, carouselIndex } = parseHandleId(sourceHandle);

  return produce(sourceScenario, (draftState) => {
    const index = sourceScenario.actions.findIndex((item) => item.uuid === actionUUID);

    if (index < 0) return;

    if (type === "fallback") {
      draftState.actions[index].config.fallback.action = "stop";
      delete draftState.actions[index].config.fallback.scenario_id;
      delete draftState.actions[index].config.fallback.scenario_uuid;
    }

    if (type === "button") {
      draftState.actions[index].message.buttons[buttonIndex].type = "postback";
      draftState.actions[index].message.buttons[buttonIndex].doopage = {};
    }

    if (type === "carousel") {
      draftState.actions[index].carousel.elements[carouselIndex].buttons[buttonIndex].type =
        "postback";
      draftState.actions[index].carousel.elements[carouselIndex].buttons[buttonIndex].doopage = {};
    }
  });
};

export const removeAllScenarioConnection = (scenario) => {
  return produce(scenario, (draftState) => {
    draftState?.actions?.forEach((action, actionIndex) => {
      const { config = { fallback: null }, message = {}, carousel } = action;

      if (config.fallback?.scenario_id) {
        draftState.actions[actionIndex].config.fallback.action = "stop";
        delete draftState.actions[actionIndex].config.fallback.scenario_id;
        delete draftState.actions[actionIndex].config.fallback.scenario_uuid;
      }

      const { buttons } = message;
      buttons?.forEach((button, index) => {
        const { scenario_id } = button?.doopage || {};
        if (scenario_id) {
          draftState.actions[actionIndex].message.buttons[index].type = "postback";
          draftState.actions[actionIndex].message.buttons[index].doopage = {};
        }
      });

      carousel?.elements?.forEach((item, elementIndex) => {
        item?.buttons?.forEach((button, index) => {
          const { scenario_id } = button?.doopage || {};
          if (scenario_id) {
            draftState.actions[actionIndex].carousel.elements[elementIndex].buttons[index].type =
              "postback";
            draftState.actions[actionIndex].carousel.elements[elementIndex].buttons[index].doopage =
              {};
          }
        });
      });
    });
  });
};

export const isViewOnly = () => window.location.pathname === "/scenario-view";

export const getAllParentNodes = () => {
  return LocalStorage.getParentNodes();
};

export const saveParentNode = (node) => {
  const listNode = getAllParentNodes();
  const index = listNode.findIndex(item => item.id === node.id);
  if (index < 0) listNode.push(node); else listNode[index] = node;

  LocalStorage.setParentNodes(listNode);
};

export const removeParentNode = (id) => {
  const listNode = getAllParentNodes();
  LocalStorage.setParentNodes(listNode.filter(item => item.id !== id));
};
