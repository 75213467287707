import { t } from '@lingui/macro';

export const validTemplateName = (name) => {
  if (name?.length < 10 || name?.length > 60) {
    return t`Tên mẫu phải có từ 10-60 ký tự`;
  }
  return null;
};

export const validTitle = (title) => {
  if (title?.length < 3) {
    return t`Tiêu đề phải có ít nhất 3 kí tự`;
  }

  if (title?.length > 65) {
    return t`Tiêu đề không quá 65 kí tự`;
  }
  return null;
};

export const countNumberOfParagraphs = (content) => {
  return content.split('\n\n')?.length;
};

export const validContent = (content) => {
  if (content?.length < 3) {
    return t`Nội dung phải có ít nhất 3 kí tự`;
  }

  if (content?.length > 400) {
    return t`Nội dung không quá 500 kí tự`;
  }

  if (content?.length > 400) {
    return t`Nội dung không quá 500 kí tự`;
  }

  if (countNumberOfParagraphs(content) > 3) {
    return t`Nội dung chỉ có thể chia làm tối đa 3 đoạn`;
  }

  if (!content.includes('<customer_name>')) {
    return t`Cần phải có mã thay thế <customer_name>`;
  }

  if (countSubstitution(content) < 3) {
    return t`Cần phải có ít nhất 3 mã thay thế để chứng thực đây là khách hàng của bạn, bạn có thể dùng , , hay `;
  }
  return null;
};

export const countSubstitution = (str) => {
  const re = /<\w+>/g;
  return ((str || '').match(re) || []).length;
};

export const validLogo = (logo) => {
  if (!logo) return t`Vui lòng chọn logo`;
  return null;
};
