import { Trans } from '@lingui/macro';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { createSelector } from 'reselect';
import { getValue } from '../../variables/utils';

export const createCanvas = (radius, image) => {
  const size = radius * 2;
  const lineWidth = 1;
  const pointImage = new Image(size, size);
  pointImage.src = image;

  const canvas = document.createElement('canvas');
  canvas.width = size + lineWidth * 2;
  canvas.height = size + lineWidth * 2;
  const ctx = canvas.getContext('2d');
  ctx.save();
  ctx.beginPath();
  ctx.arc(radius + lineWidth, radius + lineWidth, radius, 0, Math.PI * 2);
  ctx.closePath();
  ctx.clip();

  pointImage.onload = function () {
    ctx.globalAlpha = 0.6;
    ctx.drawImage(pointImage, lineWidth, lineWidth, size, size);
    ctx.strokeStyle = '#555555';
    ctx.lineWidth = lineWidth;
    ctx.stroke();
  };

  return canvas;
};

export const getFilterDate = (date, hour = 0, min = 0, sec = 0) => {
  const newDate = new Date(date.getTime());
  newDate.setHours(hour, min, sec);
  return Math.floor(newDate.getTime() / 1000);
};

export const chartsDateRangeSelector = (defaultValue = {}) =>
  createSelector(getValue('common.statisticDateRange', defaultValue), ({ startDate, endDate }) => ({
    since: getFilterDate(startDate),
    until: getFilterDate(endDate, 23, 59, 59),
  }));

export const useMaxYValue = () => {
  const [chartRef, setChartRef] = useState();
  const [maxY, setMaxY] = useState(0);

  useEffect(() => {
    setMaxY(chartRef?.scales?.['y-axis-0']?.ticksAsNumbers?.[0] ?? 0);
  }, [chartRef]);

  useEffect(() => {
    const oldAnnotation = chartRef?.options?.annotation?.annotations?.[0];
    if (maxY > 0 && oldAnnotation) {
      chartRef.options.annotation.annotations[0] = {
        ...oldAnnotation,
        endValue: maxY,
      };
      chartRef.update();
    }
  }, [maxY]);

  return setChartRef;
};

export const getTimeUnit = (min, max) => {
  console.log('// getTimeUnit ', min, max, min - max);
  if (max - min <= 86399) return 'hour';
  if (max - min <= 2678399) return 'day';
  return 'month';
};

export const convertSummaryDataForClient = (apiResponse) => {
  let result = {};
  Object.keys(apiResponse).forEach((key) => {
    if (apiResponse?.[key]?.value !== null) {
      result[key] = apiResponse?.[key]?.value;
    } else {
      let { since, until } = apiResponse?.[key]?.valid_duration ?? {};
      if (!since && !until) {
        result[key] = <Trans>Chưa có dữ liệu</Trans>;
      } else {
        let errMsg = 'Chỉ có dữ liệu';
        if (since) errMsg += ` từ ${getDateTime(since)}`;
        if (until) errMsg += ` đến ${getDateTime(until)}`;
        result[key] = errMsg;
      }
    }
  });
  return result;
};

export const CHART_DATE_FORMAT = 'DD MMM, YY';
export const getDateTime = (timeStamp) => moment.unix(timeStamp).format(CHART_DATE_FORMAT);
