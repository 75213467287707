import React from 'react';
import ReactDOM from 'react-dom';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

export function customTooltips(Component, name = 'id') {
  //need use this._chart
  return function (tooltipModel) {
    const tooltipId = `tooltip-${name}`;
    let tooltipEl = document.getElementById(tooltipId);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.id = tooltipId;
      tooltipEl.className = 'chartjs-tooltip white-space-nowrap';
      this._chart.canvas.parentNode.appendChild(tooltipEl);
    }

    if (tooltipModel.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }

    const positionY = this._chart.canvas.offsetTop;
    const positionX = this._chart.canvas.offsetLeft;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltipModel.caretX + 'px';
    tooltipEl.style.top = positionY + tooltipModel.caretY + 'px';

    //get item data
    const { dataPoints } = tooltipModel;
    const item =
      this._chart?.config?.data?.datasets?.[dataPoints?.[0]?.datasetIndex]?.data?.[
        dataPoints?.[0]?.index
      ];

    if (!item) return;

    ReactDOM.render(
      <I18nProvider i18n={i18n}>
        <Component item={item} />
      </I18nProvider>,
      tooltipEl,
    );
  };
}
