import { t } from '@lingui/macro';
import IconButton from '@material-ui/core/IconButton/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { addEmail, updateEmail } from 'api/api.js';
import { setState } from 'config/Core.js';
import { GoogleAuthHelper } from 'helper/google';
import _, { pick } from 'lodash';
import React from 'react';
import Routes from 'router/routes.js';
import DV from 'variables/DV.js';
import { LIST_DOMAIN } from 'variables/staticValue.js';
import { trans } from '../../config/LinguiConfig';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import CustomInput from '../../MUI_Kit/components/CustomInput/CustomInput';
import BaseModal from '../BaseComponent/BaseModal';
import { handleApi, validateEmail } from 'helper/helper';
import axios from 'axios';

const validateServer = (server) => {
  try {
    const url = new URL(server);
    return !!url.protocol;
  } catch (e) {
    return false;
  }
};

class EmailModal extends BaseModal {
  constructor(props) {
    super(props);
    let params = this.getDataFromRouter();
    if (_.isEmpty(params)) this.redirectTo(Routes.email.path);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      name: '',
      username: '',
      password: '',
      mailer_server: '',
      sender_server: '',
      showPassword: false,
      token: '',
      ...params,
    };
    if (DV.company && DV.company.name) {
      this.state.name = DV.company.name;
    }

    this.goPathWhenClose = !this.props.openFromChannels ? Routes.email.path : Routes.channel.path;
  }

  getTitle() {
    if (this.state.id) return t`Cập nhật tài khoản`;
    else return t`Thêm tài khoản`;
  }

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value.trim(),
    });
  };

  updateServer = (event) => {
    if (validateEmail(this.state.username)) {
      const domain = this.state.username.split('@').pop();
      axios.get(`https://dns.google.com/resolve?name=${domain}&type=MX`).then((result) => {
        let mailer_server = `imap+ssl://imap.${domain}`;
        let sender_server = `smtp+ssl://smtp.${domain}`;
        if (result && result.data && result.data.Status === 0 && result.data.Answer) {
          for (const record of result.data.Answer) {
            if (record.type === 15 && record.data.endsWith('aspmx.l.google.com.')) {
              mailer_server = `imap+ssl://imap.gmail.com`;
              sender_server = `smtp+ssl://smtp.gmail.com`;
            }
          }
        }
        this.setState({
          mailer_server,
          sender_server,
        });
      });
    } else {
      this.setState({
        mailer_server: '',
        sender_server: '',
      });
    }
  };

  handleClickShowPassword = () => {
    this.setState((state) => ({ showPassword: !state.showPassword }));
  };

  loginWithGoogle = async () => {
    let result;
    try {
      result = await GoogleAuthHelper.forAccessEmail().signInWithPopup();
    } catch (error) {
      let msg = 'Có lỗi khi đăng nhập !';
      switch (error.code) {
        case 'auth/popup-closed-by-user':
          msg = 'Bạn đã đóng cửa sổ đăng nhập !';
          break;
      }
      DV.showNotify(msg, 'danger');
      return;
    }
    const data = {
      operationType: result.operationType,
      additionalUserInfo: result.additionalUserInfo,
      credential: pick(result.credential, ['idToken', 'accessToken', 'providerId', 'signInMethod']),
      user: pick(result.user, [
        'displayName',
        'email',
        'phoneNumber',
        'photoURL',
        'refreshToken',
        'uid',
      ]),
    };
    let { success, error } = await handleApi(addEmail(data, DV.company.id));
    await setState(this)({ loading: false });

    if (error) {
      DV.showNotify(error, 'danger');
      return;
    }
    if (success) {
      this.refreshOnClose = true;
      DV.showNotify(t`Thêm thành công`);
      return this.close(true);
    }
  };

  renderLoginForm = () => {
    const { id, name, username, password, showPassword, mailer_server, sender_server } = this.state;

    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}>
        <CustomInput
          labelText={t`Địa chỉ Email`}
          validInput={(value) => validateEmail(value)}
          errorText={t`Địa chỉ Email không hợp lệ. Ví dụ example@gmail.com`}
          required
          onBlur={this.updateServer}
          inputProps={{
            disabled: !!id,
            placeholder: t`Nhập vào Email`,
            type: 'text',
            value: username || '',
            onChange: this.handleChange('username'),
          }}
        />
        {!validateEmail(username) && (
          <>
            <div className="flex-center-row-xs" style={{ textAlign: 'center', padding: '10px 0' }}>
              <Button
                type="button"
                color="info"
                className="flex-center-row"
                onClick={this.loginWithGoogle}
                margin
                round>
                {t`Đăng nhập bằng Google`}
              </Button>
            </div>
          </>
        )}
        {validateEmail(username) && (
          <>
            <CustomInput
              labelText={t`Mật khẩu`}
              required
              inputProps={{
                placeholder: t`Nhập vào mật khẩu`,
                type: showPassword ? 'text' : 'password',
                value: password || '',
                onChange: this.handleChange('password'),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}>
                      {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <CustomInput
              labelText={t`Tên Email`}
              validInput={(value) => !!value}
              required
              inputProps={{
                placeholder: t`Nhập vào Tên Email`,
                type: 'text',
                value: name || '',
                onChange: this.handleChange('name'),
              }}
            />
            <CustomInput
              labelText={t`Máy chủ thư`}
              validInput={(value) => validateServer(value)}
              errorText={t`Máy chủ thư không hợp lệ. Ví dụ: imap+ssl://imap.example.com`}
              required
              inputProps={{
                placeholder: t`Nhập vào Máy chủ thư`,
                type: 'text',
                value: mailer_server || '',
                onChange: this.handleChange('mailer_server'),
              }}
            />
            <CustomInput
              labelText={t`Máy chủ gửi thư`}
              validInput={(value) => validateServer(value)}
              errorText={t`Máy chủ gửi thư không hợp lệ. Ví dụ: smtp+ssl://smtp.example.com`}
              required
              inputProps={{
                placeholder: t`Nhập vào Máy chủ gửi thư`,
                type: 'text',
                value: sender_server || '',
                onChange: this.handleChange('sender_server'),
              }}
            />
          </>
        )}
      </form>
    );
  };

  renderBody() {
    return this.renderLoginForm();
  }

  update = async (payload, id) => {
    this.setState({ loading: true });
    let { success, error, data } = await handleApi(updateEmail(id, payload));
    this.setState({ loading: false });

    if (error) {
      DV.showNotify(error, 'danger');
      return { error };
    }
    if (success) {
      this.refreshOnClose = true;
      DV.showNotify(t`Cập nhật thành công`);
      return { success: true };
    }
  };

  add = async (
    name = '',
    username = '',
    password = '',
    mailer_server = '',
    sender_server = '',
    token = '',
  ) => {
    await setState(this)({ loading: true });
    let { success, data, error } = await handleApi(
      addEmail(
        {
          name,
          username,
          password,
          mailer_server,
          sender_server,
          token,
        },
        DV.company.id,
      ),
    );
    await setState(this)({ loading: false });

    if (error) {
      DV.showNotify(error, 'danger');
      return { error };
    }
    if (success) {
      this.refreshOnClose = true;
      DV.showNotify(t`Thêm thành công`);
      return { success: true };
    }
  };

  validate = () => {
    const { username, password, mailer_server, sender_server } = this.state;
    return !!username && !!password && !!mailer_server && !!sender_server;
  };

  handleSubmit = async () => {
    const { id, name, username, password, mailer_server, sender_server, token } = this.state;
    let res = id
      ? await this.update({ name, username, password, mailer_server, sender_server, token }, id)
      : await this.add(name, username, password, mailer_server, sender_server, token);

    if (res.success) {
      this.close(true);
    }
  };

  renderFooter() {
    const { loading } = this.state;
    return [
      <Button
        href={LIST_DOMAIN[DV.partner].guide.email}
        color="info"
        simple
        target="_blank"
        rel="noopener noreferrer">
        {t`Hướng dẫn`}
      </Button>,
      <Button onClick={this.handleSubmit} color="info" loading={loading}>
        {t`Hoàn tất`}
      </Button>,
    ];
  }
}

export default EmailModal;
