import { t } from '@lingui/macro';
import * as ChartAnnotation from 'chartjs-plugin-annotation';
import React, { memo } from 'react';
import CustomChart from '../components/CustomChart/CustomChart';
import { createCanvas } from '../utils';
import { thousandSeparate } from '../../../helper/helper';

const getOption = () => ({
  plugins: [ChartAnnotation],
  aspectRatio: 1,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
    position: 'nearest',
    mode: 'nearest',
    backgroundColor: '#f5f5f5',
    titleFontColor: '#333',
    bodyFontColor: '#666',
    bodySpacing: 4,
    xPadding: 12,
    intersect: 0,
  },
  annotation: {
    drawTime: 'beforeDatasetsDraw',
    annotations: [
      {
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: 0,
        borderColor: '#eeeeee',
        borderWidth: 2,
        borderDash: [3, 3],
      },
    ],
  },
  elements: {
    point: {
      pointStyle: (context) => {
        if (!context) return;
        const { avatar, r } = context?.dataset?.data?.[context.dataIndex] || {};
        return avatar ? createCanvas(r + 5, avatar) : 'circle';
      },
    },
  },
  scales: {
    xAxes: [
      {
        scaleLabel: {
          labelString: t`Tiếp nhận`,
        },
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return thousandSeparate(value);
            }
          },
        },
      },
    ],
    yAxes: [
      {
        scaleLabel: {
          labelString: t`Kết thúc`,
        },
        ticks: {
          suggestedMin: 0,
          beginAtZero: true,
          callback: function (value) {
            if (value % 1 === 0) {
              return thousandSeparate(value);
            }
          },
        },
      },
    ],
  },
});

const getColumns = () => [
  {
    Header: t`Nhân viên`,
    accessor: 'name',
  },
  {
    Header: t`Tiếp nhận`,
    width: 100,
    accessor: 'total_take_count',
  },
  {
    Header: t`Kết thúc`,
    width: 100,
    accessor: 'total_close_count',
  },
];

const StaffReceptionPerformance = (props) => {
  return <CustomChart getOption={getOption} columns={getColumns()} {...props} />;
};

export default memo(StaffReceptionPerformance);
