import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/index.js';
import { createExtraOrder } from 'api/api.js';
import CartItem from 'component/CartItem/CartItem.js';
import _ from 'lodash';
import React from 'react';
import Routes, { getDetailPath } from 'router/routes.js';
import DV from 'variables/DV.js';
import { trans } from 'config/LinguiConfig.js';
import { getHeightModal, handleApi, toCurrency } from 'helper/helper.js';
import Button from 'MUI_Kit/components/CustomButtons/Button.jsx';
import Avatar from 'component/Avatar.js';
import BaseModal from 'component/BaseComponent/BaseModal.js';
import ListDataModal from 'component/Modal/ListDataModal.js';
import 'css/classes.css';

class ReturnProductModal extends BaseModal {
  constructor(props) {
    super(props);
    this.maxWidth = 'md';
    this.showCloseButton = false;
    let order = this.getDataFromRouter();
    if (_.isEmpty(order)) {
      this.redirectTo(Routes.order.path);
    }
    this.state = {
      open: true,
      loading: false,
      ...order,
    };

    this.handleConfirm = {
      refund: this.confirmRefundOrder,
      exchange: this.confirmExchangeOrder,
    };
    this.goPathWhenClose = Routes.order.path;
  }

  confirm = (returnMethod) => {
    let { type } = this.state;
    this.handleConfirm[type](returnMethod);
  };

  confirmRefundOrder = async (returnMethod) => {
    let { products, id } = this.state;
    let newProducts = products.map((item) => ({
      product: item.id,
      qty: item.qty,
    }));
    let payload = {
      products: newProducts,
      order_type: 'refund',
      return_method: returnMethod,
    };
    this.setState({ loading: true });
    let { success, error, data } = await handleApi(createExtraOrder(id, payload));
    this.setState({ loading: false });
    if (success) {
      DV.showNotify(t`Tạo đơn trả hàng thành công`);
      this.redirectToModal(Routes.order.path + getDetailPath(id), data);
    } else DV.showNotify(error, 'danger');
  };

  confirmExchangeOrder = (returnMethod) => {
    const { products, customer, phone, address, recipient_name, id } = this.state;
    let payload = {
      return_products: products,
      oldTotal: this.getTotal(),
      returnMethod,
      customer,
      phone,
      address,
      recipient_name,
      id,
    };
    this.redirectToModal(Routes.order.exchange.path, payload);
  };

  getTotal = () => {
    const { products } = this.state;
    let total = 0;
    products &&
      products.map((item) => {
        if (item && item.value && item.qty) total += item.value * item.qty;
      });
    return total;
  };

  handleChangeQTY = (index) => (event) => {
    let num = parseFloat(event.target ? event.target.value : event);
    if (num < 0) num = 1;
    let products = [...this.state.products];
    products[index].qty = num;
    this.setState({
      products,
    });
  };

  renderCartItem = (item, index) => {
    item.price = item.value;
    return (
      <CartItem
        data={item}
        handleChangeQTY={this.handleChangeQTY}
        index={index}
        removeItem={this.removeItem}
      />
    );
  };

  removeItem = (item, index) => {
    let products = [...this.state.products];
    if (index > -1) products.splice(index, 1);
    this.setState({
      products,
    });
  };

  renderFooter() {
    return [
      <Button loading={this.state.loading} onClick={() => this.confirm('customer')} color="rose">
        {t`Khách tự giao hàng`}
      </Button>,
      <Button loading={this.state.loading} onClick={() => this.confirm('shop')} color="info">
        {t`Cửa hàng đến lấy`}
      </Button>,
    ];
  }

  renderBody() {
    let { products, recipient_name: name, phone, address, customer } = this.state;
    products = products || [];

    return (
      <Grid container xs={12} sm={12} md={12} spacing={2} style={{ paddingLeft: 25 }}>
        <Grid item xs={12} className="flex-center-row" style={{ height: 50 }}>
          <div style={{ fontSize: 25, marginBottom: 15 }} className="bold">
            {t`CHỌN SẢN PHẨM TRẢ HÀNG`}
          </div>
        </Grid>

        <Grid container xs={12}>
          {customer && (
            <Grid item xs={2} md={1}>
              <Avatar user={customer} size={70} margin={false} />
            </Grid>
          )}
          <Grid item xs={10} md={11}>
            <div className="text bold">{name}</div>
            <div className="text">{phone}</div>
            <div className="text">{address}</div>
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={2}>
          <ListDataModal
            data={products}
            heightList={getHeightModal(150)}
            renderItem={this.renderCartItem}
            emptyTitle={t`Không có sản phẩm nào trong giỏ hàng`}
          />
        </Grid>
        <Grid item xs={12} className="flex-end-row">
          {products.length > 0 && (
            <div className="text bold" style={{ fontSize: 20, marginTop: 10 }}>
              {t`Tổng cộng` + ': ' + toCurrency(this.getTotal())}
            </div>
          )}
        </Grid>
      </Grid>
    );
  }
}

export default ReturnProductModal;
