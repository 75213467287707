import React from 'react';
import { CirclePicker } from 'react-color';
import { COLOR_OBJECT } from '../../variables/color';

export default class CircleColorPicker extends React.PureComponent {
  render() {
    const { onChange } = this.props;
    const color_array = Object.keys(COLOR_OBJECT).map((key) => COLOR_OBJECT[key]);
    color_array.pop(); //remove white color
    return (
      <CirclePicker
        width={'100%'}
        colors={color_array}
        onChange={(color, event) => onChange(color.hex)}
      />
    );
  }
}
