import { AutoComplete, Avatar } from "@doopage/react-ui-kit";
import { t } from "@lingui/macro";
import { useQuery } from "@redux-requests/react";
import { AssetImage } from "assets/Asset";
import isEmpty from "lodash/isEmpty";
import React, { memo, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { getAllChannels, getChannels } from "redux/action/apiAction";

const PlatformsLogo = {
  fb: AssetImage.Facebook_image,
  zalo_oa: AssetImage.Zalo_image,
  shopee: AssetImage.Shopee_image,
  website: AssetImage.Web_image,
  email: AssetImage.Web_image,
  instagram: AssetImage.Instagram_image,
  google_business: AssetImage.GBMIcon
};

const filterValues = (values = [], data= [])  =>{
  return values.filter(value =>
    data.some(datum => datum.id.toString() === value.id.toString() && datum.platform === value.platform)
  );
}


const SelectChannel = ({ onChange, value = [], filterBy = () => true, ...props }) => {
  const dispatch = useDispatch();
  const { data: rawData, loading } = useQuery({ type: getAllChannels });

  const data = useMemo(() => {
    let result = [];
    if (isEmpty(rawData)) return [];
    console.log("// rawData", { rawData });

    return rawData?.data?.filter(filterBy).map((item) => ({
      ...item,
      platform:  item.platform || item.channel_type,
      id: item.id.toString()
    })) ?? [];
  }, [rawData]);

  useEffect(() => {
    dispatch(getChannels());
    dispatch(getAllChannels());
  }, []);

  const handleChange = (values) => {
    onChange(
      values.map((id) => {
        const matches = id?.split('-')
        return data.find((item) => item.platform === matches[0] && item.id === matches[1]);
      })
    );
  };



  return (
    <AutoComplete
      {...props}
      onChange={handleChange}
      loading={loading}
      multiple
      value={filterValues(value, data)?.map(({ id, platform }) => {
        return platform + "-" + id;
      })}
      placeholder={t`Chọn kênh `}
      label={t`Kênh`}
      options={data.map(({ id, name, platform, image_url }) => {
        return({
          id: platform + "-" + id,
          name: name || platform + "-" + id,
          icon: (
              <Avatar
                  src={image_url}
                  size={25}
                  rounded
                  badgeProps={{
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right"
                    }
                  }}
                  badge={<img src={PlatformsLogo[platform]} style={{ background: "white" }} />}
              />
          )
        })
      })}
    />
  );
};

export default memo(SelectChannel);
