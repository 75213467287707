import React from 'react';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from '../../assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import Switch from '@material-ui/core/Switch/Switch';
import classNames from 'classnames';
import { xPath } from '../../../testingClasses';
import { sendSwitchAction } from 'config/GoogleAnalyticsConfig';
import Zoom from '@material-ui/core/Zoom/Zoom';
import Tooltip from '@material-ui/core/Tooltip/Tooltip';

function CustomSwitch({ classes, color, checked, onChange, disabled, helperText }) {
  const {
    switchBase,
    switchChecked,
    switchIcon,
    switchIconChecked,
    switchBar,
    switchBarError,
    switchIconError,
  } = classes;

  const map = {
    primary: {
      switchBase,
      checked: switchChecked,
      icon: switchIcon,
      iconChecked: switchIconChecked,
      bar: switchBar,
    },
    danger: {
      checked: switchChecked,
      icon: switchIconError,
      iconChecked: switchIconChecked,
      bar: switchBarError,
    },
  };

  const handleChange = (event) => {
    sendSwitchAction(event.target.checked ? 'ON' : 'OFF');
    onChange && onChange(event);
  };

  const switchButton = (
    <div className={classNames(xPath.switchButton, xPath.switchButton_(checked))}>
      <Switch
        checked={checked}
        onChange={handleChange}
        // classes={map[color]}
        disabled={disabled}
      />
    </div>
  );

  if (helperText) {
    return (
      <Tooltip
        TransitionComponent={Zoom}
        id="tooltip-bottom"
        title={helperText}
        placement="bottom"
        classes={{ tooltip: classes.tooltip }}>
        {switchButton}
      </Tooltip>
    );
  }

  return switchButton;
}

CustomSwitch.propTypes = {};

export default React.memo(withStyles(extendedFormsStyle)(CustomSwitch));
