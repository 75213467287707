import Grid from '@material-ui/core/Grid/index';
import withStyles from '@material-ui/core/styles/withStyles';
import ShoppingBasket from '@material-ui/icons/ShoppingBasket';
import React, { PureComponent } from 'react';
import extendedTablesStyle from '../../../../../MUI_Kit/assets/jss/material-dashboard-pro-react/views/extendedTablesStyle';
import Button from '../../../../../MUI_Kit/components/CustomButtons/Button';
import Timeline from '../../../../../MUI_Kit/components/Timeline/Timeline';
import { getListSeller } from '../../../../../api/api';
import { handleApi, toCurrency } from '../../../../../helper/helper';
import { HEIGHT } from '../../../../../variables/staticValue';
import Avatar from '../../../../Avatar';
import './CustomerHistory.css';
import { trans } from '../../../../../config/LinguiConfig';
import { t } from '@lingui/macro';

const styles = {
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
  ...extendedTablesStyle,
};

class CustomerHistory extends PureComponent {
  getData = async () => {
    this.setState({
      loading: true,
    });
    let { success, data, error } = await handleApi(getListSeller('?q=' + this.state.keyword));
    this.setState({
      loading: false,
    });

    if (success) {
      this.setState({
        listSeller: data,
        error: null,
      });
    } else {
      this.setState({
        error,
      });
    }
  };

  render() {
    return (
      <Grid container>
        <Grid item xs={12}>
          {this.renderTotal()}
        </Grid>
        <Grid item xs={12} style={{ height: HEIGHT * 0.6, overflow: 'scroll' }}>
          {this.renderTimeline()}
        </Grid>
      </Grid>
    );
  }

  renderTotal = () => {
    return (
      <Grid container style={{ borderBottom: '1px solid #eeeeee', paddingBottom: 15 }}>
        <Grid item xs={3} className="flex-center-col">
          <div className="history-total-title">Tổng giá trị hàng đã mua</div>
          <div className="history-total-value">{toCurrency(5000000)}</div>
        </Grid>
        <Grid item xs={3} className="flex-center-col">
          <div className="history-total-title">Tổng số đơn hàng đã đặt</div>
          <div className="history-total-value">19</div>
        </Grid>
        <Grid item xs={3} className="flex-center-col">
          <div className="history-total-title">Số sản phẩm đã mua</div>
          <div className="history-total-value">39</div>
        </Grid>
        <Grid item xs={3} className="flex-center-col">
          <div className="history-total-title">Lần mua gần nhất</div>
          <div className="history-total-value">18/12/2018</div>
        </Grid>
      </Grid>
    );
  };

  renderTimeline = () => {
    return (
      <Timeline
        stories={[
          {
            simple: true,
            inverted: true,
            badgeColor: 'success',
            badgeIcon: ShoppingBasket,
            title: 'Mua sắm',
            titleColor: 'success',
            body: this.renderBodyTimelineItem(),
            // footerTitle: "11 hours ago via Twitter",
            footer: (
              <div style={{ width: '100%' }} className="flex-center-col">
                <Button round color="info" simple fullWidth>
                  {t`Đóng`}
                </Button>
              </div>
            ),
          },
          {
            simple: true,
            inverted: true,
            badgeColor: 'danger',
            badgeIcon: ShoppingBasket,
            title: 'Some Title',
            titleColor: 'danger',
            body: this.renderBodyTimelineItem(),
            // footerTitle: "11 hours ago via Twitter",
            footer: (
              <div style={{ width: '100%' }} className="flex-center-col">
                <Button round color="info" simple fullWidth>
                  {t`Đóng`}
                </Button>
              </div>
            ),
          },
        ]}
      />
    );
  };

  renderBodyTimelineItem = (data = {}) => {
    return (
      <div>
        <div className="history-timeline-date">Ngày 18 tháng 12 năm 2018.</div>
        <div className="flex-center-row" style={{ justifyContent: 'space-between' }}>
          <div className="history-timeline-total">Đã mua đơn hàng trị giá 3,979,000đ</div>
          <div className="history-timeline-seller-1">
            Seller: <span className="history-timeline-seller-2">Khánh Vi</span>
          </div>
        </div>
        <Grid container spacing={2}>
          {[1, 2, 3, 4, 5].map((item, index) => {
            return (
              <Grid item xs={12} key={index}>
                {this.renderProductItem(item)}
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  };

  renderProductItem = (item, index) => {
    let { name, image, price, unit, qty, id } = item;
    name = 'Rượu Jacky Daniel 1988 (790ml)';
    image =
      'https://uc.uxpin.com/files/793532/789453/ci-jack-daniels-tennessee-honey-1d9700d15d455076-2fd893.jpeg';
    price = 799000;
    unit = 'chai';
    qty = 5;
    return (
      <Grid container alignItems="center">
        <Grid item style={{ marginRight: 10 }}>
          {qty} x
        </Grid>
        <Grid item style={{ width: 73 }}>
          <Avatar user={{ name, avatar: image }} border={6} size={50} margin={false} />
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <div>{name}</div>
          <div style={{ fontSize: 15, fontWeight: 500 }}>
            {toCurrency(price)} &nbsp;/ {unit}
          </div>
        </Grid>
      </Grid>
    );
  };
}

export default withStyles(styles)(CustomerHistory);
