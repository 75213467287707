import { t } from '@lingui/macro';
import Grid from '@material-ui/core/Grid/Grid';
import { trans } from 'config/LinguiConfig';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import CustomInput from 'MUI_Kit/components/CustomInput/CustomInput';
import React from 'react';
import DV from 'variables/DV';
import BaseModal from '../BaseComponent/BaseModal';
import CircleColorPicker from '../ColorPicker/CircleColorPicker';
import Routes from '../../router/routes';
import { handleApi } from '../../helper/helper';
import { createSegment, updateSegment } from '../../api/api';

class SegmentModal extends BaseModal {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
      title: this.getTitle(),
      loading: false,
      color: '',
      ...this.getDataFromRouter(),
    };
    this.goPathWhenClose = Routes.segment.path;
  }

  getTitle() {
    let { id } = this.getDataFromRouter();
    if (id) return t`Cập nhật phễu khách hàng`;
    return t`Thêm phễu khách hàng`;
  }

  valid = () => {
    const { name, color, ordinal_number } = this.state;
    if (!!!name) {
      DV.showNotify(t`Vui lòng nhập tên tag`, 'danger');
      return false;
    }
    if (!!!color) {
      DV.showNotify(t`Vui lòng chọn màu`, 'danger');
      return false;
    }
    if (ordinal_number < 0) {
      DV.showNotify(t`Thứ tự không được âm`, 'danger');
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { name, color, id, ordinal_number } = this.state;

    if (!this.valid()) return;

    if (id) {
      this.update({ name: name, color, ordinal_number }, id);
    } else {
      this.add({ name: name, color, ordinal_number });
    }
  };

  add = async (data) => {
    this.setState({ loading: true });
    let { success, error } = await handleApi(createSegment(data));
    this.setState({ loading: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Thêm phễu thành công`);
    } else DV.showNotify(error, 'danger');
  };

  update = async (payload, id) => {
    this.setState({ loading: true });
    let { success, data, error } = await handleApi(updateSegment(payload, id));
    this.setState({ loading: false });

    if (success) {
      this.close(true);
      DV.showNotify(t`Cập nhật phễu thành công`);
    } else DV.showNotify(error, 'danger');
  };

  renderFooter() {
    return (
      <Button loading={this.state.loading} onClick={this.handleSubmit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }

  renderBody() {
    let { name, color, ordinal_number } = this.state;
    console.log(name, color);
    return (
      <div style={{ marginTop: -10 }}>
        <CustomInput
          labelText={t`Tên phễu`}
          inputProps={{
            type: 'text',
            value: name || '',
            onChange: this.handleChange('name'),
          }}
        />
        {/*<CustomInput*/}
        {/*    labelText={(t`Thứ tự`)}*/}
        {/*    inputProps={{*/}
        {/*        type: "number",*/}
        {/*        value: ordinal_number || 0,*/}
        {/*        onChange: this.handleChange('ordinal_number'),*/}
        {/*    }}*/}
        {/*/>*/}
        <Grid container spacing={1} alignContent="center">
          <Grid item xs={12} container>
            <Grid item className="flex-start-row">
              {t`Màu tag`}
              <div
                style={{
                  backgroundColor: color,
                  height: 30,
                  borderRadius: 5,
                  width: 30,
                  marginLeft: 10,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ overflow: 'hidden', marginBottom: 10 }}>
            <CircleColorPicker onChange={(color) => this.setState({ color })} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default SegmentModal;
