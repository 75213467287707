import { Trans } from '@lingui/macro';
import React from 'react';
import { calcPercent } from '../../../helper/helper';

const ToolTipStaffReceptionPerformance = ({ item }) => {
  const {
    customer_take_count,
    customer_transfer_take_count,
    customer_dispute_take_count,
    customer_auto_take_count,
    customer_close_count,
    customer_transfer_close_count,
    customer_dispute_close_count,
    name,
    total_take_count,
    total_close_count,
  } = item;
  return (
    <div style={{ fontSize: 13, padding: 5 }}>
      <div>{name}</div>
      <div>
        <Trans>Tiếp nhận:</Trans>&nbsp;{total_take_count}
      </div>
      <div>
        <Trans>- Chủ động:</Trans>&nbsp;{calcPercent(customer_take_count, total_take_count)}%
      </div>
      <div>
        <Trans>- Chuyển đến:</Trans>&nbsp;
        {calcPercent(customer_transfer_take_count, total_take_count)}%
      </div>
      <div>
        <Trans>- Giành:</Trans>&nbsp;
        {calcPercent(customer_dispute_take_count, total_take_count)}%
      </div>
      <div>
        <Trans>- Tự động:</Trans>&nbsp;
        {calcPercent(customer_auto_take_count, total_take_count)}%
      </div>

      <div>
        <Trans>Kết thúc:</Trans>&nbsp;{total_close_count}
      </div>
      <div>
        <Trans>- Chủ động:</Trans>&nbsp;{calcPercent(customer_close_count, total_close_count)}%
      </div>
      <div>
        <Trans>- Chuyển đi:</Trans>&nbsp;
        {calcPercent(customer_transfer_close_count, total_close_count)}%
      </div>
      <div>
        <Trans>- Bị giành:</Trans>&nbsp;
        {calcPercent(customer_dispute_close_count, total_close_count)}%
      </div>
    </div>
  );
};

export default ToolTipStaffReceptionPerformance;
