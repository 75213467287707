import { AutoComplete } from '@doopage/react-ui-kit';
import React from 'react';
import { useQuery } from '@redux-requests/react';
import { getAllChannels } from '../../redux/action/apiAction';

const SelectTelegram = ({ onChange, options, ...props }) => {
  const { data: result, loading, error } = useQuery({ type: getAllChannels });
  const data = result?.data?.filter((item) => item.channel_type === 'telegram') ?? [];
  return (
    <AutoComplete
      style={{ minWidth: 240 }}
      onChange={(value, newValue) => {
        onChange(newValue);
      }}
      options={data
        .filter((item) => item.channel_type === 'telegram')
        .map(({ id, name, image_url }) => ({
          id,
          name,
          avatar: image_url,
        }))}
      loading={loading}
      error={error}
      {...props}
    />
  );
};

export default SelectTelegram;
