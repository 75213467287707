import { Select } from '@doopage/react-ui-kit';
import { t } from '@lingui/macro';
import { useDispatch, useSelector } from 'react-redux';
import { getValue } from '../../../../../variables/utils';
import { Types } from '../../../../../redux/type';
import { useEffect } from 'react';
import { fetchData } from '../../../../../redux/action/fetchAction';
import { getZnsTemplateButtons } from '../../../../../api/api';

const SelectButtonType = ({ value, onChange }) => {
  const dispatch = useDispatch();

  // Get buttons type
  const { data = [], loading } = useSelector(getValue(Types.znsTemplateButtons, {}));
  const options = data?.map(({ buttonTag, title, description, ...rest }) => {
    return { id: buttonTag, name: title, description };
  });

  useEffect(() => {
    dispatch(fetchData(Types.znsTemplateButtons, getZnsTemplateButtons()));
  }, []);

  return (
    <Select
      fullWidth
      label={t`Chọn hành động`}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};

export default SelectButtonType;
