import React, { useEffect, useState } from 'react';
import { change_alias, handleApi } from 'helper/helper';
import { getDistricts } from 'api/api';
import { trans } from 'config/LinguiConfig';
import { t } from '@lingui/macro';
import CustomSelect from 'MUI_Kit/components/CustomSelect/CustomSelect';

const SelectProvince = ({ value, onChange, province }) => {
  const [data, setData] = useState([]);

  const fetchData = async () => {
    let { data } = await handleApi(getDistricts(province));
    if (data) setData(data.map((item) => ({ key: item.id, value: item.name })));
  };

  const filterData = (keyword) => {
    return data.filter((item) => change_alias(item.value).includes(change_alias(keyword)));
  };

  useEffect(() => {
    if (province) fetchData();
  }, []);

  return (
    <CustomSelect
      label={t`Quận/Huyện`}
      data={data}
      disabled={data.length === 0}
      value={value || ''}
      onChange={onChange('district')}
      filterData={filterData}
    />
  );
};

export default SelectProvince;
