import React from 'react';
import CustomInput from '../../MUI_Kit/components/CustomInput/CustomInput';
import Button from '../../MUI_Kit/components/CustomButtons/Button';
import BaseModal from '../BaseComponent/BaseModal';
import { trans } from '../../config/LinguiConfig';
import { t } from '@lingui/macro';
import DV from '../../variables/DV';
import Routes from '../../router/routes';
import { handleApi } from '../../helper/helper';
import { createProductAttribute } from '../../api/api';

class AttributeModal extends BaseModal {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: t`Thêm thuộc tính`,
      loading: false,
      name: '',
      ...this.getDataFromRouter(),
    };
    this.goPathWhenClose = Routes.attribute.path;
  }

  validName = (name) => {
    if (name && name.length < 1) {
      DV.showNotify(t`Vui lòng nhập tên thuộc tính`, 'danger');
      return false;
    }
    return true;
  };

  handleSubmit = () => {
    const { name } = this.state;
    if (this.validName(name)) this.add({ name: name });
  };

  add = async (data) => {
    this.setState({ isLoadingModal: true });
    let { success, error } = await handleApi(createProductAttribute(data));
    this.setState({ isLoadingModal: false });
    if (success) {
      this.close(true);
      DV.showNotify(t`Thêm thuộc tính thành công`);
    } else DV.showNotify(error, 'danger');
  };

  renderFooter() {
    return (
      <Button loading={this.state.loading} onClick={this.handleSubmit} color="info">
        {t`Hoàn tất`}
      </Button>
    );
  }

  renderBody() {
    return (
      <CustomInput
        labelText={t`Tên thuộc tính`}
        inputProps={{
          type: 'text',
          value: this.state.name,
          onChange: this.handleChange('name'),
        }}
      />
    );
  }
}

export default AttributeModal;
