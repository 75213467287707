import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import badgeStyle from '../../assets/jss/material-dashboard-pro-react/components/badgeStyle.jsx';

function Badge({ ...props }) {
  const { classes, color, children, fullWidth, small, style } = props;
  let styles = fullWidth ? { width: '100%', ...style } : { ...style };
  let className = classNames(classes.badge, classes[color], {
    [classes.small]: small,
  });
  return (
    <span className={className} style={styles}>
      {children}
    </span>
  );
}

Badge.propTypes = {
  small: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'warning', 'danger', 'success', 'info', 'rose', 'gray']),
};
Badge.defaultProps = {
  color: 'gray',
};

export default withStyles(badgeStyle)(Badge);
