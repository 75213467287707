import { Trans } from '@lingui/macro';
import React from 'react';
import { Avatar } from "@doopage/react-ui-kit";
import { getPlatformLogo } from "../../../variables/staticValue";


const TooltipChannelResponsePerformance = ({ item }) => {
  const { x, y, r, name, avatar, channel_type } = item;
  return (
    <div style={{ fontSize: 13, padding: 5 }}>
      <div className={'flex-start-row'}>
        <Avatar   src={avatar}
                  size={40}
                  badgeProps={{
                    anchorOrigin: {
                      vertical: "top",
                      horizontal: "right"
                    }
                  }}
                  badge={<Avatar src={getPlatformLogo(channel_type)} size={20} style={{background: 'white'}}/>} />
        <div>{name}</div>
      </div>
      <div>
        <Trans>Tốc độ:</Trans>&nbsp;{y}
      </div>
      <div>
        <Trans>Khách hàng:</Trans>&nbsp;{x}
      </div>
    </div>
  );
};

export default TooltipChannelResponsePerformance;
