import {t} from '@lingui/macro';
import {Grid, TextField} from '@material-ui/core';
import React from 'react';
import ButtonAdd from 'screen/Channel/Website/components/ButtonAdd';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Close from '@material-ui/icons/Close';

const ListConversationStarter = ({value, onChange, ...props}) => {
  const handleChange = (index) => {
    return (newValue) => {
      value[index] = newValue;
      onChange(value);
    };
  };
  const handleAdd = () => {
    onChange([...value, '']);
  };
  const handleRemove = (index) => () => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };
  return (
    <Grid container sm={12} spacing={2} {...props}>
      {value.map((item, index) => (
        <Grid container item sm={12} justify="center">
          <Grid item container sm={11}>
            <Grid item sm={11}>
              <TextField
                type="text"
                variant="outlined"
                size="small"
                value={item}
                placeholder={t`Nội dung gợi ý`}
                onChange={(e) => handleChange(index)(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item sm={1}>
              <IconButton onClick={handleRemove(index)}>
                <Close/>
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      ))}
      <Grid container item sm={12} justify="center">
        <Grid item sm={11}>
          <ButtonAdd label={t`Thêm gợi ý`} onClick={handleAdd}/>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ListConversationStarter;
