import { t } from '@lingui/macro';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { setState } from 'config/Core';
import { trans } from 'config/LinguiConfig';
import { setLogStyle, useLogStyle } from 'config/LogConfig';
import Button from 'MUI_Kit/components/CustomButtons/Button';
import React from 'react';
import { goBack } from 'router/helpers';
import { warningColor } from 'variables/color';
import DV from 'variables/DV';

const Transition = (props) => {
  return <Slide direction="up" {...props} />;
};

class BaseModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { open: false, title: '', loading: false };
    this.showCloseButton = true;
    this.goPathWhenClose = '';
    this.isSaveData = false;
    this.refreshOnClose = false;
    this.disableBackdropClick = false;
    this.slideDirection = 'up';
    this.isFullScreen = DV.withMobileDialog || this.fullScreen;

    console.log(useLogStyle + 'BaseModal', setLogStyle(warningColor));
  }

  close = (refreshData, willGoBack = true) => {
    const { history, onClose } = this.props;
    onClose && onClose();
    if (typeof this.onClose === 'function') this.onClose();

    this.setState({ open: false });

    if (willGoBack && this.goPathWhenClose && history) {
      if (refreshData) DV.reRender = !DV.reRender;
      goBack(history, this.goPathWhenClose);
    }
  };

  redirectTo = (pathname, state = null, search = '', isReplace = false) => {
    console.log('redirectTo', pathname, state);

    const action = isReplace ? this.props.history.replace : this.props.history.push;
    action.call(this.props.history, {
      pathname,
      search,
      state,
    });
  };

  redirectToModal = (pathname, state = null, search = '') => {
    console.log('redirectToModal', pathname, state);
    if (this.isSaveData) this.saveData();
    this.props.history.push({
      pathname: pathname,
      search,
      state: { isModal: true, ...state },
    });
  };

  open() {}

  handleChange = (name) => async (event) => {
    let value = event.target ? event.target.value : event;
    console.log('BaseModal handleChange value', { name, value });
    await setState(this)({
      [name]: value,
    });
  };

  renderBody() {}

  renderFooter() {}

  renderFooterLeft() {}

  getSaveData = () => {
    const { key } = this.props.location;
    if (!this.isSaveData) return {};
    let data = DV.store[key] || {};

    console.log(useLogStyle + 'getSaveData', setLogStyle(warningColor), data);
    return data;
  };

  saveData = () => {
    const { key } = this.props.location;
    DV.store[key] = this.state;
  };

  getTitle() {
    return this.state.title;
  }

  getDataFromRouter = () => (this.props.location && this.props.location.state) || {};

  render() {
    let { open, title, maxWidth } = this.state;
    maxWidth = maxWidth || this.maxWidth;

    return (
      <Dialog
        keepMounted={this.keepMounted}
        fullScreen={DV.withMobileDialog}
        classes={{ paper: !DV.withMobileDialog && DV.classes.paper }}
        disableBackdropClick={this.disableBackdropClick || this.props.loading}
        open={open}
        scroll="paper"
        TransitionComponent={Transition}
        onClose={() => this.close()}
        maxWidth={maxWidth || 'sm'}
        fullWidth>
        {title && (
          <DialogTitle style={{ padding: 24, paddingBottom: 10, fontSize: 18 }} className='font-sans'>
            <b>{this.getTitle()}</b>
          </DialogTitle>
        )}
        <DialogContent style={this.styleDialogContent || {}}>{this.renderBody()}</DialogContent>
        <DialogActions style={{ padding: 20, paddingTop: 10 }} className="flex-center-row-xs">
          {this.renderFooterLeft()}
          {this.showCloseButton && (
            <Button onClick={() => this.close(this.refreshOnClose)} color="danger" simple>
              {t`Đóng`}
            </Button>
          )}
          {this.renderFooter()}
        </DialogActions>
      </Dialog>
    );
  }
}

export default BaseModal;
